import fetchClient from "fetch-client";

export const getDocuments = (getState) =>
  fetchClient({
    path: `/librarydocuments`,
    getState,
  });

export const getLibraryDocument = (id, getState) =>
  fetchClient({
    path: `/librarydocuments/${id}`,
    getState,
  });

export const addDocument = (data, getState) => {
  return fetchClient({
    path: `/librarydocuments`,
    getState,
    method: `POST`,
    contentType: `form`,
    data,
  });
};

export const updateDocument = (data, getState) =>
  fetchClient({
    path: `/librarydocuments/${data.id}`,
    getState,
    method: `PUT`,
    contentType: `form`,
    data,
  });

export const deleteDocument = (id, getState) =>
  fetchClient({
    path: `/librarydocuments/${id}`,
    getState,
    method: `DELETE`,
    data: id,
  });

export const favouriteDocument = (data, getState) =>
  fetchClient({
    path: `/librarydocuments/${data.id}/favourite`,
    getState,
    method: `PUT`,
    data: data,
  });

export const downloadFile = (id, getState) =>
  fetchClient({
    path: `/librarydocuments/${id}/download`,
    getState,
    contentType: `file`,
  });

export const downloadLibraryDocumentVersion = (documentId, versionId, getState) =>
  fetchClient({
    path: `/librarydocuments/${documentId}/versions/${versionId}/download`,
    getState,
    contentType: `file`,
  });

export const getDocumentTypes = (getState) =>
  fetchClient({
    path: `/librarydocuments/types`,
    getState,
  });

export const deleteLibraryDocumentVersion = (documentId, versionId, getState) =>
  fetchClient({
    path: `/librarydocuments/${documentId}/versions/${versionId}`,
    getState,
    method: `DELETE`,
    data: versionId,
  });

import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import VisibilitySensor from "react-visibility-sensor/visibility-sensor";

import _ from "lodash";

import { getRandomInteger } from "utils/random";

import ImageMapTooltip from "../ImageMapTooltip/ImageMapTooltip";

const insidePolygon = require("point-in-polygon");

const PIXEL_RATIO = 2;
const OPACITY_TICK = 0.1;
const MAX_OPACITY = 0.87;

const getColor = (opacity) => {
  const _opacity = opacity > MAX_OPACITY ? MAX_OPACITY : opacity;
  return `rgba(255,254,171,${_opacity})`;
};

function BucLmsBuilderImageAreaMap(props) {
  const { image, clues, size, alignment } = props.block.attributes;
  const polygons = JSON.parse(props.block.attributes.polygons);

  const canvas = useRef(null);
  const canvasWrap = useRef(null);
  const imageObj = useMemo(() => new Image(), []);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [highlightedPolygon, setHighlightedPolygon] = useState(-1);
  const [showPolygons, setShowPolygons] = useState(false);

  const [latestClickedX, setLatestClickedX] = useState(0);
  const [latestClickedY, setLatestClickedY] = useState(0);

  const [isVisible, setIsVisible] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const drawImage = useCallback(
    (context) => {
      if (!canvas.current) return;
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
      // FIXME: This thoughs a warning. See: https://eslint.org/docs/rules/no-self-assign
      // Maybe set width to canvasWrap.current.clientWidth * PIXEL_RATIO ? -LR
      canvas.current.width = canvas.current.width * 1; // Clears the canvas
      context.scale(PIXEL_RATIO, PIXEL_RATIO);
      context.drawImage(imageObj, 0, 0, canvas.current.width / PIXEL_RATIO, canvas.current.height / PIXEL_RATIO);
    },
    [canvas, imageObj]
  );
  const initImage = useCallback(
    (context) => {
      setImageLoaded(false);
      const canvasWidth = canvasWrap.current.clientWidth;
      if (!canvasWidth) return;
      imageObj.onload = function () {
        const height = (this.height / this.width) * canvasWidth;
        canvas.current.setAttribute("width", canvasWidth * PIXEL_RATIO);
        canvas.current.setAttribute("height", height * PIXEL_RATIO);
        canvas.current.style.width = canvasWidth + "px";
        canvas.current.style.height = height + "px";
        setImageLoaded(true);
      };
      imageObj.src = `${image}?rnd=${getRandomInteger(1, 999999)}`;
    },
    [image, canvas, canvasWrap, imageObj, setImageLoaded]
  );

  const drawOverlay = useCallback((context) => {
    context.fillStyle = "#5c6d74";
    context.globalAlpha = 0.3;
    context.fillRect(0, 0, xFromPercent(100), yFromPercent(100));
    context.globalAlpha = 1.0;
  }, []);

  const drawPolygon = useCallback((context, points, opacity) => {
    context.fillStyle = getColor(opacity);
    context.strokeStyle = "transparent";
    context.lineWidth = 1;

    context.beginPath();
    context.moveTo(xFromPercent(points[0].x), yFromPercent(points[0].y));
    points.forEach((point) => {
      context.lineTo(xFromPercent(point.x), yFromPercent(point.y));
    });
    context.closePath();
    context.fill();
    context.stroke();
  }, []);

  const loop = useCallback(
    (context, frameCount) => {
      if (!imageLoaded) return;
      drawImage(context);
      if (highlightedPolygon > -1) {
        drawOverlay(context);
        drawPolygon(context, polygons[highlightedPolygon].points, MAX_OPACITY);
      } else if (clues && polygons.length && (showPolygons || (isVisible && !hasInteracted))) {
        polygons.forEach(({ points }, i) => {
          const op = Math.sin(frameCount * OPACITY_TICK);
          drawPolygon(context, points, op);
        });
      }
    },
    [
      imageLoaded,
      highlightedPolygon,
      polygons,
      clues,
      showPolygons,
      isVisible,
      hasInteracted,
      drawImage,
      drawOverlay,
      drawPolygon,
    ]
  );

  const onCanvasClick = (e) => {
    setHasInteracted(true);
    if (!imageLoaded) return;
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    let _highlightedPolygon = -1;

    polygons.forEach(({ points }, i) => {
      const arrOfArrs = points.map((point) => [xFromPercent(point.x), yFromPercent(point.y)]);
      if (_highlightedPolygon === -1 && insidePolygon([x, y], arrOfArrs)) {
        _highlightedPolygon = i;
      }
    });

    if (_highlightedPolygon === -1 && highlightedPolygon === -1) {
      setShowPolygons(true);
    } else if (highlightedPolygon > -1 && highlightedPolygon === _highlightedPolygon) {
      _highlightedPolygon = -1;
    }

    setHighlightedPolygon(_highlightedPolygon);
    setLatestClickedX(x);
    setLatestClickedY(y);
  };

  const xFromPercent = (val) => {
    return (val / 100) * (canvas.current.width / PIXEL_RATIO);
  };

  const yFromPercent = (val) => {
    return (val / 100) * (canvas.current.height / PIXEL_RATIO);
  };

  useEffect(() => {
    let timer;
    if (showPolygons) {
      timer = setTimeout(() => setShowPolygons(false), 700);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showPolygons]);

  useEffect(() => {
    const debouncedHandleResize = _.debounce(() => {
      if (!canvas.current) return;
      const context = canvas.current.getContext("2d");
      initImage(context);
    }, 500);

    const context = canvas.current.getContext("2d");
    initImage(context);

    window.addEventListener("resize", debouncedHandleResize);

    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [canvas, initImage]);

  useEffect(() => {
    const c = canvas.current;
    const context = c.getContext("2d");
    let frameCount = 0;
    let animationFrameId;

    const render = () => {
      frameCount++;
      loop(context, frameCount);

      if ((isVisible && !hasInteracted) || showPolygons) {
        animationFrameId = window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [loop, isVisible, hasInteracted, showPolygons]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (canvasWrap.current && !canvasWrap.current.contains(event.target)) {
        setHighlightedPolygon(-1);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [canvasWrap, setHighlightedPolygon]);

  useEffect(() => {
    let timer;
    if (isVisible && !hasInteracted) {
      timer = setTimeout(() => {
        setHasInteracted(true);
      }, 1500);
    }

    // If component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, hasInteracted, setHasInteracted]);

  const highlightedPolygonObj = highlightedPolygon > -1 ? polygons[highlightedPolygon] : null;

  const mainCls = "Fact-block--BucLmsBuilderImageAreaMap";
  const cls = `${mainCls} ${mainCls}--size${size} ${mainCls}--align${alignment}`;

  return (
    <div className={cls}>
      <VisibilitySensor
        onChange={(_isVisible) => {
          setIsVisible(_isVisible);
        }}
        partialVisibility={"top"}
        minTopValue={canvas.current ? canvas.current.height / PIXEL_RATIO / 2 : 100}>
        <div className="Fact-block--BucLmsBuilderImageAreaMap__canvaswrap" ref={canvasWrap}>
          <canvas ref={canvas} onClick={onCanvasClick} />
          {highlightedPolygonObj ? (
            <ImageMapTooltip
              x={latestClickedX}
              y={latestClickedY}
              heading={highlightedPolygonObj.heading}
              text={highlightedPolygonObj.text}
              closeFn={() => setHighlightedPolygon(-1)}
              canvasWidth={canvas.current.width / PIXEL_RATIO}
            />
          ) : null}
        </div>
      </VisibilitySensor>
    </div>
  );
}
export default BucLmsBuilderImageAreaMap;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { StartRoute } from "routes/Start";
import Loading from "components/Loading/Loading";

const StartRedirect = (props) => {
  const dispatch = useDispatch();

  const { user, isLoadingUser } = useSelector((state) => state.oidc);

  useEffect(() => {
    if (!isLoadingUser) {
      // console.log("user", user, isLoadingUser);

      if (user) {
        dispatch(push(StartRoute().path));
      } else {
        window.location.href = process.env?.REACT_APP_NOT_LOGGED_IN_REDIRECT;
      }
    }
  }, [user, isLoadingUser, dispatch]);

  return <Loading />;
};

export default StartRedirect;

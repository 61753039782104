import { useSelector } from 'react-redux';
import { useBlocksSaveable } from './use-blocks-saveable';
import {
  /*WAITING,*/ LOADING /*, NOT_FOUND*/
} from /* ERROR,*/ /*SUCCESS */ '../constants/network-states';

/**
 * useBlocksPublishable - Tests the current publishable state of the blocks in redux section state
 *
 * @param {boolean} [isPublished=false] Provides a way to stear the state externally
 *
 * @returns {array} An array containing parameters with the current publishable state
 */
export const useBlocksPublishable = (isPublished = false) => {
  const { blocks, saveSectionNetworkState, publishSectionNetworkState } = useSelector(
    (state) => state.sectionState
  );
  const [isSaveable] = useBlocksSaveable();
  const isPublishable =
    !isPublished &&
    Object.values(blocks).length &&
    !isSaveable &&
    saveSectionNetworkState !== LOADING &&
    publishSectionNetworkState !== LOADING;

  return [isPublishable];
};

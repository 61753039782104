import React from "react";
import { useDispatch } from "react-redux";
import { deleteDocument } from "actions/librarydocuments-actions";
import LayoutColumns from "components/LayoutColumns/LayoutColumns";
import __ from "constants/static-texts";

import { useParams, useHistory, useLocation } from "react-router-dom";
// import __ from "constants/static-texts";
import { Overlay } from "react-draft-editor";

const CLASS_NS = "form_overlay";
const DeleteDocument = ({ cancelUrl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const clickDelete = (e) => {
    e.preventDefault();
    dispatch(deleteDocument(parseInt(id)));

    history.push(state.cancelUrl || cancelUrl, { disableScroll: true });
  };
  const clickCancel = (e) => {
    e.preventDefault();

    history.push(state.cancelUrl || cancelUrl, { disableScroll: true });
  };

  return (
    <Overlay cancelUrl={state.cancelUrl || cancelUrl} disableScroll={true}>
      <div className={CLASS_NS}>
        <h5 className={`${CLASS_NS}_form-row_sub-header`}>{__.document.deleteConfirmText(state.documentTitle)}</h5>
        <LayoutColumns>
          <button className={`button-inverted`} onClick={clickCancel}>
            {__.document.deleteCancel}
          </button>
          <button className={`button-warning`} onClick={clickDelete}>
            {__.document.deleteConfirm}
          </button>
        </LayoutColumns>
      </div>
    </Overlay>
  );
};

export default DeleteDocument;

import React from "react";
import __ from "constants/static-texts";
import useLoggedInUser from "hooks/use-logged-in-user";
import RichText from "components/RichText/RichText";
import { ReactComponent as Pencil } from "icons/Pencil.svg";
import { getUniqueId } from "utils/random";
import CalendarsRoutes from "routes/Calendars";
import LayoutIcons from "components/LayoutIcons/LayoutIcons";
import LinkIcon from "components/LinkIcon/LinkIcon";

const CLASS_NS = "calendar-activity";

const CalendarActivity = ({
  calendarId,
  activity,
  classNs = CLASS_NS,
  idNs = getUniqueId().toLowerCase(),
  heading,
  text,
}) => {
  const { isCompanyReader } = useLoggedInUser();
  return (
    <article className={`${classNs}`}>
      <header className={`${classNs}_header`}>
        <div className={`${classNs}_heading-group`}>
          <h3 className={`${classNs}_heading`}>{heading}</h3>
          <span className={`${classNs}_sub-heading`}>Vecka {activity.weekNumber}</span>
        </div>
        {!isCompanyReader && (
          <LayoutIcons>
            <LinkIcon
              data-hide-in-export
              link={{
                pathname: `${CalendarsRoutes().path}/calendar/${calendarId}/activity/${activity.id}/edit`,
                state: { disableScroll: true },
              }}
              icon={<Pencil aria-hidden />}
              aria-label={__.calendar.editCalendarActivity}
            />
          </LayoutIcons>
        )}
      </header>
      <div className={`${classNs}_content`}>
        <div className={`${classNs}_text`}>
          <RichText nodes={text} headingLevel={4} />
        </div>
      </div>
    </article>
  );
};

export default CalendarActivity;

import React from "react";
import { Link } from "react-router-dom";

import SupervisorsRoute from "routes/Supervisors";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as Pencil } from "icons/Pencil.svg";
import { ReactComponent as Check } from "icons/check.svg";

import __ from "constants/static-texts";

const SupervisorListItem = ({ classNs, user }) => {
  const companiesInstances = (user.companyIds.length && (
    <Link
      data-tip={__.supervisors.showCompanies}
      data-for={`supervisor_list_${user.id}`}
      to={`${SupervisorsRoute().path}/${user.id}/companies`}>{`${user.companyIds.length} företag`}</Link>
  )) || <span>0 företag</span>;
  return (
    <li className={`${classNs}_list_item`}>
      <div>
        {!user.firstName && !user.lastName ? (
          <span className="not-set">{__.users.neverLoggedIn}</span>
        ) : (
          `${user.firstName} ${user.lastName}`
        )}
      </div>
      <div>{user.userName}</div>
      <div>{companiesInstances}</div>
      <div className="iconHolder">{user.details?.isOpenForNewAssignments && <Check className="iconCheck" />}</div>
      <div className="iconHolder">
        <Link
          to={{ pathname: `${SupervisorsRoute().path}/${user.id}/edit`, state: { disableScroll: true } }}
          data-tip={__.supervisors.editSupervisor}
          data-for={`supervisor_list_${user.id}`}>
          <Pencil className="pencil" />
        </Link>
      </div>
      <Tooltip theme={"dark"} id={`supervisor_list_${user.id}`} />
    </li>
  );
};

export default SupervisorListItem;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getComments } from "actions/comments-actions";

import { WAITING, LOADING } from "constants/network-states";

import { sortArray } from "utils/js-helpers";

/**
 * Provides the comments
 */
const useComments = (ids = []) => {
  const dispatch = useDispatch();
  const { comments, commentsNetworkState } = useSelector((state) => state.commentsState);

  const fetchDocuments = () => {
    comments.length === 0 && commentsNetworkState === WAITING && dispatch(getComments());
  };

  const commentsIsLoading = commentsNetworkState === WAITING || commentsNetworkState === LOADING;

  useEffect(() => {
    fetchDocuments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredComments = comments.filter((comment) => ids.includes(comment.id)).sort(sortArray("createdAt", false));

  return { comments, commentsIsLoading, filteredComments };
};

export default useComments;

import React, { useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "react-aria-menubutton";

import useCompanies from "hooks/use-companies";
import useLoggedInUser from "hooks/use-logged-in-user";

import { setActiveCompany } from "actions/companies-actions";
import { getGuide, getGuideMeta, resetGuide } from "actions/guide-actions";
import { setSearchQuery } from "actions/companies-actions";

import { StartRoute } from "routes/Start";

import Search from "components/Search/Search";
import ToolsMenuItem from "components/ToolsMenuItem/ToolsMenuItem";
import { ReactComponent as Company } from "icons/company.svg";

import { sortArray } from "utils/js-helpers";

import __ from "constants/static-texts";

const CLASS_NS = "company-list";

const CompanyList = ({ classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { filteredCompanies, activeCompany } = useCompanies();
  const { user, isMultiCompanyUser, isServiceAdmin, isSupervisor } = useLoggedInUser();

  const companyClick = useCallback((company) => {
    dispatch(setActiveCompany(company));
    if ((company && !isMultiCompanyUser) || (company && isMultiCompanyUser && company.isGuideOpen)) {
      if (!company.guideId) return;
      dispatch(getGuide(company.guideId));
      dispatch(getGuideMeta(company.guideId));
    } else {
      dispatch(resetGuide());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMultiCompanyUser && !activeCompany) {
      const primaryCompany = filteredCompanies.find((comp) => comp.id === user.companyIds[0]);

      companyClick(primaryCompany);
    }
  }, [filteredCompanies, user, activeCompany, companyClick, isMultiCompanyUser]);

  const resetItem = {
    label: __.companies.headerSelectorLabel,
    onClick: () => companyClick(null),
    path: StartRoute().path,
    id: -1,
  };

  const parsedFilteredCompanies = filteredCompanies
    .map((company) => {
      return {
        ...company,
        label: company.name,
        subLabel: !company.isGuideOpen && !isMultiCompanyUser ? "Guide ej aktiverad" : "",
        path: StartRoute().path,
        onClick: (company) => companyClick(company),
      };
    })
    .sort(sortArray("label"));

  const activeCompanyItem =
    parsedFilteredCompanies.find((company) => {
      return company.id === activeCompany?.id;
    }) || resetItem;

  const menuItems =
    activeCompanyItem.id > -1 && !isMultiCompanyUser
      ? [
          {
            ...resetItem,
            label: "Nollställ val",
          },
        ].concat(parsedFilteredCompanies)
      : parsedFilteredCompanies;

  const { searchQuery } = useSelector((state) => state.companiesState);

  const companySearchChange = (value) => {
    dispatch(setSearchQuery(value));
  };
  return (
    <ToolsMenuItem
      id="company"
      tip={activeCompanyItem.label}
      visible={isServiceAdmin || isSupervisor || isMultiCompanyUser}
      label={activeCompanyItem.label}
      isActive={activeCompanyItem.id > -1}
      Icon={Company}>
      <Search
        onChange={companySearchChange}
        searchQuery={searchQuery}
        placeholder={__.companies.searchList}
        compact={true}
      />
      <div className={`${classNs}_menu`}>
        {menuItems.map((item, index) => (
          <MenuItem className={`${classNs}_item`} key={`${classNs}-${index}`}>
            {item.pretitle ? <span className={`${classNs}_pretitle`}>{item.pretitle}</span> : null}
            <NavLink
              exact={item.path === StartRoute().path}
              onClick={() => item.onClick(item)}
              to={item.path}
              className={`${classNs}_link`}
              activeClassName="active"
              isActive={() => activeCompanyItem.id === item.id}>
              <div className={`${classNs}_linktext`}>
                <div className={`${classNs}_title`}>{item.label}</div>
                {item.subLabel && (
                  <div className={`${classNs}_progress`} data-type="italic">
                    {item.subLabel}
                  </div>
                )}
              </div>
            </NavLink>
          </MenuItem>
        ))}
        {(menuItems.length === 0 || (menuItems.length === 1 && activeCompanyItem.id !== -1)) && (
          <span className={`${classNs}_no-result`}>{__.companies.searchListNoFound}</span>
        )}
      </div>
    </ToolsMenuItem>
  );
};

export default CompanyList;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCalendarInstance } from "actions/calendar-actions";

import { WAITING, LOADING } from "constants/network-states";

/**
 * Provides a calendar by id
 */
const useCalendar = (calendarId) => {
  const dispatch = useDispatch();
  const { calendar, calendarNetworkState } = useSelector((state) => state.calendarState);

  const fetchCalendar = () => {
    if (calendar?.id !== calendarId || (calendar.length === 0 && calendarNetworkState === WAITING))
      dispatch(getCalendarInstance(calendarId));
  };

  const calendarIsLoading = calendarNetworkState === WAITING || calendarNetworkState === LOADING;

  useEffect(() => {
    fetchCalendar();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [calendar, calendarIsLoading];
};

export default useCalendar;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import ListBox from "components/ListBox/ListBox";
import __ from "constants/static-texts";
import useCalendar from "hooks/use-calendar";
import useBucEvents from "hooks/use-buc-events";
import useByggforetagenEvents from "hooks/use-byggforetagen-events";
import { getDateOfISOWeek, getMonthFromISOWeek, months } from "utils/date-helpers";
import { ReactComponent as ChevronUpIcon } from "icons/chevron-up.svg";
import { ReactComponent as ArrowRightIcon } from "icons/Arrow-Right.svg";
import CalendarsRoute from "routes/Calendars";
import TimeSpan from "components/TimeSpan/TimeSpan";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import LayoutIcons from "components/LayoutIcons/LayoutIcons";
import TypographyHeadingMeta from "components/TypographyHeadingMeta/TypographyHeadingMeta";
import TypographyMeta from "components/TypographyMeta/TypographyMeta";
import "./_MyActivities.scss";

const classNs = "my-activities";

const MyActivities = ({ calendarId }) => {
  const [calendar] = useCalendar(calendarId);
  const [bucEvents] = useBucEvents();
  const [byggforetagenEvents] = useByggforetagenEvents();
  const [activeMonth, setActiveMonth] = useState(new Date().getMonth());
  const BYGGFORETAGEN_PERMALINK_BASE = "https://ui.ungpd.com/Events/";

  const allActivities = [...bucEvents, ...byggforetagenEvents, ...(calendar?.activities ?? [])];

  const parseDate = (activity) => {
    switch (activity.bkmaType) {
      case "buc":
        return new Date(activity.time_start);
      case "byggforetagen":
        return new Date(activity.Start);
      default:
        return getDateOfISOWeek(activity.weekNumber);
    }
  };

  const filterMonth = (activity) =>
    activity.bkmaType === "buc"
      ? new Date(activity.time_start).getMonth() === activeMonth
      : activity.bkmaType === "byggforetagen"
      ? new Date(activity.Start).getMonth() === activeMonth
      : getMonthFromISOWeek(activity.weekNumber) === activeMonth;

  const sortedActivities = allActivities.sort((prev, next) => parseDate(prev) > parseDate(next));

  const changeMonth = (newMonth) => {
    setActiveMonth(newMonth > 11 ? 0 : newMonth < 0 ? 11 : newMonth);
  };

  const MyActivitiesTime = ({ activity }) => {
    switch (activity.bkmaType) {
      case "buc":
        return (
          <>
            <TimeSpan dateTimeStart={new Date(activity.time_start)} dateTimeEnd={new Date(activity.time_end)} />
            {activity?.city && <div className={`${classNs}_city`}>{activity.city}</div>}
          </>
        );
      case "byggforetagen":
        return <TimeSpan dateTimeStart={new Date(activity.Start)} dateTimeEnd={new Date(activity.End)} />;
      default:
        const dateTimeWeek = getDateOfISOWeek(activity.weekNumber);
        return <time dateTime={dateTimeWeek.toLocaleString()}>Vecka {activity.weekNumber}</time>;
    }
  };

  const MyActivitiesFilteredActivities = () => {
    const filteredActivities = sortedActivities.filter(filterMonth);
    return filteredActivities.length > 0 ? (
      filteredActivities.map((activity, i) => (
        <li className={`${classNs}_item`} key={i}>
          <div className={`${classNs}_main`}>
            <TypographyHeadingMeta
              headingLevel={3}
              heading={activity.title || activity.Name || ""}
              meta={<MyActivitiesTime activity={activity} />}
            />
            <span className={`${classNs}_label`}>
              {activity.bkmaType === "buc"
                ? __.myActivities.bucTag
                : activity.bkmaType === "byggforetagen"
                ? __.myActivities.byggforetagenTag
                : __.myActivities.calendarTag}
            </span>
          </div>
          {!activity.bkmaType ? (
            <Link to={`${CalendarsRoute().path}/calendar/${calendarId}#month-${activeMonth}`}>
              {__.myActivities.internalLink} <ArrowRightIcon aria-hidden />
            </Link>
          ) : (
            <a
              href={activity.permalink || `${BYGGFORETAGEN_PERMALINK_BASE}${activity.EventId}`}
              target="_blank"
              rel="noopener noreferrer">
              {__.myActivities.externalLink} <ArrowRightIcon aria-hidden />
            </a>
          )}
        </li>
      ))
    ) : (
      <li className={`${classNs}_item`}>
        <TypographyMeta>{__.myActivities.emptyState}</TypographyMeta>
      </li>
    );
  };

  return sortedActivities ? (
    <div className={`${classNs}`}>
      <ListBox
        title={__.myActivities.title}
        NodeType="ol"
        actions={
          <LayoutIcons>
            <ButtonIcon
              icon={<ChevronUpIcon aria-hidden data-toggle="decrement" />}
              aria-label={__.myActivities.decrementMonth}
              onClick={() => changeMonth(activeMonth - 1)}
            />
            <span className={`${classNs}_months`}>{months[activeMonth]}</span>
            <ButtonIcon
              icon={<ChevronUpIcon aria-hidden data-toggle="increment" />}
              aria-label={__.myActivities.incrementMonth}
              onClick={() => changeMonth(activeMonth + 1)}
            />
          </LayoutIcons>
        }>
        {sortedActivities.length ? <MyActivitiesFilteredActivities /> : null}
      </ListBox>
    </div>
  ) : null;
};

export default MyActivities;

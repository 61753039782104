import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { normalizeSectionsData } from "react-draft-editor";

import useCurrentPart from "hooks/useCurrentPart";

import NavList from "components/NavList/NavList";
// import CompleteIndicator from "./CompleteIndicator";

import { courseNavSchema } from "constants/navigation";

const CLASS_NS = "Course-section-chapter-nav";

function CourseSectionChapterNav({ classNs = CLASS_NS }) {
  const { chapterId } = useParams();
  const { currentPart } = useCurrentPart();
  const chapters = currentPart.blbCourseSectionChapterPosts;
  const params = useParams();
  const { workbookSlug, partId } = params;

  const linkFn = useCallback((id) => `/workbooks/${workbookSlug}/${partId}/${id}`, [workbookSlug, partId]);
  //TODO: fix replace hot fix
  const chapterData = useMemo(() => normalizeSectionsData(chapters, courseNavSchema, linkFn), [chapters, linkFn]).map(
    (navlink) => {
      return {
        ...navlink,
        link: navlink.link.replace("/undefined", "/" + navlink.id),
      };
    }
  );

  return (
    <NavList
      classNs={classNs}
      data={chapterData}
      activeId={chapterId}
      enumFn={null}
      AdditionalComponent={() => {
        return <div></div>;
      }}
    />
  );
}

export default CourseSectionChapterNav;

import React from "react";
import ToolsMenu from "components/ToolsMenu/ToolsMenu";
import MainNavigation from "./MainNavigation";

import { Link } from "react-router-dom";

import useInitCompany from "hooks/use-init-company";

import { StartRoute } from "routes/Start";
import { useSelector } from "react-redux";

const CLASS_NS = "main-header";

const MainHeader = ({ classNs = CLASS_NS }) => {
  const { user, isCompanyReader, isServiceAdmin, isSupervisor } = useSelector((state) => state.loggedInUserState);
  const { currentNonValidatedUser } = useSelector((state) => state.nonValidatedUsersState);

  const showNotifications = user && !isCompanyReader && !isServiceAdmin && !isSupervisor;

  useInitCompany();

  return (
    <header className={`${classNs}`}>
      <div className={`${classNs}_container`}>
        <div className={`${classNs}_main-nav`}>
          <Link to={StartRoute().path} className={`${classNs}_logo`}>
            <span>bkma</span>
          </Link>
          {user && <MainNavigation />}
        </div>
        {(user || currentNonValidatedUser) && (
          <ToolsMenu
            user={user && user}
            currentNonValidatedUser={currentNonValidatedUser && currentNonValidatedUser}
            showNotifications={showNotifications}
          />
        )}
      </div>
    </header>
  );
};

export default MainHeader;

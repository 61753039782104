import React from "react";
import { useParams, useHistory } from "react-router-dom";

import useGuides from "hooks/use-guides";
import useCompanies from "hooks/use-companies";

import { Overlay } from "react-draft-editor";

import { sortArray } from "utils/js-helpers";

const CLASS_NS = "form_overlay";
const GuideInstances = ({ cancelUrl }) => {
  const [guides] = useGuides();
  const history = useHistory();
  const { guideId } = useParams();
  const { companiesMap } = useCompanies();

  const currentGuide = guides.find((guide) => guide.id === guideId - 0);

  const clickCancel = (e) => {
    e.preventDefault();
    history.push(cancelUrl);
  };

  return (
    <Overlay cancelUrl={cancelUrl}>
      <div className={CLASS_NS}>
        <h2>{`${currentGuide.companyIdsHavingAnInstanceOriginatingFromThisBlueprint.length} företag använder ${currentGuide.title}`}</h2>
        <div className={"guides_instances"}>
          {currentGuide.companyIdsHavingAnInstanceOriginatingFromThisBlueprint
            .map((companyId) => companiesMap && companiesMap[companyId])
            .filter((company) => company)
            .sort(sortArray("name"))
            .map((company) => {
              return (
                <div className={"guides_instances_instance"} key={company.id}>
                  {company && company.name}
                </div>
              );
            })}
        </div>

        <div className={`${CLASS_NS}_actions button-row`}>
          <button className={`button`} onClick={clickCancel}>
            Stäng
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default GuideInstances;

/**
 * This template literal tag lets you assign slots with default values inside of the string,
 * somewhat like Python, Rust etc.
 * These can then be assigned new contextual content when the string is used.
 * Ex:
 * ```
 * const greetingTemplate = slotted`Hello ${`[lastName]`}, how are you?`;
 * const greeting = greetingTemplate('Picard');
 * // Output: 'Hello Picard';
 * ```
 *
 * @param {*} strings
 * @param  {...any} defaults
 * @returns {slotted~populateSlots}
 */

export const slotted = (strings, ...defaults) => {
  // Interleave function is taken from here: https://stackoverflow.com/questions/47061160/merge-two-arrays-with-alternating-values
  // It accepts two arrays and produces a new array with alternating values.
  const interleave = ([x, ...xs], ys = []) =>
    x === undefined
      ? ys // base: no x
      : [x, ...interleave(ys, xs)]; // inductive: some x

  const populateSlots = (...args) => {
    const combinedArgs =
      args.length < defaults.length
        ? [...args, ...defaults.slice(args.length - defaults.length)]
        : args.slice(0, defaults.length);
    return interleave(strings, combinedArgs.slice(0, defaults.length)).join("");
  };
  return populateSlots;
};

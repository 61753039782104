import React from "react";

import { getUrlForModuleId, InputField, SectionEditor } from "react-draft-editor";

import __ from "constants/static-texts";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GuideRequirementEditor from "./GuideRequirementEditor";
import GuideBlockBarLabel from "./GuideBlockBarLabel";

const CLASS_NS = "GuideMainRequirementEditor";

const GuideMainRequirementEditorContent = ({
  classNs = CLASS_NS,
  block,
  getValidationErrorForField,
  validate,
  onChange,
}) => {
  const onTitleChange = (e) => {
    onChange(e, block.id);
  };

  return (
    <form className={`${classNs}_section-form`}>
        <label htmlFor="title" className={`${classNs}_section-label`}>
          {__.guide.mainRequirement.main_requirement_label}
        </label>
        <InputField
          className={`${classNs}_section_textfield`}
          value={block?.title ?? ""}
          type="text"
          name="title"
          onChange={onTitleChange}
          onBlur={validate}
          maxLength="100"
          required
          data-errormessage={getValidationErrorForField("title")}
        />
    </form>
  );
};

const GuideMainRequirementEditor = ({ 
    classNs = CLASS_NS,
    block,
    blocksSelectorConfig,
    onSave,
    nonExpandedComponents,
}) => {
  const history = useHistory();
  const { blocks } = useSelector((state) => state.sectionState);
  const saveDocument = () => {
    // update state to set mainRequirementTitle on each chosen requirement
    onSave().then(() => {
      const parentSectionUrl = getUrlForModuleId(block.parentId, blocks);
      history.push(parentSectionUrl);
    });
  };

  return (
    <>
      <SectionEditor
        classNs={classNs}
        blockId={block.id}
        togglerComponents={[<GuideBlockBarLabel label={"Lägg till krav information"} />]}
        LeadContent={<GuideMainRequirementEditorContent />}
        customBlocks={[<GuideRequirementEditor
                        titleLabel="Rubrik"
                        descriptionLabel="Introduktion"
                        onSave={onSave}
                        blocksSelectorConfig={blocksSelectorConfig}
                      />]}
        blocksSelectorConfig={blocksSelectorConfig}
        nonExpandedComponents={nonExpandedComponents}
        />
      <div className={`${classNs}_footer`}>
        <button onClick={saveDocument} className={`${classNs}_footer_button`}>
          {__.guide.saveRequirement}
        </button>
      </div>
    </>
  );
};

GuideMainRequirementEditor.blockType = "guide-mainRequirement";

export default GuideMainRequirementEditor;

import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ModuleUpload from '../module-upload/module-upload';

import { saveImage } from '../../actions/section-actions';
import { getUploads, removeSectionImage } from '../../actions/image-actions';

const ModuleImage = ({ module = {} }) => {
  const dispatch = useDispatch();
  const { uploads } = useSelector((state) => state.uploadState);
  const previousRef = useRef({ uploads }).current;

  const availableUploads = useMemo(() => Object.values(uploads).filter((file) => file.imageUrl), [
    uploads
  ]);

  useEffect(() => {
    if (
      Object.keys(previousRef.uploads).length === Object.keys(uploads).length &&
      availableUploads().length <= 0
    ) {
      dispatch(getUploads());
    }
  }, [uploads, dispatch, availableUploads, previousRef.uploads]);

  const onSubmit = () => {
    dispatch(saveImage(module.id));
  };

  const onRemoveFile = () => dispatch(removeSectionImage(module.id));

  return (
    <ModuleUpload
      module={module}
      containerId={module.images.length ? module.images[0] : null}
      storeFileRefKey={'images'}
      textContext="image"
      availableUploads={availableUploads()}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
      acceptedFileTypes="image/*"
    />
  );
};

ModuleImage.propTypes = {
  module: PropTypes.object // Todo replace with moduleDataType,
};

export default ModuleImage;

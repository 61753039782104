import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  calendars: [],
  calendarsNetworkState: WAITING,
  deleteCalendarNetworkState: WAITING,
  error: null,
};

const calendarsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_CALENDARS_SUCCESS:
      return {
        ...state,
        calendars: payload,
        calendarsNetworkState: SUCCESS,
      };
    case types.GET_CALENDARS_LOADING:
      return {
        ...state,
        calendarsNetworkState: LOADING,
      };
    case types.GET_CALENDARS_ERROR:
      return {
        ...state,
        error: payload,
        calendarsNetworkState: ERROR,
      };
    case types.ADD_CALENDAR_SUCCESS:
      return {
        ...state,
        calendars: [...state.calendars, payload],
        calendarsNetworkState: SUCCESS,
      };
    case types.ADD_CALENDAR_LOADING:
      return {
        ...state,
        calendarsNetworkState: LOADING,
      };
    case types.ADD_CALENDAR_ERROR:
      return {
        ...state,
        error: payload,
        calendarsNetworkState: ERROR,
      };
    case types.UPDATE_CALENDAR_SUCCESS:
      return {
        ...state,
        calendars: state.calendars.map((calendar) => (calendar.id === payload.id ? payload : calendar)),
        calendarsNetworkState: SUCCESS,
      };
    case types.UPDATE_CALENDAR_LOADING:
      return {
        ...state,
        calendarsNetworkState: LOADING,
      };
    case types.UPDATE_CALENDAR_ERROR:
      return {
        ...state,
        error: payload,
        calendarsNetworkState: ERROR,
      };
    case types.DELETE_CALENDAR_LOADING:
      return {
        ...state,
        deleteCalendarNetworkState: LOADING,
      };
    case types.DELETE_CALENDAR_SUCCESS:
      return {
        ...state,
        calendars: state.calendars.filter((calendar) => calendar.id !== action.payload),
        deleteCalendarNetworkState: SUCCESS,
      };
    case types.DELETE_CALENDAR_ERROR:
      return {
        ...state,
        error: action.payload,
        deleteCalendarNetworkState: ERROR,
      };

    case types.ADD_CALENDAR_INSTANCE_SUCCESS:
      return {
        ...state,
        calendars: [...state.calendars, payload],
        calendarsNetworkState: SUCCESS,
      };
    case types.ADD_CALENDAR_INSTANCE_LOADING:
      return {
        ...state,
        calendarsNetworkState: LOADING,
      };
    case types.ADD_CALENDAR_INSTANCE_ERROR:
      return {
        ...state,
        error: payload,
        calendarsNetworkState: ERROR,
      };
    default:
      return state;
  }
};

export default calendarsReducer;

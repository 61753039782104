import { isEqualReact as equal } from '@react-hookz/deep-equal';
/**
 * uniqWithEqual - Filters out duplicates from an array. Ment to be a replacement for lodash's uniqWith
 *
 * @param {array} array Array which to perform the action
 *
 * @returns {type} Returns the new duplicate free array.
 */
export const uniqWithEqual = (array) =>
  array.length
    ? array.reduce((acc, item) => {
        // TODO: Test functionality. This is NOT production ready.
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, [])
    : [];

export const isObject = (val) => {
  if (val === null || Array.isArray(val)) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
};

// From https://stackoverflow.com/a/37837872/7197027
export const isIterable = (object) =>
  object != null && typeof object[Symbol.iterator] === 'function';

/**
 * isElement - Checks if the argument is a DOM element. from https://stackoverflow.com/a/36894871/7197027
 *
 * @param {object} element Argument to be tested
 *
 * @returns {Boolean} Result if the argument is a DOM element or not.
 */
export const isElement = (element) => element instanceof Element || element instanceof HTMLDocument;

/**
 * Checks if the value is a Number.
 * For a longer discussion on the solution see: https://medium.com/javascript-in-plain-english/how-to-check-for-a-number-in-javascript-8d9024708153
 */
export const isNumber = (val) => Number.isFinite(val);

/**
 * getDistinctItems - Compares the equality of the objects in two arrays. Object comparison is based on object id.
 *
 * @param {array} array1 First array
 * @param {array} array2 Second array
 * @param {string} key   Additional parameter to be used to search on the object key
 *
 * @returns {array} Returns the objects in the array which differs
 */
export const getDistinctItems = (array1, array2, key) => {
  let first, second;

  if (array1.length > array2.length) {
    first = array1;
    second = array2;
  } else {
    first = array2;
    second = array1;
  }
  return first.reduce((acc, item) => {
    const other = key ? second.find((s) => s[key] === item[key]) : second.find((s) => s === item);
    if (equal(other, item) === false) {
      acc = acc.concat([item]);
    }
    return acc;
  }, []);
};

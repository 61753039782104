import React from "react";
import PropTypes from "prop-types";

const CLASS_NS = "typography-dynamic-heading";

/**
 * Unstyled heading component that accepts different heading levels.
 * Very handy for components where you have no exact control of where
 * they appear in the document structure.
 */
const TypographyDynamicHeading = ({ classNs = CLASS_NS, level, children }) => {
  switch (level) {
    case 1:
      return <h1 className={classNs}>{children}</h1>;
    case 2:
      return <h2 className={classNs}>{children}</h2>;
    case 3:
      return <h3 className={classNs}>{children}</h3>;
    case 4:
      return <h4 className={classNs}>{children}</h4>;
    case 5:
      return <h5 className={classNs}>{children}</h5>;
    case 6:
      return <h6 className={classNs}>{children}</h6>;
    default:
      return null;
  }
};

TypographyDynamicHeading.propTypes = {
  classNs: PropTypes.string,
  /**
   * Renders as `h1`, `h2`, `h3` etc.
   */
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
};

export default TypographyDynamicHeading;

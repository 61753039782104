import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const CLASS_NS = "GuideChildLables";

export const GuideChildLabels = ({
    classNs = CLASS_NS,
    block: currentBlock
}) => {
    const { blocks } = useSelector((state) => state.sectionState);

    const childBlocks = useMemo(
        () => Object.entries(blocks)
            .filter(([blockId, block]) => block.parentId === currentBlock.id)
            .map(([blockId, block]) => block),
        [currentBlock, blocks]
      );

    return <div className={`${classNs}_child-labels_container`}>{childBlocks.map((block) => <p className={`${classNs}_child-labels_label`} key={block.id}>{block.title}</p>)}</div>
}
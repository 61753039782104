import Start from "./components/Start";
import StartRedirect from "./components/StartRedirect";

import { UserIsAuthenticated } from "utils/auth-helpers";

const StartRedirectRoute = () => ({
  path: "/",
  component: StartRedirect,
  exact: true,
});

const StartRoute = () => ({
  path: "/start",
  component: UserIsAuthenticated(Start),
  exact: true,
});

export { StartRedirectRoute, StartRoute };

import { useMemo } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import useWorkbooks from "hooks/use-workbooks";
import useWorkbook from "hooks/use-workbook";

/**
 * Provides the sub routes for the workbooks
 */
const useWorkbookParts = () => {
  const workbookUrl = "/workbooks/:workbookSlug/:partId?/:chapterId?";
  const routeMatch = useRouteMatch(workbookUrl);
  const { pathname } = useLocation();

  const [workbooks] = useWorkbooks();

  const workbookRoutes =
    (routeMatch &&
      workbooks?.length &&
      workbooks.map((workbook) => ({
        ...workbook.node,
        label: workbook.node.title,
        path: `/workbooks/${workbook.node.slug}`,
      }))) ||
    [];

  const [workbook] = useWorkbook(routeMatch?.params.workbookSlug);

  const activeWorkbookRoute = workbookRoutes.find((route) => pathname.startsWith(route.path)) || workbookRoutes[0];

  const workbookSections = useMemo(() => workbook?.blbGroupCourseSections?.blbCourseSections, [workbook]);

  const workbookSectionRoutes =
    (routeMatch &&
      workbookSections &&
      workbookSections.map((section) => ({
        ...section,
        label: section.blbCourseSectionTitle,
        path: `/workbooks/${routeMatch.params.workbookSlug}/${section.blbCourseSectionId}`,
      }))) ||
    [];
  const activeWorkbookSectionRoute = workbookSectionRoutes.find((route) => pathname.startsWith(route.path)) || {
    blbCourseSectionId: null,
  };

  if (routeMatch) {
    return {
      workbookRoutes,
      activeWorkbookRoute,
      workbookSectionRoutes,
      activeWorkbookSectionRoute,
    };
  }

  return {
    workbookRoutes: [],
    activeWorkbookRoute: null,
    workbookSectionRoutes: [],
    activeWorkbookSectionRoute: null,
  };
};

export default useWorkbookParts;

import React from "react";
import PropTypes from "prop-types";
import Tooltip from "components/Tooltip/Tooltip";
import { getUniqueId } from "utils/random";
import __ from "constants/static-texts";
import "./_ButtonIcon.scss";

/**
 * A button component that is just an icon. It must have either an `aria-label` or `aria-labelledby` attribute
 * The aria-label also becomes an automatic tooltip
 */
const ButtonIcon = ({ icon, type, idNs, size, ...props }) => {
  const label = props["aria-labelledby"] || props["aria-label"];
  return label ? (
    <>
      <button
        id={idNs}
        className="button-icon"
        type={type}
        data-tip={props["aria-label"] || null}
        data-for={props["aria-label"] ? `${idNs}_tooltip` : null}
        data-size={size}
        {...props}>
        {icon}
      </button>
      {props["aria-label"] && <Tooltip theme={"dark"} id={`${idNs}_tooltip`} />}
    </>
  ) : (
    console.warn(__.dev.ariaLabelMissing) || null
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  size: PropTypes.oneOf(["medium", "large", "dynamic"]),
  /**
   * Unique id is needed to tie the tooltip to the button.
   * Defaults to a unique id
   */
  idNs: PropTypes.string,
};

ButtonIcon.defaultProps = {
  type: "button",
  size: "medium",
  idNs: getUniqueId().toLowerCase(),
};

export default ButtonIcon;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBucEvents } from "actions/buc-actions";

import { WAITING, LOADING } from "constants/network-states";

const useBucEvents = () => {
  const dispatch = useDispatch();
  const { bucEvents, bucNetworkState } = useSelector((state) => state.bucState);

  const fetchBucEvents = () => {
    bucEvents.length === 0 && bucNetworkState === WAITING && dispatch(getBucEvents());
  };

  const bucIsLoading = bucNetworkState === WAITING || bucNetworkState === LOADING;

  useEffect(() => {
    fetchBucEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [bucEvents, bucIsLoading];
};

export default useBucEvents;

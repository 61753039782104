import { useState, useMemo, useEffect /*, useRef*/ } from 'react';
import { useSelector } from 'react-redux';
import { getDistinctItems } from '../utils/js-helpers';

import useBlockParams from './use-block-params';
import { SUCCESS } from '../constants/network-states';

/**
 * Hook for detecting if sections have changed, i.e. reindexed
 */
export const useSectionsChange = (sections) => {
  // const previousSections = useRef([]);
  const [previousSections, setPreviousSections] = useState([]);
  const { guideSaveNetworkState } = useSelector((state) => state.guideState);
  // const [changedSectionIds, setChangedSectionIds] = useState([]);
  const [sectionId] = useBlockParams();

  useEffect(() => {
    // Reset the previous blocks reference when the section changes
    // console.log('resetting blocks : ', sectionId);
    setPreviousSections(sections);
  }, [sectionId]); // eslint-disable-line

  useEffect(() => {
    // Initial state when sections are loaded
    if (previousSections.length === 0 && sections.length) {
      setPreviousSections(sections);
    }
  }, [sections, previousSections.length]);

  useEffect(() => {
    // reset on save
    if (guideSaveNetworkState === SUCCESS) {
      setPreviousSections(sections);
    }
  }, [guideSaveNetworkState]); // eslint-disable-line

  const changedSectionIds = useMemo(() => {
    if (previousSections.length === 0) {
      return [];
    }
    // Find out which sections differ from the previous
    return getDistinctItems(previousSections, sections, 'id').map((o) => o?.id);
  }, [sections, previousSections]);

  return [changedSectionIds];
};

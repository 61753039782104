import { guideSectionTypes } from "constants/guide-types";
import { guideRequirementData, guideMainRequirementData, guideResponseSlotData } from "utils/reused-initial-states";
// import { blockTypes } from "react-draft-editor";
import { ReactComponent as IconPlus } from "icons/plus.svg";
import __ from "constants/static-texts";

// depths is 0-based. For example, a depth level of 2 makes the module available at levels 0, 1 and 2.
export const guideSectionBlockSelector = [
  {
    key: guideSectionTypes.GUIDE_MAIN_REQUIREMENT,
    name: __.guide.mainRequirement.add_main_requirement,
    icon: IconPlus,
    // icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M8 1v14M1 8h14"/></svg>',
    depths: [1],
    initialStateFn: guideMainRequirementData,
  },
  {
    key: guideSectionTypes.GUIDE_REQUIREMENT,
    name: __.guide.requirement.add_requirement,
    icon: IconPlus,
    // icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M8 1v14M1 8h14"/></svg>',
    depths: [2],
    initialStateFn: guideRequirementData,
  },
  {
    key: guideSectionTypes.GUIDE_RESPONSE_SLOT,
    name: __.guide.responseslot.add_responseslot,
    icon: IconPlus,
    // icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M8 1v14M1 8h14"/></svg>',
    depths: [3],
    initialStateFn: guideResponseSlotData,
  },
  // {
  //   key: blockTypes.RICHTEXT,
  //   name: "Text",
  //   icon: '<svg class="icon-text" width="94" height="94" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><g fill="currentColor"><path d="M63.75 33.5h-37.5a1.25 1.25 0 0 1 0-2.5h37.5a1.25 1.25 0 0 1 0 2.5zM53.75 41h-27.5a1.25 1.25 0 0 1 0-2.5h27.5a1.25 1.25 0 0 1 0 2.5zM63.75 48.5h-37.5a1.25 1.25 0 0 1 0-2.5h37.5a1.25 1.25 0 0 1 0 2.5zM53.75 56h-27.5a1.25 1.25 0 0 1 0-2.5h27.5a1.25 1.25 0 0 1 0 2.5zM63.75 63.5h-37.5a1.25 1.25 0 0 1 0-2.5h37.5a1.25 1.25 0 0 1 0 2.5z"/></g></g></svg>',
  //   depths: [2],
  // },
];

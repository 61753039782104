import useCurrentPart from "hooks/useCurrentPart";
import { useParams } from "react-router-dom";

function useCurrentChapter() {
  const { chapterId } = useParams();

  const { currentPart } = useCurrentPart();

  const currentChapter =
    currentPart?.blbCourseSectionChapterPosts.find((chapter) => {
      return chapter.blbChapterId === chapterId;
    }) || currentPart?.blbCourseSectionChapterPosts[0];

  const chapterNumber = currentPart?.blbCourseSectionChapterPosts.findIndex((chapter) => {
    return chapter.blbChapterId === currentChapter.blbChapterId;
  });

  return {
    currentChapter: currentChapter ? { ...currentChapter, chapterNumber: chapterNumber + 1 } : null,
  };
}

export default useCurrentChapter;

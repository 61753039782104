// https://usehooks.com/useWindowSize/

import { useState, useEffect } from "react";
import { LARGE_DESKTOP_LAYOUT_BREAKPOINT, DESKTOP_LAYOUT_BREAKPOINT, TABLET_LAYOUT_BREAKPOINT } from "constants/course";

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,

      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const isLargeDesktop = windowSize.width >= LARGE_DESKTOP_LAYOUT_BREAKPOINT;
  const isSmallDesktop = !isLargeDesktop && windowSize.width >= DESKTOP_LAYOUT_BREAKPOINT;
  const isDesktop = isSmallDesktop || isLargeDesktop;
  const isTablet = !isDesktop && windowSize.width >= TABLET_LAYOUT_BREAKPOINT;
  const isMobile = !isDesktop && !isTablet;
  return {
    windowSize,
    isLargeDesktop,
    isSmallDesktop,
    isDesktop,
    isTablet,
    isMobile,
  };
}

export default useWindowSize;

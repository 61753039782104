import React from 'react';

// Article on Error boundry: https://css-tricks.com/handling-errors-with-error-boundary/

class ErrorBoundry extends React.Component {
  static defaultProps = {
    classNs: 'error-boundry'
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={`${this.props.classNs}`}>
          <h3 className={`${this.props.classNs}_title`}>Något gick fel</h3>
          <p className={`${this.props.classNs}_error`}>Felet: {this.state.error.toString()}</p>
          <p className={`${this.props.classNs}_position`}>
            ...hände i komponenten: {this.state.info.componentStack}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundry;

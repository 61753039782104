import React /*, { useState, useEffect }*/ from 'react';
import { /*useSelector,*/ useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { updateSectionDetails } from '../../actions/section-actions';

import ContentEditor from '../content-editor/content-editor';

const CLASS_NS = 'block-richtext';
const CONTENT_FIELD_NAME = 'richText';
/*
 * Provides a rich text content editor to edit the context dependent content.
 */
export const ModuleRichText = ({
  classNs = CLASS_NS,
  block,
  updateModuleContent = () => {},
  plugins,
  Toolbar,
  contentFieldName = CONTENT_FIELD_NAME // Could be set to a custom named field if the component is wrapped by a custom component. Otherwise the block data should be transformed.
}) => {
  const dispatch = useDispatch();
  const onValidate = (isValid) => {
    dispatch(updateSectionDetails(block.id, { isValid: isValid }));
  };

  const onEditorChange = (editorStateData) =>
    updateModuleContent(editorStateData, block, contentFieldName);

  // tools={tools}
  return (
    <div className={`${classNs}`}>
      <ContentEditor
        content={block?.[contentFieldName]}
        blockId={block?.id ?? 1}
        onChange={onEditorChange}
        isRequired={true}
        onValidate={onValidate}
        plugins={plugins}
        Toolbar={Toolbar}
      />
    </div>
  );
};

ModuleRichText.propTypes = {
  classNs: PropTypes.string,
  block: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    index: PropTypes.number,
    content: PropTypes.string
  }),
  blockIndex: PropTypes.number,
  updateModuleContent: PropTypes.func
};

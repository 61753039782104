import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CLASS_NS = "NavList";

const NavList = ({
  classNs = CLASS_NS,
  data = [],
  activeId = data?.[0]?.id,
  enumFn = (index) => `#${index + 1}`,
  AdditionalComponent,
}) => {
  return (
    <nav className={`${classNs}`}>
      <ul className={`${classNs}_list`}>
        {data.map((item, index) => (
          <li className={`${classNs}_item`} key={index}>
            <Link to={item.link} className={`${classNs}_link`} data-active={activeId === item.id}>
              <span className={`${classNs}_text`}>
                {typeof enumFn === "function" ? <span className={`${classNs}_enum`}>{enumFn(index)}</span> : null}
                <span className={`${classNs}_title`}>{item.title}</span>
              </span>
              {AdditionalComponent ? <AdditionalComponent classNs={classNs} navObj={item} /> : null}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

NavList.propTypes = {
  classNs: PropTypes.string,
};

export default NavList;

import { guideSectionTypes } from "constants/guide-types";
import { randomDecimal } from "react-draft-editor";

import { guideTempIdConverter } from "utils/normalize";
import __ from "constants/static-texts";

export const normalizedDraftData = function () {
  return {
    title: "",
    sections: [],
  };
};

export const guidePartData = function () {
  return {
    type: guideSectionTypes.GUIDE_PART,
    parentId: null,
    index: null,
    title: __.guide.part.part_title_default,
  };
};

export const guideSectionData = function () {
  return {
    type: guideSectionTypes.GUIDE_SECTION,
    parentId: null,
    index: 0,
    title: __.guide.section.section_title_default,
  };
};

export const guideRequirementData = ({ parentId, index = 0, selectorObj }) => {
  const id = selectorObj ? guideTempIdConverter(selectorObj) : randomDecimal();
  return {
    type: guideSectionTypes.GUIDE_REQUIREMENT,
    index: index,
    id: id,
    tempId: id,
    parentId: parentId,
    title: "",
    description: "",
    recommendedRequirementDocumentIds: [],
    responseSlots: [],
    isDone: false,
    isEnabled: true,
    assignedTo: null,
    assignmentDeadline: null,
    commentIds: [],
    
  };
};

export const guideMainRequirementData = ({ parentId, index = 0, selectorObj }) => {
  const id = selectorObj ? guideTempIdConverter(selectorObj) : randomDecimal();
  return {
    type: guideSectionTypes.GUIDE_MAIN_REQUIREMENT,
    index: index,
    id: id,
    tempId: id,
    parentId: parentId,
    title: "",
    isEnabled: true,
  };
}

export const guideResponseSlotData = ({ parentId, index = 0, selectorObj }) => {
  const id = selectorObj ? guideTempIdConverter(selectorObj) : randomDecimal();
  return {
    type: guideSectionTypes.GUIDE_RESPONSE_SLOT,
    index: index,
    id: id,
    tempId: id,
    parentId: parentId,
    title: "",
    description: "",
  };
};

import { useSelector, useDispatch } from "react-redux";
import { getLoggedInUser } from "actions/logged-in-user-actions";
import { getCurrentNonValidatedUser } from "actions/non-validated-users-actions";

import { trigger500error, trigger403error } from "actions/debug-actions";

import { WAITING, LOADING, ERROR, FORBIDDEN } from "constants/network-states";

import {
  isServiceAdmin,
  isCompanyAdmin,
  isSupervisor,
  isCompanyEditor,
  isCompanyReader,
  isCompanyUser,
} from "utils/user-helper";

/**
 * Provides the documents list
 */
const useLoggedInUser = () => {
  const dispatch = useDispatch();
  const { user, loggedInUserNetworkState, error } = useSelector((state) => state.loggedInUserState);
  const { currentNonValidatedUser, currentNonValidatedUserNetworkState } = useSelector(state => state.nonValidatedUsersState);
  const { statusMessage } = useSelector((state) => state.layoutState);
  const { user: oidcUser } = useSelector((state) => state.oidc);

  const userIsLoading = loggedInUserNetworkState === WAITING || loggedInUserNetworkState === LOADING;
  const userIsUnauthorized = loggedInUserNetworkState === ERROR && error?.status === 401;

  
  const fetchUser = () => {
    dispatch(getLoggedInUser());
    
    const __DEBUG__ = false;
    
    if (__DEBUG__) {
      !statusMessage.isOpen && dispatch(trigger500error());
      !statusMessage.isOpen && dispatch(trigger403error());
    }
  };
  
  if (oidcUser 
    && loggedInUserNetworkState === WAITING
    && loggedInUserNetworkState !== LOADING
    && loggedInUserNetworkState !== ERROR
    && !user) {
      fetchUser();
    }
    
  if(loggedInUserNetworkState === FORBIDDEN
    && currentNonValidatedUserNetworkState === WAITING
    && currentNonValidatedUserNetworkState !== LOADING
    && currentNonValidatedUserNetworkState !== ERROR
    && !currentNonValidatedUser){
      dispatch(getCurrentNonValidatedUser())
    }
  
  return {
    user,
    userIsLoading,
    userIsUnauthorized,
    isServiceAdmin: isServiceAdmin(user),
    isCompanyAdmin: isCompanyAdmin(user),
    isSupervisor: isSupervisor(user),
    isCompanyEditor: isCompanyEditor(user),
    isCompanyReader: isCompanyReader(user),
    isCompanyUser: isCompanyUser(user),
    isMultiCompanyUser: isCompanyUser(user) && user.companyIds.length > 1,
    loggedInUserNetworkState: loggedInUserNetworkState,
  };
};
    
export default useLoggedInUser;

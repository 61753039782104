import React from "react";

// import SvgIcon from "components/svg-icon/svg-icon";

const Loading = ({ text = "Laddar ...", variant }) => {
  return (
    <div className="Loader" data-variant={variant}>
      <div className="inner">
        <div className="spinner"></div>
        {text && <span>{text}</span>}
      </div>
    </div>
  );
};

export default Loading;

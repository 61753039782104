import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useForm from "hooks/use-form";
import { updateCompanyDocument, downloadCompanyFile } from "actions/companydocuments-actions";
import __ from "constants/static-texts";
import LayoutColumns from "components/LayoutColumns/LayoutColumns";
import ButtonLink from "components/ButtonLink/ButtonLink";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";
import { ReactComponent as TrashIcon } from "icons/trash.svg";

const CLASS_NS = "form_overlay";

const EditCompanyDocumentEdit = ({
  companyDocument,
  onCancelClick,
  clickVersions,
  clickDelete,
  onSaveClick,
  canEditVisibilty = false,
}) => {
  const [showUpload, setShowUpload] = useState(false);
  const dispatch = useDispatch();

  const { inputs, handleChange, reInit } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...companyDocument,
      file: "",
    }
  );

  useEffect(() => {
    reInit({
      ...companyDocument,
      file: "",
    });
  }, [companyDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCompanyDocument({ ...inputs }));
    onSaveClick();
  };

  const downloadClick = () => {
    dispatch(downloadCompanyFile(companyDocument));
  };

  const toggleUpload = () => {
    setShowUpload(!showUpload);
  };

  return (
    <>
      <h2>{__.document.editDoc}</h2>
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        <label className={`${CLASS_NS}_form-row`} htmlFor="title">
          <span className="label">{__.document.title_label}</span>
          <input
            type="text"
            id="title"
            name="title"
            placeholder={__.placeholder.documentName}
            value={inputs.title}
            onChange={handleChange}
          />
        </label>
        {showUpload ? (
          <label className={`${CLASS_NS}_form-row`} htmlFor="file">
            <span className="label">{__.document.newVersion}</span>
            <input type="file" id="file" name="file" onChange={handleChange} />
          </label>
        ) : (
          <div className={`${CLASS_NS}_form-row`}>
            <span className="label">
              {__.document.file}{" "}
              <ButtonLink type="button" size="small" onClick={clickVersions}>
                {__.document.versions(companyDocument.versions.length)}
              </ButtonLink>
            </span>
            <LayoutColumns maxWidth="max-content">
              <ButtonLink type="button" size="small" variant="neutral" onClick={downloadClick}>
                {`${companyDocument.versions[0].fileName}`}
              </ButtonLink>
              <ButtonLink size="small" variant="neutral" onClick={toggleUpload} type="button">
                ({__.document.newVersionButton})
              </ButtonLink>
            </LayoutColumns>
          </div>
        )}

        {canEditVisibilty && (
          <div className={`${CLASS_NS}_form-row`}>
            <ChoiceGroup
              onChange={handleChange}
              chosen={inputs.isForManagementOnly ? "1" : "0"}
              type="checkbox"
              inversed={true}
              groupName="isForManagementOnly"
              choices={[
                {
                  value: "1",
                  label: __.upload.managementOnly,
                  name: "isForManagementOnly",
                },
              ]}
            />
          </div>
        )}
        <div className={`${CLASS_NS}_form-row`} data-align="right">
          <ButtonLink icon={<TrashIcon aria-hidden />} variant="action" size="small" onClick={clickDelete}>
            {__.document.delete}
          </ButtonLink>
        </div>
        <button type="submit" className={`${CLASS_NS}_button`}>
          Uppdatera
        </button>
      </form>
    </>
  );
};

export default EditCompanyDocumentEdit;

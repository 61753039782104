import React from "react";
import PropTypes from "prop-types";
import { toDate } from "utils/date-helpers";

/**
 * Accepts a start time and end time and creates a semantic time span
 */
const TimeSpan = ({ dateTimeStart, dateTimeEnd }) => {
  const parsedStartDate = toDate(dateTimeStart);
  const parsedEndDate = toDate(dateTimeEnd);

  return (
    <>
      <time dateTime={parsedStartDate}>
        {parsedStartDate.toLocaleString("sv-SE", {
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </time>{" "}
      -{" "}
      <time dateTime={parsedEndDate}>
        {parsedEndDate.toLocaleString("sv-SE", {
          hour: "numeric",
          minute: "numeric",
        })}
      </time>
    </>
  );
};

TimeSpan.propTypes = {
  dateTimeStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  dateTimeEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
};

export default TimeSpan;

import React from "react";

import SupervisorOverviewListItem from "./SupervisorOverviewListItem";

function SupervisorOverviewList({ users, classNs }) {
  const hasLoggedIn = ({ firstName, lastName }) => [firstName, lastName].every((name) => name !== null);

  return (
    <ul className={`${classNs}_list`}>
      {users.map((user) =>
        hasLoggedIn(user) && user.details?.isOpenForNewAssignments ? (
          <SupervisorOverviewListItem key={user.id} user={user} classNs={classNs} />
        ) : null
      )}
    </ul>
  );
}

export default SupervisorOverviewList;

import React from "react";
import "./_TypographyMeta.scss";

const CLASS_NS = "typography-meta";

const TypographyMeta = ({ as = "span", children }) => {
  const Element = as;
  return <Element className={CLASS_NS}>{children}</Element>;
};

export default TypographyMeta;

import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS, NOT_FOUND, FORBIDDEN } from "constants/network-states";

const initialState = {
  user: null,
  loggedInUserNetworkState: WAITING,
};

const loggedInUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOGGED_IN_USER_LOADING:
      return {
        ...state,
        loggedInUserNetworkState: LOADING,
      };
    case types.GET_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loggedInUserNetworkState: SUCCESS,
      };
    case types.GET_LOGGED_IN_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loggedInUserNetworkState: ERROR,
      };
    case types.GET_LOGGED_IN_USER_NOT_FOUND:
        return {
          ...state,
          loggedInUserNetworkState: NOT_FOUND,
        }
    case types.GET_LOGGED_IN_USER_FORBIDDEN:
      return {
        ...state,
        loggedInUserNetworkState: FORBIDDEN,
      }

    default:
      return state;
  }
};

export default loggedInUserReducer;

export const isDecimal = (num) => Math.floor(num) !== num;

// export const randomDecimal = (min = 0.0001, max = 10000, decimalPoints = 3) => {
//   const num =
//     Math.floor((Math.random() * (max - min) + min) * Math.pow(10, decimalPoints)) /
//     Math.pow(10, decimalPoints);
//   // Check if the generated really is a decimal. Otherwise try again
//   return isDecimal(num) ? num : randomDecimal(min, max, decimalPoints);
// };

export const randomDecimal = (decimalPoints = 4) => {
  const max = Math.pow(10, decimalPoints);
  const min = 0;
  const num = Math.floor(Math.random() * (max - min) + min) / Math.pow(10, decimalPoints);
  return num > 0 ? num : randomDecimal();
};

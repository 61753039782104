import React, { useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { approveNonValidatedUser, blockNonValidatedUser } from 'actions/non-validated-users-actions';

import __ from 'constants/static-texts';
import { NON_VALIDATED_USER_STATUSES } from 'constants/non-validated-user-statuses';
import { USER_TYPES } from 'constants/user-types';
import Dropdown from 'components/Dropdown/Dropdown';
import { Overlay } from 'react-draft-editor';
import TypographyMeta from 'components/TypographyMeta/TypographyMeta';
import useForm from 'hooks/use-form';
import { getUserName } from 'utils/user-helper';
import { getNonValidatedUsers } from '../../../actions/non-validated-users-actions';
import { getUsers } from '../../../actions/users-actions';

const CLASS_NS = 'form_overlay';
const EditNonValidatedUser = ({
  cancelUrl,
  users: neverUsedHere,
  userRoles,
  nonValidatedUsersWithCompanyNames: nonValidatedUsers,
}) => {
  const actionTypes = {
    IS_EDITING: 'IS_EDITING',
    NEW_ASSIGNMENT_NOT_ALLOWED: 'NEW_ASSIGNMENT_NOT_ALLOWED',
    SET_NEW_ASSIGNEE: 'SET_NEW_ASSIGNEE',
    IS_DELETING: 'IS_DELETING',
  };

  const localReducer = (currentState, action) => {
    switch (action.type) {
      case actionTypes.IS_EDITING:
        return { ...currentState, editState: action.type };
      case actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED:
        return { ...currentState, editState: action.type };
      case actionTypes.EXIT_DELETING:
        return { ...currentState, deleteState: null };
      default:
        return { ...currentState };
    }
  };

  const [localState, dispatchLocalState] = useReducer(localReducer, {
    editState: actionTypes.IS_EDITING,
    deleteState: null,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const { activeCompany } = useSelector((state) => state.companiesState);
  const currentUser = nonValidatedUsers.find((user) => user.id === parseInt(userId));
  const isPendingUser =
    currentUser.companyValidationStatuses &&
    !!currentUser.companyValidationStatuses?.find(
      (validationStatus) =>
        validationStatus.companyId === activeCompany.id &&
        validationStatus.status === NON_VALIDATED_USER_STATUSES.PENDING
    );

  // Form hook for edit user form
  const { inputs, handleChange } = useForm({
    ...currentUser,
    role: currentUser.role,
    newAssigneeUserId: null,
  });

  const dispatchAndClose = async (updatedUserData) => {
    if (updatedUserData.role !== NON_VALIDATED_USER_STATUSES.BLOCKED)
      await dispatch(
        approveNonValidatedUser({ id: updatedUserData.id, role: updatedUserData.role, companyId: activeCompany.id })
      );
    if (isPendingUser && updatedUserData.role === NON_VALIDATED_USER_STATUSES.BLOCKED)
      await dispatch(blockNonValidatedUser({ nonValidatedUserId: updatedUserData.id, companyId: activeCompany.id }));
    dispatch(getNonValidatedUsers());
    dispatch(getUsers());
    history.push(cancelUrl, { disableScroll: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (localState.editState) {
      case actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED:
        return;
      case actionTypes.IS_EDITING:
        const { newAssigneeUserId, ...strippedInputs } = inputs;
        dispatchAndClose(strippedInputs);
        return;
      default:
        return;
    }
  };

  const usableRolesOptions = [
    {
      id: 'UNASSIGNED',
      text: 'Välj en roll',
    },
  ];
  userRoles
    .filter((role) => {
      const currentRole = Object.values(USER_TYPES).find((roleItem) => {
        return role.id === roleItem.id;
      });
      return (
        (role.id !== USER_TYPES.SUPERVISOR.id &&
          role.id !== USER_TYPES.SERVICEADMIN.id &&
          role.privilege >= currentRole.privilege) ||
        (isPendingUser && role.id === USER_TYPES.BLOCKED.id)
      );
    })
    .map((role) =>
      usableRolesOptions.push({
        id: role.id,
        text: __.usertypes[role.name],
      })
    );

  const multipleCompanies = currentUser.companyNames.length > 1;

  const handleChangeRole = (e) => {
    handleChange(e);
    dispatchLocalState({ type: actionTypes.IS_EDITING });
  };

  const handleCancel = () => {
    cancelUrl && history.push(cancelUrl);
  };

  const EditUserIsEditing = () => (
    <>
      <div className={`${CLASS_NS}_header`}>
        <h2>{__.users.editUser(getUserName(currentUser))}</h2>
      </div>
      {multipleCompanies && <TypographyMeta as="p">{__.users.multipleCompanies}</TypographyMeta>}
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        <Dropdown
          value={inputs.role || ''}
          options={usableRolesOptions}
          label={__.document.productType}
          onChange={handleChangeRole}
          classNs={`${CLASS_NS}_dropdown`}
          id={`role`}
          labelPos="top"
        />
        {localState.editState === actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED && (
          <p>{__.users.multipleAssignmentToReader}</p>
        )}

        <div className="button-row">
          <button type="button" className="button-inverted" onClick={handleCancel}>
            {__.general.cancel}
          </button>
          <button
            type="submit"
            className="button"
            disabled={
              localState.editState === actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED ||
              inputs.role === usableRolesOptions[0].id ||
              !inputs.role
            }>
            {__.users.editUserSubmit}
          </button>
        </div>
      </form>
    </>
  );

  return currentUser?.id === parseInt(userId) ? (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <EditUserIsEditing />
    </Overlay>
  ) : null;
};

export default EditNonValidatedUser;

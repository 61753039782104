import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getByggforetagenEvents } from "actions/byggforetagen-actions";

import { WAITING, LOADING } from "constants/network-states";

const useByggforetagenEvents = () => {
  const dispatch = useDispatch();
  const { byggforetagenEvents, byggforetagenNetworkState } = useSelector((state) => state.byggforetagenState);

  const fetchByggforetagenEvents = () => {
    byggforetagenEvents.length === 0 && byggforetagenNetworkState === WAITING && dispatch(getByggforetagenEvents({'surpressStatusMessage': true}));
  };

  const byggforetagenIsLoading = byggforetagenNetworkState === WAITING || byggforetagenNetworkState === LOADING;

  useEffect(() => {
    fetchByggforetagenEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [byggforetagenEvents, byggforetagenIsLoading];
};

export default useByggforetagenEvents;

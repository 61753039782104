import React from "react";
import PropTypes from "prop-types";
import LayoutIcons from "components/LayoutIcons/LayoutIcons";
import "./_ListItem.scss";

function ListItem({ variant, actions, children }) {
  return (
    <li className="list-item" data-variant={variant}>
      {children}
      {actions && <LayoutIcons>{actions}</LayoutIcons>}
    </li>
  );
}

ListItem.propTypes = {
  actions: PropTypes.node,
  variant: PropTypes.oneOf(["standard"]),
};

ListItem.defaultProps = {
  variant: "standard",
};

export default ListItem;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import __ from 'constants/static-texts';
import Search from 'components/Search/Search';
import { setSearchQuery } from 'actions/companies-actions';
import SubHeader from 'components/SubHeader/SubHeader';
import Loading from 'components/Loading/Loading';

import CompaniesList from './CompaniesList';
import useCompanies from 'hooks/use-companies';
import useProductTypes from 'hooks/use-productTypes';
import useSupervisors from 'hooks/use-supervisors';
import useGuides from 'hooks/use-guides';
import useCalendars from 'hooks/use-calendars';

const CLASS_NS = 'companies';

const Companies = ({ route, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { filteredCompanies, companiesIsLoading } = useCompanies();

  const { productTypesIsLoading } = useProductTypes();
  const { supervisorsAreLoading } = useSupervisors();

  const [guides, guidesIsLoading] = useGuides();
  const { calendarsIsLoading } = useCalendars();

  const { searchQuery } = useSelector((state) => state.companiesState);

  const companySearchChange = (value) => {
    dispatch(setSearchQuery(value));
  };

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{__.companies.company}</h1>
        <p className={`${classNs}_description`}>{__.companies.description}</p>
      </SubHeader>
      <div className={`${classNs}`}>
        <Search onChange={companySearchChange} searchQuery={searchQuery} placeholder={__.companies.search} />
        {(supervisorsAreLoading || productTypesIsLoading || companiesIsLoading || guidesIsLoading) && <Loading />}
        {!supervisorsAreLoading && !guidesIsLoading && (
          <CompaniesList companies={filteredCompanies} guides={guides} classNs={classNs} />
        )}
        {!productTypesIsLoading &&
        !supervisorsAreLoading &&
        !guidesIsLoading &&
        !companiesIsLoading &&
        !calendarsIsLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
            })
          : null}
      </div>
    </>
  );
};

export default Companies;

import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  workbook: null,
  workbookNetworkState: WAITING,
  error: null,
  activeGuideSection: null,
};

const workbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WORKBOOK_LOADING:
      return {
        ...state,
        workbookNetworkState: LOADING,
      };
    case types.GET_WORKBOOK_SUCCESS:
      return {
        ...state,
        workbook: action.payload,
        workbookNetworkState: SUCCESS,
      };
    case types.GET_WORKBOOK_ERROR:
      return {
        ...state,
        error: action.payload,
        workbookNetworkState: ERROR,
      };
    default:
      return state;
  }
};

export default workbookReducer;

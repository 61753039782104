import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNews } from "actions/news-actions";

import { WAITING, LOADING } from "constants/network-states";
import { searchArray } from "utils/search-helper";

/**
 * Provides the documents list
 */
const useNews = () => {
  const dispatch = useDispatch();
  const { news, newsNetworkState, searchQuery } = useSelector((state) => state.newsState);
  const { user: oidcUser } = useSelector((state) => state.oidc);

  const fetchNews = () => {
    newsNetworkState === WAITING && dispatch(getNews());
  };

  const newsIsLoading = newsNetworkState === WAITING || newsNetworkState === LOADING;

  useEffect(() => {
    if (oidcUser) {
      fetchNews();
    }
  }, [oidcUser]); // eslint-disable-line react-hooks/exhaustive-deps

  if (searchQuery === "") {
    return {
      news,
      newsIsLoading,
      filteredNews: news,
    };
  }

  const filteredNews = searchArray(news, ["companyRegistrationNumber", "name"], searchQuery);
  return {
    news,
    newsIsLoading,
    filteredNews,
  };
};

export default useNews;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Block from '../block/block';
import ModuleSelector from '../module-selector/module-selector';
import { ModuleRichText } from '../module-richtext/module-richtext';
import ModuleImage from '../module-image/module-image';
import ModuleDownload from '../module-download/module-download';
import ModuleHeading from '../module-heading/module-heading';
import ModuleExternalLink from '../module-external-link/module-external-link';
import ModuleTable from '../module-table/module-table';
import ModuleTip from '../module-tip/module-tip';
// import ModuleExampleContainer from '../routes/CollectiveAgreements/components/module-example/module-example.container'; // TODO: Look into making this a generic component
import ErrorBoundry from '../error-boundry/error-boundry';
import { blockTypes } from '../../constants/block-types';
import { getHashmapDepth } from '../../utils/section';
import { isNumber } from '../../utils/js-helpers';
import useValidate from '../../hooks/use-validate';
import useStateWithLabel from '../../hooks/use-state-with-label';
// Actions
import {
  updateSectionDetails,
  addNewSectionBlock,
  setSectionIsValid
} from '../../actions/section-actions';

const CLASS_NS = 'section-editor';

// TODO: Memoize the filtering of child blocks in render function
/**
 * Presents the current section details and modules.
 * Provides tools to add and remove modules.
 * This component is context agnostic.
 * Section can have a state of being part of the document index or not.
 * @returns {*} - React.Component
 */
export const SectionEditor = ({
  classNs = CLASS_NS,
  blockId,
  blocksSelectorConfig,
  LeadContent,
  togglerComponents,
  nonExpandedComponents,
  displayHeaderControlsInline = false,
  TrailingContent,
  customBlocks = [],
  showBackgroundOverlay,
}) => {
  const isNew = blockId && blockId.split('_')[1] * 1 < 0;
  const dispatch = useDispatch();
  const { blocks } = useSelector((state) => state.sectionState);
  const sectionDepth = getHashmapDepth(blockId, blocks);
  const [currentSection, setCurrentSection] = useState({});
  const [currentModuleBlocks, setCurrentModuleBlocks] = useStateWithLabel(
    [],
    'currentModuleBlocks'
  );
  const { errorMessages } = useSelector((state) => state.sectionState);
  const [validate, getErrorForField] = useValidate(errorMessages, (valid) =>
    dispatch(setSectionIsValid(blockId, valid))
  );

  useEffect(() => {
    if (!blocks[blockId]) {
      return;
    }
    setCurrentSection(blocks[blockId]);
  }, [blockId, blocks]);

  useEffect(() => {
    const blocks =
      blocksSelectorConfig?.filter((block) => block.depths.includes(sectionDepth)) ?? [];
    setCurrentModuleBlocks(blocks);
  }, [sectionDepth, blocksSelectorConfig, setCurrentModuleBlocks]);

  const onAddModule = (moduleType, e) => {
    e.preventDefault();
    dispatch(
      addNewSectionBlock({
        parentId: blockId,
        type: moduleType.key,
        ...moduleType
      })
    );
  };

  const onDetailsChange = useCallback(
    (id, e) => {
      const { name, value, type, checked } = e.target;
      let parsedValue = value;
      try {
        parsedValue = value && isNumber(value - 0) ? value - 0 : value;
      } catch (e) {
        // Silent catch. Will fallback on original value.
      }
      const isCheckedType = type === 'checkbox' || type === 'radio';
      const append = isCheckedType ? checked : null;

      dispatch(
        updateSectionDetails(
          id,
          {
            [name]: parsedValue
          },
          append
        )
      );
    },
    [dispatch]
  );

  // TODO: Rename to better fit the purpose.
  // Used to update rich text content.
  const updateModuleContent = useCallback(
    (editorStateData, block, fieldName) => {
      // dispatch(updateSectionModule(block.id, editorStateData));

      dispatch(
        updateSectionDetails(block.id, {
          [fieldName]: editorStateData
        })
      );
    },
    [dispatch]
  );

  const getComponentProps = useCallback(
    (block) => ({
      classNs: classNs,
      block: block,
      updateModuleContent: updateModuleContent,
      getValidationErrorForField: getErrorForField,
      validate: validate,
      onChange: onDetailsChange.bind(null, block.id),
      customBlocks: customBlocks,
      blocksSelectorConfig: blocksSelectorConfig // Needed for section-editor type components
    }),
    [
      classNs,
      updateModuleContent,
      getErrorForField,
      validate,
      onDetailsChange,
      customBlocks,
      blocksSelectorConfig
    ]
  );

  const getModuleComponent = (block, index) => {
    const componentProps = getComponentProps(block);
    if (!block || !block.id) {
      console.error('undefined module data attempted to be set in getModuleComponent : ', block);
      return null;
    }
    const customBlock = customBlocks.find((m) => {
      return m?.type.blockType === block.type;
    });

    switch (block.type) {
      case customBlock?.type.blockType:
        // Give president to custom blocks which might use the same moduleType as the defailt ones.
        // This gives the posibility to override the default module type settings.
        let Custom = customBlock.type;
        return <Custom {...componentProps} {...customBlock.props} />;
      case blockTypes.RICHTEXT:
        return <ModuleRichText {...componentProps} />;
      // case blockTypes.EXAMPLE:
      // TODO: Refactor before using.
      //   return <ModuleExampleContainer {...componentProps} />;
      case blockTypes.TABLE:
        return <ModuleTable {...componentProps} />;
      case blockTypes.IMAGE:
        return <ModuleImage {...componentProps} />;
      case blockTypes.DOWNLOAD:
        return <ModuleDownload {...componentProps} />;
      case blockTypes.HEADING: //passthorugh
      case blockTypes.SUBHEADING:
        return <ModuleHeading {...componentProps} />;
      case blockTypes.EXTERNAL_LINK:
        return <ModuleExternalLink {...componentProps} />;
      case blockTypes.TIP:
        return <ModuleTip {...componentProps} />;
      case blockTypes.SECTION: //passthrough
      case blockTypes.SUBSECTION:
        return <SectionEditor {...componentProps} />;
      default:
        return null;
    }
  };

  const leadContentComponent = useMemo(() => {
    if (typeof LeadContent?.type === 'string') {
      // Regular DOM elements
      return LeadContent;
    } else if (typeof LeadContent?.type === 'function') {
      // An instanciated React component
      let Lead = LeadContent.type;
      return <Lead {...getComponentProps(currentSection)} {...LeadContent.props}></Lead>;
    } else {
      return null;
    }
  }, [LeadContent, currentSection, getComponentProps]);

  const sectionTitle = useMemo(() => {
    if (LeadContent || !currentSection?.title) {
      return null;
    }
    const HeaderTag = `h${sectionDepth}`;
    return <HeaderTag>{currentSection.title}</HeaderTag>;
  }, [LeadContent, currentSection.title, sectionDepth]);

  const sectionDescription = useMemo(() => {
    if (LeadContent || currentSection?.description) {
      return null;
    }
    return <p className={`${classNs}_section_description`}>{currentSection.description}</p>;
  }, [LeadContent, classNs, currentSection?.description]);

  return (
    <section className={`${classNs}_section`}>
      {leadContentComponent}
      {sectionTitle}
      {sectionDescription}
      {currentModuleBlocks.length && sectionDepth < 2 ? (
        <ModuleSelector
          blockTypes={currentModuleBlocks}
          onAddModule={onAddModule}
          contextType={sectionDepth === 0 ? 'page' : 'inline'}
          buttonText={sectionDepth === 0 ? '' : `Lägg till innehåll`}
          sectionDepth={sectionDepth}
          disabled={isNew}
        />
      ) : null}
      <div className={`${classNs}_section_blocks-container`} key={blockId}>
        {Object.values(blocks)
          .filter((block) => block?.parentId === blockId)
          .sort((a, b) => {
            if (a.index < b.index) {
              return -1;
            } else if (a.index > b.index) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((block, index) => (
            <Block
              classNs={classNs}
              key={block?.id ?? block.tempId}
              block={block}
              togglerComponents={togglerComponents}
              nonExpandedComponents={nonExpandedComponents}
              displayHeaderControlsInline={displayHeaderControlsInline}
              showBackgroundOverlay={showBackgroundOverlay}
            >
              <ErrorBoundry>{getModuleComponent(block, index)}</ErrorBoundry>
            </Block>
          ))}
      </div>
      {currentModuleBlocks.length && sectionDepth > 1 ? (
        <ModuleSelector
          blockTypes={currentModuleBlocks}
          onAddModule={onAddModule}
          contextType={sectionDepth === 0 ? 'page' : 'inline'}
          buttonText={sectionDepth === 0 ? '' : `Lägg till innehåll`}
          sectionDepth={sectionDepth}
          disabled={isNew}
        />
      ) : null}
      {TrailingContent}
    </section>
  );
};

SectionEditor.propTypes = {
  classNs: PropTypes.string,
  blockId: PropTypes.string.isRequired,
  customBlocks: PropTypes.array,
};

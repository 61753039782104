import News from "./components/News";
import EditNews from "./components/EditNews";

import { UserIsAuthenticated } from "utils/auth-helpers";

const NewsRoute = () => ({
  path: "/news", // include the additional slug for testing current route
  component: UserIsAuthenticated(News),

  routes: [
    {
      path: "/news/item/:newsId?",
      exact: true,
      component: EditNews,
    },
  ],
});

export default NewsRoute;

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { downloadFile, favouriteDocument } from "actions/librarydocuments-actions";
import { downloadCompanyFile, favouriteCompanyDocument } from "actions/companydocuments-actions";
import { favouriteBlock } from "actions/guide-actions";
import { guideSectionTypes } from "constants/guide-types";
import GuidesRoute from "routes/Guides";

import { ReactComponent as StarYellow } from "icons/star_yellow.svg";
import { ReactComponent as SquareDocument } from "icons/SquareDocument.svg";
import { ReactComponent as Book } from "icons/Book.svg";
import { ReactComponent as Download } from "icons/Download.svg";

import ButtonLink from "components/ButtonLink/ButtonLink";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import RichText from "components/RichText/RichText";

const CLASS_NS = "my-favourites-item";

const MyFavouritesItem = ({ classNs = CLASS_NS, favourite }) => {
  const { title, type, id, parentId, description, guideId } = favourite;

  const descriptionRef = useRef();

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  //   const [isOverflowing, setIsOverflowing] = useState(false);

  //   useEffect(() => {
  //     setIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.offsetHeight);
  //   });

  const dispatch = useDispatch();

  const unFavourite = () => {
    if (type === "companydoc") {
      dispatch(favouriteCompanyDocument({ id, isFavourite: false }));
    } else if (type === "librarydoc") {
      dispatch(favouriteDocument({ id, isFavourite: false }));
    } else {
      console.log(id);
      dispatch(
        favouriteBlock({
          guideId: guideId,
          sectionId: id.replace(`${guideSectionTypes.GUIDE_SECTION}_`, ""),
          isFavourite: false,
        })
      );
    }
  };

  const downloadClick = () => {
    console.log("favorite", favourite, type);
    if (type === "companydoc") {
      dispatch(downloadCompanyFile(favourite));
    } else {
      dispatch(downloadFile(favourite));
    }
  };

  return (
    <div className={`${classNs}`}>
      <div className={`${classNs}_wrapper`}>
        <div className={`${classNs}_wrapper_content`}>
          <div className={`${classNs}_header_content`}>
            <div className={`${classNs}_header_content_left`}>
              {type === "companydoc" || type === "librarydoc" ? (
                <>
                  <div className={`${classNs}_header_content_icon`}>
                    <SquareDocument />
                  </div>
                  <h4 className={`${classNs}_header`}>{title}</h4>
                </>
              ) : (
                <>
                  <div className={`${classNs}_header_content_icon`}>
                    <Book />
                  </div>
                  <Link to={`${GuidesRoute().path}/${guideId}/guide/${parentId}/${id}`}>
                    <h4 className={`${classNs}_header`}>{title}</h4>
                  </Link>
                </>
              )}
            </div>
            <ButtonIcon
              icon={<StarYellow aria-hidden />}
              aria-label={`Avfavorisera ${title}`}
              onClick={unFavourite}></ButtonIcon>
          </div>
          <div className={`${classNs}_text_wrapper`}>
            <div className={`${classNs}_text`} ref={descriptionRef} data-is-expanded={isExpanded}>
              <RichText nodes={description} headingLevel={3} />
            </div>
          </div>
          <div className={`${classNs}_wrapper_buttons`}>
            {description?.length > 300 && (
              <ButtonLink onClick={toggleExpanded} size="small">
                {isExpanded ? "Visa mindre" : "Visa mer"}
              </ButtonLink>
            )}
            {(type === "companydoc" || type === "librarydoc") && (
              <ButtonLink icon={<Download />} size="small" onClick={downloadClick}>
                Ladda ned
              </ButtonLink>
            )}
          </div>
        </div>
        <p className={`${classNs}_source`}>
          {type === "companydoc" || type === "librarydoc" ? "Dokument & mallar" : "Certifieringsguiden"}
        </p>
      </div>
    </div>
  );
};

export default MyFavouritesItem;

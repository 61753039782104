import * as types from "./action-types";
import * as guideApi from "api/guide-api";
import { createActionSequence } from "./action-helpers";
import { saveBlocks, saveSection, getSectionsTransformed } from "react-draft-editor";

import { guideTypeIdToIdConverter, getGuideSchema, guideTypeIdConverter } from "utils/normalize";

export const getGuide = (id) => createActionSequence("GET_GUIDE", guideApi.getGuide)(id);

export const getGuideMeta = createActionSequence("GET_GUIDE_META", guideApi.getGuideMeta);

export const getGuides = createActionSequence("GET_GUIDES", guideApi.getGuides);

export const addGuide = createActionSequence("ADD_GUIDE", guideApi.addGuide);
export const deleteGuide = createActionSequence("DELETE_GUIDE", guideApi.deleteGuide);

export const updateGuide = createActionSequence("UPDATE_GUIDE", guideApi.updateGuide);

export const addNewGuidePart = (data) => createActionSequence("ADD_NEW_GUIDE_PART", guideApi.addGuidePart)(data);

export const addNewGuideSection = (guideId, parentId, index, isIntroduction) =>
  createActionSequence(
    "ADD_NEW_GUIDE_SECTION",
    guideApi.addGuideSection
  )({
    guideId: guideTypeIdConverter(guideId),
    parentId: guideTypeIdConverter(parentId),
    index,
    isIntroduction,
  });

export const saveGuideBlocks = (blockIds, guideId) => async (dispatch, getState) => {
  const results = await dispatch(
    saveBlocks(blockIds, getGuideSchema(), guideApi.saveGuideBlocks, { guideId: guideId, blockIds: blockIds })
  );
  // Repopulate guide store
  if (results) {
    dispatch({
      type: types.SAVE_GUIDE_BLOCKS_SUCCESS,
      payload: results,
    });
    return results;
  }
  // The section error will be triggered in the case of an error.
};

export const updateSectionBlocks = (guideId) => async (dispatch, getState) => {
  const results = await dispatch(saveSection(getGuideSchema(), guideApi.updateSectionBlocks, { guideId: guideId }));

  // Repopulate guide store
  if (results) {
    dispatch({
      type: types.UPDATE_GUIDE_BLOCKS_SUCCESS,
      payload: results,
    });
    return results;
  }
  // The section error will be triggered in the case of an error.
};

export const updateSections = (ids, guideId) => async (dispatch, getState) => {
  const store = getState();
  const { guide } = store.guideState;
  let sections = guide.filter((block) => ids.includes(block.id));

  sections = JSON.parse(JSON.stringify(sections)); // Make deep copy, otherwise the transformed blocks will mutate the store.
  const transformedSections = getSectionsTransformed(sections, getGuideSchema());

  const update = await dispatch(
    createActionSequence(
      "SAVE_GUIDE_BLOCKS",
      guideApi.updateSectionBlocks
    )({
      guideId: guideId,
      blocks: transformedSections,
    })
  );
  return update;
};

export const swapSectionIndexes = (to, from, id, type) => ({
  type: types.SWAP_SECTION_INDEXES,
  payload: {
    from: from,
    to: to,
    id: id,
    type: type,
  },
});

export const deleteGuideBlocks = (blockIds, guideId) => async (dispatch, getState) => {
  const store = getState();
  const { guide } = store.guideState;
  const blocks = guide.filter((block) => blockIds.includes(block.id));
  //Filter out "top" blocks without parents
  const parentBlocks = blocks.filter((block) => {
    return !blocks.find((b) => b.id === block.parentId);
  });
  const convertedBlocks = guideTypeIdToIdConverter(parentBlocks);
  return dispatch(
    createActionSequence(
      "DELETE_GUIDE_BLOCKS",
      guideApi.deleteGuideBlocks
    )({
      blocks: convertedBlocks,
      guideId: guideId,
    })
  );
};

export const updateBlockValue = createActionSequence("UPDATE_BLOCK_VALUE", guideApi.updateSectionBlocks);

export const favouriteBlock = createActionSequence("FAVOURITE_GUIDE_BLOCKS", guideApi.favouriteSectionBlock);

export const resetGuide = () => ({
  type: types.RESET_GUIDE,
});

export const createRoutine = createActionSequence("CREATE_ROUTINE", guideApi.createRoutine);

export const updateRoutine = createActionSequence("UPDATE_ROUTINE", guideApi.updateRoutine);

export const setActiveComment = (id) => ({
  type: types.SET_ACTIVE_COMMENT,
  payload: id,
});

export const openRequirement = (id) => ({
  type: types.OPEN_REQUIREMENT,
  payload: id,
});

export const closeRequirement = (id) => ({
  type: types.CLOSE_REQUIREMENT,
  payload: id,
});

export const setActiveSection = (path) => ({
  type: types.SET_ACTIVE_SECTION,
  payload: path,
});

export const setRequirementDirty = (id) => ({
  type: types.SET_REQUIREMENT_DIRTY,
  payload: id,
});

export const resetAllRequirementsDirty = () => ({
  type: types.RESET_ALL_REQUIREMENTS_DIRTY,
});

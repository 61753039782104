import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import mime from 'mime';
import Dropzone from 'react-dropzone';

import { updateSectionDetails, setSectionIsValid } from '../../actions/section-actions';

import { getInitialUploadData } from '../../constants/reused-initial-states';
import { stripFileExtension } from '../../utils/files';

import useValidate from '../../hooks/use-validate';

const iconFileSVG =
  '<svg width="29" height="42" xmlns="http://www.w3.org/2000/svg"><path d="M0 .164v41.548H29V9.083L20.322.163H0zm2.636 2.71h15.819v8.128H26.363v28H2.637V2.873zm18.455 1.904 3.419 3.515h-3.42V4.778z" fill="#000" fill-rule="evenodd"/></svg>';

const CLASS_NS = 'module-upload';

const ModuleUpload = ({
  classNs = CLASS_NS,
  module = {},
  containerId,
  storeFileRefKey = 'files',
  textContext = 'download',
  availableUploads = [],
  acceptedFileTypes,
  onRemoveFile = () => {},
  onSubmit = () => {},
  iconFile = iconFileSVG,
  labelHeader = '',
  labelFileName = '',
  labelDescription = '',
  buttonUpload = '',
  availableImages = ''
  // moduleIndex=-1,
  // updateModuleContent=()=>{}
}) => {
  const dispatch = useDispatch();
  const { errorMessages } = useSelector((state) => state.sectionState);
  const moduleId = module.id;
  const [validate, getErrorForField] = useValidate(
    errorMessages,
    setSectionIsValid.bind(null, module.id)
  );
  const { uploads } = useSelector((state) => state.uploadState);
  const [dropError, setDropError] = useState('');
  const fileIsUploaded = module?.fileData?.size > 0 || !!module?.fileData?.id;
  const fileIsLoaded = module?.fileData?.size > 0 && !containerId; // TODO: Chnage !containerId to check if fileUrl starts with "blob:" which means it's a temp file.

  // TODO: Move fileData and tempFile to uploads as it interfere's with save detection in DocumentHeader

  // // File data is obtained seperately from then main module.
  // const loadModuleUploadData = () => {
  //   if (!containerId) {
  //     return
  //   }
  //   dispatch(getUploadData(containerId))
  // }
  useEffect(() => {
    if (!module?.fileData) {
      // Set file field in store for temp storage of future file data..
      dispatch(
        updateSectionDetails(moduleId, {
          fileData: getInitialUploadData()
        })
      );
    }
    // loadModuleUploadData(); // TODO: Consider if this is needed as all data is boing loaded below.
  }, [dispatch, module?.fileData, moduleId]);

  useEffect(() => {
    if (containerId && uploads[containerId] && uploads[containerId].id !== module?.fileData?.id) {
      // Set file in sectionmodule in store.
      dispatch(
        updateSectionDetails(moduleId, {
          fileData: uploads[containerId]
        })
      );
    }
  }, [uploads, dispatch, containerId, module?.fileData?.id, moduleId]);

  const onDetailsChange = (e) => {
    const { name, value } = e.target;

    if (getErrorForField(name)) {
      validate(e);
    }

    dispatch(
      updateSectionDetails(moduleId, {
        [name]: value
      })
    );
  };

  const onModuleUploadDetailsChange = (e) => {
    const { name, value } = e.target;

    if (getErrorForField(name)) {
      validate(e);
    }

    dispatch(
      updateSectionDetails(moduleId, {
        fileData: {
          ...module?.fileData,
          [name]: value
        }
      })
    );
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles.length) {
      setDropError(
        rejectedFiles > 1
          ? 'Du får bara ladda upp en fil i taget!'
          : 'Filen du valt är ogiltig. Försök igen med en annan.'
      );
      return;
    }

    const { name, preview, type, size } = acceptedFiles[0];

    // Setting new file values to store.
    dispatch(
      updateSectionDetails(moduleId, {
        fileData: {
          ...module?.fileData,
          fileUrl: preview,
          contentType: type,
          name: stripFileExtension(name),
          originalFileName: name,
          size: size
        },
        tempFile: acceptedFiles[0]
      })
    );
  };

  const selectAvailableUpload = (file) => {
    // Update the id reference to the file
    dispatch(
      updateSectionDetails(moduleId, {
        [storeFileRefKey]: [file.id],
        fileData: file
      })
    );
  };

  return (
    <div className="module-upload">
      <div className="form-lineup">
        {module.description ? (
          <div className="form-lineup_form-group">
            <label htmlFor="description" className="form-lineup_form-group_label">
              {labelHeader}
            </label>
            <textarea
              className="inputfield inputfield--textarea  inputfield--moduleFile_name"
              value={module.description}
              name="description"
              onChange={onDetailsChange}
              onBlur={validate}
              required
              noValidate={true}
              data-haserror={getErrorForField('description')}
            />
            <p className="input-error-message">{getErrorForField('description')}</p>
          </div>
        ) : (
          <div className="form-lineup_form-group">
            <label htmlFor="title" className="form-lineup_form-group_label">
              {labelHeader}
            </label>
            <textarea
              className="inputfield inputfield--textarea  inputfield--moduleFile_name"
              value={module.title}
              name="title"
              onChange={onDetailsChange}
              onBlur={validate}
              required
              noValidate={true}
              data-haserror={getErrorForField('title')}
            />
            <p className="input-error-message">{getErrorForField('title')}</p>
          </div>
        )}
      </div>
      {/* File is either to-be uploaded or is uploaded */}
      {fileIsUploaded ? (
        <div className="form-lineup_form-group">
          <div className="form-lineup_actions_btn form-lineup_actions_btn--secondary form-lineup_actions_btn_file">
            <div className="form-lineup_actions_btn_file_action">
              <div className="form-lineup_actions_btn_file_action_info">
                <div className="form-lineup_actions_btn_file_action_info_icon">{iconFile}</div>
                <div className="form-lineup_actions_btn_file_action_info_name">
                  {module?.fileData?.name}.{mime.getExtension(module?.fileData?.contentType)}
                </div>
              </div>
              <button
                type="button"
                className="form-lineup_actions_btn_file_action_delete"
                onClick={onRemoveFile}
              >
                <div className="form-lineup_actions_btn_file_action_delete_icon" />
              </button>
            </div>
            {module.type === 'image' ? (
              <img
                className="module-image_info_preview"
                src={module?.fileData?.imageUrl || module?.fileData?.fileUrl}
                alt={module?.fileData?.name}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {/* File is loaded temporally */}
      {fileIsLoaded ? (
        <div className="form-lineup">
          <div className="form-lineup_form-group">
            <label htmlFor="name" className="form-lineup_form-group_label">
              {labelFileName}
            </label>
            <input
              className="inputfield inputfield--text  inputfield--moduleFile_file_name"
              value={module?.fileData?.name ?? module?.fileData?.originalFileName ?? ''}
              name="name"
              onChange={onModuleUploadDetailsChange}
              onBlur={validate}
              required
              noValidate={true}
              data-haserror={getErrorForField('name')}
            />
            <p className="input-error-message">{getErrorForField('name')}</p>
            <span>.{mime.getExtension(module?.fileData?.contentType ?? null)}</span>
          </div>
          <div className="form-lineup_form-group">
            <label htmlFor="description" className="form-lineup_form-group_label">
              {labelDescription}
            </label>
            <textarea
              className="inputfield inputfield--textarea  inputfield--moduleFile_name"
              value={module?.fileData?.description}
              name="description"
              onChange={onModuleUploadDetailsChange}
              onBlur={validate}
              required
              noValidate={true}
              data-haserror={getErrorForField('description')}
            />
            <p className="input-error-message">{getErrorForField('description')}</p>
          </div>
          <div className="form-lineup_actions">
            <button
              type="button"
              onClick={onSubmit}
              className="form-lineup_actions_btn form-lineup_actions_btn--primary"
            >
              {buttonUpload}
            </button>
          </div>
        </div>
      ) : null}
      {/* No file is present*/}
      {fileIsLoaded === false && fileIsUploaded === false ? (
        <div role="form">
          <div className="form-lineup">
            <div className="form-lineup_form-group">
              <Dropzone
                onDrop={onDrop}
                className="filedrop"
                accept={acceptedFileTypes}
                multiple={false}
              >
                <div className="filedrop_placeholder">
                  {iconFile}
                  Dra en fil hit!
                </div>
                {dropError && <div className="filedrop_error">{dropError}</div>}
              </Dropzone>
            </div>
          </div>
          {availableUploads.length ? (
            <details className={`${classNs}_uploads-container`}>
              <summary className={`${classNs}_uploads-bar`}>
                <h3 className={`${classNs}_uploads-title`}>{availableImages}</h3>
              </summary>
              <div className={`${classNs}_uploads-content`}>
                <ul className={`${classNs}_uploads-list`}>
                  {availableUploads.map((file) => {
                    return (
                      <li
                        key={file.id}
                        className={`${classNs}_uploads-item`}
                        onClick={selectAvailableUpload.bind(null, file)}
                      >
                        {file.name}
                        {file.fileType ? `.${mime.getExtension(file.fileType)}` : null}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </details>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

ModuleUpload.propTypes = {
  classNs: PropTypes.string,
  module: PropTypes.object, // Todo replace with moduleDataType,
  availableUploads: PropTypes.array,
  onRemoveFile: PropTypes.func,
  onSubmit: PropTypes.func,
  acceptedFileTypes: PropTypes.string,
  storeFileRefKey: PropTypes.string,
  textContext: PropTypes.string
};

export default ModuleUpload;

import React, { useRef, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

const CLASS_NS = "sub-header";

const SubHeader = ({ classNs = CLASS_NS, ToolsComponent = null, children }) => {
  const router = useSelector((state) => state.router);  
  const subHeaderEl = useRef();

  const toolBarHeight = 75;
  const minHeightValue = 325;
  const [minHeight, setMinHeight] = useState(minHeightValue);

  // eslint-disable-next-line
  useLayoutEffect(() => {
    const height = subHeaderEl.current?.clientHeight || minHeightValue;
    setMinHeight(height < minHeightValue ? minHeightValue : height);
  });

  return (
    <section className={`${classNs} ${router.location.pathname === "/start" ? `${classNs}_start` : ""}`} style={{ minHeight: `${minHeight + toolBarHeight}px` }}>
      <div className={`${classNs}_wrapper`} ref={subHeaderEl}>
        {children}
      </div>
      {ToolsComponent ? ToolsComponent : null}
    </section>
  );
};
export default SubHeader;

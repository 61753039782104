import useAllUsers from "hooks/use-all-users";
import { USER_TYPES } from "constants/user-types";
import { useSelector } from "react-redux";
import { searchArray } from "utils/search-helper";

/**
 * Provides the users list except supervisors
 */
const useUsers = () => {
  const { users, usersIsLoading, userRoles, usersMap } = useAllUsers();
  const { searchQueryUsers } = useSelector((state) => state.usersState);
  const { activeCompany, companiesMap } = useSelector((state) => state.companiesState);

  const supervisorRole = userRoles.find((role) => role.name === USER_TYPES.SUPERVISOR.name);
  const usersExceptSupervisors = usersIsLoading ? [] : users.filter((user) => user.role !== supervisorRole.id);

  const companyNames = (user) =>
    (companiesMap &&
      user?.role !== USER_TYPES.SERVICEADMIN.id &&
      user?.companyIds.length > 0 &&
      user.companyIds.map((id) => {
        return companiesMap[id] ? companiesMap[id] : "";
      })) ||
    [];

  const usersFilteredByCompany = usersExceptSupervisors
    .filter((userItem) => {
      return !activeCompany || (activeCompany && userItem.companyIds.includes(activeCompany.id));
    })
    .map((user) => {
      return {
        ...user,
        companyNames: companyNames(user),
      };
    });

  if (searchQueryUsers === "") {
    return {
      users: usersFilteredByCompany,
      usersMap,
      usersFilteredByCompany,
      usersIsLoading,
      userRoles,
      filteredUsers: usersFilteredByCompany,
    };
  }

  const filteredUsers = searchArray(
    usersFilteredByCompany,
    ["firstName", "lastName", "userName", "companyNames.name"],
    searchQueryUsers
  );

  return { users: usersFilteredByCompany, usersMap, usersFilteredByCompany, usersIsLoading, userRoles, filteredUsers };
};

export default useUsers;

import React, { useRef } from "react";

import { ReactComponent as Plus } from "icons/plus.svg";
import { ReactComponent as Minus } from "icons/Minus.svg";

// const CLASS_NS = "guide-viewer-toolbar";

const GuideViewerToolOpen = ({ classNs, isOpen, onClick, disabled = false }) => {
  const tooltipEl = useRef(null);
  const onPlusClick = () => {
    onClick();
  };
  return (
    <>
      <button
        className={`${classNs}_button`}
        disabled={disabled}
        data-icon="plus"
        onClick={onPlusClick}
        ref={tooltipEl}>
        {isOpen ? <Minus className="plus" /> : <Plus className="plus" />}
      </button>
    </>
  );
};

export default GuideViewerToolOpen;

import React, { useState, useEffect, useMemo } from "react";
import __ from "constants/static-texts";
import LibraryDocsRoute from "routes/LibraryDocs";
import { ReactComponent as Document } from "icons/Document.svg";
import { ReactComponent as PencilIcon } from "icons/Pencil.svg";
import { ReactComponent as DocumentsIcon } from "icons/documents.svg";
import { ReactComponent as CheckmarkIcon } from "icons/check.svg";
import { ReactComponent as StarGreyOutlined } from "icons/star_grey_outlined.svg";
import { ReactComponent as StarYellow } from "icons/star_yellow.svg";
import { formatDate } from "utils/date-helpers";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import LinkIcon from "components/LinkIcon/LinkIcon";
import LayoutIcons from "components/LayoutIcons/LayoutIcons";
import DownloadDocumentButton from "components/DownloadDocumentButton/DownloadDocumentButton";
import EditCompanyDocument from "components/EditCompanyDocument/EditCompanyDocument";

const LibraryDocListItem = ({ classNs, doc, toggleFavourite, users, isCompanyDocument, loggedInUser }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [showEditCompanyDocument, setShowEditCompanyDocument] = useState(false);
  const COPY_SUCCESS_MESSAGE_TIMEOUT = 4000;

  const { isServiceAdmin, isCompanyEditor, isCompanyAdmin, isSupervisor } = loggedInUser;

  const updatedAt = useMemo(() => formatDate(doc.updatedAt), [doc.updatedAt]);

  const updatedByUser = users.find((user) => user.id === doc.updatedByUserId);

  const updatedBy = updatedByUser ? `${updatedByUser?.firstName} ${updatedByUser?.lastName}` : doc?.updatedByUser;

  const documentPath = LibraryDocsRoute().path.replace("/:id?", "");

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${documentPath}/${doc.id}`);
      setIsCopied(true);
    } catch (error) {
      setIsCopied(false);
    }
  };

  useEffect(() => {
    isCopied &&
      setTimeout(() => {
        setIsCopied(false);
      }, COPY_SUCCESS_MESSAGE_TIMEOUT);
  }, [isCopied]);

  const clickEditCompanyDocumentHandler = () => {
    setShowEditCompanyDocument(true);
  };

  const cancelEditDocumentHandler = () => {
    setShowEditCompanyDocument(false);
  };

  const saveEditDocumentHandler = () => {
    setShowEditCompanyDocument(false);
  };

  return (
    <li className={`${classNs}_list_item`}>
      <div className={`${classNs}_list_item_header`}>
        <div className={`${classNs}_list_item_header_icon`}>
          <Document />
        </div>
        <div className={`${classNs}_list_item_header_content`}>
          <h4 className={`${classNs}_list_item_title`}>{doc.title}</h4>
          <p className={`${classNs}_list_item_last_date`}>
            {doc.literation}{" "}
            {__.document.updated(updatedAt, updatedBy)}
          </p>
        </div>
        <LayoutIcons variant="spaced">
          {isServiceAdmin && !isCompanyDocument && (
            <>
              <LinkIcon
                icon={<PencilIcon />}
                aria-label={__.document.edit}
                link={{
                  pathname: `${documentPath}/${doc.id}/edit`,
                  state: { disableScroll: true },
                }}
                idNs={`${doc.id}_edit-library-document`}
              />

              {navigator.clipboard &&
                (!isCopied ? (
                  <ButtonIcon
                    onClick={copyLink}
                    icon={<DocumentsIcon />}
                    aria-label={__.document.copy}
                    idNs={`${doc.id}_copy`}
                  />
                ) : (
                  <CheckmarkIcon className={`${classNs}_copy-success`} aria-label={__.document.copySuccess} />
                ))}
            </>
          )}
          {!isServiceAdmin && !isSupervisor && (
            <ButtonIcon
              onClick={() => toggleFavourite(doc)}
              icon={doc.isFavourite ? <StarYellow /> : <StarGreyOutlined />}
              aria-label={doc.isFavourite ? __.document.unfavourite : __.document.favourite}
              idNs={`${doc.id}_toggle-favorite`}
            />
          )}
          {isCompanyDocument && (isCompanyAdmin || isCompanyEditor) && (
            <ButtonIcon
              icon={<PencilIcon />}
              onClick={clickEditCompanyDocumentHandler}
              aria-label={__.document.edit}
              idNs={`${doc.id}_edit-company-document`}
            />
          )}
        </LayoutIcons>
      </div>
      <div className={`${classNs}_list_item_main`}>
        <div className={`${classNs}_list_item_description`}>
          <p className="description">{doc.description}</p>
          <DownloadDocumentButton
            document={doc}
            size="dynamic"
            isCompanyDocument={isCompanyDocument}
            downloadIcon={true}>{`${__.document.download} ${doc.title}`}</DownloadDocumentButton>
        </div>
      </div>
      {showEditCompanyDocument && (
        <EditCompanyDocument
          documentId={doc.id}
          onSaveClick={saveEditDocumentHandler}
          onCancelClick={cancelEditDocumentHandler}
          canEditVisibilty={!isCompanyEditor}
        />
      )}
    </li>
  );
};

export default LibraryDocListItem;

import React, { useState } from "react";
import __ from "constants/static-texts";
import { ReactComponent as Upload } from "icons/Upload.svg";
import { ReactComponent as PencilIcon } from "icons/Pencil.svg";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import DownloadDocumentButton from "components/DownloadDocumentButton/DownloadDocumentButton";
import ButtonLink from "components/ButtonLink/ButtonLink";
import { formatDate } from "utils/date-helpers";
import UploadFile from "components/UploadFile/UploadFile";
import EditCompanyDocument from "components/EditCompanyDocument/EditCompanyDocument";

const CLASS_NS = "upload-files";

const UploadFiles = ({
  title,
  files,
  canUploadAndEdit,
  uploadDocument = () => {},
  deleteDocument = () => {},
  classNs = CLASS_NS,
  canEditVisibilty,
  hideUploadedFileTitle,
}) => {
  const [showUpload, setShowUpload] = useState(false);
  const [activeDocumentId, setActiveDocumentId] = useState();
  const [showEdit, setShowEdit] = useState(false);

  const renderUpload = (index) => (
    <div className={`${classNs}_row`}>
      {title && <div className={`${classNs}_title`}>{`${title} ${index}`}</div>}
      <ButtonLink
        icon={<Upload aria-hidden />}
        onClick={() => setShowUpload(true)}
        disabled={!canUploadAndEdit}
        variant="neutral">
        {index === 1 ? `${__.guide.responseslot.upload}` : `${__.guide.responseslot.uploadMore}`}
      </ButtonLink>
    </div>
  );
  const uploadFile = (inputs) => {
    uploadDocument(inputs);
  };

  const cancelClick = () => {
    setShowUpload(false);
  };

  const cancelEditDocumentHandler = () => {
    setShowEdit(false);
    setActiveDocumentId(null);
  };

  const saveEditDocumentHandler = () => {
    setShowEdit(false);
    setActiveDocumentId(null);
  };

  const clickEditHandler = (documentId) => {
    setActiveDocumentId(documentId);
    setShowEdit(true);
  };

  return (
    <div className={classNs}>
      {files.length ? (
        <>
          {files.map((file, index) => {
            return (
              <div className={`${classNs}_row`} key={index}>
                {title && <div className={`${classNs}_title`}>{`${title} ${index + 1}`}</div>}
                <div className={`${classNs}_document`}>
                  <div className={`${classNs}_topbar`}>
                    <DownloadDocumentButton isCompanyDocument={true} document={file}>
                      {hideUploadedFileTitle ? "" : `${file.title} - `}
                      {file.title ? `${file.title}` : ""}
                    </DownloadDocumentButton>
                    {canUploadAndEdit && <div className={`${classNs}_actions`}>
                      <ButtonIcon
                        icon={<PencilIcon />}
                        onClick={() => clickEditHandler(file.id)}
                        aria-label={__.document.edit}
                      />
                    </div>}
                  </div>
                  <p className={`${classNs}_when_who`}>
                    <span className={`${classNs}_when`}>Uppladdad:</span>
                    {formatDate(file.uploadedAt)}
                  </p>
                  <p className={`${classNs}_when_who`}>
                    <span className={`${classNs}_who`}>Synlighet:</span>
                    {file.isForManagementOnly ? "Endast ledning" : "Alla i företaget"}
                  </p>
                </div>
              </div>
            );
          })}
          {canUploadAndEdit && renderUpload(files.length + 1)}
        </>
      ) : (
        canUploadAndEdit && renderUpload(1)
      )}
      {/* overlays */}
      <UploadFile
        onUploadClick={uploadFile}
        cancelClick={cancelClick}
        canEditVisibilty={canEditVisibilty}
        showUpload={showUpload}
      />
      {showEdit && (
        <EditCompanyDocument
          documentId={activeDocumentId}
          onSaveClick={saveEditDocumentHandler}
          onCancelClick={cancelEditDocumentHandler}
          onDeleteClick={deleteDocument}
          canEditVisibilty={canEditVisibilty}
        />
      )}
    </div>
  );
};

export default UploadFiles;

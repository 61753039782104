import React from "react";
import { useParams, useHistory } from "react-router-dom";

import useSupervisors from "hooks/use-supervisors";
import useCompanies from "hooks/use-companies";
import { Overlay } from "react-draft-editor";
import { sortArray } from "utils/js-helpers";

const CLASS_NS = "form_overlay";
const SupervisorCompanies = ({ cancelUrl }) => {
  const { supervisors } = useSupervisors();
  const history = useHistory();
  const { supervisorId } = useParams();
  const { companiesMap } = useCompanies();

  const currentSupervisor = supervisors.find((supervisor) => supervisor.id === supervisorId - 0);
  const clickCancel = (e) => {
    e.preventDefault();
    history.push(cancelUrl);
  };

  const supervisorName =
    currentSupervisor.firstName && currentSupervisor.lastName
      ? `${currentSupervisor.firstName} ${currentSupervisor.lastName}`
      : currentSupervisor.userName;

  return (
    <Overlay cancelUrl={cancelUrl}>
      <div className={CLASS_NS}>
        <h2>{`${supervisorName} ansvarar för`}</h2>
        <div className={"supervisors_instances"}>
          {currentSupervisor.companyIds
            .map((companyId) => companiesMap && companiesMap[companyId])
            .filter((company) => company)
            .sort(sortArray("name"))
            .map((company) => {
              return (
                <div className={"supervisors_instances_instance"} key={company.id}>
                  {company && company.name}
                </div>
              );
            })}
        </div>

        <div className={`${CLASS_NS}_actions button-row`}>
          <button className={`button`} onClick={clickCancel}>
            Stäng
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default SupervisorCompanies;

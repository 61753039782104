import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Cross } from "icons/cross.svg";

import { closeStatusMessage } from "actions/layout-actions";

/*
 * A global status message component which is displayed when the 'statusMessage' action is invoked
 */

const StatusMessage = () => {
  const dispatch = useDispatch();
  const { statusMessage: message } = useSelector((state) => state.layoutState);

  useEffect(() => {
    if (message.keepOpen === false && message.isOpen) {
      setTimeout(() => dispatch(closeStatusMessage()), 4000);
    }
  }, [message.keepOpen, message.isOpen, dispatch]);

  const onClickHandler = () => {
    const fn = message?.actionFn;
    if (typeof fn === "function") {
      fn();
    }
  };

  const closeMessage = () => {
    dispatch(closeStatusMessage());
  };

  // const reAuth = () => {
  //   dispatch(userWillSignout());
  //   // TODO: Handle this in admin and member app.
  //   // userManager.signoutRedirect();
  // };

  if (!message?.isOpen) {
    return null;
  }
  return (
    <div className={`status-message status-message--active`} data-status={message.status}>
      <button className="status-message_icon" onClick={closeMessage}>
        <Cross />
      </button>
      <div className="status-message_message">
        <h2>{message.text}</h2>
        {message?.actionFn ? (
          <div className="status-message_action">
            <button href="#" onClick={onClickHandler} className="status-message_action_link">
              {message.actionLabel}
            </button>
          </div>
        ) : (
          ""
        )}
        {message.actionHref && message?.actionFn === null ? (
          <div className="status-message_action">
            <Link to={message.actionHref} className="status-message_action_link">
              {message.actionLabel}
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StatusMessage;

// TODO: Move this over to server side.
export const createCanonicalKeyForModule = () => {
  let randomKey = Math.floor(Math.random() * 10000000000);
  return 'mod-' + randomKey;
};

/**
 * Example canonicalURL:
 * /agreements/145/documents/183#section-4857
 * @param {String} moduleCanonicalKey unique id used to identify module
 * @param {String} agreementId id of currently navigated agreement
 * @param {String} documentId id of currently navigated document
 * @returns {String} canonicalURL is the URL which navigates us to this module
 */
export const getLinkInputCanonicalURL = (moduleCanonicalKey, agreementId, documentId) => {
  const canonicalKey = moduleCanonicalKey
    ? '#' + moduleCanonicalKey
    : '#' + createCanonicalKeyForModule();

  const agreementRouteString = agreementId ? `/agreements/${agreementId}` : '';
  const documentRouteString = documentId ? `/documents/${documentId}` : '';

  const canonicalURL = `${agreementRouteString}${documentRouteString}${canonicalKey}`;
  return canonicalURL;
};

export const removeAttributesFromBlocks = (blocks, attributesToRemove) => {
  return blocks
    ? Object.entries(blocks).map(([a, block], index) => {
        const obj = Object.entries(block).reduce((acc, [key, value]) => {
          if (!attributesToRemove.includes(key)) acc[key] = value;
          return acc;
        }, {});
        return obj;
      })
    : {};
};

import React from "react";

import stripHtml from "utils/stripHtml";

function CoreHeading(props) {
  const { block } = props;
  return (
    <div className="Fact-block--CoreHeading" >
      <h3>{stripHtml(block.originalContent)}</h3>
    </div>
  );
}

export default CoreHeading;

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function useCurrentPart() {
  const { partId } = useParams();

  const { workbook } = useSelector((state) => state.workbookState);

  const currentPart = workbook?.blbGroupCourseSections.blbCourseSections.find((section) => {
    return section.blbCourseSectionId === partId;
  });

  return {
    currentPart,
  };
}

export default useCurrentPart;

/**
 * From https://github.com/mjrussell/redux-auth-wrapper
 *  Doc: https://mjrussell.github.io/redux-auth-wrapper/
 **/

import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { routerActions } from "connected-react-router";

const devMode = false;

export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: "/login",
  redirectAction: routerActions.replace, // the redux action to dispatch for redirect
  authenticatedSelector: (state) => devMode || (state.oidc && state.oidc.user !== null), // how to get the user state
  authenticatingSelector: (state) => state.oidc && state.oidc.isLoadingUser, // wait for async loading of user to complete
  wrapperDisplayName: "UserIsAuthenticated", // a nice name for this auth check
});

const locationHelper = locationHelperBuilder({});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || "/start",
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state) => state.oidc.user === null,
  // A nice display name for this check
  wrapperDisplayName: "UserIsNotAuthenticated",
});

export const oidcConfig = {
  authority: process.env?.REACT_APP_OIDC_CONFIG_AUTHORITY,
  client_id: process.env?.REACT_APP_OIDC_CONFIG_CLIENT_ID,
  redirect_uri: process.env?.REACT_APP_OIDC_CONFIG_REDIRECT_URI,
  post_logout_redirect_uri: process.env?.REACT_APP_OIDC_CONFIG_POST_LOGOUT_REDIRECT_URI,
  response_type: process.env?.REACT_APP_OIDC_CONFIG_RESPONSE_TYPE,
  scope: process.env?.REACT_APP_OIDC_CONFIG_SCOPE,
  monitorSession: process.env?.REACT_APP_BUILT_WITH_ENVIRONMENT !== 'local',
};
import React from "react";
import { useLocation } from "react-router-dom";

import MenuComponent from "components/MenuComponent/MenuComponent";

import { MAIN_NAVIGATION_ROUTES } from "constants/navigation";
import { StartRoute } from "routes/Start";

import useGuideParts from "hooks/use-guide-parts";
import useWorkbookParts from "hooks/use-workbook-parts";
import useNavigation from "hooks/use-navigation";
import useLoggedInUser from "hooks/use-logged-in-user";

const CLASS_NS = "main-header";

const MainNavigation = ({ classNs = CLASS_NS }) => {
  const { pathname } = useLocation();

  const { mainNavigation } = useNavigation();

  const { isCompanyReader } = useLoggedInUser();

  const { allPartRoutes, activePartRoute, currentSectionRoutes, activeSectionRoute, routeMatch } = useGuideParts();

  const { workbookRoutes, activeWorkbookRoute, workbookSectionRoutes, activeWorkbookSectionRoute } = useWorkbookParts();

  const activeMainRoute =
    mainNavigation.find((route) => {
      if (routeMatch) {
        return route.path !== StartRoute().path && routeMatch.url.startsWith(route.path);
      } else {
        return route.path !== StartRoute().path && pathname.startsWith(route.path);
      }
    }) || MAIN_NAVIGATION_ROUTES.MAIN;

  return (
    <div className={`${classNs}_menu_container`}>
      <MenuComponent menuItems={mainNavigation} selectedItem={activeMainRoute} hideProgress={isCompanyReader} />

      {/* Guide part navigation */}
      {routeMatch && allPartRoutes.length ? (
        <MenuComponent menuItems={allPartRoutes} selectedItem={activePartRoute} hideProgress={isCompanyReader} />
      ) : null}
      {routeMatch && currentSectionRoutes.length ? (
        <MenuComponent
          menuItems={currentSectionRoutes}
          selectedItem={activeSectionRoute}
          hideProgress={isCompanyReader}
        />
      ) : null}

      {/* Workbook part navigation */}
      {workbookRoutes.length ? <MenuComponent menuItems={workbookRoutes} selectedItem={activeWorkbookRoute} /> : null}
      {workbookSectionRoutes.length && activeWorkbookSectionRoute.blbCourseSectionId ? (
        <MenuComponent menuItems={workbookSectionRoutes} selectedItem={activeWorkbookSectionRoute} />
      ) : null}
    </div>
  );
};

export default MainNavigation;

// import rssResponseInterceptor from "./interceptors/rssResponseInterceptor";
// import xmlResponseInterceptor from "./interceptors/xmlResponseInterceptor";

const DEFAULT_CONTENT_TYPE = "application/json";

const getContentTypeFromType = (type = "json") => {
  switch (type) {
    case "rss":
      return "application/rss+xml";
    case "xml":
      return "application/xml";
    case "json":
      return "application/json";
    case "html": // pass through
    case "xhtml":
      return "text/html";
    case "svg":
      return "image/svg+xml";
    case "png":
      return "image/png";
    case "jpg":
    case "jpeg":
      return "image/jpg";
    // case "form":
    //   return undefined;
    // return "multipart/form-data"; // https://stackoverflow.com/a/40362274/7197027
    // return "multipart/form-data"
    default:
      return DEFAULT_CONTENT_TYPE;
  }
};

const getFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
};
/**
 * Receive backend resources with the asynchronous fetch API
 */
// TODO: Receive all fetchClient calls with a getState in params
const fetchClient = async (params) => {
  const isParamsString = typeof params === "string";
  const route = isParamsString ? params : params.path;
  // Breaks when imported as submodule babel build UNLESS the submodules root is defined in babel config:
  // ex: babelrcRoots: ["./", path.join(commonDir, "submodules")],
  // See: https://github.com/facebook/create-react-app/pull/8526
  // https://github.com/palantir/blueprint/issues/4112#issuecomment-629718993
  const apiBase = params?.apiBase ?? process.env?.REACT_APP_API_BASE;
  // const apiBase = params && params.apiBase ||
  // process &&
  // process.env &&
  // process.env.REACT_APP_API_BASE ||
  // null;

  if (!apiBase) {
    console.error("fetchClient wasn't given an apiBase parameter or REACT_APP_API_BASE isn't set in env variables.");
    throw new Error("The api base couldn't be establed in fetchClient.");
  }
  const url = `${apiBase}${route}`;

  if (!apiBase) {
    throw new Error("No API base was given");
  }

  const additionalHeaders = params?.additionalHeaders ?? {};
  const authorization = !params?.skipAuthorization
    ? { Authorization: `Bearer ${params?.access_token ?? params?.getState()?.oidc?.user?.access_token ?? null}` }
    : {};

  const contentType = params?.contentType ?? "json";

  const headers =
    params.headers || {
      ...additionalHeaders,
      ...authorization,
    } ||
    {};

  if (contentType !== "form") {
    headers["Content-Type"] = getContentTypeFromType(contentType);
  }

  try {
    const body =
      (contentType === "form" && params.data && getFormData(params.data)) ||
      (params.data && JSON.stringify(params.data)) ||
      null;

    const response = await fetch(url, {
      headers: headers,
      method: params.method || "GET", // The default method of fetch is GET
      credentials: "omit", // https://developer.mozilla.org/sv-SE/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
      body: body,
    });

    if (response.ok === false) {
      // createAsyncAction will handle the rest of the error
      // throw ({name: json.Title, message: json.Details}) This is the propper way of throwing an error but the code is reliant on the json object being thrown.
      throw response;
    } else if (response.status === 204) {
      // In the case when there is no content like when deleting.
      return params.data;
    } else if (contentType === "rss") {
      // const htmlText = await response.text();
      console.warn("Skipping RSS parsing temporarily");
      // return rssResponseInterceptor(htmlText);
    } else if (contentType === "xml") {
      // const htmlText = await response.text();
      console.warn("Skipping XML parsing temporarily");
      // return xmlResponseInterceptor(htmlText);
    } else if (contentType === "json" || contentType === "form") {
      const json = await response.json();

      if (!json) {
        // TODO: check the proper way of cheking json errors
        throw json;
      }
      // TODO: Handle SyntaxError: "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data" Ex. password change
      return json;
    } else if (contentType === "file") {
      const blob = await response.blob();
      return blob;
    }
  } catch (e) {
    console.error(`Error in fetchClient : ${e}. URL : ${url}`);
    throw e;
  }
};
export default fetchClient;

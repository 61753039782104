export const BOGUS = 'BOGUS';
////////////// SECTIONS ///////////////////////////////////////////
export const SET_SECTION = 'SET_SECTION';
// export const ADD_MODULE_TO_SECTION = 'ADD_MODULE_TO_SECTION'
// export const DELETE_MODULE_TO_SECTION = 'DELETE_MODULE_TO_SECTION'
// export const UPDATE_SECTION_DATA = 'UPDATE_SECTION_DATA'
// export const CHANGE_SECTION_MODULE_TYPE = 'CHANGE_SECTION_MODULE_TYPE'
// export const ADD_CA_MODULE_TO_REMOVE = 'ADD_CA_MODULE_TO_REMOVE'
// export const ADD_SUBSECTION_TO_REMOVE = 'ADD_SUBSECTION_TO_REMOVE'
// export const MOVE_SECTION_MODULE = 'MOVE_SECTION_MODULE'
// export const GET_SUBSECTION_EDITION = 'GET_SUBSECTION_EDITION'
// export const CLEAR_SELECTED_SUBSECTION = 'CLEAR_SELECTED_SUBSECTION'
// export const ADD_MODULE_TO_SUBSECTION = 'ADD_MODULE_TO_SUBSECTION'
// export const UPDATE_SUBSECTION_DATA = 'UPDATE_SUBSECTION_DATA'
// export const UPDATE_SUBSECTION_MODULE_CONTENT = 'UPDATE_SUBSECTION_MODULE_CONTENT'
// export const DELETE_MODULE_TO_SUBSECTION = 'DELETE_MODULE_TO_SUBSECTION'
// export const ADD_MODULE_TO_REMOVE_TO_SUBSECTION = 'ADD_MODULE_TO_REMOVE_TO_SUBSECTION'
// export const MOVE_SECTION_MODULE_IN_SUBSECTION = 'MOVE_SECTION_MODULE_IN_SUBSECTION'
// export const UPDATE_SUB_SUBSECTION_DATA = 'UPDATE_SUB_SUBSECTION_DATA'
// export const SET_SECTION_HAS_CHANGE = 'SET_SECTION_HAS_CHANGE'
// // export const SET_SECTION_LOADING = 'SET_SECTION_LOADING'
// export const SET_SECTION_SUCCESS = 'SET_SECTION_SUCCESS'
// // export const SET_SECTION_ERROR = 'SET_SECTION_ERROR'
// export const ADD_MODULE_TO_SUB_SUBSECTION = 'ADD_MODULE_TO_SUB_SUBSECTION'
// export const UPDATE_SUB_SUBSECTION_MODULE_CONTENT = 'UPDATE_SUB_SUBSECTION_MODULE_CONTENT'
// export const DELETE_MODULE_TO_SUB_SUBSECTION = 'DELETE_MODULE_TO_SUB_SUBSECTION'
// export const MOVE_SECTION_MODULE_IN_SUB_SUBSECTION = 'MOVE_SECTION_MODULE_IN_SUB_SUBSECTION'
// export const SET_SUB_SECTION_HAS_CHANGE = 'SET_SUB_SECTION_HAS_CHANGE'
// export const SET_SELECTED_DOCUMENT_SECTION_WEIGHT = 'SET_SELECTED_DOCUMENT_SECTION_WEIGHT'
// export const UPDATE_SECTION_MODULE_CONTENT = 'UPDATE_SECTION_MODULE_CONTENT' // TODO DEPRECATE
// Newer section Actions
export const UPDATE_SECTION_MODULE = 'UPDATE_SECTION_MODULE';
export const DELETE_SECTION_MODULE = 'DELETE_SECTION_MODULE';
export const REPOSITION_SECTION_MODULE = 'REPOSITION_SECTION_MODULE';
export const SET_SECTION_INDEXED = 'SET_SECTION_INDEXED';
export const UPDATE_SECTION_DETAILS = 'UPDATE_SECTION_DETAILS';
export const SET_SECTION_IS_VALID = 'SET_SECTION_IS_VALID';
export const ADD_NEW_SECTION_BLOCK = 'ADD_NEW_SECTION_BLOCK';
export const SAVE_SECTION_IMAGE = 'SAVE_SECTION_IMAGE';
export const SAVE_SECTION_FILE = 'SAVE_SECTION_FILE';
export const REMOVE_SECTION_IMAGE = 'REMOVE_SECTION_IMAGE';
export const REMOVE_SECTION_FILE = 'REMOVE_SECTION_FILE';

export const SAVE_SECTION_LOADING = 'SAVE_SECTION_LOADING';
export const SAVE_SECTION_SUCCESS = 'SAVE_SECTION_SUCCESS';
export const SAVE_SECTION_NOT_FOUND = 'SAVE_SECTION_NOT_FOUND';
export const SAVE_SECTION_ERROR = 'SAVE_SECTION_ERROR';

export const SAVE_BLOCKS_LOADING = 'SAVE_BLOCKS_LOADING';
export const SAVE_BLOCKS_SUCCESS = 'SAVE_BLOCKS_SUCCESS';
export const SAVE_BLOCKS_NOT_FOUND = 'SAVE_BLOCKS_NOT_FOUND';
export const SAVE_BLOCKS_ERROR = 'SAVE_BLOCKS_ERROR';

export const RESET_SAVE_SECTION_NETWORK_STATE = 'RESET_SAVE_SECTION_NETWORK_STATE';

export const DELETE_SECTION_LOADING = 'DELETE_SECTION_LOADING';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_NOT_FOUND = 'DELETE_SECTION_NOT_FOUND';
export const DELETE_SECTION_ERROR = 'DELETE_SECTION_ERROR';

export const PUBLISH_SECTION_LOADING = 'PUBLISH_SECTION_LOADING';
export const PUBLISH_SECTION_SUCCESS = 'PUBLISH_SECTION_SUCCESS';
export const PUBLISH_SECTION_NOT_FOUND = 'PUBLISH_SECTION_NOT_FOUND';
export const PUBLISH_SECTION_ERROR = 'PUBLISH_SECTION_ERROR';

export const RESET_SECTIONS_STATE = 'RESET_SECTIONS_STATE';
export const RESET_DELETE_SECTION_NETWORK_STATE = 'RESET_DELETE_SECTION_NETWORK_STATE';

////////////// IMAGES ///////////////////////////////////////////
export const GET_UPLOADS_LOADING = 'GET_UPLOADS_LOADING';
export const GET_UPLOADS_SUCCESS = 'GET_UPLOADS_SUCCESS';
export const GET_UPLOADS_NOT_FOUND = 'GET_UPLOADS_NOT_FOUND';
export const GET_UPLOADS_ERROR = 'GET_UPLOADS_ERROR';

export const GET_UPLOAD_DATA_LOADING = 'GET_UPLOAD_DATA_LOADING';
export const GET_UPLOAD_DATA_SUCCESS = 'GET_UPLOAD_DATA_SUCCESS';
export const GET_UPLOAD_DATA_NOT_FOUND = 'GET_UPLOAD_DATA_NOT_FOUND';
export const GET_UPLOAD_DATA_ERROR = 'GET_UPLOAD_DATA_ERROR';

export const SET_UPLOAD_CONTAINER_LOADING = 'SET_UPLOAD_CONTAINER_LOADING';
export const SET_UPLOAD_CONTAINER_SUCCESS = 'SET_UPLOAD_CONTAINER_SUCCESS';
export const SET_UPLOAD_CONTAINER_NOT_FOUND = 'SET_UPLOAD_CONTAINER_NOT_FOUND';
export const SET_UPLOAD_CONTAINER_ERROR = 'SET_UPLOAD_CONTAINER_ERROR';

export const UPLOAD_FILE_LOADING = 'UPLOAD_FILE_LOADING';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_NOT_FOUND = 'UPLOAD_FILE_NOT_FOUND';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const UPDATE_UPLOAD_DETAILS = 'UPDATE_UPLOAD_DETAILS';

export const SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES';

////////////// LOCATION ///////////////////////////////////////////
export const SET_PAGE_NAME = 'SET_PAGE_NAME';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

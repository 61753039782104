import React /* , { useState } */ from "react";
import { NavLink } from "react-router-dom";

import useWorkbooks from "hooks/use-workbooks";
import useLoggedInUser from "hooks/use-logged-in-user";

import useTextByRole from "hooks/use-text-by-role";
import SubHeader from "components/SubHeader/SubHeader";
import __ from "constants/static-texts";

const CLASS_NS = "workbook";

const Workbooks = ({ location, match, route, classNs = CLASS_NS }) => {
  const [workbooks /* , workbooksLoading */] = useWorkbooks();
  const { isServiceAdmin } = useLoggedInUser();

  const workbooksTitle = useTextByRole(__.workbook.title);
  const workbooksIntro = useTextByRole(__.workbook.intro);

  // TODO: isServiceAdmin query: är en tillfällig lösning ock ska tas bort när copyn för arbetsbökerna är klar.
  return isServiceAdmin ? (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{workbooksTitle}</h1>
        <div className={`${classNs}_description`}>
          {workbooksIntro.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </SubHeader>
      <div className={`${classNs}`}>
        {workbooks ? (
          <div className={`${classNs}_buttons`}>
            {workbooks.map((workbook) => (
              <NavLink
                to={`${route.path}/${workbook.node.slug}`}
                className={`${classNs}_button`}
                activeClassName="active"
                key={workbook.node.id}>
                {workbook.node.title}
              </NavLink>
            ))}
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

export default Workbooks;

import React, { useEffect, useState, useMemo } from "react";
import { renderRoutes } from "react-router-config";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import __ from "constants/static-texts";
import { isCompanyUser, isBaseProduct, getCompanyFromUser } from "utils/user-helper";

import { setSearchQuery, setFilterCategory } from "actions/librarydocuments-actions";

import useLibraryDocuments from "hooks/use-library-documents";
import useCompanyDocuments from "hooks/use-company-documents";
import useAllUsers from "hooks/use-all-users";
import useProductTypes from "hooks/use-productTypes";
import useLoggedInUser from "hooks/use-logged-in-user";
import useTextByRole from "hooks/use-text-by-role";

import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";
import LibraryDocList from "./LibraryDocList";
import Search from "components/Search/Search";
import Filter from "components/Filter/Filter";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";
import SubHeader from "components/SubHeader/SubHeader";
import Loading from "components/Loading/Loading";

const CLASS_NS = "documents";

const LibraryDocs = ({ route, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();

  const guideUrl = "/documents/:documentId?";
  const routeMatch = useRouteMatch(guideUrl);
  const { documentId } = routeMatch.params;

  const isSingleDocument = routeMatch.isExact && documentId;

  const [librarydocuments, extendLibraryDocTypes, librarydocumentsIsLoading, filteredLibraryDocs] = useLibraryDocuments(
    isSingleDocument ? documentId : null
  );

  const { companiesMap, activeCompany } = useSelector((state) => state.companiesState);
  const { user } = useSelector((state) => state.loggedInUserState);
  const { filteredCompanyDocuments, companyDocumentsIsLoading } = useCompanyDocuments();
  const { isServiceAdmin, isSupervisor, isCompanyReader } = useLoggedInUser();
  const [showCompanyDocs, setShowCompanyDocs] = useState(isCompanyReader);

  const [showButton, setShowButton] = useState(true);
  let [docListSize, setDocListSize] = useState(20);

  useEffect(() => {
    setShowButton(
      showCompanyDocs ? docListSize < filteredCompanyDocuments.length : docListSize < filteredLibraryDocs.length
    );
  }, [filteredLibraryDocs]);

  const docs = useMemo(() => {
    if (showCompanyDocs) {
      return [...filteredCompanyDocuments.slice(0, docListSize)];
    } else {
      return [...filteredLibraryDocs.slice(0, docListSize)];
    }
  }, [showCompanyDocs, docListSize, filteredCompanyDocuments, filteredLibraryDocs]);

  const { productTypes, productTypesIsLoading } = useProductTypes();
  const { users, usersIsLoading } = useAllUsers();
  const { searchQuery, filterCategory } = useSelector((state) => state.librarydocumentsState);

  const documentSearchChange = (value) => {
    setDocListSize(20);
    dispatch(setSearchQuery(value));
  };
  const documentCategoryChange = (value) => {
    setDocListSize(20);
    dispatch(setFilterCategory(value));
  };

  const company =
    ((isServiceAdmin || isSupervisor) && activeCompany) ||
    getCompanyFromUser(isCompanyUser, companiesMap, user, activeCompany) ||
    null;

  const baseProduct = isBaseProduct(company);

  const libraryDocsTitle = useTextByRole(__.document.title);
  const libraryDocsText = useTextByRole(__.document.description);

  const showMore = () => {
    const updatedDocListSize = docListSize + 20;
    setDocListSize(updatedDocListSize);
    setShowButton(
      showCompanyDocs
        ? updatedDocListSize < filteredCompanyDocuments.length
        : updatedDocListSize < filteredLibraryDocs.length
    );
  };

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{libraryDocsTitle}</h1>
        <div className={`${classNs}_description`}>
          {libraryDocsText.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </SubHeader>
      <div className={`${classNs}`}>
        {isSingleDocument ? (
          <Link to={route.path} className={`${classNs}_link_back`}>
            <ArrowLeft />
            {__.document.back}
          </Link>
        ) : (
          <>
            <Search onChange={documentSearchChange} searchQuery={searchQuery} placeholder={__.document.search} />

            <div className={`${classNs}_list-top`}>
              {user && !baseProduct && !isCompanyReader && (
                <ChoiceGroup
                  classNs={`${classNs}_type-switch`}
                  onChange={() => setShowCompanyDocs(!showCompanyDocs)}
                  chosen={showCompanyDocs ? "1" : "0"}
                  choices={[
                    {
                      value: "0",
                      label: "Alla originalmallar",
                    },
                    {
                      value: "1",
                      label: "Uppladdade av företaget",
                    },
                  ]}
                />
              )}
              {isServiceAdmin && !showCompanyDocs ? (
                <Link to={`${route.path}/new/edit`} className={`${classNs}_add`}>
                  {__.document.addNew}
                </Link>
              ) : null}
            </div>
            {extendLibraryDocTypes.length > 0 && !showCompanyDocs && !isCompanyReader && (
              <Filter
                onChange={documentCategoryChange}
                filterAlternatives={extendLibraryDocTypes}
                active={filterCategory}
                defaultAlternative={`${__.filter.all} (${librarydocuments.length})`}
              />
            )}
          </>
        )}

        {!extendLibraryDocTypes.length &&
        (productTypesIsLoading || usersIsLoading || companyDocumentsIsLoading || librarydocumentsIsLoading) ? (
          <Loading />
        ) : (
          <>
            <LibraryDocList classNs={classNs} documents={docs} users={users} isCompanyDocuments={showCompanyDocs} />
            {showButton && (
              <button className={`${classNs}_button_show-more`} onClick={showMore}>
                {__.document.showMore}
              </button>
            )}
          </>
        )}
        {!librarydocumentsIsLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
              documents: librarydocuments,
              productTypes: productTypes,
            })
          : null}
      </div>
    </>
  );
};

export default LibraryDocs;

import React from "react";
import { Link } from "react-router-dom";
import News from "routes/News";
import __ from "constants/static-texts";
import { formatDateInput } from "utils/date-helpers";

import { ReactComponent as Pencil } from "icons/Pencil.svg";
import Tooltip from "components/Tooltip/Tooltip";

const NewsListItem = ({ classNs, news, productTypes }) => {
  const productTypeNames = news.products
    .map((product) => {
      const type = productTypes?.find((pt) => pt.id === product);
      return type ? type.name : product;
    })
    .join(", ");
  return (
    <li className={`${classNs}_list_item`}>
      <div></div>
      <div>{formatDateInput(news.createdAt)}</div>
      <div>{news.title}</div>
      <div>{productTypeNames}</div>
      <div className={`${classNs}_tools`} data-align="center">
        <Link
          to={{ pathname: `${News().path}/item/${news.id}`, state: { disableScroll: true } }}
          className={`${classNs}_tool`}>
          <div className={`${classNs}_icon`} data-tip={__.news.editNews} data-for={`company_list_${news.id}`}>
            <Pencil />
          </div>
        </Link>
      </div>

      <Tooltip theme={"dark"} id={`company_list_${news.id}`} />
    </li>
  );
};

export default NewsListItem;

import { useSelector } from "react-redux";

import { WAITING, LOADING } from "constants/network-states";

/**
 * Provides the product type list
 */
const useProductTypes = () => {
  const { productTypes, productTypesNetworkState } = useSelector((state) => state.productTypesState);
  const productTypesIsLoading = productTypesNetworkState === WAITING || productTypesNetworkState === LOADING;

  return { productTypes, productTypesIsLoading };
};

export default useProductTypes;

import React from 'react';
import PropTypes from 'prop-types';
import TableEditorCell from './table-editor-cell';

import { getMatrixIndex, cleanTable } from '../../utils/editor';

class TableEditorCellContainer extends React.Component {
  onChange(editorState) {
    if (editorState !== this.props.cellContent) {
      this.props.onCellChange(this.props.rowIndex, this.props.colIndex, editorState);
    }
  }

  merge(direction) {
    const rowIndex = this.props.rowIndex;
    const colIndex = this.props.colIndex;
    let editedTable = this.props.tableContent;
    let mergingCell = null,
      mergingColspan = null,
      colspan = null,
      editedColspanCols = null,
      mergingRowspan = null,
      rowspan = null,
      editedRowspanCols = null;
    const tableMatrix = this.props.tableMatrix;

    const selectedCell = editedTable.rows[rowIndex].cols[colIndex];
    const selectedRowspan = selectedCell.rowspan ? selectedCell.rowspan : 1;
    const selectedColspan = selectedCell.colspan ? selectedCell.colspan : 1;

    const currentMatrixCellIndex = getMatrixIndex(rowIndex, colIndex, tableMatrix);

    switch (direction) {
      case 'up':
        const matrixCellAbove =
          tableMatrix[currentMatrixCellIndex.rowId - 1][currentMatrixCellIndex.colId];
        const mergingRowAbove = editedTable.rows[matrixCellAbove.tableContentRow];
        //Corner case when merging with single column, then remove full row above
        if (mergingRowAbove.cols.length === 1) {
          this.props.destroyRow(matrixCellAbove.tableContentRow);
          return;
        }

        mergingCell = mergingRowAbove.cols[matrixCellAbove.tableContentCol];

        mergingRowspan = mergingCell.rowspan ? mergingCell.rowspan : 1;

        rowspan = selectedRowspan + mergingRowspan;

        editedRowspanCols = [
          ...editedTable.rows[matrixCellAbove.tableContentRow].cols.slice(
            0,
            matrixCellAbove.tableContentCol
          ),
          {
            ...editedTable.rows[matrixCellAbove.tableContentRow].cols[
              matrixCellAbove.tableContentCol
            ],
            rowspan: rowspan
          },
          ...editedTable.rows[matrixCellAbove.tableContentRow].cols.slice(
            matrixCellAbove.tableContentCol + 1
          )
        ];
        const updatedTableUp = {
          ...editedTable
        };
        updatedTableUp.rows[matrixCellAbove.tableContentRow].cols = editedRowspanCols;
        updatedTableUp.rows[rowIndex].cols = updatedTableUp.rows[rowIndex].cols.filter(
          (col, index) => index !== colIndex
        );
        editedTable = {
          ...updatedTableUp
        };
        break;
      case 'down':
        const matrixCellBelow =
          tableMatrix[currentMatrixCellIndex.rowId + selectedRowspan][currentMatrixCellIndex.colId];

        const mergingRowBelow = editedTable.rows[matrixCellBelow.tableContentRow];

        //Corner case when merging with single column, then remove full row below
        if (mergingRowBelow.cols.length === 1) {
          this.props.destroyRow(matrixCellBelow.tableContentRow);
          return;
        }
        mergingCell = mergingRowBelow.cols[matrixCellBelow.tableContentCol];

        mergingRowspan = mergingCell.rowspan ? mergingCell.rowspan : 1;

        rowspan = selectedRowspan + mergingRowspan;

        editedRowspanCols = [
          ...editedTable.rows[rowIndex].cols.slice(0, colIndex),
          {
            ...editedTable.rows[rowIndex].cols[colIndex],
            rowspan: rowspan
          },
          ...editedTable.rows[rowIndex].cols.slice(colIndex + 1)
        ];

        const updatedTableDown = {
          ...editedTable
        };
        updatedTableDown.rows[rowIndex].cols = editedRowspanCols;
        updatedTableDown.rows[matrixCellBelow.tableContentRow].cols = updatedTableDown.rows[
          matrixCellBelow.tableContentRow
        ].cols.filter((col, index) => index !== matrixCellBelow.tableContentCol);

        editedTable = {
          ...updatedTableDown
        };
        break;
      case 'right':
        //TODO: WHAT CELL TO MERGE
        mergingCell = editedTable.rows[rowIndex].cols[colIndex + 1];

        mergingColspan = mergingCell.colspan ? mergingCell.colspan : 1;

        colspan = selectedColspan + mergingColspan;
        // add colspan to selected cell
        editedColspanCols = [
          ...editedTable.rows[rowIndex].cols.slice(0, colIndex),
          {
            ...editedTable.rows[rowIndex].cols[colIndex],
            colspan: colspan
          },
          ...editedTable.rows[rowIndex].cols.slice(colIndex + 1)
        ];
        // remove cell on the right
        editedTable = {
          ...editedTable,
          rows: [
            ...editedTable.rows.slice(0, rowIndex),
            {
              ...editedTable.rows[rowIndex],
              cols: editedColspanCols.filter((col, index) => index !== colIndex + 1)
            },
            ...editedTable.rows.slice(rowIndex + 1)
          ]
        };
        break;
      case 'left':
        // selectedCell = editedTable.rows[rowIndex].cols[colIndex]

        //TODO: WHAT CELL TO MERGE
        mergingCell = editedTable.rows[rowIndex].cols[colIndex - 1];

        mergingColspan = mergingCell.colspan ? mergingCell.colspan : 1;

        colspan = selectedColspan + mergingColspan;
        // add colspan to selected cell
        editedColspanCols = [
          ...editedTable.rows[rowIndex].cols.slice(0, colIndex),
          {
            ...editedTable.rows[rowIndex].cols[colIndex],
            colspan: colspan
          },
          ...editedTable.rows[rowIndex].cols.slice(colIndex + 1)
        ];
        // remove cell on the right
        editedTable = {
          ...editedTable,
          rows: [
            ...editedTable.rows.slice(0, rowIndex),
            {
              ...editedTable.rows[rowIndex],
              cols: editedColspanCols.filter((col, index) => index !== colIndex - 1)
            },
            ...editedTable.rows.slice(rowIndex + 1)
          ]
        };
        break;
      default:
      // Nothing
    }

    editedTable = cleanTable(editedTable);

    this.props.onTableChange(editedTable, true);
  }

  render() {
    return (
      <TableEditorCell
        {...this.props}
        onChange={(editorState) => {
          this.onChange(editorState);
        }}
        isSubmitted={this.props.isSubmitted}
        editTableMode={this.props.editTableMode}
        merge={(direction) => this.merge(direction)}
        tableMatrix={this.props.tableMatrix}
      />
    );
  }
}

TableEditorCellContainer.propTypes = {
  tableContent: PropTypes.object,
  tableMatrix: PropTypes.array,
  rowIndex: PropTypes.number,
  colIndex: PropTypes.number,
  cellContent: PropTypes.string,
  onCellChange: PropTypes.func,
  isSubmitted: PropTypes.bool,
  destroyRow: PropTypes.func
};
TableEditorCellContainer.getDefaultProps = {
  tableContent: {
    rows: []
  },
  rowIndex: 0,
  colIndex: 0,
  isSubmitted: false
};

export default TableEditorCellContainer;

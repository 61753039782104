import React, { useState, useMemo, useEffect, useCallback } from "react";

import Selector from "components/Selector/Selector";
import useWorkbooks from "hooks/use-workbooks";
import useWorkbook from "hooks/use-workbook";
import __ from "constants/static-texts";
import { ReactComponent as ChevronDown } from "icons/chevron-down1.svg";

const CLASS_NS = "workbook-section-picker";

const WorkbookSectionPicker = ({ classNs = CLASS_NS, onComplete, workbookRef, partRef, chapterRef }) => {
  const [workbooks, workbooksLoading] = useWorkbooks();

  const [workbookOption, setWorkbookOption] = useState(null);
  const [partOption, setPartOption] = useState(null);
  const [chapterOption, setChapterOption] = useState(null);

  const [workbook] = useWorkbook(workbookOption?.value);

  // Set preselected states if applicable
  const getWorkBookOption = useCallback(() => {
    return workbooks
      ?.filter((book) => book.node.slug === workbookRef)
      .map((book) => ({
        value: book.node.slug,
        id: book.node.id,
        label: book.node.title,
      }))?.[0];
  }, [workbookRef, workbooks]);

  useEffect(() => {
    setWorkbookOption(getWorkBookOption());
  }, [workbooks, getWorkBookOption]);

  const getPartOption = useCallback(() => {
    return workbook?.blbGroupCourseSections.blbCourseSections
      ?.filter((part) => part.blbCourseSectionId === partRef)
      .map((part) => ({
        value: part.blbCourseSectionId,
        id: part.blbCourseSectionId,
        label: part.blbCourseSectionTitle,
      }))?.[0];
  }, [partRef, workbook?.blbGroupCourseSections.blbCourseSections]);

  useEffect(() => {
    setPartOption(getPartOption());
  }, [getPartOption]);

  const workbooksOptions = useMemo(
    () =>
      workbooks
        ? workbooks?.map((book) => ({
            value: book.node.slug,
            id: book.node.id,
            label: book.node.title,
          }))
        : [],
    [workbooks]
  );

  const partsOptions = useMemo(
    () =>
      workbook?.blbGroupCourseSections.blbCourseSections?.map((part) => ({
        value: part.blbCourseSectionId,
        id: part.blbCourseSectionId,
        label: part.blbCourseSectionTitle,
      })),
    [workbook?.blbGroupCourseSections.blbCourseSections]
  );

  const getChapterOptions = useCallback(
    (filter) =>
      workbook?.blbGroupCourseSections?.blbCourseSections
        ?.filter((part) => part.blbCourseSectionId === filter)
        .map((part) =>
          part.blbCourseSectionChapterPosts.map((chapter) => ({
            value: chapter.blbChapterId,
            id: chapter.blbChapterId,
            label: chapter.title,
          }))
        )
        .flat(Infinity) ?? [],
    [workbook?.blbGroupCourseSections.blbCourseSections]
  );

  useEffect(() => {
    const chapterOption = getChapterOptions(partRef).filter((option) => option.value === chapterRef)?.[0];
    setChapterOption(chapterOption);
  }, [chapterRef, getChapterOptions, partRef]);

  const chapterOptions = getChapterOptions(partOption?.value);

  const onSelectedWorkbook = (choice) => {
    setWorkbookOption(choice);
    setPartOption(null);
    setChapterOption(null);
  };
  const onSelectedPart = (choice) => {
    setPartOption(choice);
    setChapterOption(null);
  };
  const onSelectedChapter = (choice) => {
    setChapterOption(choice);
  };

  const submit = () => {
    onComplete({
      workbook: workbookOption.value,
      part: partOption.value,
      chapter: chapterOption.value,
    });
  };

  const isSaveable = useMemo(() => chapterOption && chapterOption.value !== chapterRef, [chapterOption, chapterRef]);

  return (
    <fieldset className={`${classNs}`}>
      <legend className={`${classNs}_label`}>{__.workbook.chapter_selector}</legend>
      <div className={`${classNs}_selectors`}>
        <Selector
          options={workbooksOptions}
          placeholder={__.workbook.choose_book}
          selectedOption={workbookOption}
          onSelectedOption={onSelectedWorkbook}
          ArrowIcon={ChevronDown}
          disabled={workbooksLoading}
        />
        <Selector
          options={partsOptions}
          placeholder={__.workbook.choose_part}
          selectedOption={partOption}
          onSelectedOption={onSelectedPart}
          ArrowIcon={ChevronDown}
          disabled={workbookOption === null ? true : false}
        />
        <Selector
          options={chapterOptions}
          placeholder={__.workbook.choose_chapter}
          selectedOption={chapterOption}
          onSelectedOption={onSelectedChapter}
          ArrowIcon={ChevronDown}
          disabled={partOption ? false : true}
        />
      </div>
      <button className={`${classNs}_submit`} disabled={isSaveable ? false : true} onClick={submit}>
        {__.guide.section.choose_chapter}
      </button>
    </fieldset>
  );
};

export default WorkbookSectionPicker;

// https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
export const getRandomInteger = (min = 1, max = 9999999) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getRandomLeters = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * getUniqueId - Genrerates an id with enough entrpoy to be useful for current project
 *
 * @returns {string} A random string with letters and numbers
 */
export const getUniqueId = () => {
  return getRandomLeters(20) + getRandomInteger();
};

import React from "react";
import SubFooter from "components/SubFooter/SubFooter";
const CLASS_NS = "footer";

const Footer = ({ classNs = CLASS_NS }) => {
  return (
    <footer className={`${classNs}`}>
      <div className={`${classNs}_subfooter`}>
        <SubFooter />
      </div>
      <div className={`${classNs}_container`}></div>
    </footer>
  );
};

export default Footer;

import React from "react";
import PropTypes from "prop-types";
import "./_LayoutIcons.scss";

/**
 * Expects one ore more icon or icon-like (icon buttons fpr example) components.
 * Useful for lists of tools and such.
 */
const LayoutIcons = ({ justify, align, variant, children }) => {
  return (
    <div
      className="layout-icons"
      style={{
        "--layout-icons--justify": justify,
        "--layout-icons--align": align,
      }}
      data-variant={variant}>
      {children}
    </div>
  );
};

LayoutIcons.propTypes = {
  gap: PropTypes.string,
  justify: PropTypes.oneOf(["start", "center", "end"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  variant: PropTypes.oneOf(["compressed", "regular", "spaced"]),
};

LayoutIcons.defaultProps = {
  variant: "compressed",
};

export default LayoutIcons;

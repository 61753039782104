import React from "react";

import { useParams, useHistory } from "react-router-dom";
import __ from "constants/static-texts";
import { Overlay } from "react-draft-editor";

const CLASS_NS = "form_overlay";
const ShowCompanies = ({ cancelUrl, users, userRoles, nonValidatedUsersWithCompanyNames }) => {
  const history = useHistory();
  const { userId } = useParams();

  const currentUser = nonValidatedUsersWithCompanyNames.find(user => user.id === parseInt(userId)) || users.find((user) => user.id === parseInt(userId));

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(cancelUrl);
  };

  return (
    <Overlay cancelUrl={cancelUrl}>
      <h2>{__.users.companiesTitle}</h2>
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        <div className={"users_instances"}>
          <p className={"users_instances_instance"}>{__.users.allCompanies}</p>
          {currentUser && currentUser.companyNames.map((company) => {
            return (
              <div className={"users_instances_instance"} key={company.id}>
                {company.name}
              </div>
            );
          })}
        </div>
        <button type="submit" className={`${CLASS_NS}_button`}>
          Stäng
        </button>
      </form>
    </Overlay>
  );
};

export default ShowCompanies;

import React from 'react';
import { Link } from 'react-router-dom';
import LinkIcon from 'components/LinkIcon/LinkIcon';
import LayoutIcons from 'components/LayoutIcons/LayoutIcons';

import GuideRoutes from 'routes/Guides';

import __ from 'constants/static-texts';

import useProductTypes from 'hooks/use-productTypes';

import { ReactComponent as IconPencil } from 'icons/Pencil.svg';
import { ReactComponent as IconCog } from 'icons/Cog.svg';

function GuideListItem({ classNs, guide }) {
  const { productTypes } = useProductTypes();
  const productType = productTypes.find((pt) => pt.id === guide.product);

  const guideInstances =
    (guide.companyIdsHavingAnInstanceOriginatingFromThisBlueprint?.length && (
      <Link
        data-tip={__.guide.showCompanies}
        data-for={`guide_list_${guide.id}`}
        to={`${GuideRoutes().path}/guide/${guide.id}/instances`}
        className={`${classNs}_link`}>
        {guide.companyIdsHavingAnInstanceOriginatingFromThisBlueprint.length} företag
      </Link>
    )) ||
    '0 företag';

  // TODO: Remove the link on guide.title
  return (
    <li className={`${classNs}_list_item`}>
      <div>{guide.title}</div>
      <div>{productType && productType.name}</div>
      <div>{guideInstances}</div>
      <LayoutIcons>
        <LinkIcon
          link={`${GuideRoutes().path}/${guide.id}/guide-part-editor/`}
          icon={<IconPencil aria-hidden />}
          aria-label={__.guide.editGuide}
        />
        <LinkIcon
          link={{ pathname: `${GuideRoutes().path}/guide/${guide.id}`, state: { disableScroll: true } }}
          icon={<IconCog aria-hidden />}
          aria-label={__.guide.editGuideSettings}
        />
      </LayoutIcons>
    </li>
  );
}

export default GuideListItem;

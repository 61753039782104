// import loadable from "@loadable/component";
import Login from "./components/login";
// import LoginContainer from './components/login/login.container'
import { UserIsNotAuthenticated } from "utils/auth-helpers";
import { withRouter } from "react-router";

// import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
// import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
// const locationHelper = locationHelperBuilder({});

// const LoadableLoginContainer = loadable(() =>
//   import(/* webpackChunkName: "LoginContainer" */ "./components/login/login.container")
// );

// export const userIsNotAuthenticated = connectedRouterRedirect({
//   // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
//   redirectPath: (state, ownProps) => {
//     return locationHelper.getRedirectQueryParam(ownProps) || '/'},
//   // This prevents us from adding the query parameter when we send the user away from the login page
//   allowRedirectBack: false,
//    // If selector is true, wrapper will not redirect
//    // So if there is no user data, then we show the page
//   authenticatedSelector: state => state.oidc.user === null,
//   // A nice display name for this check
//   wrapperDisplayName: 'UserIsNotAuthenticated'
// })

const LoginRoute = () => ({
  path: "/login",
  component: withRouter(UserIsNotAuthenticated(Login)),
});

export default LoginRoute;

// import Guide from "./components/Guide";

// import { UserIsAuthenticated } from "utils/auth-helpers";

// // NB! Keeping the wording as singular as multiple guides might be produced in the future.
// const GuidesRoute = () => ({
//   path: "/guide", // include the additional slug for testing current route
//   component: UserIsAuthenticated(Guide),
//   exact: true,
// });

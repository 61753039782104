import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import useForm from "hooks/use-form";
import __ from "constants/static-texts";
import { Overlay } from "react-draft-editor";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";

const CLASS_NS = "form_overlay";

const UploadFile = ({ cancelClick, onUploadClick, canEditVisibilty = false, showUpload }) => {
  const { error, documentAddNetworkState } = useSelector((state) => state.companyDocsState);
  const STATUS_LOADING = "loading";
  const STATUS_SUCCESS = "done";
  const STATUS_ERROR = "error";
  const STATUS_RESET = "reset";
  const initialDocument = {
    title: "",
    file: null,
    isForManagementOnly: false,
  };

  const { inputs, handleChange, resetForm } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...initialDocument,
    }
  );

  const fileInput = useRef(null);
  const mgmtOnlyInput = useRef(null);
  const [fileError, setFileError] = useState(false);
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [animationDone, setAnimationDone] = useState(false);
  const [InputDisabled, setInputsDisabled] = useState(false);

  document.addEventListener("OverlaySnackbarMessage", (e) => {
    setAnimationDone(e.detail.animationDone);
  });

  const resetErrors = () => {
    setFileError(false);
    setSnackbarStatus(STATUS_RESET);
    setSnackbarMessage("");
  };

  const resetInputs = () => {
    resetForm();
    // Setting DOM node of input of type file to null specifically to reset
    // as updating input state will have no effect
    fileInput.current.value = null;
  };

  const cancel = () => {
    resetInputs();
    resetErrors();
    setInputsDisabled(false);
    setAnimationDone(false);
    cancelClick();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrors();
    onUploadClick(inputs);
  };

  const onChange = (e) => {
    if (e.target.name === "file") {
      const fileName = e.target.value.split("\\").pop().split(".").shift();
      inputs.title = fileName;
      setFileError(false);
      resetErrors();
    }
    handleChange(e);
  };

  useEffect(() => {
    if (documentAddNetworkState === "LOADING") {
      setSnackbarStatus(STATUS_LOADING);
      setSnackbarMessage(__.upload.loading);
      setInputsDisabled(true);
    }

    if (documentAddNetworkState === "SUCCESS") {
      setSnackbarStatus(STATUS_SUCCESS);
      setSnackbarMessage(__.upload.success);
    }

    if (documentAddNetworkState === "ERROR") {
      error
        .json()
        .then((err) => {
          if (err.title === "File content missing") {
            setFileError(true);
            setSnackbarStatus(STATUS_ERROR);
            setSnackbarMessage(__.upload.error_file);
          }
        })
        .catch((err) => {
          if (err && err.message.includes("Body has already been consumed")) {
            return resetInputs();
          }

          setSnackbarStatus(STATUS_ERROR);
          setSnackbarMessage(__.upload.error_general);
        });
    }
  }, [documentAddNetworkState, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Overlay
      cancelClick={cancel}
      onSubmit={handleSubmit}
      open={showUpload}
      status={snackbarStatus}
      message={snackbarMessage}>
      <h2>{__.upload.add}</h2>
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        <>
          <label id="file" className={`${CLASS_NS}_form-row`} htmlFor="file">
            <span className="label">{__.document.addFile}</span>
            <input
              required={true}
              type="file"
              name="file"
              onChange={onChange}
              data-invalid={fileError}
              ref={fileInput}
              disabled={InputDisabled}
            />
          </label>

          {canEditVisibilty && (
            <div className={`${CLASS_NS}_form-row`}>
              <ChoiceGroup
                onChange={handleChange}
                chosen={inputs.isForManagementOnly ? "1" : "0"}
                type="checkbox"
                inversed={true}
                groupName="isForManagementOnly"
                choices={[
                  {
                    value: "1",
                    label: __.upload.managementOnly,
                    name: "isForManagementOnly",
                    disabled: InputDisabled,
                  },
                ]}
              />
            </div>
          )}
        </>

        <div className="button-row">
          <button type="button" className={animationDone ? "button" : "button-inverted"} onClick={cancel}>
            {animationDone ? __.upload.close : __.upload.cancel}
          </button>
          {!animationDone && (
            <button
              type="submit"
              className="button"
              disabled={
                !inputs.title || /^\s*$/.test(inputs.title) || !inputs.file || documentAddNetworkState === "LOADING"
              }>
              {__.upload.upload}
            </button>
          )}
        </div>
      </form>
    </Overlay>
  );
};

export default UploadFile;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "actions/users-actions";

import { WAITING, LOADING, SUCCESS } from "constants/network-states";

/**
 * Provides the documents list
 */
const useAllUsers = () => {
  const dispatch = useDispatch();
  const { user: loggedInUser, loggedInUserNetworkState } = useSelector((state) => state.loggedInUserState);
  const { users, usersNetworkState, usersMap } = useSelector((state) => state.usersState);
  const { userRoles, userRolesNetworkState } = useSelector((state) => state.userRolesState);

  const fetchUsers = () => {
    //users.length === 0 && usersNetworkState === WAITING && dispatch(getUsers());
    usersNetworkState === WAITING && dispatch(getUsers());
  };

  // const fetchUserRoles = () => {
  //   userRoles.length === 0 && userRolesNetworkState === WAITING && dispatch(getUserRoles());
  // };

  const usersIsLoading =
    usersNetworkState === WAITING ||
    usersNetworkState === LOADING ||
    userRolesNetworkState === WAITING ||
    userRolesNetworkState === LOADING;

  useEffect(() => {
    if (loggedInUserNetworkState === SUCCESS && loggedInUser) {
      fetchUsers();
    }
  }, [loggedInUserNetworkState, loggedInUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return { users, usersIsLoading, userRoles, usersMap };
};

export default useAllUsers;

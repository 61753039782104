import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import __ from "constants/static-texts";

import { isGuideBlueprint } from "utils/guide";

import GuideViewerToolDone from "./GuideViewerToolDone";
import GuideViewerToolOpen from "./GuideViewerToolOpen";
import GuideViewerResponseSlots from "./GuideViewerResponseSlots";

import { updateBlockValue, setActiveComment, openRequirement, closeRequirement } from "actions/guide-actions";

import useUsers from "hooks/use-users";
import useLoggedInUser from "hooks/use-logged-in-user";

import IconModal from "components/IconModal/IconModal";
import Assign from "components/Assign/Assign";
import Comment from "components/Comment/Comment";
import Tooltip from "components/Tooltip/Tooltip";
import RichText from "components/RichText/RichText";

import { guideSectionTypes } from "constants/guide-types";

import { getUserName } from "utils/user-helper";

import { ReactComponent as ChatBubble } from "icons/Chat-bubble.svg";
import { ReactComponent as User } from "icons/User.svg";
import { ReactComponent as Eye } from "icons/Eye.svg";
import { ReactComponent as EyeStrike } from "icons/Eye-strike.svg";

const CLASS_NS = "guide-viewer-requirement";

const GuideViewerRequirement = ({ requirement, index, classNs = CLASS_NS }) => {
  const { guideId } = useParams();
  const dispatch = useDispatch();

  const [requirementIsDone, setRequirementIsDone] = useState(requirement.isDone);
  const [requirementIsEnabled, setRequirementIsEnabled] = useState(requirement.isEnabled);
  const [isOpen, setIsOpen] = useState(false);
  const { usersIsLoading, usersMap } = useUsers();

  const [closeAssignModal, setCloseAssignModal] = useState(false);

  const { isSupervisor, isServiceAdmin, isCompanyReader } = useLoggedInUser();

  const { guide, guideMeta, activeRequirements } = useSelector((state) => state.guideState);

  const disabled = !requirementIsEnabled;

  const onOpenClick = () => {
    if (!disabled) {
      isOpen ? dispatch(closeRequirement(requirement.id)) : dispatch(openRequirement(requirement.id));
    }
  };

  const updateBlock = (data) => {
    dispatch(
      updateBlockValue({
        guideId,
        blocks: [
          {
            ...requirement,
            id: requirement.id.replace(`${guideSectionTypes.GUIDE_REQUIREMENT}_`, ""),
            parentId: requirement.parentId.replace(`${guideSectionTypes.GUIDE_MAIN_REQUIREMENT}_`, ""),
            ...data,
          },
        ],
      })
    );
  };

  const setRequirementDone = () => {
    const isDone = !requirementIsDone;
    updateBlock({ isDone: isDone });
    setRequirementIsDone(isDone);
  };
  const setRequirementEnabled = () => {
    const isEnabled = !requirementIsEnabled;
    updateBlock({ isEnabled: isEnabled });
    setRequirementIsEnabled(isEnabled);
  };

  const assignRequirement = (userId, date) => {
    setCloseAssignModal(true);
    updateBlock({ assignedToUserId: userId, assignmentDeadline: date });
  };

  const assignedUserName =
    usersMap && usersMap[requirement.assignedToUserId]
      ? getUserName(usersMap[requirement.assignedToUserId])
      : "Ej satt";

  const getAssignedUserText = () => {
    return `${__.assign.assigned} ${assignedUserName}`;
  };

  const assignedTooltip =
    !usersIsLoading && requirement.assignedToUserId ? getAssignedUserText() : __.guide.requirement.assign_tooltip;

  useEffect(() => {
    setRequirementIsDone(requirement.isDone);
  }, [requirement]);

  useEffect(() => {
    const isOpen = activeRequirements.includes(requirement.id);
    setIsOpen(isOpen);
  }, [activeRequirements, requirement]);

  const status = disabled ? "disabled" : isCompanyReader ? "reader" : requirementIsDone ? "done" : "not_started";

  const responseSlots = Object.values(guide)
    .filter(block => block.parentId === requirement.id);
  return (
    <div className={`${classNs}_holder`} data-status={status} id={`${requirement.id}`}>
      {(isSupervisor || isServiceAdmin) && !isGuideBlueprint(guideMeta) && (
        <label
          className={`${classNs}_enabled`}
          data-for={"eyeId"}
          data-tip={requirementIsEnabled ? __.guide.requirement.hide : __.guide.requirement.show}>
          <input type="checkbox" onChange={setRequirementEnabled} checked={requirementIsEnabled} />
          {requirementIsEnabled && <Eye />}
          {!requirementIsEnabled && <EyeStrike />}
        </label>
      )}
      <Tooltip id={"eyeId"} theme="dark" />
      <div className={`${classNs}`} data-status={status}>
        <div className={`${classNs}_toolbar`}>
          <h4 className={`${classNs}_title`}>
            {`${requirement.title}`}
            {!requirementIsEnabled ? __.guide.requirement.not_relevant : ""}
          </h4>
          {!isCompanyReader ? (
            <div className={`${classNs}_tools`}>
              <IconModal
                extraClass={`${classNs}_assign`}
                text={requirement?.assignedToUserId ? "1" : ""}
                title={__.assign.requirement.title}
                tooltip={{ text: assignedTooltip, theme: "dark", id: `requirement_assign_${requirement.id}` }}
                blockData={requirement}
                closeModal={closeAssignModal}
                clickHandler={() => setCloseAssignModal(false)}
                disabled={disabled}
                Icon={User}>
                <Assign
                  onAssign={assignRequirement}
                  assignmentData={requirement}
                  description={__.assign.requirement.description}
                />
              </IconModal>
              <IconModal
                extraClass={`${classNs}_comment`}
                text={requirement?.commentIds.length ? requirement?.commentIds.length : ""}
                title={__.comment.requirement.title}
                tooltip={{
                  text: __.guide.requirement.comment_tooltip,
                  theme: "dark",
                  id: `requirement_comment_${requirement.id}`,
                }}
                disabled={disabled}
                blockData={requirement}
                isComment={true}
                onClose={() => dispatch(setActiveComment(null))}
                Icon={ChatBubble}>
                <Comment commentData={requirement} type="requirement" />
              </IconModal>
            </div>
          ) : (
            <div className={`${classNs}_responsible`}>
              {`${__.assign.responsible}: `}
              <span>{assignedUserName}</span>
            </div>
          )}
        </div>
        <div className={`${classNs}_titlebar`}>
          <div className={`${classNs}_titlebar_left`}>
            {!isCompanyReader && (
              <GuideViewerToolDone
                classNs={classNs}
                hideText={true}
                checked={requirementIsDone}
                onClick={setRequirementDone}
                disabled={disabled}
                tooltip={{
                  text: requirementIsDone ? __.guide.requirement.undone_tooltip : __.guide.requirement.done_tooltip,
                  theme: "light",
                  id: `requirement_done_${requirement.id}`,
                }}
              />
            )}
            <div className={`${classNs}_description`} data-is-open={isOpen} onClick={onOpenClick}>
              <RichText nodes={requirement.description} headingLevel={2} />
            </div>
          </div>
          <GuideViewerToolOpen disabled={disabled} classNs={classNs} isOpen={isOpen} onClick={onOpenClick} />
        </div>
        {isOpen && responseSlots.length ?
          (<GuideViewerResponseSlots responseSlots={responseSlots} />) : null
        }
      </div>
    </div>
  );
};

export default GuideViewerRequirement;

import React from 'react';
import PropTypes from 'prop-types';

import TableEditor from './table-editor';

import { getFirstEmpty, cleanTable } from '../../utils/editor';
import { uniqWithEqual } from '../../utils/js-helpers';

class TableEditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transitioningColIndex: -1,
      editTableMode: false
    };
  }

  createEmptyCellContent() {
    return '';
  }
  newRowState(colCount) {
    const rowState = {
      cols: new Array(colCount).fill({
        cellContent: this.createEmptyCellContent()
      })
    };
    return rowState;
  }

  tableClassName(colCount) {
    return 'table-editor-table--' + colCount + '-cols';
  }

  colCount(rows) {
    rows = rows || this.props.tableContent.rows;
    let count = 0;
    rows.length &&
      rows[0].cols.forEach((col) => {
        count += col.colspan ? col.colspan : 1;
      });
    return count;
  }

  createTableMatrix() {
    if (this.state.editTableMode) {
      const rows = this.props.tableContent.rows;

      const matrix = new Array(rows.length)
        .fill(0)
        .map(() =>
          new Array(this.colCount()).fill({ tableContentRow: null, tableContentCol: null })
        );

      rows.forEach((row, rowIndex) => {
        row.cols.forEach((col, colIndex) => {
          const colspan = col.colspan ? col.colspan : 1;
          const rowspan = col.rowspan ? col.rowspan : 1;

          const firstEmptyCell = getFirstEmpty(matrix);

          for (let i = firstEmptyCell.rowId; i < firstEmptyCell.rowId + rowspan; i++) {
            for (let j = firstEmptyCell.colId; j < firstEmptyCell.colId + colspan; j++) {
              matrix[i][j] = { tableContentRow: rowIndex, tableContentCol: colIndex };
            }
          }
        });
      });
      return matrix;
    }
    return [];
  }

  insertRow() {
    const newRow = this.newRowState(this.colCount());
    const tableStateToSet = {
      ...this.props.tableContent,
      rows: [...this.props.tableContent.rows, newRow]
    };
    this.props.onChange(tableStateToSet, true);
    setTimeout(() => {
      this.props.onContentHeightChanged();
    }, 10);
  }

  insertCol() {
    const tableStateToSet = {
      ...this.props.tableContent,
      rows: this.props.tableContent.rows.map((row) => {
        return {
          ...row,
          cols: [
            ...row.cols,
            {
              cellContent: this.createEmptyCellContent()
            }
          ]
        };
      })
    };
    this.setState(
      {
        ...this.state,
        transitioningColIndex: this.colCount()
      },
      () => {
        this.props.onChange(tableStateToSet, true);
        setTimeout(() => {
          this.setState({
            ...this.state,
            transitioningColIndex: -1
          });
        }, 10);
      }
    );
  }

  destroyRow(rowIndex) {
    const matrix = this.createTableMatrix();
    const uniqueRow = uniqWithEqual(matrix[rowIndex]);

    let tableStateToSet = {
      ...this.props.tableContent
    };
    uniqueRow.reverse().forEach((tableCellToRemove) => {
      const tableCell =
        tableStateToSet.rows[tableCellToRemove.tableContentRow].cols[
          tableCellToRemove.tableContentCol
        ];
      const rowspan = tableCell.rowspan ? tableCell.rowspan : 1;

      tableStateToSet = {
        ...tableStateToSet,
        rows: tableStateToSet.rows.map((row, index) => {
          if (index === rowIndex && index === tableCellToRemove.tableContentRow) {
            if (rowspan > 1) {
              const uniqueRowNext = uniqWithEqual(matrix[rowIndex + 1]);
              let nextRowColIndex = 0;
              let foundCol = false;
              uniqueRowNext.forEach((cellNext) => {
                if (cellNext.tableContentRow === index + 1 && !foundCol) {
                  nextRowColIndex++;
                }
                if (
                  cellNext.tableContentRow === tableCellToRemove.tableContentRow &&
                  cellNext.tableContentCol === tableCellToRemove.tableContentCol
                ) {
                  foundCol = true;
                }
              });
              tableStateToSet.rows[index + 1].cols.splice(nextRowColIndex, 0, {
                ...tableCell,
                rowspan: rowspan - 1
              });
            }
            return {
              ...row,
              cols: row.cols.filter((col, cIndex) => {
                return cIndex !== tableCellToRemove.tableContentCol;
              })
            };
          } else if (index === tableCellToRemove.tableContentRow) {
            if (rowspan > 1) {
              tableStateToSet.rows[tableCellToRemove.tableContentRow].cols[
                tableCellToRemove.tableContentCol
              ] = { ...tableCell, rowspan: rowspan - 1 };
            }
            return row;
          } else {
            return row;
          }
        })
      };
    });

    tableStateToSet = cleanTable(tableStateToSet);
    this.props.onChange(tableStateToSet, true);
  }

  destroyCol(colIndex) {
    const matrix = this.createTableMatrix();
    const column = matrix.map((row) => {
      return row[colIndex];
    });
    const uniqueCol = uniqWithEqual(column);

    let tableStateToSet = {
      ...this.props.tableContent
    };
    uniqueCol.forEach((tableCellToRemove) => {
      const tableCell =
        tableStateToSet.rows[tableCellToRemove.tableContentRow].cols[
          tableCellToRemove.tableContentCol
        ];
      const colspan = tableCell.colspan ? tableCell.colspan : 1;

      tableStateToSet = {
        ...tableStateToSet,
        rows: tableStateToSet.rows.map((row, index) => {
          if (colspan > 1) {
            if (index === tableCellToRemove.tableContentRow) {
              tableStateToSet.rows[tableCellToRemove.tableContentRow].cols[
                tableCellToRemove.tableContentCol
              ] = { ...tableCell, colspan: colspan - 1 };
            } else {
            }
            return row;
          } else {
            if (index === tableCellToRemove.tableContentRow) {
              return {
                ...row,
                cols: [
                  ...row.cols.slice(0, tableCellToRemove.tableContentCol),
                  ...row.cols.slice(tableCellToRemove.tableContentCol + 1)
                ]
              };
            } else {
              return row;
            }
          }
        })
      };
    });

    tableStateToSet = cleanTable(tableStateToSet);
    this.props.onChange(tableStateToSet, true);
  }

  onCellChange(rowIndex, colIndex, cellContent) {
    const tableStateToSet = {
      ...this.props.tableContent,
      rows: [
        ...this.props.tableContent.rows.slice(0, rowIndex),
        {
          ...this.props.tableContent.rows[rowIndex],
          cols: [
            ...this.props.tableContent.rows[rowIndex].cols.slice(0, colIndex),
            {
              ...this.props.tableContent.rows[rowIndex].cols[colIndex],
              cellContent: cellContent
            },
            ...this.props.tableContent.rows[rowIndex].cols.slice(colIndex + 1)
          ]
        },
        ...this.props.tableContent.rows.slice(rowIndex + 1)
      ]
    };
    this.props.onChange(tableStateToSet, cellContent);
  }

  _handleSwitchEditMode() {
    this.setState({
      ...this.state,
      editTableMode: !this.state.editTableMode
    });
  }

  render() {
    const tableClassName = this.tableClassName(this.colCount(this.props.tableContent.rows));
    return (
      <TableEditor
        {...this.state}
        tableContent={this.props.tableContent}
        tableMatrix={this.createTableMatrix()}
        tableClassName={tableClassName}
        colCount={this.colCount()}
        insertRow={() => this.insertRow()}
        insertCol={() => this.insertCol()}
        destroyRow={(rowIndex) => this.destroyRow(rowIndex)}
        destroyCol={(colIndex) => this.destroyCol(colIndex)}
        onCellChange={(rowIndex, colIndex, cellContent) =>
          this.onCellChange(rowIndex, colIndex, cellContent)
        }
        onTableChange={(tableState, hasChanged) => this.props.onChange(tableState, hasChanged)}
        handleSwitchEditMode={() => this._handleSwitchEditMode()}
        isSubmitted={this.props.isSubmitted}
      />
    );
  }
}

TableEditorContainer.propTypes = {
  tableContent: PropTypes.object,
  onChange: PropTypes.func,
  onContentHeightChanged: PropTypes.func,
  isSubmitted: PropTypes.bool,
  onTableStructureChange: PropTypes.func
};

TableEditorContainer.defaultProps = {
  isSubmitted: false
};

export default TableEditorContainer;

import React from "react";
import __ from "constants/static-texts";

import NewsListItem from "./NewsListItem";
import useProductTypes from "hooks/use-productTypes";

import Loading from "components/Loading/Loading";

function NewsList({ news, guides, classNs }) {
  const sort = (e) => {
    console.log("SORTING!! ", e.target.textContent);
  };
  const { productTypes, productTypesIsLoading } = useProductTypes();

  if (productTypesIsLoading) return <Loading />;

  return (
    //TODO: Klickevent på ickeinteraktiva element är inte bra ur tillgänglighetsperspektiv (de går inte att tabba till och syns inte för skärmläsare etc), så föreslår att vi sätter eventet på en <button> istället.
    <ul className={`${classNs}_list`}>
      <li className={`${classNs}_list_item table-header`}>
        <div aria-disabled></div>
        <div onClick={sort}>{__.table.date}</div>
        <div onClick={sort}>{__.table.heading}</div>
        <div onClick={sort}>{__.table.visible}</div>
        <div className={`${classNs}_tools`} data-align="center" onClick={sort}>
          {__.table.edit}
        </div>
      </li>

      {news.map((news, index) => {
        return <NewsListItem key={news.id} news={news} productTypes={productTypes} classNs={classNs} />;
      })}
    </ul>
  );
}

export default NewsList;

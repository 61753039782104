import React from 'react';
import __ from 'constants/static-texts';

import GuideListItem from './GuideListItem';

function GuideList({ guides, classNs }) {
  // const [guides, guidesNetworkState] = useGuides();
  const sort = (e) => {
    console.log('SORTING!! ', e.target.textContent);
  };

  return (
    <ul className={`${classNs}_list`}>
      <li className={`${classNs}_list_item table-header`}>
        <div onClick={sort}>{__.table.name}</div>
        <div onClick={sort}>{__.table.product}</div>
        <div onClick={sort}>{__.table.activeGuides}</div>
        <div onClick={sort}>{__.guide.edit}</div>
      </li>
      {guides.map((guide, index) => (
        <GuideListItem key={guide.id} classNs={classNs} guide={guide} />
      ))}
    </ul>
  );
}

export default GuideList;

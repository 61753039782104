import React from 'react';
import __ from 'constants/static-texts';

import UserListItem from './UserListItem';

function UserList({ users, classNs, userRoles, type }) {
  return (
    <ul className={`${classNs}_list`}>
      <li
        className={`${classNs}_list_item${
          type === 'pending' || type === 'blocked' ? '_nonvalidated' : ''
        } table-header`}>
        <div>{__.table.name}</div>
        <div>{__.table.email}</div>
        <div>{__.table.company}</div>
        <div>{userRoles ? __.table.competence : ''}</div>
      </li>
      {users.map((user) => (
        <UserListItem key={user.id} user={user} classNs={classNs} userRoles={userRoles} type={type} />
      ))}
    </ul>
  );
}

export default UserList;

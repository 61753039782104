import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadGuide } from "utils/guide";

import { /*WAITING,*/ LOADING /*SUCCESS*/ } from "constants/network-states";

// Async fetching and hooks are a bad idea:
// https://github.com/facebook/react/issues/14326
// This didn't help either for multiple calls: https://www.robinwieruch.de/react-hooks-fetch-data
/**
 * Provides a Guide by Id and its network state
 */
const useGuide = (id, fetchGuide = false) => {
  const dispatch = useDispatch();
  const { guide, guideMeta, guideNetworkState } = useSelector((state) => state.guideState);
  const { statusMessage } = useSelector((state) => state.layoutState);

  useEffect(() => {
    let mounted = true; // Safeguard against unmounting
    if (guideNetworkState === LOADING) {
      return;
    }

    if (statusMessage.isOpen) {
      return;
    }

    if (!mounted) {
      return;
    }

    if (fetchGuide) {
      loadGuide(id, guide, dispatch);
    }

    return () => {
      mounted = false;
    };
  }, [id, dispatch, guide, fetchGuide, guideNetworkState, statusMessage.isOpen]);

  return [guide, guideNetworkState, guideMeta];
};

export default useGuide;

import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  byggforetagenEvents: [],
  byggforetagenNetworkState: WAITING,
  error: null,
};

const byggforetagenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_BYGGFORETAGEN_EVENTS_SUCCESS:
      return {
        ...state,
        byggforetagenEvents: payload
          .filter((event) => event.Start && event.Tags?.find((tag) => tag.Title === "BKMA"))
          .map((event) => {
            event.bkmaType = "byggforetagen";
            return event;
          }),
        byggforetagenNetworkState: SUCCESS,
      };
    case types.GET_BYGGFORETAGEN_EVENTS_LOADING:
      return {
        ...state,
        byggforetagenNetworkState: LOADING,
      };
    case types.GET_BYGGFORETAGEN_EVENTS_ERROR:
      return {
        ...state,
        error: payload,
        byggforetagenNetworkState: ERROR,
      };
    default:
      return state;
  }
};

export default byggforetagenReducer;

import Passthrough from "routes/Passthrough";
import Calendars from "./components/Calendars";
import Calendar from "./components/Calendar";
import CalendarInstances from "./components/CalendarInstances";
import EditCalendarActivity from "./components/EditCalendarActivity";
import EditCalendar from "./components/EditCalendar";

import { UserIsAuthenticated } from "utils/auth-helpers";

const rootPath = "/calendars";

const CalendarsRoute = () => ({
  path: `${rootPath}`, // include the additional slug for testing current route
  component: Passthrough,
  routes: [
    {
      path: `${rootPath}/calendar/:calendarId`,
      component: UserIsAuthenticated(Calendar),
      routes: [
        {
          path: `${rootPath}/calendar/:calendarId/activity/:activityId/edit`,
          component: EditCalendarActivity,
          exact: true,
        },
      ],
    },
    {
      path: `${rootPath}`,
      component: UserIsAuthenticated(Calendars),
      routes: [
        {
          path: `${rootPath}/edit/:calendarId`,
          component: EditCalendar,
          exact: true,
        },
        {
          path: `${rootPath}/instances/:calendarId`,
          component: CalendarInstances,
          exact: true,
        },
      ],
    },
  ],
});

export default CalendarsRoute;

/**
 *
 * @param {HTMLElement} elementToPrint
 * @param {string} css
 *
 * Prints part of the page. Provide an optional custom print css to inject custom styles
 */
export const printPartOfPage = (elementToPrint, css) => {
  const printArea = window.open();
  printArea.document.write(`
    <style>
      .print-wrapper {
        display: none;
      }

      @media print {
        .print-wrapper {
          display: block;
        }
        
        ${css ? css : ""}
      }
    </style>
  `);

  printArea.document.write(`<div class="print-wrapper">${elementToPrint.outerHTML}</div>`);
  printArea.document.close();
  printArea.focus();
  printArea.print();
  printArea.close();
};

// TODO: Consider adding a timeout helper like mentioned here:
// 'https://stackoverflow.com/a/50101022/7197027'
//
export const responseErrorInterceptor = (error) => {
  let fetchStatus = error.status;
  let errorType = error.statusText || 'unknown-error';

  switch (fetchStatus) {
    case 401:
      errorType = 'unauthorized-error';
      break;
    case 403:
      errorType = 'unauthorized-error';
      break;
    case 404:
      errorType = 'not-found-error';
      break;
    case 500:
      // TODO: Check this error as the json isn't parsed when throwing an error with fetch
      // ErrorCode 8 and http status 500: Session timeout, when loosing sync with Merkurius
      if (error.response && error.response.data && error.response.data.ErrorCode === '8') {
        errorType = 'session-timeout-error';
      }

      break;
    default:
    // Nothing
  }

  return errorType;
};

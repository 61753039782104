import Supervisors from "./components/Supervisors";
import AddSupervisor from "./components/AddSupervisor";
import EditSupervisor from "./components/EditSupervisor";
import SupervisorCompanies from "./components/SupervisorCompanies";
import DeleteSupervisor from "./components/DeleteSupervisor";

import { UserIsAuthenticated } from "utils/auth-helpers";

const rootPath = "/supervisors";

const SupervisorsRoute = () => ({
  path: `${rootPath}`,
  component: UserIsAuthenticated(Supervisors),
  routes: [
    {
      path: `${rootPath}/:supervisorId`,
      exact: true,
      component: AddSupervisor,
    },
    {
      path: `${rootPath}/:supervisorId/edit`,
      exact: true,
      component: EditSupervisor,
    },
    {
      path: `${rootPath}/:supervisorId/delete`,
      exact: true,
      component: DeleteSupervisor,
    },
    {
      path: `${rootPath}/:supervisorId/companies`,
      exact: true,
      component: SupervisorCompanies,
    },
  ],
});

export default SupervisorsRoute;

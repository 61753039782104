import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  calendar: [],
  calendarNetworkState: WAITING,
};

const calendarReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ADD_CALENDAR_INSTANCE_SUCCESS:
      return {
        ...state,
        calendarNetworkState: SUCCESS,
      };
    case types.ADD_CALENDAR_INSTANCE_LOADING:
      return {
        ...state,
        calendarNetworkState: LOADING,
      };
    case types.ADD_CALENDAR_INSTANCE_ERROR:
      return {
        ...state,
        error: payload,
        calendarNetworkState: ERROR,
      };
    case types.GET_CALENDAR_INSTANCE_SUCCESS:
      return {
        ...state,
        calendar: payload,
        calendarNetworkState: SUCCESS,
      };
    case types.GET_CALENDAR_INSTANCE_LOADING:
      return {
        ...state,
        calendarNetworkState: LOADING,
      };
    case types.GET_CALENDAR_INSTANCE_ERROR:
      return {
        ...state,
        error: payload,
        calendarNetworkState: ERROR,
      };
    case types.ADD_CALENDAR_ACTIVITY_SUCCESS:
      return {
        ...state,
        calendarNetworkState: SUCCESS,
        calendar: { ...state.calendar, activities: [...state.calendar.activities, payload] },
      };
    case types.ADD_CALENDAR_ACTIVITY_LOADING:
      return {
        ...state,
        calendarNetworkState: LOADING,
      };
    case types.ADD_CALENDAR_ACTIVITY_ERROR:
      return {
        ...state,
        error: payload,
        calendarNetworkState: ERROR,
      };
    case types.UPDATE_CALENDAR_ACTIVITY_SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          activities: state.calendar.activities.map((activity) => (activity.id === payload.id ? payload : activity)),
        },
        calendarNetworkState: SUCCESS,
      };
    case types.UPDATE_CALENDAR_ACTIVITY_LOADING:
      return {
        ...state,
        calendarNetworkState: LOADING,
      };
    case types.UPDATE_CALENDAR_ACTIVITY_ERROR:
      return {
        ...state,
        error: payload,
        calendarNetworkState: ERROR,
      };

    case types.DELETE_CALENDAR_ACTIVITY_LOADING:
      return {
        ...state,
        calendarNetworkState: LOADING,
      };
    case types.DELETE_CALENDAR_ACTIVITY_SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          activities: state.calendar.activities.filter((activity) => action.params.activityId !== activity.id),
        },
        calendarNetworkState: SUCCESS,
      };

    case types.DELETE_CALENDAR_ACTIVITY_ERROR:
      return {
        ...state,
        error: action.payload,
        calendarNetworkState: ERROR,
      };

    default:
      return state;
  }
};

export default calendarReducer;

import React from "react";

import { BLOCK_GROUP_NAME } from "constants/course";

import useCurrentChapter from "hooks/useCurrentChapter";

import ChapterFactsSection from "./ChapterFactsSection";
import FactBlock from "./FactBlock/FactBlock";

function ChapterFacts({ chapter }) {
  const { currentChapter } = useCurrentChapter();
  // const { data } = chapter;
  if (!currentChapter) return null;

  return (
    <div className="Chapter-facts">
      {currentChapter.blocks.map((block, i) => {
        if (block.name === BLOCK_GROUP_NAME) {
          return (
            <div key={i} className="Chapter-facts__section">
              <ChapterFactsSection block={block} isFirst={i === 0} isLast={i === currentChapter.blocks.length - 1} />
            </div>
          );
        } else {
          return <FactBlock key={i} block={block} />;
        }
      })}
    </div>
  );
}

export default ChapterFacts;

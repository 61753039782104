import { USER_TYPES } from "constants/user-types";
import { PRODUCT_TYPES } from "constants/product-types";

export const isServiceAdmin = (user) => {
  return user?.role === USER_TYPES.SERVICEADMIN.id;
};
export const isCompanyAdmin = (user) => {
  return user?.role === USER_TYPES.COMPANYADMIN.id;
};
export const isSupervisor = (user) => {
  return user?.role === USER_TYPES.SUPERVISOR.id;
};
export const isCompanyEditor = (user) => {
  return user?.role === USER_TYPES.COMPANYEDITOR.id;
};
export const isCompanyReader = (user) => {
  return user?.role === USER_TYPES.COMPANYREADER.id;
};

export const isCompanyUser = (user) => {
  return isCompanyAdmin(user) || isCompanyEditor(user) || isCompanyReader(user);
};

export const isBaseProduct = (company) => {
  return company?.product === PRODUCT_TYPES.ORDNING.id;
};

export const getCompanyFromUser = (companyUser, companiesMap, user, activeCompany) => {
  return (
    (companyUser && companiesMap && user?.companyIds?.length > 1 && activeCompany) ||
    (companyUser && companiesMap && user?.companyIds?.length === 1 && companiesMap[user?.companyIds?.[0]]) ||
    null
  );
};

export const getNewAndExistingUsers = (companyAdmins, allUsers) => {
  const newCompanyAdmins = companyAdmins.filter((user) => !user.id);

  const newUsersAlreadyExistIds = allUsers
    .filter((user) => newCompanyAdmins.find((u) => u.value.toLowerCase() === user.userName.toLowerCase()))
    .map((user) => user.id);

  const newUsersNotExist = newCompanyAdmins.filter((user) => {
    return !allUsers.find((u) => u.userName.toLowerCase() === user.value.toLowerCase());
  });

  return {
    newUsersAlreadyExistIds,
    newUsersNotExist,
  };
};

/**
 * Accepts a user object and a user set and returns all other users from the same company
 */
export const getColleagues = (user, users) =>
  users.filter(
    (currUser) =>
      currUser.companyIds?.some((company) => user.companyIds?.includes(company)) && currUser.id !== parseInt(user.id)
  );

export const getUserName = (user) =>
  user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.userName;

import React from "react";

const toolsListItem = ({ classNs, tool, route }) => {
  return (
    <li className={`${classNs}_list_item`}>
      <a href={tool.toolLink} target="_blank" rel="noreferrer" className={`${classNs}_list_item_main`}>

        <div className={`${classNs}_list_item_main_content`}>
          <h2>{tool.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: tool.description }}></div>
        </div>
      </a>
      {tool.callToAction ? (
        <div className={`${classNs}_list_item_footer`}>
          <span>{tool.callToAction.call}</span>
          <a href={tool.callToAction.link.url} rel="noreferrer" target="_blank" className="button-secondary">
            {tool.callToAction.link.button}
          </a>
        </div>
      ) : null}
    </li>
  );
};

export default toolsListItem;

import fetchClient from "fetch-client";

export const getCompanies = (getState) => {
  return fetchClient({
    path: `/companies`,
    getState,
  });
};
export const addCompanyGuide = (data, getState) => {
  return fetchClient({
    path: `/guides/${data.bluePrint}/instance`,
    getState,
    method: `POST`,
    data,
  });
};

export const getCompanyUsers = (data, getState) => {
  const companyId = data.params ? data.params.companyId : data.companyId;
  return fetchClient({
    path: `/companies/${companyId}/users`,
    getState,
  });
};

export const putCompanyInfo = (data, getState) => {
  const params = {
    supervisorUserId: data.supervisorUserId,
    companyAdminUserIds: [...data.adminIds],
    note: data.note,
  };
  return fetchClient({
    path: `/companies/${data.companyId}/companyinfo`,
    getState,
    method: "PUT",
    data: params,
  });
};

export const getSSOCompanyUsers = (data, getState) => {
  return fetchClient({
    path: `/sso/users/${data}`,
    getState,
  });
};

import React from "react";
// import { ReactComponent as ChevronDown } from "icons/chevron-down.svg";
// import { ReactComponent as ChevronDown } from "icons/chevron-down1.svg";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

const CLASS_NS = "searchable-selector";

const SearchableSelector = ({
  classNs = CLASS_NS,
  label,
  selectText = "Välj ...",
  missingOptions = () => "Det finns inga val",
  options,
  value,
  onChange,
  id,
  canAdd = false,
  multi = true,
  validateAdded = () => {},
  selectedOptions = [],
  name,
  themeDark = false,
}) => {
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: themeDark ? "var(--c_searchable_backgrund-color--focus)" : "var(--c_searchable_backgrund-color)",
      color: "var(--c_searchable-text-color)",
      border: "none",
      borderRadius: "0px",
      padding: "4px 10px",
      minHeight: "42px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable-text-color)",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable-text-color)",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable_icon-color)",
      "&:hover": {
        color: "var(--c_searchable_icon-color--hover)",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable_icon-color)",
      "&:hover": {
        color: "var(--c_searchable_icon-color--hover)",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--c_searchable_icon-color)",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: themeDark
        ? "var(--c_searchable_background-color-dark)"
        : "var(--c_searchable_backgrund-color--focus)",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable-text-color)",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable_icon-color)",
      "&:hover": {
        color: "var(--c_searchable_icon-color--hover)",
        backgroundColor: "transparent",
        cursor: "pointer",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      zIndex: 10,
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--c_searchable_backgrund-color)",
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused
          ? "var(--c_searchable_backgrund-color--focus)"
          : "var(--c_searchable_backgrund-color)",
        borderTop: "solid 1px var(--c_searchable_backgrund-color--focus)",
      };
    },
    noOptionsMessage: (provided, state) => ({
      ...provided,
      color: "var(--c_searchable-text-color)",
    }),
  };

  const CustomOption = (props) => {
    return !props.isDisabled ? (
      <components.Option {...props}>
        <div ref={props.innerRef} className={`searchable-selector_option`}>
          <span className={`searchable-selector_label`}>{`${props.data.label}`}</span>
          <span className={`searchable-selector_sublabel`}>{props.data.subLabel}</span>
        </div>
      </components.Option>
    ) : null;
  };
  const filterOption1 = (item, searchStr) => {
    return (
      item.data?.label?.toLowerCase().includes(searchStr.toLowerCase()) ||
      item.data?.subLabel?.toLowerCase().includes(searchStr.toLowerCase()) ||
      item.data?.__isNew__
    );
  };

  const handleChange = (newValues, addedValue) => {
    onChange(newValues, addedValue);
  };

  return (
    <div className={`${classNs}`}>
      <label className="label">{label}</label>
      {canAdd ? (
        <CreatableSelect
          options={options}
          styles={customStyles}
          isMulti={multi}
          components={{ Option: CustomOption /* , MultiValueLabel: CustomMultiValueContainer */ }}
          placeholder={selectText}
          noOptionsMessage={missingOptions}
          onChange={handleChange}
          filterOption={filterOption1}
          formatCreateLabel={(value) => `Lägg till "${value}"`}
          isValidNewOption={validateAdded}
          defaultValue={selectedOptions}
          name={name}
        />
      ) : (
        <Select
          options={options}
          styles={customStyles}
          isMulti={multi}
          components={{ Option: CustomOption /* , MultiValueLabel: CustomMultiValueContainer */ }}
          placeholder={selectText}
          noOptionsMessage={missingOptions}
          onChange={handleChange}
          value={selectedOptions}
          name={name}
        />
      )}
    </div>
  );
};

export default SearchableSelector;

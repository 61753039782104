import { DateTime } from "luxon";

export const formatDate = (date) => {
  const dateFormat = { month: "long", day: "numeric", year: "numeric" };

  return DateTime.fromISO(date, { locale: "sv" }).toLocaleString(dateFormat);
};

export const formatMonthYear = (date) => {
  const dateTime = DateTime.fromISO(date, { locale: "sv" });

  return `${dateTime.toLocaleString({ month: "2-digit" })}-${dateTime.toLocaleString({ year: "numeric" })}`;
};

export const formatDateInput = (date) => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
};

export const defaultDate = () => {
  return DateTime.now().toFormat("yyyy-MM-dd");
};

export const toDate = (date) => {
  return date instanceof Date ? date : new Date(date);
};

export const getDateDiff = (start, end, unit = "days") => {
  return DateTime.fromISO(start, { locale: "sv" })
    .diff(DateTime.fromISO(end, { locale: "sv" }), unit)
    .toObject();
};

/**
 * Returns a month index based on week number. If month changes on Wednesday, we return the later month
 *
 * @param {*} weekNumber
 * @returns int
 */
export const getMonthFromISOWeek = (weekNumber) => {
  const thisYear = new Date().getFullYear();
  const simple = new Date(thisYear, 0, 1 + (weekNumber - 1) * 7);
  const dayOfWeek = simple.getDay();
  const ISOweekStart = simple;

  if (dayOfWeek <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }

  const wednesday = new Date(ISOweekStart);
  wednesday.setDate(ISOweekStart.getDate() + 2);

  const ISOweekStartMonth = ISOweekStart.getMonth();
  const wednesdayMonth = wednesday.getMonth();
  return wednesdayMonth === ISOweekStartMonth ? ISOweekStartMonth : wednesdayMonth;
};

export const getDateOfISOWeek = (weekNumber) => {
  const thisYear = new Date().getFullYear();
  var simple = new Date(thisYear, 0, 1 + (weekNumber - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }

  return ISOweekStart;
};

export const months = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

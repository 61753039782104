import React from "react";
import ReactTooltip from "react-tooltip";

const CLASS_NS = "user-account";

const themes = {
  light: {
    text: "#082E4B",
    bg: "#5EBBFF",
  },
  dark: {
    text: "#fff",
    bg: "#2e3a40",
  },
};

const Tooltip = ({ classNs = CLASS_NS, theme = "light", id = null }) => {
  const textColor = themes[theme].text;
  const bgColor = themes[theme].bg;
  return (
    <>
      <ReactTooltip
        id={id}
        place="bottom"
        effect="solid"
        border={false}
        textColor={textColor}
        backgroundColor={bgColor}></ReactTooltip>
    </>
  );
};

export default Tooltip;

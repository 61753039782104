import React from "react";

import "./FactBlock.scss";

import titleCase from "utils/titleCase";

let FactBlocks = {};

// FactBlocks.AcfTask = require("./AcfTask").default;
FactBlocks.BucLmsBuilderColumnItem = require("./BucLmsBuilderColumnItem").default;
FactBlocks.BucLmsBuilderColumns = require("./BucLmsBuilderColumns").default;
FactBlocks.BucLmsBuilderImageAreaMap = require("./BucLmsBuilderImageAreaMap").default;
FactBlocks.BucLmsBuilderImagePointMap = require("./BucLmsBuilderImagePointMap").default;
FactBlocks.BucLmsBuilderModal = require("./BucLmsBuilderModal").default;
FactBlocks.BucLmsBuilderRichList = require("./BucLmsBuilderRichList").default;
FactBlocks.BucLmsBuilderRichListItem = require("./BucLmsBuilderRichListItem").default;
FactBlocks.BucLmsBuilderSlider = require("./BucLmsBuilderSlider").default;
FactBlocks.BucLmsBuilderSliderItem = require("./BucLmsBuilderSliderItem").default;
FactBlocks.CoreEmbedVimeo = require("./CoreEmbedVimeo").default;
FactBlocks.CoreHeading = require("./CoreHeading").default;
FactBlocks.CoreImage = require("./CoreImage").default;
FactBlocks.CoreList = require("./CoreList").default;
FactBlocks.CoreParagraph = require("./CoreParagraph").default;
FactBlocks.CoreTable = require("./CoreTable").default;

const camelCaseIt = (blockName) => {
  const noHyphOrSlash = blockName.replace(/-/g, " ").replace(/\//g, " ");
  return titleCase(noHyphOrSlash).split(" ").join("");
};

function FactBlock(props) {
  const { block, allowedBlocks } = props;
  const componentName = camelCaseIt(block.name);
  const ComponentToRender = FactBlocks[componentName];
  if (!ComponentToRender || (allowedBlocks && allowedBlocks.length && !allowedBlocks.includes(componentName)))
    return null;
  return <ComponentToRender block={block} />;
}

export default FactBlock;

import React from "react";
import PropTypes from "prop-types";
import TypographyDynamicHeading from "components/TypographyDynamicHeading/TypographyDynamicHeading";
import TypographyMeta from "components/TypographyMeta/TypographyMeta";
import "./_TypographyHeadingMeta.scss";

const CLASS_NS = "typography-heading-meta";

const TypographyHeadingMeta = ({ heading, meta, headingLevel }) => {
  return (
    <div className={CLASS_NS}>
      <TypographyDynamicHeading classNs={`${CLASS_NS}_heading`} level={headingLevel}>
        {heading}
      </TypographyDynamicHeading>
      {meta && <TypographyMeta>{meta}</TypographyMeta>}
    </div>
  );
};

TypographyHeadingMeta.propTypes = {
  heading: PropTypes.node,
  meta: PropTypes.node,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
};

export default TypographyHeadingMeta;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import IconModal from "components/IconModal/IconModal";

import GuideViewerToolDone from "./GuideViewerToolDone";
import Assign from "components/Assign/Assign";
import Comment from "components/Comment/Comment";
import { RichTextEditorInitValue } from "components/RichTextEditor/RichTextEditor";

import { updateBlockValue, setActiveComment, favouriteBlock } from "actions/guide-actions";
import { getUserName } from "utils/user-helper";

import __ from "constants/static-texts";

import useUsers from "hooks/use-users";
import useLoggedInUser from "hooks/use-logged-in-user";
import { guideSectionTypes } from "constants/guide-types";
import { ReactComponent as ChatBubble } from "icons/Chat-bubble.svg";
import { ReactComponent as User } from "icons/User.svg";
import { ReactComponent as StarGreyOutlined } from "icons/star_grey_outlined.svg";
import { ReactComponent as StarYellow } from "icons/star_yellow.svg";

const CLASS_NS = "guide-viewer-toolbar";

const GuideViewerToolbar = ({ activeSection, guideId, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const [sectionIsDone, setSectionIsDone] = useState(activeSection.isDone);

  const [closeAssignModal, setCloseAssignModal] = useState(false);

  const { usersIsLoading, usersMap } = useUsers();

  const { isCompanyReader, isSupervisor } = useLoggedInUser();

  const updateBlock = (data) => {
    dispatch(
      updateBlockValue({
        guideId,
        blocks: [
          {
            ...activeSection,
            parentId: activeSection.parentId.replace(`${guideSectionTypes.GUIDE_PART}_`, ""),
            id: activeSection.id.replace(`${guideSectionTypes.GUIDE_SECTION}_`, ""),
            routine: !activeSection.routine
              ? {
                  subTitle: __.guide.routine.subtitle,
                  description: JSON.stringify(RichTextEditorInitValue),
                  companyDocumentIds: [],
                }
              : activeSection.routine,
            ...data,
          },
        ],
      })
    );
  };

  const setSectionDone = () => {
    updateBlock({ isDone: !sectionIsDone });
  };

  const setSectionFavourite = () => {
    dispatch(
      favouriteBlock({
        guideId,
        sectionId: activeSection.id.replace(`${guideSectionTypes.GUIDE_SECTION}_`, ""),
        isFavourite: !activeSection?.isFavourite,
      })
    );
  };

  const assignSection = (userId, date) => {
    setCloseAssignModal(true);
    updateBlock({ assignedToUserId: userId, assignmentDeadline: date });
  };

  const getAssignedUser = () => {
    return `${__.assign.assigned} ${getUserName(usersMap[activeSection.assignedToUserId])}`;
  };

  const assignedTooltip =
    !usersIsLoading && activeSection.assignedToUserId ? getAssignedUser() : __.guide.toolbar.assign_tooltip;

  useEffect(() => {
    setSectionIsDone(activeSection.isDone);
  }, [activeSection]);

  const isIntroduction = activeSection.isIntroduction;

  return (
    <div className={classNs}>
      <div className={`${classNs}_right`}>
        {!isSupervisor && (
          <IconModal
            text={activeSection?.isFavourite ? __.favourite.saved : __.favourite.save}
            clickHandler={setSectionFavourite}
            tooltip={{
              text: activeSection?.isFavourite ? __.favourite.undo : __.favourite.do,
              id: "section_favourite",
            }}
            Icon={activeSection?.isFavourite ? StarYellow : StarGreyOutlined}
            extraClass="star"
          />
        )}
        {!isCompanyReader && (
          <>
            {!isIntroduction ? (
              <>
                <IconModal
                  text={activeSection?.assignedToUserId ? "1" : __.guide.toolbar.assign}
                  title={__.assign.section.title}
                  tooltip={{ text: assignedTooltip, id: "section_assign" }}
                  blockData={activeSection}
                  closeModal={closeAssignModal}
                  clickHandler={() => setCloseAssignModal(false)}
                  Icon={User}>
                  <Assign
                    onAssign={assignSection}
                    assignmentData={activeSection}
                    description={__.assign.section.description}
                  />
                </IconModal>
                <IconModal
                  text={activeSection?.commentIds.length ? activeSection?.commentIds.length : __.guide.toolbar.comment}
                  title={__.comment.section.title}
                  tooltip={{ text: __.guide.toolbar.comment_tooltip, id: "section_comment" }}
                  blockData={activeSection}
                  isComment={true}
                  onClose={() => dispatch(setActiveComment(null))}
                  Icon={ChatBubble}>
                  <Comment commentData={activeSection} />
                </IconModal>

                <GuideViewerToolDone
                  classNs={classNs}
                  checked={sectionIsDone}
                  onClick={setSectionDone}
                  text={__.guide.toolbar.done}
                  tooltip={{
                    text: sectionIsDone ? __.guide.toolbar.undone_tooltip : __.guide.toolbar.done_tooltip,
                    id: "section_done",
                  }}
                />
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default GuideViewerToolbar;

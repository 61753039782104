import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';

import SupervisorsRoute from 'routes/Supervisors';
import { updateUserDetails, updateUserProfileImage } from 'actions/users-actions';
import ChoiceGroup from 'components/ChoiceGroup/ChoiceGroup';
import { Overlay } from 'react-draft-editor';
import RichTextEditor, { RichTextEditorVariant } from 'components/RichTextEditor/RichTextEditor';

import __ from 'constants/static-texts';
import { ReactComponent as TrashCan } from 'icons/trash.svg';

import useForm from 'hooks/use-form';
import useSupervisors from 'hooks/use-supervisors';

const CLASS_NS = 'form_overlay';

const EditSupervisor = ({ cancelUrl, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { supervisors } = useSupervisors();
  const { supervisorId } = useParams();
  const currentSupervisor = supervisors.find((supervisor) => supervisor.id === parseInt(supervisorId));
  const initialValue = {
    id: supervisorId,
    description: currentSupervisor.details?.description ?? '',
    phoneNumber: currentSupervisor.details?.phoneNumber ?? '',
    image: '',
    isOpenForNewAssignments: currentSupervisor.details?.isOpenForNewAssignments ?? false,
  };
  const [showFileInput, setShowFileInput] = useState(false);

  const { inputs, handleChange } = useForm({
    ...initialValue,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { id, description, phoneNumber, image, isOpenForNewAssignments } = inputs;

    if (!!image) {
      await dispatch(updateUserProfileImage({ id, image }));
    }

    const details = { id, description, phoneNumber, isOpenForNewAssignments };

    dispatch(updateUserDetails(details));

    history.push(cancelUrl, { disableScroll: true });
  };

  const handleCancel = () => {
    history.push(cancelUrl, { disableScroll: true });
  };

  return (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <form onSubmit={handleSubmit} className={classNs}>
        <h2>{__.supervisors.editSupervisor}</h2>
        <div className={`${classNs}_form-row_sub-header`}>
          {currentSupervisor.firstName || currentSupervisor.lastName
            ? `${currentSupervisor.firstName} ${currentSupervisor.lastName}`
            : currentSupervisor.userName}
        </div>
        <div className={`${classNs}_form-row`}>
          <ChoiceGroup
            name="isOpenForNewAssignments"
            type="checkbox"
            onChange={() => {
              handleChange({ value: !inputs.isOpenForNewAssignments, name: 'isOpenForNewAssignments' });
            }}
            chosen={inputs.isOpenForNewAssignments ? '1' : '0'}
            inversed={true}
            choices={[
              {
                value: '1',
                label: __.supervisors.Supervisoractive,
              },
            ]}
          />
        </div>
        <label className={`${CLASS_NS}_form-row label-top`} htmlFor="description">
          <span className="label">{__.supervisors.presentation}</span>
          <RichTextEditor
            initValue={inputs.description}
            variant={RichTextEditorVariant.SECONDARY}
            onChange={(value) => handleChange({ value, name: 'description' })}
          />
        </label>
        <label className={`${classNs}_form-row`} htmlFor="phoneNumber">
          <span className="label">{__.supervisors.phone}</span>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder={__.supervisors.phone}
            value={inputs.phoneNumber}
            onChange={handleChange}
          />
        </label>
        <label className={`${classNs}_form-row`} htmlFor="image">
          <span className="label">{__.supervisors.image}</span>
          {currentSupervisor.details?.hasProfileImage && !showFileInput ? (
            <div>
              <img
                className={`${classNs}_image`}
                src={`${process.env.REACT_APP_API_BASE}/users/${currentSupervisor.id}/profileimage?${Date.now()}`}
                alt={__.supervisors.image}
              />
              <button className={`${classNs}_link`} type="button" onClick={() => setShowFileInput(true)}>
                {__.supervisors.changeImage}
              </button>
            </div>
          ) : (
            <input type="file" id="image" name="image" onChange={handleChange} />
          )}
        </label>
        <div className="button-row">
          <button type="button" className="button-inverted" onClick={handleCancel}>
            {__.general.cancel}
          </button>
          <button type="submit" className={`${classNs}_button`}>
            {__.supervisors.save}
          </button>
        </div>
        {currentSupervisor.companyIds.length === 0 ? (
          <Link
            className={`${classNs}_button-delete`}
            to={{
              pathname: `${SupervisorsRoute().path}/${supervisorId}/delete`,
              state: { disableScroll: true, currentSupervisor: currentSupervisor },
            }}>
            {__.supervisors.delete}
            <TrashCan className="right" />
          </Link>
        ) : null}
      </form>
    </Overlay>
  );
};

export default EditSupervisor;

import React, { useState } from "react";
import useCompanyDocument from "hooks/use-company-document";
import { Overlay } from "react-draft-editor";
import EditCompanyDocumentEdit from "./EditCompanyDocumentEdit";
import EditCompanyDocumentDelete from "./EditCompanyDocumentDelete";
import EditCompanyDocumentVersions from "./EditCompanyDocumentVersions";

const EditCompanyDocument = ({ documentId, onCancelClick, onDeleteClick, onSaveClick, canEditVisibilty }) => {
  const state = {
    EDIT: "edit",
    DELETE: "delete",
    VERSIONS: "versions",
  };
  const [currentState, setCurrentState] = useState(state.EDIT);
  const { companyDocument } = useCompanyDocument(documentId);
  const clickDelete = () => {
    setCurrentState(state.DELETE);
  };

  const clickCancelDelete = () => {
    setCurrentState(state.EDIT);
  };

  const clickVersions = () => {
    setCurrentState(state.VERSIONS);
  };

  const clickVersionsBack = () => {
    setCurrentState(state.EDIT);
  };

  return companyDocument ? (
    <Overlay cancelClick={onCancelClick}>
      {currentState === state.DELETE ? (
        <EditCompanyDocumentDelete
          documentId={documentId}
          companyDocument={companyDocument}
          clickCancelDelete={clickCancelDelete}
          onSaveClick={onSaveClick}
          onDeleteClick={onDeleteClick}
        />
      ) : currentState === state.EDIT ? (
        <EditCompanyDocumentEdit
          companyDocument={companyDocument}
          clickVersions={clickVersions}
          clickDelete={clickDelete}
          onSaveClick={onSaveClick}
          canEditVisibilty={canEditVisibilty}
        />
      ) : currentState === state.VERSIONS ? (
        <EditCompanyDocumentVersions
          documentId={documentId}
          companyDocument={companyDocument}
          clickVersionsBack={clickVersionsBack}
        />
      ) : null}
    </Overlay>
  ) : null;
};

export default EditCompanyDocument;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";
import { DeleteProcess, useBlocksSaveable, useBlocksPublishable, useSectionsChange } from "react-draft-editor";

import __ from "constants/static-texts";
const CLASS_NS = "guide-savebar";

// NB! This might be made to a generic save bar and therefore the save/publish methods are present
const GuideEditorSavebar = ({
  classNs = CLASS_NS,
  currentSections = [],
  backUrl,
  onBack,
  backLabel = __.general.back,
  reviewLink,
  reviewLabel = __.general.review,
  onSave,
  saveLabel = __.general.save,
  onDelete,
  disableDeleteButton = false,
  deleteLabel = __.general.delete,
  onPublish,
  publishLabel = __.general.publish,
}) => {
  const [isBlocksSaveable] = useBlocksSaveable();
  const [isPublishable] = useBlocksPublishable();
  const [changedSectionIds] = useSectionsChange(currentSections);
  const [isSaveable, setIsSaveable] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setIsSaveable(isBlocksSaveable || changedSectionIds.length ? true : false);
  }, [isBlocksSaveable, changedSectionIds]);
  const onBackHandler = (e) => {
    if (isSaveable) {
      e.preventDefault();
    } else {
      onBack(e);
    }
  };

  const onSaveHandler = (e) => {
    if (!isSaveable) {
      e.preventDefault();
    } else {
      onSave(e);
    }
  };

  return (
    <div className={`${classNs}`}>
      <DeleteProcess
        onCancel={() => {
          setShowDelete(false);
        }}
        onDelete={onDelete}
        open={showDelete}></DeleteProcess>

      {onBack ? (
        <Link to={backUrl} className={`${classNs}_button-back`} onClick={onBackHandler} disabled={isSaveable}>
          <span className={`${classNs}_button-back_icon`}>
            <ArrowLeft />
          </span>
          <span className={`${classNs}_button-back_label`}>{backLabel}</span>
        </Link>
      ) : null}
      {reviewLink ? (
        <Link to={reviewLink} className={`${classNs}_button-review`}>
          {reviewLabel}
        </Link>
      ) : null}
      {onSave ? (
        <button className={`${classNs}_button-save`} onClick={onSaveHandler} disabled={!isSaveable}>
          {saveLabel}
        </button>
      ) : null}
      <button
        className={`${classNs}_button-delete`}
        onClick={() => setShowDelete(true)}
        disabled={!disableDeleteButton}>
        {deleteLabel}
      </button>
      {onPublish ? (
        <button className={`${classNs}_button-publish`} onClick={onPublish} disabled={!isPublishable}>
          {publishLabel}
        </button>
      ) : null}
    </div>
  );
};

GuideEditorSavebar.propTypes = {
  classNs: PropTypes.string,
};
export default GuideEditorSavebar;

import React, { useCallback, useMemo, useState } from "react";
import isHotkey, { isKeyHotkey } from "is-hotkey";
import isUrl from "is-url";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, createEditor, Range, Element as SlateElement } from "slate";
import { withHistory } from "slate-history";
import useLibraryDocuments from "hooks/use-library-documents";
import SearchableSelector from "components/SearchableSelector/SearchableSelector";
import { ReactComponent as EditorBoldIcon } from "icons/editor-bold.svg";
import { ReactComponent as EditorItalicIcon } from "icons/editor-italic.svg";
import { ReactComponent as EditorLinkIcon } from "icons/editor-link.svg";
import { ReactComponent as EditorUnderlineIcon } from "icons/editor-underline.svg";
import { ReactComponent as EditorListUnorderedIcon } from "icons/editor-list-unordered.svg";
import { ReactComponent as EditorListOrderedIcon } from "icons/editor-list-ordered.svg";
import { ReactComponent as EditorHeading1Icon } from "icons/editor-heading-1.svg";
import { ReactComponent as EditorHeading2Icon } from "icons/editor-heading-2.svg";
import { ReactComponent as DocumentIcon } from "icons/Document.svg";
import DownloadDocumentButton from "components/DownloadDocumentButton/DownloadDocumentButton";
import { sortArray } from "utils/js-helpers";
import { normalizeToSchema } from "utils/normalize";
import __ from "constants/static-texts";

// TODO: Clean this up a bit. It is a combination of two of Slate's official examples (inline and rich text), and is a bit messy

export const RichTextEditorVariant = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
};

export const RichTextEditorInitValue = [{ type: "paragraph", children: [{ text: "" }] }];

//TODO: Make sure this works for all cases
// Recursively checks that there exist a "text" value among the children
const itemHasTextValue = (value) => {
  return value.children.some((paragraph) => {
    const hasText = paragraph.children
      ? itemHasTextValue(paragraph)
      : typeof paragraph.text !== "undefined"
      ? paragraph.text !== ""
      : false;
    return hasText;
  });
};
export const RichTextValueEmpty = (val) => {
  const value = typeof val === "string" ? JSON.parse(val) : val;
  const hasValue = value.reduce((acc, item) => {
    const childHasValue = itemHasTextValue(item);
    return acc ? acc : childHasValue;
  }, false);
  return !hasValue;
};

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

const docSelectorSchema = {
  value: "id",
  id: "id",
  label: "title",
};

const LIST_TYPES = ["list-ordered", "list-unordered"];
const CLASS_NS = "rich-text-editor";

const RichTextEditor = ({
  classNs = CLASS_NS,
  initValue = RichTextEditorInitValue,
  placeholder = __.richTextEditor.placeholder,
  variant = RichTextEditorVariant.PRIMARY,
  disableDocumentLink = false,
  onChange = () => {},
}) => {
  const parseInitValue = (value) => {
    if (Array.isArray(value)) {
      return value;
    } else if (typeof value === "string") {
      if (value.charAt(0) === "[" && value.charAt(value.length - 1) === "]") {
        return JSON.parse(value);
      } else {
        return [{ type: "paragraph", children: [{ text: value }] }];
      }
    }
  };

  const [value, setValue] = useState(parseInitValue(initValue));
  const [showDocumentSelector, toggleDocumentSelector] = useState(false);
  const [librarydocuments] = useLibraryDocuments();

  const docs = useMemo(
    () => normalizeToSchema(librarydocuments, docSelectorSchema).sort(sortArray("label", true, true)),
    [librarydocuments]
  );
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const withInlines = (editor) => {
    const { insertText, isInline } = editor;

    editor.isInline = (element) => ["link", "button", "librarydocument"].includes(element.type) || isInline(element);

    editor.insertText = (text) => {
      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertText(text);
      }
    };

    return editor;
  };

  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    });
    const newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isBlockActive = (editor, format) => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  };

  const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  };

  const handleChange = (value) => {
    setValue(value);
    onChange(JSON.stringify(value));
  };

  const handleDocumentChange = (selectedDocument) => {
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    // console.log(selectedDocument)
    const documentItem = {
      type: "librarydocument",
      document: selectedDocument,
      children: isCollapsed ? [{ text: selectedDocument.label }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, documentItem);
    } else {
      Transforms.wrapNodes(editor, documentItem, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  const handleKeyDownEditable = (event) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey("left", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset", reverse: true });
        return;
      }
      if (isKeyHotkey("right", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset" });
        return;
      }
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case "line-break":
        return <br {...attributes} />;
      case "list-unordered":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "list-ordered":
        return <ol {...attributes}>{children}</ol>;
      case "link":
        return <a {...attributes}>{children}</a>;
      case "heading1":
        return <h2 {...attributes}>{children}</h2>;
      case "heading2":
        return <h3 {...attributes}>{children}</h3>;
      case "librarydocument":
        return (
          <span {...attributes}>
            <DownloadDocumentButton
              document={element.document}
              onClick={(e) => {
                e.preventDefault();
              }}>
              {children}
            </DownloadDocumentButton>
          </span>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  const ToolbarButton = ({ format, icon, block = false }) => {
    const editor = useSlate();
    return (
      <button
        className={`${classNs}_toolbar-button ${
          isBlockActive(editor, format) ||
          isMarkActive(editor, format) ||
          (format === "librarydocument" && showDocumentSelector)
            ? `${classNs}_toolbar-button--active`
            : ""
        }`}
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          if (format === "link") {
            const url = window.prompt(__.richTextEditor.linkPrompt);
            if (!url) return;
            insertLink(editor, url);
          } else if (format === "librarydocument") {
            toggleDocumentSelector(!showDocumentSelector);
          } else {
            block ? toggleBlock(editor, format) : toggleMark(editor, format);
          }
        }}>
        {icon}
      </button>
    );
  };

  return (
    <div className={`${classNs} ${variant ? `${classNs}--${variant}` : ""}`}>
      <Slate editor={editor} value={value} onChange={handleChange}>
        <div className={`${classNs}_toolbar`}>
          <ToolbarButton format="bold" aria-label={__.richTextEditor.bold} icon={<EditorBoldIcon aria-hidden />} />
          <ToolbarButton
            format="italic"
            aria-label={__.richTextEditor.italic}
            icon={<EditorItalicIcon aria-hidden />}
          />
          <ToolbarButton
            format="underline"
            aria-label={__.richTextEditor.underline}
            icon={<EditorUnderlineIcon aria-hidden />}
          />
          <ToolbarButton
            format="heading1"
            block
            aria-label={__.richTextEditor.heading1}
            icon={<EditorHeading1Icon aria-hidden />}
          />
          <ToolbarButton
            format="heading2"
            block
            aria-label={__.richTextEditor.heading2}
            icon={<EditorHeading2Icon aria-hidden />}
          />
          <ToolbarButton
            format="link"
            block
            aria-label={__.richTextEditor.link}
            icon={<EditorLinkIcon aria-hidden />}
          />
          {!disableDocumentLink ? (
            <ToolbarButton
              format="librarydocument"
              block
              aria-label={__.richTextEditor.link}
              icon={<DocumentIcon aria-hidden />}
            />
          ) : null}
          <ToolbarButton
            format="list-ordered"
            block
            aria-label={__.richTextEditor.listOrdered}
            icon={<EditorListOrderedIcon aria-hidden />}
          />
          <ToolbarButton
            format="list-unordered"
            block
            label={__.richTextEditor.listUnordered}
            icon={<EditorListUnorderedIcon aria-hidden />}
          />
        </div>
        {showDocumentSelector && (
          <div className="rich-text-editor_document-selector">
            <SearchableSelector
              selectText={"Länka till ett dokument"}
              onChange={handleDocumentChange}
              options={docs}
              missingOptions={() => `Ingen träff`}
              themeDark={true}
              multi={false}
            />
          </div>
        )}
        <Editable
          className={`${classNs}_editor`}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          onKeyDown={handleKeyDownEditable}
        />
      </Slate>
    </div>
  );
};

export default RichTextEditor;

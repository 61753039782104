import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

import { saveAs } from "file-saver";

const initialState = {
  documents: [],
  documentsNetworkState: WAITING,
  libraryDocument: null,
  libraryDocumentNetworkState: WAITING,
  documentTypes: [],
  documentTypesNetworkState: WAITING,
  documentAddNetworkState: WAITING,
  documentDeleteNetworkState: WAITING,
  documentFavouriteNetworkState: WAITING,
  error: null,
  activeDownload: null,
  activeVersionDownloadFilename: `untitled.unknown`,
  documentDownloadNetworkState: WAITING,
  libraryDocumentVersionDeleteNetworkState: WAITING,
  libraryDocumentVersionDownloadNetworkState: WAITING,
  searchQuery: "",
  filterCategory: null,
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DOCUMENTS_LOADING:
      return {
        ...state,
        documentsNetworkState: LOADING,
      };
    case types.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        documentsNetworkState: SUCCESS,
      };
    case types.GET_DOCUMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        documentsNetworkState: ERROR,
      };
    case types.GET_LIBRARY_DOCUMENT_LOADING:
      return {
        ...state,
        libraryDocumentNetworkState: LOADING,
      };
    case types.GET_LIBRARY_DOCUMENT_SUCCESS:
      return {
        ...state,
        libraryDocument: action.payload,
        libraryDocumentNetworkState: SUCCESS,
      };
    case types.GET_LIBRARY_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        libraryDocumentNetworkState: ERROR,
      };

    case types.GET_DOCUMENT_TYPES_LOADING:
      return {
        ...state,
        documentTypesNetworkState: LOADING,
      };
    case types.GET_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentTypes: action.payload,
        documentTypesNetworkState: SUCCESS,
      };
    case types.GET_DOCUMENT_TYPES_ERROR:
      return {
        ...state,
        error: action.payload,
        documentTypesNetworkState: ERROR,
      };

    case types.ADD_DOCUMENT_LOADING:
      return {
        ...state,
        documentAddNetworkState: LOADING,
      };
    case types.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.concat(action.payload),
        documentAddNetworkState: SUCCESS,
      };
    case types.ADD_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentAddNetworkState: ERROR,
      };

    case types.UPDATE_DOCUMENT_LOADING:
      return {
        ...state,
        documentAddNetworkState: LOADING,
      };
    case types.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.map((doc) => {
          if (doc.id === action.payload.id) {
            return action.payload;
          } else {
            return doc;
          }
        }),
        documentAddNetworkState: SUCCESS,
      };
    case types.UPDATE_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentAddNetworkState: ERROR,
      };

    case types.DELETE_DOCUMENT_LOADING:
      return {
        ...state,
        documentDeleteNetworkState: LOADING,
      };
    case types.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.filter((document) => document.id !== action.payload),
        documentDeleteNetworkState: SUCCESS,
      };
    case types.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentDeleteNetworkState: ERROR,
      };

    case types.FAVOURITE_DOCUMENT_LOADING:
      return {
        ...state,
        documentFavouriteNetworkState: LOADING,
      };
    case types.FAVOURITE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.map((doc) => {
          if (doc.id === action.payload.id) {
            return action.payload;
          } else {
            return doc;
          }
        }),
        documentFavouriteNetworkState: SUCCESS,
      };
    case types.FAVOURITE_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentFavouriteNetworkState: ERROR,
      };

    case types.SET_ACTIVE_DOWNLOAD:
      const currentFile = state.documents.find((doc) => doc.id === action.file.id);
      return {
        ...state,
        activeDownload: currentFile || action.file,
      };

    case types.SET_ACTIVE_VERSION_DOWNLOAD:
      return {
        ...state,
        activeVersionDownloadFilename: action.filename || "untitled.unknown",
      };

    case types.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.value,
      };
    case types.SET_FILTER_CATEGORY:
      return {
        ...state,
        filterCategory: action.value,
      };

    case types.DOWNLOAD_FILE_LOADING:
      return {
        ...state,
        documentDownloadNetworkState: LOADING,
      };
    case types.DOWNLOAD_FILE_SUCCESS:
      const fileName = !state.activeDownload ? `untitled.unknown` : state.activeDownload?.fileName;
      saveAs(action.payload, fileName);
      return {
        ...state,
        documentDownloadNetworkState: SUCCESS,
      };
    case types.DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
        documentDownloadNetworkState: ERROR,
      };
    case types.DELETE_LIBRARY_DOCUMENT_VERSION_LOADING:
      return {
        ...state,
        libraryDocumentVersionDeleteNetworkState: LOADING,
      };
    case types.DELETE_LIBRARY_DOCUMENT_VERSION_SUCCESS:
      return {
        ...state,
        libraryDocument: {
          ...state.libraryDocument,
          versions: state.libraryDocument.versions.filter((version) => version.id !== action.payload),
        },
        libraryDocumentVersionDeleteNetworkState: SUCCESS,
      };
    case types.DELETE_LIBRARY_DOCUMENT_VERSION_ERROR:
      return {
        ...state,
        error: action.payload,
        libraryDocumentVersionDownloadNetworkState: ERROR,
      };
    case types.DOWNLOAD_LIBRARY_DOCUMENT_VERSION_LOADING:
      return {
        ...state,
        libraryDocumentVersionDownloadNetworkState: LOADING,
      };
    case types.DOWNLOAD_LIBRARY_DOCUMENT_VERSION_SUCCESS:
      saveAs(action.payload, state.activeVersionDownloadFilename);
      return {
        ...state,
        libraryDocumentVersionDownloadNetworkState: SUCCESS,
      };
    case types.DOWNLOAD_LIBRARY_DOCUMENT_VERSION_ERROR:
      return {
        ...state,
        error: action.payload,
        libraryDocumentVersionDownloadNetworkState: ERROR,
      };
    default:
      return state;
  }
};

export default documentsReducer;

import React from "react";

import GuideViewerResponseSlot from "./GuideViewerResponseSlot";

import useLoggedInUser from "hooks/use-logged-in-user";

import __ from "constants/static-texts";

const CLASS_NS = "guide-viewer-response-slots";

const GuideViewerResponseSlots = ({ responseSlots, classNs = CLASS_NS }) => {
  const { isCompanyReader, isCompanyAdmin } = useLoggedInUser();

  if (responseSlots.length === 0) return <></>;

  const companyDocsFound = responseSlots.some(slot => slot.companyDocumentIds.length);
  const uploadSlotFound = responseSlots.some(slot => slot.hasUploadSlot);

  return (
    !(isCompanyReader && !companyDocsFound) ? 
      (<div className={classNs}>
        <h5 className={`${classNs}_title h3`}>Kravdokument</h5>
        <div className={`${classNs}_header`}>
          {!isCompanyReader && <span>{__.document.download}</span>}
          {((isCompanyAdmin && uploadSlotFound) || companyDocsFound) && <span>{__.document.uploaded_docs}</span>}
        </div>
        <div className={`${classNs}_instances`}>
          {responseSlots.map((responseSlot, index) => {
            return <GuideViewerResponseSlot responseSlot={responseSlot} index={index + 1} key={responseSlot.id} />;
          })}
        </div>
      </div>) : null
  );
};

export default GuideViewerResponseSlots;

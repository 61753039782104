export const getCalculatedDocumentTypes = (docs, types) => {
  if (docs.length === 0 || types.length === 0) return [];
  const typesMap = types.reduce((acc, type) => {
    acc[type.id] = { ...type, count: 0 };
    return acc;
  }, {});
  docs.map((doc) => {
    if (typesMap[doc.documentType]) {
      typesMap[doc.documentType].count = typesMap[doc.documentType].count + 1;
    }
    return null;
  });

  return Object.values(typesMap).map((type) => {
    return {
      ...type,
      name: `${type.name} (${type.count})`,
    };
  });
};

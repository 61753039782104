/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  defaultMessage: 'Ogiltigt värde.',
  badInput: 'Fel inmatning.',
  rangeOverflow: 'Värdet överstiger den begränsade nivån.',
  rangeUnderflow: 'Värdet understiger den begränsade nivån.',
  tooLong: 'För många tecken.',
  tooLongFn: (maxLength) => `Du får ange max ${maxLength} tecken.`,
  tooShort: 'För få tecken.',
  tooShortFn: (minLength) => `Du får ange max ${minLength} tecken.`,
  patternMismatch: 'Värdet passar inte.',
  patternMismatchFn: (type = 'bokstäver') => `Fältet får endast innehålla ${type}.`,
  typeMismatch: 'Fel typ av värde.',
  typeMismatchFn: (str = 'Värdet') => `${str} överensstämmer ej.`,
  valueMissing: 'Fältet får inte vara tomt.',
  valueMissingFn: (str = 'ett värde') => `Du måste ange ${str}.`,
  passwordValidation:
    'Lösenordet måste vara minst 6 tecken, max 32 tecken lång och inte innehålla några mellanslag.',
  passwordConfirmMessage: 'Lösenorden bör överensstämma med varann.',
  emailPattern: 'E-posten måste vara angiven i ett korrekt e-post format.',
  customError: 'Fel.',
  stepMismatch: 'Fälten bör vara lika.'
};

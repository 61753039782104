import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useBlocksValidity = () => {
  const { blocks } = useSelector((state) => state.sectionState);

  const isValid = useMemo(
    () => Object.values(blocks).every((block) => block?.isValid ?? true),
    [blocks]
  );

  return [isValid];
};

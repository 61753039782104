import React from "react";
import { useDispatch } from "react-redux";
import { deleteLibraryDocumentVersion, downloadLibraryDocumentVersion } from "actions/librarydocuments-actions";
import { useParams, Link } from "react-router-dom";
import __ from "constants/static-texts";
import { Overlay } from "react-draft-editor";
import LibraryDocsRoute from "routes/LibraryDocs";
import useLibraryDocument from "hooks/use-library-document";
import TypographyHeadingMeta from "components/TypographyHeadingMeta/TypographyHeadingMeta";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import { formatDate } from "utils/date-helpers";
import { ReactComponent as TrashIcon } from "icons/trash.svg";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { ReactComponent as DownloadIcon } from "icons/Download.svg";

import "./_LibraryDocumentVersions.scss";

const CLASS_NS = "library-document-versions";
const LibraryDocumentsVersions = ({ cancelUrl }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [libraryDocument] = useLibraryDocument(id);

  const handleDelete = (version) => {
    dispatch(deleteLibraryDocumentVersion(id, version.id));
  };

  const handleDownload = (version) => {
    dispatch(downloadLibraryDocumentVersion(id, version.id, version.fileName));
  };

  return libraryDocument ? (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <div className={CLASS_NS}>
        <h1 className={`${CLASS_NS}_heading`}>{__.document.versionsHeading(libraryDocument.title)}</h1>
        <List type="ol">
          {libraryDocument.versions.map((version) => (
            <ListItem
              key={version.id}
              actions={
                <>
                  <ButtonIcon
                    icon={<DownloadIcon />}
                    onClick={() => handleDownload(version)}
                    aria-label={__.document.download}
                  />
                  {libraryDocument.versions.length > 1 && (
                    <ButtonIcon
                      icon={<TrashIcon />}
                      onClick={() => handleDelete(version)}
                      aria-label={__.document.delete}
                    />
                  )}
                </>
              }>
              <TypographyHeadingMeta
                heading={version.fileName}
                headingLevel={2}
                meta={
                  <span className={`${CLASS_NS}_meta`}>
                    Uppladdad <time>{formatDate(version.uploadedAt)}</time> av {version.uploadedByUser.fullName}
                  </span>
                }
              />
            </ListItem>
          ))}
        </List>
        <Link
          className={`${CLASS_NS}_back`}
          to={{
            pathname: `${LibraryDocsRoute().path}/${id}/edit`,
            state: { disableScroll: true },
          }}>
          <BackIcon aria-hidden /> {__.document.versionsBack(libraryDocument.title)}
        </Link>
      </div>
    </Overlay>
  ) : null;
};

export default LibraryDocumentsVersions;

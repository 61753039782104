import React from "react";
import PropTypes from "prop-types";
import Tooltip from "components/Tooltip/Tooltip";
import { isExternalLink } from "utils/link-helpers";
import { Link } from "react-router-dom";
import { getUniqueId } from "utils/random";
import __ from "constants/static-texts";
import "./_LinkIcon.scss";

/**
 * A link component that is just an icon. It must have either an `aria-label` or `aria-labelledby` attribute
 * The aria-label also becomes an automatic tooltip. It renders a react router link if the link si internal
 * or if the link prop is an object
 */
const LinkIcon = ({ icon, idNs, size, link, ...props }) => {
  const label = props["aria-labelledby"] || props["aria-label"];
  return label ? (
    <>
      {isExternalLink(link) ? (
        <a
          id={idNs}
          className="link-icon"
          href={link}
          data-tip={props["aria-label"] || null}
          data-for={props["aria-label"] ? `${idNs}_tooltip` : null}
          data-size={size}
          {...props}
          rel={props.rel || props.target === "_blank" ? "noopener noreferrer" : null}>
          {icon}
        </a>
      ) : (
        <Link
          id={idNs}
          className="link-icon"
          to={link}
          data-tip={props["aria-label"] || null}
          data-for={props["aria-label"] ? `${idNs}_tooltip` : null}
          data-size={size}
          {...props}>
          {icon}
        </Link>
      )}
      {props["aria-label"] && <Tooltip theme={"dark"} id={`${idNs}_tooltip`} />}
    </>
  ) : (
    console.warn(__.dev.ariaLabelMissing) || null
  );
};

LinkIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large", "dynamic"]),
  /**
   * Unique id is needed to tie the tooltip to the link.
   * Defaults to a unique id
   */
  idNs: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LinkIcon.defaultProps = {
  size: "medium",
  idNs: getUniqueId().toLowerCase(),
};

export default LinkIcon;

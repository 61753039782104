import fetchClient from "fetch-client";

// NON VALIDATED USERS - NEW USERS AWAITING APPROVAL FROM COMPANY ADMIN
export const getNonValidatedUsers = (getState) => {
    return fetchClient({
      path: '/nonvalidatedusers',
      getState,
    });
  }
  
export const getNonValidatedUser = (data, getState) => {
return fetchClient({
    path: `/nonvalidatedusers/${data.id}`,
    getState,
  });
}
  
export const approveNonValidatedUser = (data, getState) => {
  return fetchClient({
    path: `/nonvalidatedusers/${data.id}/approve`,
    getState,
    method: `POST`,
    data: {
      companyId: data.companyId,
      role: data.role
    },
  });
};
export const blockNonValidatedUser = (data, getState) => {
  return fetchClient({
    path: `/nonvalidatedusers/${data.nonValidatedUserId}/block`,
    getState,
    method: `POST`,
    data: {
      companyId: data.companyId
    },
  });
};
export const getCurrentNonValidatedUser = (getState) => {

  return fetchClient({ 
    path: '/nonvalidatedusers/me',
    getState,
  })

};
import useLoggedInUser from "hooks/use-logged-in-user";
import useCompanies from "hooks/use-companies";
import useGuideParts from "hooks/use-guide-parts";
import { getCompanyFromUser } from "utils/user-helper";

/**
 * Provides the requirements and responseSlots for the current guide section
 */
const useGuideNavigation = (currentSection) => {
  const { user, isServiceAdmin, isSupervisor, isCompanyUser } = useLoggedInUser();
  const { activeCompany, companiesMap } = useCompanies();
  const company =
    ((isServiceAdmin || isSupervisor) && activeCompany) ||
    getCompanyFromUser(isCompanyUser, companiesMap, user, activeCompany) ||
    null;

  const { allPartRoutes, allSectionRoutes, progress } = useGuideParts(company?.guideId);

  const nextPart = allPartRoutes.find((part) => part.isDoneCount !== part.childCount);

  //   const sectionWithRequirmentsNotDone = allSectionRoutes.find((section) => {
  //     if (!nextPart) {
  //       return section.isDoneCount !== section.childCount;
  //     } else {
  //       return (
  //         nextPart.id === section.parentId && (section.isDoneCount !== section.childCount || section.childCount === 0)
  //       );
  //     }
  //   });
  const sectionNotDone = allSectionRoutes.find((section) => {
    if (!nextPart) {
      return !section.isDone;
    } else {
      return nextPart.id === section.parentId && !section.isDone;
    }
  });

  const nextPartNotDone = !nextPart ? allPartRoutes.find((part) => part.id === sectionNotDone?.parentId) : nextPart;

  const nextSectionNotDone =
    !sectionNotDone && nextPartNotDone
      ? allSectionRoutes.find((section) => section.parentId === nextPartNotDone.id)
      : sectionNotDone;

  const currentSectionIndex = allSectionRoutes.findIndex((section) => section.id === currentSection);

  const previousSection = currentSectionIndex - 1 >= 0 ? allSectionRoutes[currentSectionIndex - 1] : null;
  const nextSection =
    currentSectionIndex + 1 <= allSectionRoutes.length ? allSectionRoutes[currentSectionIndex + 1] : null;

  const nextParentPart = allPartRoutes.find((part) => nextSection?.parentId === part.id);

  return {
    nextPartNotDone,
    nextSectionNotDone,
    previousSection,
    nextSection,
    nextParentPart,
    progress,
    guideId: company?.guideId,
  };
};

export default useGuideNavigation;

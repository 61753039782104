import React from "react";
import { Overlay } from "react-draft-editor";

const CLASS_NS = "form_overlay";
export const DeleteProcess = ({ 
  open = false,
  onCancel,
  onDelete }) => {
  const clickDelete = (e) => {
    e?.preventDefault();
    onDelete();
    onCancel();
  };

  const clickCancel = (e) => {
    e?.preventDefault();
    onCancel();
  };

  return (
    <Overlay cancelClick={clickCancel} open={open}>
      <div className={CLASS_NS}>
        <h2>Är du säker att du vill ta bort?</h2>

        <div className="button-row">
          <button className={`button-inverted`} onClick={clickCancel}>
            Nej, avbryt
          </button>
          <button className={`button-warning`} onClick={clickDelete}>
            Ja, radera
          </button>
        </div>
      </div>
    </Overlay>
  );
};

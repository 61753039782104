import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";
import { sortArray } from "utils/js-helpers";

const initialState = {
  news: [],
  newsNetworkState: WAITING,
  addNewsNetworkState: WAITING,
  updateNewsNetworkState: WAITING,
  deleteNewsNetworkState: WAITING,
  searchQuery: "",
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NEWS_LOADING:
      return {
        ...state,
        newsNetworkState: LOADING,
      };
    case types.GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload.sort(sortArray("createdAt", false)),
        newsNetworkState: SUCCESS,
      };
    case types.GET_NEWS_ERROR:
      return {
        ...state,
        error: action.payload,
        newsNetworkState: ERROR,
      };

    case types.ADD_NEWS_LOADING:
      return {
        ...state,
        addNewsNetworkState: LOADING,
      };
    case types.ADD_NEWS_SUCCESS:
      return {
        ...state,
        news: [action.payload, ...state.news],
        addNewsNetworkState: SUCCESS,
      };
    case types.ADD_NEWS_ERROR:
      return {
        ...state,
        error: action.payload,
        addNewsNetworkState: ERROR,
      };

    case types.UPDATE_NEWS_LOADING:
      return {
        ...state,
        updateNewsNetworkState: LOADING,
      };
    case types.UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.map((newsItem) => (newsItem.id === action.payload.id ? action.payload : newsItem)),
        updateNewsNetworkState: SUCCESS,
      };
    case types.UPDATE_NEWS_ERROR:
      return {
        ...state,
        error: action.payload,
        updateNewsNetworkState: ERROR,
      };

    case types.DELETE_NEWS_LOADING:
      return {
        ...state,
        deleteNewsNetworkState: LOADING,
      };
    case types.DELETE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.filter((newsItem) => newsItem.id !== action.payload),
        deleteNewsNetworkState: SUCCESS,
      };
    case types.DELETE_NEWS_ERROR:
      return {
        ...state,
        error: action.payload,
        deleteNewsNetworkState: ERROR,
      };

    default:
      return state;
  }
};

export default newsReducer;

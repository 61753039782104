import React from "react";
import { MenuItem } from "react-aria-menubutton";
import __ from "constants/static-texts";
import useUsers from "hooks/use-users";
import useNotifications from "hooks/use-notifications";
import useSession from "hooks/use-session";

import { ReactComponent as UserLarge } from "icons/User-large.svg";
import Notifications from "components/Notifications/Notifications";

import ToolsMenuItem from "components/ToolsMenuItem/ToolsMenuItem";
import CompanyList from "components/CompanyList/CompanyList";

const CLASS_NS = "tools-menu";

const ToolsMenu = ({ user, currentNonValidatedUser, showNotifications, classNs = CLASS_NS }) => {
  const { logoutHandler } = useSession();

  const { notifications, unReadNotifications } = useNotifications();

  const { userRoles } = useUsers();

  const userRole = userRoles.find((userRole) => {
    return userRole.id === user?.role;
  });
  if (user && !userRole) {
    return null;
  }
  const roleName = userRole ? __.usertypes[userRole.name] : "";

  return (
    <div className={classNs}>
      <CompanyList />
      {showNotifications && <Notifications notifications={notifications} newNotifications={unReadNotifications} />}
      <ToolsMenuItem
        id="user_account"
        tip={
          user
            ? `${user.userName} (${roleName})`
            : currentNonValidatedUser
            ? `${currentNonValidatedUser.firstName} ${currentNonValidatedUser.lastName}`
            : `${__.users.notLoggedIn}`
        }
        Icon={UserLarge}>
        <MenuItem value="logout" text="Logga ut" className={`${classNs}_menu_item`}>
          <button className={`${classNs}_link`} onClick={logoutHandler}>
            <span className={`${classNs}_link_text`}>Logga ut</span>
          </button>
        </MenuItem>
      </ToolsMenuItem>
    </div>
  );
};

export default ToolsMenu;

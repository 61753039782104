import React, { /*useEffect,*/ useMemo, useState } from 'react';
// import PropTypes from 'prop-types';
// import { createEditor } from 'slate';
// import { Slate, Editable, withReact } from 'slate-react';
import 'tippy.js/dist/tippy.css';

// import { CustomEditor, editorTools } from '../../utils/editor';

import {
  // ELEMENT_H1,
  // ELEMENT_IMAGE,
  // ELEMENT_PARAGRAPH,
  createSlatePluginsComponents,
  createSlatePluginsOptions,
  // MentionSelect,
  // SlatePlugin,
  SlatePlugins
  // ToolbarSearchHighlight,
  // createAlignPlugin,
  // createAutoformatPlugin,
  // createBlockquotePlugin,
  // createBoldPlugin,
  // createCodeBlockPlugin,
  // createCodePlugin,
  // createExitBreakPlugin,
  // createHeadingPlugin,
  // createHighlightPlugin,
  // createHistoryPlugin,
  // createKbdPlugin,
  // createImagePlugin,
  // createItalicPlugin,
  // createLinkPlugin,
  // createListPlugin,
  // createMediaEmbedPlugin,
  // createNodeIdPlugin,
  // createNormalizeTypesPlugin,
  // createParagraphPlugin,
  // createReactPlugin,
  // createResetNodePlugin,
  // createSelectOnBackspacePlugin,
  // createSoftBreakPlugin,
  // createStrikethroughPlugin,
  // createSubscriptPlugin,
  // createSuperscriptPlugin,
  // createTablePlugin,
  // createTodoListPlugin,
  // createTrailingBlockPlugin,
  // createUnderlinePlugin,
  // createDeserializeHTMLPlugin,
  // useFindReplacePlugin,
  // useMentionPlugin,
  // withProps,
  // MentionElement,
  // ELEMENT_MENTION
} from '@udecode/slate-plugins';
import { withStyledPlaceHolders } from './config/withStyledPlaceHolders';
// import { renderMentionLabel } from './config/renderMentionLabel';

// import {
//   SlatePlugins,
//   HeadingToolbar,
//   createSlatePluginsComponents,
//   ELEMENT_PARAGRAPH,
//   MARK_BOLD
// } from '@udecode/slate-plugins';
import 'tippy.js/dist/tippy.css';
// import * as DefaultPlugins from './config/Plugins';
import ToolbarDefault from './config/ToolbarDefault';

// TODO:
let defaultComponents = createSlatePluginsComponents();
//   {
//   [ELEMENT_MENTION]: withProps(MentionElement, {
//     renderLabel: renderMentionLabel
//   })
//   // customize your components by plugin key
// }
defaultComponents = withStyledPlaceHolders(defaultComponents);
// defaultComponents = withStyledDraggables(defaultComponents);

const defaultOptions = createSlatePluginsOptions({
  // customize your options by plugin key
});

const defaultContent = [
  {
    children: [
      {
        text: 'Börja skriva här.'
      }
    ]
  }
];

/**
 *
 * The user has to implement defined both the toobar button, the plugins they use as well as additional options and components.
 * The use has also the responsibility to implement the HeadingToolbar component which handles the active state in the toolbar buttons.
 */
const ContentEditor = ({
  blockId,
  content = defaultContent,
  onChange = () => {},
  Toolbar = ToolbarDefault,
  plugins,
  options = defaultOptions,
  isRequired = false,
  components = defaultComponents,
  onValidate = () => {}
}) => {
  const [value, setValue] = useState(typeof content === 'string' ? JSON.parse(content) : content);

  // const { getMentionSelectProps, plugin } = useMentionPlugin(
  //   optionsMentionPlugin
  // )

  const onChangeHandler = (newValue) => {
    setValue(newValue);
    onChange(JSON.stringify(newValue));
  };

  const editableProps = {
    placeholder: 'Type…',
    spellCheck: true,
    style: {
      padding: '15px'
    }
  };

  const usedPlugins = useMemo(() => {
    if (!plugins || !Array.isArray(plugins)) {
      console.error('The plugins array is empty or malformed : ', plugins);
    }
    // Plugins may come in as uninitialzed
    return plugins?.map((plugin) => (plugin instanceof Function ? plugin() : plugin));
  }, [plugins]);

  return (
    <SlatePlugins
      id={blockId}
      editableProps={editableProps}
      plugins={usedPlugins}
      options={options}
      components={components}
      initialValue={value}
      onChange={onChangeHandler}
    >
      <Toolbar />
    </SlatePlugins>
  );
};

export default ContentEditor;

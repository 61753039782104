import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// import { useTranslation } from "react-i18next";
import __ from "constants/static-texts";

// import { setCurrentSoundIdPlaying } from "actions";

import { getUniqueId } from "utils/random";

function SoundPlayer(props) {
  const { sourceUrl, playText } = props;
  const dispatch = useDispatch();
  const currentSoundIdPlaying = useSelector((state) => state.layout.currentSoundIdPlaying);
  // const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const audioEl = useRef(null);

  useEffect(() => {
    const uniqid = getUniqueId();
    setUniqueId(uniqid);
  }, []);

  // If the id from store changes and
  // doesn't match the id generated for
  // this instance, it means some other
  // player started playing somewhere.
  useEffect(() => {
    if (uniqueId && currentSoundIdPlaying !== uniqueId && isPlaying) {
      audioEl.current.pause();
      setIsPlaying(false);
    }
  }, [currentSoundIdPlaying, audioEl, isPlaying, uniqueId]);

  // Handle when audio plays to the end
  useEffect(() => {
    const _current = audioEl.current;
    function handleAudioEnded(e) {
      setIsPlaying(false);
      // dispatch(setCurrentSoundIdPlaying(null));
    }

    _current.addEventListener("ended", handleAudioEnded);
    return () => _current.removeEventListener("ended", handleAudioEnded);
  }, [audioEl, setIsPlaying, dispatch]);

  const togglePlay = () => {
    if (isPlaying) {
      audioEl.current.pause();
    } else {
      audioEl.current.play();
      // dispatch(setCurrentSoundIdPlaying(uniqueId));
    }
    setIsPlaying(!isPlaying);
  };

  const cls = "Sound-player" + (isPlaying ? " Sound-player--isPlaying" : "");

  const _playText = playText ? playText : __.workbook.play;

  return (
    <div className={cls}>
      <div className="Sound-player__controls">
        <button className="Sound-player__playpause" type="button" onClick={togglePlay}>
          {isPlaying ? __.workbook.pause : _playText}
        </button>
      </div>
      <audio ref={audioEl} className="Sound-player__audioel" preload="none" tabIndex="-1" type="audio/mpeg">
        <source type="audio/mp3" src={sourceUrl} />
      </audio>
    </div>
  );
}

SoundPlayer.propTypes = {
  sourceUrl: PropTypes.string.isRequired,
};

export default SoundPlayer;

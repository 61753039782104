import fetchClient from "fetch-client";

export const getNotifications = (getState) =>
  fetchClient({
    path: `/notifications`,
    getState,
  });

export const updateNotification = (id, getState) =>
  fetchClient({
    path: `/notifications/${id}/read`,
    getState,
    method: `PUT`,
  });

import React from "react";
import PropTypes from "prop-types";

import { InputField } from "react-draft-editor";

const CLASS_NS = "GuidePartHeader";

const GuidePartHeader = ({
  block,
  updateModuleContent,
  getValidationErrorForField,
  validate,
  onChange,
  customBlocks,
  blocksSelectorConfig,
  titleLabel,
  descriptionLabel,
}) => {
  const classNs = CLASS_NS;
  const onChangeHandler = (e) => {
    e.persist(); // NB! Not needed as of React v.17. See: https://reactjs.org/docs/events.html#overview
    if (getValidationErrorForField(e.target.name)) {
      validate(e);
    }
    onChange(e);
  };

  return (
    <div className={`${classNs}`}>
      <form>
        <label htmlFor="title" className={`${classNs}_label`}>
          {titleLabel}
        </label>
        <InputField
          value={block.title}
          type="text"
          name="title"
          onChange={onChangeHandler}
          onBlur={validate}
          maxLength="50"
          required
          data-errormessage={getValidationErrorForField("title")}
        />
      </form>
    </div>
  );
};
// <label htmlFor="description" className={`${classNs}_label`}>
//   {descriptionLabel}
// </label>
// <InputField
//   value={block?.description ?? ""}
//   type="textarea"
//   name="description"
//   onChange={onChangeHandler}
//   onBlur={validate}
//   maxLength="1000"
//   required
//   data-errormessage={getValidationErrorForField("description")}
// />

GuidePartHeader.blockType = "guide-part";

GuidePartHeader.propTypes = {
  classNs: PropTypes.string,
  titleLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  block: PropTypes.object,
  updateModuleContent: PropTypes.func,
  getValidationErrorForField: PropTypes.func,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  customBlocks: PropTypes.array,
  blocksSelectorConfig: PropTypes.array,
};
export default GuidePartHeader;

import React from 'react';
import {
  ToolbarElement,
  ToolbarList,
  ToolbarMark,
  ToolbarLink,
  ToolbarImage,
  HeadingToolbar,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_OL,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_UNDERLINE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH
} from '@udecode/slate-plugins';

import { FormatListBulleted } from '@styled-icons/material/FormatListBulleted';
import { FormatListNumbered } from '@styled-icons/material/FormatListNumbered';
import { FormatBold } from '@styled-icons/material/FormatBold';
import { FormatItalic } from '@styled-icons/material/FormatItalic';

import { FormatUnderlined } from '@styled-icons/material/FormatUnderlined';
import { FormatStrikethrough } from '@styled-icons/material/FormatStrikethrough';
import { FormatQuote } from '@styled-icons/material/FormatQuote';
import { Image } from '@styled-icons/material/Image';
import { Link } from '@styled-icons/material/Link';

const ToolbarButtonsBasicMarks = () => {
  return (
    <>
      <ToolbarMark type={MARK_BOLD} icon={<FormatBold />} />
      <ToolbarMark type={MARK_ITALIC} icon={<FormatItalic />} />
      <ToolbarMark type={MARK_UNDERLINE} icon={<FormatUnderlined />} />
      <ToolbarMark type={MARK_STRIKETHROUGH} icon={<FormatStrikethrough />} />
      <ToolbarElement type={ELEMENT_BLOCKQUOTE} icon={<FormatQuote />} />
    </>
  );
};

const ToolbarButtonsList = () => (
  <>
    <ToolbarList type={ELEMENT_UL} icon={<FormatListBulleted />} />
    <ToolbarList type={ELEMENT_OL} icon={<FormatListNumbered />} />
  </>
);
const ToolbarDefault = () => {
  return (
    <HeadingToolbar>
      <ToolbarButtonsBasicMarks />
      <ToolbarButtonsList />
      <ToolbarLink icon={<Link />} />
      <ToolbarImage icon={<Image />} />
    </HeadingToolbar>
  );
};

export default ToolbarDefault;

import React, { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { push } from "connected-react-router";
import PropTypes from "prop-types";

// import GuideEditorRichText from "./GuideEditorRichText";
import GuideSectionHeader from "./GuideSectionHeader";
import GuideMainRequirementEditor from "./GuideMainRequirementEditor";
import GuideEditorSavebar from "./GuideEditorSavebar";
import GuideBlockBarLabel from "./GuideBlockBarLabel";
import GuideEditorSectionTypeSelector from "./GuideEditorSectionTypeSelector";

import Loading from "components/Loading/Loading";
import DraftEditor, { DraftNavigation, useBlocksSaveable, resetSectionsState } from "react-draft-editor";
// import DraftNavigation from "submodules/react-draft-editor/components/draft-navigation/draft-navigation";
// import { moduleTypes } from "submodules/react-draft-editor/constants/module-types";

import {
  swapSectionIndexes,
  addNewGuideSection,
  saveGuideBlocks,
  updateSectionBlocks,
  deleteGuideBlocks,
  updateSections,
  resetAllRequirementsDirty,
} from "actions/guide-actions";

import useGuide from "hooks/use-guide";
import useStateWithLabel from "hooks/use-state-with-label";

import GuidesRoute from "routes/Guides";
import { normalizeSectionsData, useBlocksDeleted } from "react-draft-editor";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import { ReactComponent as TrashIcon } from "icons/trash.svg";
import { ReactComponent as ChevronUpIcon } from "icons/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "icons/chevron-down1.svg";

import { /*WAITING, */ LOADING /*, ERROR, SUCCESS */ } from "constants/network-states";
import { guideSectionTypes } from "constants/guide-types";
import { guideSectionBlockSelector } from "constants/guide-blocks";
import __ from "constants/static-texts";
import { GuideChildLabels } from "./GuideChildLabels";

const iconArrowUp =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10 8 5l-5 5"/></svg>';
const iconArrowDown =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m3 6 5 5 5-5"/></svg>';

/**
 * The hierarchy for the guide is
 * guide-part
 * guide-section
 * guide-mainRequirement
 * guide-requirement
 * guide-responseSlot
 */

/**
 * Entry point for editing guide sections
 *
 */
const GuideEditor = ({ match }) => {
  const classNs = "GuideEditor";
  const dispatch = useDispatch();
  const guideId = useMemo(() => {
    return match.params?.guideId ?? null;
  }, [match]);
  const guidePartId = useMemo(() => {
    return match.params?.guidePartId ?? null;
  }, [match]);
  const guideSectionId = useMemo(() => {
    return match.params?.guideSectionId ?? null;
  }, [match]);
  const [isSaveable] = useBlocksSaveable();
  const [deletedBlockIds] = useBlocksDeleted();
  const [reindexedIds, setReindexedIds] = useStateWithLabel([], "reindexedIds");
  const prevGuideSections = useRef([]);
  const { blocks } = useSelector((state) => state.sectionState);
  const [guide, guideNetworkState] = useGuide(guideId, true);
  const [showTypeOverlay, setShowTypeOverlay] = useState(false);
  const [nextSectionIndex, setNextSectionIndex] = useState(null);
  const [changingSection, setChangingSection] = useState(false);
  const getPartUrl = () => `${GuidesRoute().path}/${guideId}/guide-part-editor/${guidePartId}`;
  const getSectionLinkFn = useCallback(
    (id) => {
      return `${GuidesRoute().path}/${guideId}/guide-part-editor/${guidePartId}/guide-editor/${id}`;
    },
    [guideId, guidePartId]
  );
  const getPublishedUrl = () => `${GuidesRoute().path}/${guideId}/guide/${guidePartId}/${guideSectionId}`;
  const guideSections = useMemo(
    () => guide?.filter((block) => block.type === guideSectionTypes.GUIDE_SECTION && block.parentId === guidePartId),
    [guide, guidePartId]
  );
  const getCurrentSection = useCallback(() => {
    // Choose either the first section if navigating to the page,
    // the last section if adding a new section
    // or the section accorsing to the url.
    const currentSection = guideSections.find((block) => block.id === guideSectionId);
    const prevSectionsLength = prevGuideSections.current.length;
    const currentSectionsLength = guideSections.length;

    let nextSection = null;

    if (prevSectionsLength === currentSectionsLength || prevSectionsLength === 0) {
      nextSection = currentSection ?? guideSections?.[0] ?? null; // current or first.
    } else if (currentSectionsLength > prevSectionsLength) {
      nextSection = guideSections?.[currentSectionsLength - 1]; // Last section as that's where the new sections are placed.
    } else {
      // Choose previous section if length is less
      const currentIds = guideSections.map((section) => section.id);
      const deletedSection = prevGuideSections.current.find((section) => currentIds.includes(section.id) === false);
      const nextIndex = deletedSection?.index > 0 ? deletedSection?.index - 1 : 0; // Next index above.

      nextSection = guideSections?.[nextIndex] ?? null;
    }
    prevGuideSections.current = guideSections;
    return nextSection;
  }, [guideSections, guideSectionId]);

  const [currentSection, setCurrentSection] = useStateWithLabel(null, "currentSection");
  useEffect(() => {
    return () => {
      dispatch(resetSectionsState());
    };
  }, []);
  useEffect(() => {
    setChangingSection(false);
    setCurrentSection(getCurrentSection());
  }, [guide, setCurrentSection, getCurrentSection]);

  const getCurrentSectionBlocks = useCallback((id, blks, result = []) => {
    if (!id) {
      return result;
    }
    return blks.reduce((acc, block) => {
      if (block.id === id) {
        acc = acc.concat([block]);
      } else if (block.parentId === id) {
        acc = getCurrentSectionBlocks(block.id, blks, acc);
      }
      return acc;
    }, result);
  }, []);

  const currentSectionBlocks = useMemo(
    () => getCurrentSectionBlocks(currentSection?.id, guide),
    [guide, currentSection?.id, getCurrentSectionBlocks]
  );

  const onNewSection = (nextIndex, isIntroduction) => {
    if (typeof nextIndex !== "number") {
      console.error(`Index was not an number : ${nextIndex}`);
    }
    dispatch(addNewGuideSection(guideId, guidePartId, nextIndex, isIntroduction));
  };

  const onMove = (event) => {
    const { index, direction, id } = event;
    const toIndex = direction === "up" ? index - 1 : index + 1;
    dispatch(swapSectionIndexes(toIndex, index, id, currentSection.type));
  };

  const onSectionChange = async (e) => {
    e.preventDefault();
    const link = e.target.closest("a[href]");
    const { pathname, search } = link;
    setChangingSection(true);
    // Auto save for now.
    if (isSaveable) {
      await onSave();
    }

    dispatch(push(`${pathname}${search}`));
    // TODO: set confirmation dialog here.
    // const serverSectionData = await dispatch(saveSection());
    // dispatch(
    //   loadOverlayContent({
    //     instance: ConfirmDialog,
    //     passedProps: {
    //       isInOverlay: true,
    //       confirm: handleSubmitAndSelectNextSection.bind(null, id),
    //       discard: setUrlToSectionId.bind(null, id),
    //       overlayInnerClass: 'overlay_inner overlay_inner_confirm',
    //       dialogText: 'Vill du spara förändringarna i denna sektion?',
    //       confirmText: 'Spara',
    //       cancelText: 'Kassera ändringar'
    //     }
    //   })
    // );
  };
  const onSectionsChange = (ids) => {
    setReindexedIds(ids);
  };

  const onBack = (e) => {
    // TODO: set confirmation dialog here whereafter take over the navigation programatically.
    // e.preventDefault();
    // history.push(getPartUrl());
  };

  const onSave = async () => {
    const newBlockIds = Object.values(blocks)
      .filter((block) => block?.tempId ?? false)
      .map((block) => block.id);

    //Remove all dirty checks on requirements, used inside GuideRequirementEditor
    dispatch(resetAllRequirementsDirty());

    if (newBlockIds.length) {
      await dispatch(saveGuideBlocks(newBlockIds, guideId));
    }
    if (deletedBlockIds.length) {
      await dispatch(deleteGuideBlocks(deletedBlockIds, guideId));
    }
    if (reindexedIds.length) {
      await dispatch(updateSections(reindexedIds, guideId));
    }
    // There isn't a current mechanism to test if a block has ONLY been updated.
    // This is ok though as the delta is zero after above updates.
    return await dispatch(updateSectionBlocks(guideId));
  };

  const onDelete = () => {
    dispatch(
      deleteGuideBlocks(
        Object.values(blocks).map((b) => b.id),
        guideId
      )
    );
  };

  const showTypeSelector = (nextIndex) => {
    setNextSectionIndex(nextIndex);
    setShowTypeOverlay(!showTypeOverlay);
  };

  // const onPublish = () => {};
  const normalizedSections = useMemo(() => {
    return normalizeSectionsData(guideSections, undefined, getSectionLinkFn);
  }, [guideSections, getSectionLinkFn]);
  return (
    <div className={`${classNs}_page`}>
      {showTypeOverlay ? (
        <GuideEditorSectionTypeSelector
          onHide={showTypeSelector}
          nextSectionIndex={nextSectionIndex}
          onSubmit={onNewSection}
        />
      ) : null}
      <GuideEditorSavebar
        currentBlock={currentSection}
        currentSections={normalizedSections}
        classNs={`${classNs}_savebar`}
        backUrl={getPartUrl()}
        onBack={onBack}
        reviewLink={getPublishedUrl()}
        onSave={onSave}
        onDelete={onDelete}
        disableDeleteButton={!!normalizedSections.length}
        //onPublish={onPublish}
      />
      <DraftNavigation
        classNs="DocEditorNav"
        sectionId={currentSection?.id}
        sections={normalizedSections}
        onNewSection={showTypeSelector}
        onSectionChange={onSectionChange}
        onSectionsChange={onSectionsChange}
        onMoveSection={onMove}
        addButtonLabel={__.guide.section.add_section}
        AddButtonIcon={PlusIcon}
        upButtonIcon={iconArrowUp}
        downButtonIcon={iconArrowDown}
      />
      {guideNetworkState === LOADING ? (
        <Loading />
      ) : currentSection ? (
        <DraftEditor
          classNs={classNs}
          section={currentSectionBlocks}
          schema={{}}
          togglerComponents={[<GuideBlockBarLabel label={__.guide.mainRequirement.add_main_requirement_name} />]}
          LeadContent={
            !changingSection ? (
              <GuideSectionHeader titleLabel="Rubrik" descriptionLabel="Ingress" routineLabel="Våra rutiner" />
            ) : (
              <Loading />
            )
          }
          customBlocks={[
            <GuideMainRequirementEditor onSave={onSave} blocksSelectorConfig={guideSectionBlockSelector} />,
          ]}
          nonExpandedComponents={[<GuideChildLabels />]}
          moduleTypesByDepth={{}}
          blocksSelectorConfig={!currentSection.isIntroduction ? guideSectionBlockSelector : []}
          LoadingComponent={Loading}
          showBackgroundOverlay={true}
          iconsConfigCustom={{
            block: {
              delete: TrashIcon,
              move_up: ChevronUpIcon,
              move_down: ChevronDownIcon,
            },
          }}
        />
      ) : null}
    </div>
  );
};

GuideEditor.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};
export default GuideEditor;

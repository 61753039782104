import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";
// import { guide } from "../mock-data/guide-data";
import { idToTypeId, guideIdConverter, guideParentIdConverter } from "utils/normalize";
import { guideTypeHierarchy } from "constants/guide-types";
import { guideSectionTypes } from "constants/guide-types";

const initialState = {
  guide: [],
  guideMeta: null,
  guideNetworkState: WAITING,
  guideMetaNetworkState: WAITING,
  guideSaveNetworkState: WAITING,
  error: null,
  activeComment: null,
  activeRequirements: [],
  activeSection: null,
  requirementsDirty: [],
};

const guideReducer = (state = initialState, action) => {
  const { type, payload, params } = action;
  switch (type) {
    case types.GET_GUIDE_LOADING:
      return {
        ...state,
        guideNetworkState: LOADING,
      };
    case types.GET_GUIDE_SUCCESS:
      return {
        ...state,
        guide: idToTypeId(payload, guideTypeHierarchy, guideIdConverter, guideParentIdConverter),
        guideNetworkState: SUCCESS,
      };
    case types.GET_GUIDE_ERROR:
      return {
        ...state,
        error: payload,
        guideNetworkState: ERROR,
      };

    case types.GET_GUIDE_META_LOADING:
      return {
        ...state,
        guideMetaNetworkState: LOADING,
      };
    case types.GET_GUIDE_META_SUCCESS:
      return {
        ...state,
        guideMeta: payload,
        // guide: guide,
        guideMetaNetworkState: SUCCESS,
      };
    case types.GET_GUIDE_META_ERROR:
      return {
        ...state,
        error: payload,
        guideMetaNetworkState: ERROR,
      };

    case types.ADD_NEW_GUIDE_PART_LOADING:
      return {
        ...state,
        guideNetworkState: LOADING,
      };
    case types.ADD_NEW_GUIDE_PART_SUCCESS:
      return {
        ...state,
        guide: [...state.guide, ...idToTypeId(payload, guideTypeHierarchy, guideIdConverter, guideParentIdConverter)],
        guideNetworkState: SUCCESS,
      };
    case types.ADD_NEW_GUIDE_PART_ERROR:
      return {
        ...state,
        error: payload,
        guideNetworkState: ERROR,
      };

    case types.ADD_NEW_GUIDE_SECTION_LOADING:
      return {
        ...state,
        guideNetworkState: LOADING,
      };
    case types.ADD_NEW_GUIDE_SECTION_SUCCESS:
      return {
        ...state,
        guide: [...state.guide, ...idToTypeId(payload, guideTypeHierarchy, guideIdConverter, guideParentIdConverter)],
        guideNetworkState: SUCCESS,
      };
    case types.ADD_NEW_GUIDE_SECTION_ERROR:
      return {
        ...state,
        error: payload,
        guideNetworkState: ERROR,
      };

    case types.SWAP_SECTION_INDEXES:
      const { from, to, id, type } = payload;
      // TODO: Rework with ids etc
      return {
        ...state,
        guide: state.guide.map((block) => {
          if (block.type !== type) {
            return block;
          }
          if (block.id === id) {
            return {
              ...block,
              index: to,
            };
          } else if (block.index === to) {
            return {
              ...block,
              index: from,
            };
          } else {
            return block;
          }
        }),
      };

    case types.SAVE_GUIDE_BLOCKS_LOADING:
      return {
        ...state,
        guideSaveNetworkState: LOADING,
      };
    case types.SAVE_GUIDE_BLOCKS_SUCCESS:
      const ids = payload.map((block) => block.id);
      // Merge in the blocks from the payload
      const convertedPayload = idToTypeId(payload, guideTypeHierarchy, guideIdConverter, guideParentIdConverter);
      return {
        ...state,
        guide: state.guide.map((guide) => {
          let guideId = guide.id;
          return ids.includes(guideId) ? convertedPayload.find((block) => block.id === guideId) : guide;
        }),
        guideSaveNetworkState: SUCCESS,
      };
    case types.SAVE_GUIDE_BLOCKS_ERROR:
      return {
        ...state,
        error: payload,
        guideSaveNetworkState: ERROR,
      };

    case types.UPDATE_GUIDE_BLOCKS_LOADING:
      return {
        ...state,
        guideSaveNetworkState: LOADING,
      };
    case types.UPDATE_GUIDE_BLOCKS_SUCCESS:
      return {
        ...state,
        guide: [...idToTypeId(payload, guideTypeHierarchy, guideIdConverter, guideParentIdConverter)],
        guideSaveNetworkState: SUCCESS,
      };
    case types.UPDATE_GUIDE_BLOCKS_ERROR:
      return {
        ...state,
        error: payload,
        guideSaveNetworkState: ERROR,
      };

    case types.DELETE_GUIDE_BLOCKS_LOADING:
      return {
        ...state,
        guideNetworkState: LOADING,
      };
    case types.DELETE_GUIDE_BLOCKS_SUCCESS:
      // TODO: Consider reindexing of deleted blocks siblings
      const deletedBlocks = idToTypeId(params.blocks, guideTypeHierarchy, guideIdConverter, guideParentIdConverter);
      return {
        ...state,
        // Filter away the deleted blocks out of the guide
        guide: state.guide.filter((block) => (deletedBlocks.some((dblock) => dblock.id === block.id) ? false : true)),
        guideNetworkState: SUCCESS,
      };
    case types.DELETE_GUIDE_BLOCKS_ERROR:
      return {
        ...state,
        error: payload,
        guideNetworkState: ERROR,
      };

    case types.FAVOURITE_GUIDE_BLOCKS_LOADING:
      return {
        ...state,
        guideNetworkState: LOADING,
      };
    case types.FAVOURITE_GUIDE_BLOCKS_SUCCESS:
      return {
        ...state,
        guide: state.guide.map((block) => {
          return block.type === guideSectionTypes.GUIDE_SECTION && `guide-section_${payload.id}` === block.id
            ? { ...payload, id: `guide-section_${payload.id}`, parentId: `guide-part_${payload.parentId}` }
            : block;
        }),
        guideNetworkState: SUCCESS,
      };
    case types.FAVOURITE_GUIDE_BLOCKS_ERROR:
      return {
        ...state,
        error: payload,
        guideNetworkState: ERROR,
      };

    case types.CREATE_ROUTINE_SUCCESS:
    case types.UPDATE_ROUTINE_SUCCESS:
      return {
        ...state,
        guide: state.guide.map((block) => {
          return block.type === guideSectionTypes.GUIDE_SECTION && `guide-section_${payload.sectionId}` === block.id
            ? { ...block, routine: payload }
            : block;
        }),
      };

    //When a company uploads a document
    case types.ADD_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        guide: state.guide.map((block) => {
          if (
            block.type === guideSectionTypes.GUIDE_RESPONSE_SLOT &&
            payload.responseSlotId &&
            `${guideSectionTypes.GUIDE_RESPONSE_SLOT}_${payload.responseSlotId}` === block.id
          ) {
            return { ...block, companyDocumentIds: block.companyDocumentIds.concat(payload.id) };
          } else if (
            block.type === guideSectionTypes.GUIDE_SECTION &&
            payload.routineId &&
            payload.routineId === block.routine?.id
          ) {
            return {
              ...block,
              routine: {
                ...block.routine,
                companyDocumentIds: block.routine.companyDocumentIds.concat(payload.id),
              },
            };
          } else {
            return block;
          }
        }),
      };
    //When a company deletes a document
    case types.DELETE_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        guide: state.guide.map((block) => {
          if (
            block.type === guideSectionTypes.GUIDE_RESPONSE_SLOT &&
            payload.responseSlotId &&
            payload.responseSlotId === block.id
          ) {
            return { ...block, companyDocumentIds: block.companyDocumentIds.filter((cdi) => cdi !== payload.id) };
          } else if (
            block.type === guideSectionTypes.GUIDE_SECTION &&
            payload.routineId &&
            payload.routineId === block.routine?.id
          ) {
            return {
              ...block,
              routine: {
                ...block.routine,
                companyDocumentIds: block.routine.companyDocumentIds.filter((cdi) => cdi !== payload.id),
              },
            };
          } else {
            return block;
          }
        }),
      };

    case types.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        guide: state.guide.map((block) => {
          if (
            block.type === guideSectionTypes.GUIDE_SECTION &&
            payload.sectionId &&
            `${guideSectionTypes.GUIDE_SECTION}_${payload.sectionId}` === block.id
          ) {
            return { ...block, commentIds: block.commentIds.concat(payload.id) };
          } else if (
            block.type === guideSectionTypes.GUIDE_REQUIREMENT &&
            payload.requirementId &&
            `${guideSectionTypes.GUIDE_REQUIREMENT}_${payload.requirementId}` === block.id
          ) {
            return { ...block, commentIds: block.commentIds.concat(payload.id) };
          } else {
            return block;
          }
        }),
      };

    case types.UPDATE_BLOCK_VALUE_LOADING:
      return {
        ...state,
        guideSaveNetworkState: LOADING,
      };
    case types.UPDATE_BLOCK_VALUE_SUCCESS:
      return {
        ...state,
        guide: [...idToTypeId(payload, guideTypeHierarchy, guideIdConverter, guideParentIdConverter)],
        guideSaveNetworkState: SUCCESS,
      };
    case types.UPDATE_BLOCK_VALUE_ERROR:
      return {
        ...state,
        error: payload,
        guideSaveNetworkState: ERROR,
      };

    case types.SET_ACTIVE_COMMENT:
      return {
        ...state,
        activeComment: payload,
      };

    case types.OPEN_REQUIREMENT:
      return {
        ...state,
        activeRequirements: state.activeRequirements.concat(payload),
      };
    case types.CLOSE_REQUIREMENT:
      return {
        ...state,
        activeRequirements: state.activeRequirements.filter((requirement) => requirement !== payload),
      };

    case types.RESET_GUIDE:
      return {
        ...initialState,
      };

    case types.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: payload,
      };

    case types.SET_REQUIREMENT_DIRTY:
      return {
        ...state,
        requirementsDirty: state.requirementsDirty.includes(payload)
          ? state.requirementsDirty
          : state.requirementsDirty.concat([payload]),
      };
    case types.RESET_ALL_REQUIREMENTS_DIRTY:
      return {
        ...state,
        requirementsDirty: [],
      };

    default:
      return state;
  }
};

export default guideReducer;

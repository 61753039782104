import React, { useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Wrapper, Button, Menu, closeMenu } from "react-aria-menubutton";

import { ReactComponent as UserLarge } from "icons/User-large.svg";
import { ReactComponent as ChevronDown } from "icons/chevron-down1.svg";
import Tooltip from "components/Tooltip/Tooltip";
import { ConditionalWrapper } from "../ConditionalWrapper/ConditionalWrapper";
const CLASS_NS = "tools-menu-item";

const ToolsMenuItem = ({
  classNs = CLASS_NS,
  id,
  tip = "",
  visible = true,
  label = "",
  Icon = UserLarge,
  children,
  isActive = false,
}) => {
  const tooltipEl = useRef(null);

  const hideTooltip = () => ReactTooltip.hide(tooltipEl.current);

  const handleCloseMenu = () => {
    closeMenu(`${id}_tools-menu`);
  };
  return visible ? (
    <>
      <Wrapper id={`${id}_tools-menu`} className={classNs} onClick={hideTooltip}>
        <ConditionalWrapper
          condition={label}
          wrapper={(children) => (
            <label data-active={isActive}>
              {label}
              {children}
            </label>
          )}>
          <Button
            className={`${classNs}_button`}
            data-tip={label ? null : tip}
            forwardedRef={tooltipEl}
            active={label}
            data-for={`${id}_tooltip`}>
            <div className="icon-main">
              <Icon />
            </div>
            <div className="icon-chevron">
              <ChevronDown />
            </div>
          </Button>
        </ConditionalWrapper>

        <Menu className={`${classNs}_menu`} onClick={handleCloseMenu}>
          {children}
        </Menu>
      </Wrapper>
      <Tooltip id={`${id}_tooltip`} />
    </>
  ) : null;
};

export default ToolsMenuItem;

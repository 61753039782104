import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNonValidatedUsers } from "actions/non-validated-users-actions";

import { WAITING, LOADING } from "constants/network-states";
import { USER_TYPES } from "constants/user-types";

/**
 * Provides all non validated users
 */
const useAllNonValidatedUsers = (user) => {
  const dispatch = useDispatch();
  const { nonValidatedUsers, nonValidatedUsersNetworkState } = useSelector((state) => state.nonValidatedUsersState);
  const { activeCompany, companiesMap } = useSelector((state) => state.companiesState);
  const { user: oidcUser } = useSelector((state) => state.oidc);

  const fetchNonValidatedUsers = useCallback(() => {
    nonValidatedUsersNetworkState === WAITING && dispatch(getNonValidatedUsers());
  }, [oidcUser, nonValidatedUsersNetworkState]); // eslint-disable-line react-hooks/exhaustive-deps


  const nonValidatedUsersIsLoading =
    nonValidatedUsersNetworkState === WAITING ||
    nonValidatedUsersNetworkState === LOADING;

  useEffect(() => {
    if (oidcUser && [USER_TYPES.SERVICEADMIN.id, USER_TYPES.COMPANYADMIN.id].includes(user?.role)) {
      fetchNonValidatedUsers();
    }
  }, [oidcUser, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const companyNames = (user) => (
      companiesMap &&
      user?.role !== USER_TYPES.SERVICEADMIN.id &&
      user?.companyValidationStatuses?.length &&
      user.companyValidationStatuses
        .filter(status => !!companiesMap[status.companyId])
        .map(status => companiesMap[status.companyId])) ||
    [];

  const nonValidatedUsersWithCompanyNames = nonValidatedUsers
    .filter((user) => {
      return !activeCompany || (activeCompany && user.companyValidationStatuses.filter(validationStatus => validationStatus.companyId === activeCompany.id));
    })
    .map((user) => {
      return {
        ...user,
        companyNames: companyNames(user),
      };
    });

  return { nonValidatedUsers, nonValidatedUsersIsLoading, nonValidatedUsersWithCompanyNames };
};

export default useAllNonValidatedUsers;

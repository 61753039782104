export const WAITING = 'WAITING'
export const LOADING = 'LOADING'
export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'
export const NOT_FOUND = 'NOT_FOUND'
export const FORBIDDEN = 'FORBIDDEN'
export const SUBMITTING = 'SUBMITTING'
export const DELETING = 'DELETING'

export const CANCEL_GENERATOR = "CANCEL_GENERATOR";

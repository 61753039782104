import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Plus } from "icons/plus.svg";

import Tooltip from "components/Tooltip/Tooltip";
import ReactTooltip from "react-tooltip";

const CLASS_NS = "icon-modal";

const IconModal = ({
  classNs = CLASS_NS,
  extraClass = "",
  text,
  title,
  blockData,
  type = "section",
  clickHandler = () => {},
  onClose = () => {},
  isComment = false,
  Icon,
  isOpen = false,
  closeModal = false,
  tooltip = {
    id: "",
    text: "",
    theme: "",
  },
  disabled = false,
  children,
}) => {
  const buttonEl = useRef(null);
  const targetRef = useRef();

  const [modalOpen, setModalOpen] = useState(isOpen);

  const { activeComment } = useSelector((state) => state.guideState);

  const onButtonClick = () => {
    clickHandler();
    ReactTooltip.hide(buttonEl.current);
    if (modalOpen) {
      onClose();
    }
    setModalOpen(!modalOpen);
  };

  const onDocumentClick = useCallback((e) => {
    // Close on click outside the modal.
    const { target } = e;
    const containsTarget = targetRef?.current.contains(target);

    if (containsTarget) {
      return;
    }
    close();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener("click", onDocumentClick);
    } else {
      document.removeEventListener("click", onDocumentClick);
    }
  }, [modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = useCallback(() => {
    setModalOpen(false);
    onClose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isComment && activeComment === blockData?.id) {
      setModalOpen(true);
    }
  }, [activeComment, blockData, isComment]);

  useEffect(() => {
    if (closeModal) {
      close();
    }
  }, [closeModal, close]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`${classNs} ${extraClass}`} ref={targetRef}>
      <button
        className={`${classNs}_button`}
        data-icon="comment"
        data-tip={tooltip.text}
        data-for={tooltip.id}
        onClick={onButtonClick}
        disabled={disabled}
        ref={buttonEl}>
        <div className="icon">
          <Icon />
        </div>
        {text}
      </button>
      <Tooltip theme={tooltip.theme} id={tooltip.id} />
      {modalOpen && children ? (
        <div className={`${classNs}_modal`}>
          <div className={`${classNs}_modal_top`}>
            <h3 className={`${classNs}_modal_title`}>{title}</h3>
            <button onClick={close} className={`${classNs}_modal_close`}>
              <div className={`${classNs}_modal_close_button`}>
                <Plus />
              </div>
            </button>
          </div>
          <div className={`${classNs}_modal_content`}>{React.cloneElement(children, { close })}</div>
        </div>
      ) : null}
    </div>
  );
};

export default IconModal;

import Tools from "./components/Tools";

import { UserIsAuthenticated } from "utils/auth-helpers";

const ToolsRoute = () => ({
  path: "/tools",
  component: UserIsAuthenticated(Tools),
});

export default ToolsRoute;

import React, { useState } from "react";
import { Overlay } from "react-draft-editor";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";

const CLASS_NS = "form_overlay";
const GuideEditorSectionTypeSelector = ({ cancelUrl, onHide, onSubmit, nextSectionIndex }) => {
  const [isIntroduction, setIsIntroduction] = useState(false);
  const clickSubmit = (e) => {
    e.preventDefault();
    onSubmit(nextSectionIndex, isIntroduction);
    onHide();
  };

  return (
    <Overlay cancelClick={onHide}>
      <div className={CLASS_NS}>
        <h2>Välj avsnittstyp</h2>
        <div className={`${CLASS_NS}_form-row`}>Vilken typ av avsnitt vill du lägga till?</div>
        <div className={`${CLASS_NS}_form-row`}>
          <ChoiceGroup
            classNs={`${CLASS_NS}_type-switch`}
            onChange={() => setIsIntroduction(!isIntroduction)}
            chosen={isIntroduction ? "1" : "0"}
            choices={[
              {
                value: "0",
                label: "Ett vanligt avsnitt",
              },
              {
                value: "1",
                label: "Ett introduktionsavsnitt",
              },
            ]}
          />
        </div>

        <div className="button-row">
          <button className={`button-inverted`} onClick={onHide}>
            Avbryt
          </button>
          <button className={`button`} onClick={clickSubmit}>
            Skapa avsnitt
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default GuideEditorSectionTypeSelector;

import { MAIN_NAVIGATION_ROUTES } from "constants/navigation";
import useLoggedInUser from "hooks/use-logged-in-user";
import useCompanies from "hooks/use-companies";
import useGuideParts from "hooks/use-guide-parts";
import useWorkbooks from "hooks/use-workbooks";
import __ from "constants/static-texts";
import { getCompanyFromUser } from "utils/user-helper";

/**
 * Provides the main navigation
 */
const useNavigation = () => {
  const [workbooks] = useWorkbooks();
  const { user, isServiceAdmin, isSupervisor, isCompanyUser, isCompanyReader, isMultiCompanyUser } = useLoggedInUser();
  const { activeCompany, companiesMap } = useCompanies();
  const { activePartRoute, progress } = useGuideParts();
  const company =
    ((isServiceAdmin || isSupervisor || isMultiCompanyUser) &&
      activeCompany?.guideId &&
      activePartRoute?.parentId &&
      activeCompany?.guideId === activePartRoute?.parentId &&
      activeCompany) ||
    getCompanyFromUser(isCompanyUser, companiesMap, user, activeCompany) ||
    null;

  const mainNavigation = Object.entries(MAIN_NAVIGATION_ROUTES)
    .map(([key, route]) => {
      if (key === "GUIDE") {
        return company && (company.isGuideOpen || isServiceAdmin || isSupervisor)
          ? {
              ...route,
              path: route.path.replace(":id", company.guideId),
              progress: progress,
              label: isCompanyReader ? __.navigation.manual : route.label,
            }
          : null;
      } else if (key === "WORKBOOKS") {
        return workbooks?.length ? route : null;
      } else {
        return route;
      }
    })
    .filter((route) => {
      return route && (route.roles.length === 0 || route.roles.includes(user?.role));
    })
    .filter((route) => {
      return route && (route.products.length === 0 || route.products.includes(company?.product));
    })
    .filter((route) => {
      return !activeCompany || (activeCompany && !route.hideIfCompanyActive);
    });

  return { mainNavigation };
};

export default useNavigation;

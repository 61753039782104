import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";
import { sortArray } from "utils/js-helpers";

const initialState = {
  notifications: [],
  notificationsNetworkState: WAITING,
  // addGuideNetworkState: WAITING,
  updateNotificationsNetworkState: WAITING,
  // deleteGuideNetworkState: WAITING,

  error: null,
};

const notificationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsNetworkState: LOADING,
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload.sort(sortArray("createdAt", false)),
        notificationsNetworkState: SUCCESS,
      };
    case types.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: payload,
        notificationsNetworkState: ERROR,
      };

    case types.UPDATE_NOTIFICATION_LOADING:
      return {
        ...state,
        updateNotificationsNetworkState: LOADING,
      };
    case types.UPDATE_NOTIFICATION_SUCCESS:
      console.log(payload);
      return {
        ...state,
        notifications: state.notifications.map((note) => {
          return note.id === payload.id ? payload : note;
        }),
        updateNotificationsNetworkState: SUCCESS,
      };
    case types.UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        error: payload,
        updateNotificationsNetworkState: ERROR,
      };

    default:
      return state;
  }
};

export default notificationsReducer;

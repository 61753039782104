import fetchClient from "fetch-client";

export const getCalendars = (getState) =>
  fetchClient({
    path: `/calendars`,
    getState,
  });

export const getCalendarInstance = (calendarId, getState) =>
  fetchClient({
    path: `/calendars/${calendarId}`,
    getState,
  });

export const addCalendarInstance = (data, getState) =>
  fetchClient({
    path: `/calendars/${data.calendar}/instance`,
    getState,
    method: "POST",
    data,
  });

export const addCalendarActivity = (data, getState) =>
  fetchClient({
    path: `/calendars/${data.calendar}/activities`,
    getState,
    method: "POST",
    data,
  });

export const updateCalendarActivity = (data, getState) =>
  fetchClient({
    path: `/calendars/${data.calendar}/activities/${data.activity}`,
    getState,
    method: "PUT",
    data,
  });

export const deleteCalendarActivity = (data, getState) =>
  fetchClient({
    path: `/calendars/${data.calendarId}/activities/${data.activityId}`,
    getState,
    method: `DELETE`,
  });

export const addCalendar = (data, getState) =>
  fetchClient({
    path: `/calendars`,
    getState,
    method: "POST",
    data,
  });

export const updateCalendar = (data, getState) =>
  fetchClient({
    path: `/calendars/${data.calendar}`,
    getState,
    method: "PUT",
    data,
  });

export const deleteCalendar = (id, getState) =>
  fetchClient({
    path: `/calendars/${id}`,
    getState,
    method: `DELETE`,
    data: id,
  });

import React, { useState } from "react";

import { useSelector } from "react-redux";
import useLibraryDocuments from "hooks/use-library-documents";
import useCompanyDocuments from "hooks/use-company-documents";

// import { ReactComponent as Pencil } from "icons/Pencil.svg";
import MyFavouritesItem from "./MyFavouritesItem";

const CLASS_NS = "my-favourites";

const MyFavourites = ({ classNs = CLASS_NS }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  // Remember to change this number in the CSS as well.
  const maxFavouriteItems = 4;

  const { guide, guideMeta } = useSelector((state) => state.guideState);
  const [librarydocuments] = useLibraryDocuments();
  const { companyDocuments } = useCompanyDocuments();

  const filteredGuide = guide
    ?.filter((part) => !!part.isFavourite)
    .map((part) => ({ ...part, guideId: guideMeta?.id }));
  const filteredLibraryDocuments = librarydocuments
    ?.filter((part) => !!part.isFavourite)
    .map((doc) => ({ ...doc, type: "librarydoc" }));
  const filteredCompanyDocuments = companyDocuments
    ?.filter((part) => !!part.isFavourite)
    .map((doc) => ({ ...doc, type: "companydoc" }));

  const favourites = [...filteredGuide, ...filteredLibraryDocuments, ...filteredCompanyDocuments];

  return favourites.length > 0 ? (
    <div>
      <div className={`${classNs}_header_wrapper`}>
        <h2>Dina favoriter</h2>
        {/* <div className={`${classNs}_edit_wrapper`}>
          <button className={`${classNs}_button`}>
            Redigera <Pencil />
          </button>
        </div> */}
      </div>
      <div className={`${classNs}_list_wrapper`} data-is-expanded={isExpanded}>
        {favourites.map((favourite, index) => (
          <MyFavouritesItem key={index} favourite={favourite} />
        ))}
      </div>
      {favourites.length > maxFavouriteItems && (
        <button className={`${classNs}_button_show_more`} onClick={toggleExpanded}>
          {isExpanded ? <p>Visa färre</p> : <p>Visa fler</p>}
        </button>
      )}
    </div>
  ) : null;
};

export default MyFavourites;

import React from "react";
import { Link } from "react-router-dom";
import CalendarsRoutes from "routes/Calendars";
import Tooltip from "components/Tooltip/Tooltip";
import __ from "constants/static-texts";
import useProductTypes from "hooks/use-productTypes";
import LinkIcon from "components/LinkIcon/LinkIcon";
import LayoutIcons from "components/LayoutIcons/LayoutIcons";
import { ReactComponent as IconPencil } from "icons/Pencil.svg";
import { ReactComponent as ExternalLink } from "icons/External-Link.svg";

function CalendarListItem({ classNs, calendar, editable = false }) {
  const { productTypes } = useProductTypes();
  const productType = productTypes.find((pt) => pt.id === calendar.product);

  const calendarInstances =
    (calendar.companyIdsHavingAnInstanceOriginatingFromThisBlueprint?.length && (
      <Link
        data-tip={__.calendar.showCompanies}
        data-for={`calendar_list_${calendar.id}`}
        to={`${CalendarsRoutes().path}/instances/${calendar.id}`}
        className={`${classNs}_link`}>
        {calendar.companyIdsHavingAnInstanceOriginatingFromThisBlueprint.length} företag
      </Link>
    )) ||
    "0 företag";

  return (
    <li className={`${classNs}_list_item`}>
      <div></div>
      <div>{calendar.title}</div>
      <div>{productType && productType.name}</div>
      <div>{calendarInstances}</div>
      <LayoutIcons>
        {editable && (
          <LinkIcon
            link={{ pathname: `${CalendarsRoutes().path}/edit/${calendar.id}`, state: { disableScroll: true } }}
            icon={<IconPencil aria-hidden />}
            aria-label={__.calendar.editCalendar}
          />
        )}
      </LayoutIcons>
      <LayoutIcons justify="end">
        <LinkIcon
          link={`${CalendarsRoutes().path}/calendar/${calendar.id}`}
          icon={<ExternalLink aria-hidden />}
          aria-label={__.calendar.viewCalendar}
        />
      </LayoutIcons>

      <Tooltip theme={"dark"} id={`calendar_list_${calendar.id}`} />
    </li>
  );
}

export default CalendarListItem;

import React from "react";
import { Link } from "react-router-dom";
import { renderRoutes } from "react-router-config";

import useGuides from "hooks/use-guides";
import useProductTypes from "hooks/use-productTypes";
import GuideList from "./GuideList";
import SubHeader from "components/SubHeader/SubHeader";
import Loading from "components/Loading/Loading";
import { guideType } from "constants/guide-types";
import __ from "constants/static-texts";

const CLASS_NS = "guides";

const Guides = ({ location, match, route, classNs = CLASS_NS }) => {
  const [guides, isLoading] = useGuides();
  const { productTypesIsLoading } = useProductTypes();

  const blueprintGuides = guides.filter((guide) => guide.guideType === guideType.bluePrint);
  // const instanceGuides = guides.filter((guide) => guide.guideType !== 1);

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{__.guide.all_guides}</h1>
        <p className={`${classNs}_description`}>{__.guide.description}</p>
      </SubHeader>
      <div className={`${classNs}`}>
        <Link to={`${route.path}/guide`} className={`${classNs}_add`}>
          {__.guide.createNew}
        </Link>
        {isLoading && <Loading />}
        {!isLoading && <GuideList classNs={classNs} guides={blueprintGuides} />}

        {/* <h1 className={`${classNs}_title`}>Alla instanser av guider</h1>
        {!isLoading && <GuideList classNs={classNs} guides={instanceGuides} productTypes={productTypes} />}
  */}
        {!productTypesIsLoading && !isLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
            })
          : null}
      </div>
    </>
  );
};

export default Guides;

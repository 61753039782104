import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CLASS_NS = 'inputfield';
/**
 * InputText - A Controlled input field with validation possibilities
 * Validation is conducted outside this component using the useValidate hook.
 *
 * @param {object} props All attributes which would normally be placed on an input field
 *
 * @returns {*} Controlled input field
 */
export const InputField = ({ value = '', onChange = () => {}, type = 'text', ...props }) => {
  // Using state to maintain value and cursor position.
  // https://github.com/facebook/react/issues/955#issuecomment-469352730
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const onChangeHandler = (e) => {
    setValueState(e.target.value);
    onChange(e);
  };

  const NodeType = type === 'textarea' ? 'textarea' : 'input';

  return (
    <>
      <NodeType
        className={`${CLASS_NS} ${CLASS_NS}--${type}`}
        {...props}
        type={type}
        value={valueState}
        onChange={onChangeHandler}
        noValidate={true}
        data-haserror={props?.['data-errormessage'] ?? null}
      />
      <p className="input-error-message">{props?.['data-errormessage']}</p>
    </>
  );
};

InputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
};

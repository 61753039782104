import React, { useState, useEffect } from 'react';
import { Overlay } from 'react-draft-editor';
import useCalendars from 'hooks/use-calendars';
import useProductTypes from 'hooks/use-productTypes';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useForm from 'hooks/use-form';
import useLoggedInUser from 'hooks/use-logged-in-user';
import Loading from 'components/Loading/Loading';
import Dropdown from 'components/Dropdown/Dropdown';
import ButtonLink from 'components/ButtonLink/ButtonLink';

import __ from 'constants/static-texts';
import { addCalendar, updateCalendar, deleteCalendar } from 'actions/calendar-actions';
import { ReactComponent as TrashIcon } from 'icons/trash.svg';
import { isProductBas } from 'utils/product';

const CLASS_NS = 'form_overlay';

const EditCalendar = ({ history, cancelUrl }) => {
  const { productTypes } = useProductTypes();
  const { isCompanyReader } = useLoggedInUser();
  const dispatch = useDispatch();
  const params = useParams();
  const [showRemove, setShowRemove] = useState(false);
  const { calendars, calendarsIsLoading } = useCalendars();
  const calendar = calendars?.find((calendar) => {
    return calendar.id === parseInt(params.calendarId);
  });

  useEffect(() => {
    if (isCompanyReader) {
      history.push(cancelUrl, { disableScroll: true });
    }
  });

  const productTypeOptions = productTypes
    .map((productType) => {
      return { ...productType, text: productType.name };
    })
    .filter((pt) => !isProductBas(pt));

  const initialState = {
    title: calendar?.title || '',
    product: calendar?.product || productTypeOptions[0].id,
  };

  const { inputs, handleChange } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...initialState,
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    calendar ? dispatch(updateCalendar({ ...inputs, calendar: calendar.id })) : dispatch(addCalendar(inputs));

    history.push(cancelUrl, { disableScroll: true });
  };

  const handleCancel = () => {
    history.push(cancelUrl, { disableScroll: true });
  };

  const handleDelete = () => {
    dispatch(deleteCalendar(parseInt(params.calendarId)));
    history.push(cancelUrl, { disableScroll: true });
  };

  return isCompanyReader ? null : calendarsIsLoading ? (
    <Loading />
  ) : (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <h2>{showRemove ? __.calendar.removeCalendar : calendar ? __.calendar.editCalendar : __.calendar.addCalendar}</h2>
      <form className={`${CLASS_NS}`} onSubmit={handleSubmit}>
        {showRemove ? (
          <>
            <div className={`${CLASS_NS}_form-row`}>{__.calendar.confirmDeletion}</div>
          </>
        ) : (
          <>
            <label className={`${CLASS_NS}_form-row`} htmlFor="title">
              <span className="label">{__.calendar.editCalendarFormLabelTitle}</span>
              <input
                type="text"
                id="title"
                name="title"
                placeholder={__.calendar.editCalendarFormLabelTitle}
                value={inputs.title}
                onChange={handleChange}
              />
            </label>
            <Dropdown
              label={__.calendar.editCalendarFormLabelProductTypes}
              value={inputs.product}
              options={productTypeOptions}
              onChange={handleChange}
              classNs={`${CLASS_NS}_dropdown`}
              id={`product`}
              labelPos="top"
            />
          </>
        )}
        <div className="button-row">
          <button className="button-inverted" type="button" onClick={handleCancel}>
            {__.calendar.editCalendarFormLabelCancel}
          </button>
          {showRemove ? (
            <button type="button" className="button-warning" onClick={handleDelete}>
              {__.general.confirmDelete}
            </button>
          ) : (
            <button className="button" type="submit">
              {__.calendar.editCalendarFormLabelSubmit}
            </button>
          )}
        </div>
        {!showRemove && calendar && (
          <ButtonLink
            icon={<TrashIcon aria-hidden />}
            variant="action"
            size="small"
            alignment="center"
            onClick={() => setShowRemove(true)}>
            {__.calendar.removeCalendarLink}
          </ButtonLink>
        )}
      </form>
    </Overlay>
  );
};

export default EditCalendar;

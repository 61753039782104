import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconCheck } from "icons/check.svg";

const DEFAULT_DESCRIPTION = "växlande knappar";

const CLASS_NS = "choice-group";
/**
 * A controlled component which uses native HTML radio/checkbox input fields.
 * The buttons ´checked´ value is boolean (instead of native "checked" value)
 * which signals to React to make the component controlled.
 * This component has the option of forwarding a reference to the top node.
 * @returns {*} - React component
 */
const ChoiceGroup = ({
  classNs = CLASS_NS,
  choices = [],
  chosen = "",
  onChange = () => {},
  groupName = "choiceButtons",
  description = DEFAULT_DESCRIPTION,
  type = "radio",
  inversed = false,
}) => {
  // Internal state which triggers a visual state change
  const [chosenState, setChosenState] = useState(""); // eslint-disable-line no-unused-vars

  useEffect(() => {
    setChosenState(chosen);
  }, [chosen]);

  // Discussion about the use of aria-label:
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-label_attribute
  return (
    <div className={`${classNs}`} role={type === "radio" ? "radiogroup" : ""} aria-describedby={description}>
      {choices.map((choice, index) => {
        let active = Array.isArray(chosen) ? chosen.includes(choice.value) : choice.value === chosen;
        return (
          <label
            key={index}
            className={`${classNs}_label`}
            data-active={active}
            data-type={type}
            data-inversed={inversed}
            data-disabled={choice.disabled}>
            <input
              className={`${classNs}_input`}
              type={type}
              name={type === "radio" ? groupName : choice.name}
              value={choice.value}
              checked={active}
              onChange={onChange}
              disabled={choice.disabled}
              readOnly={choice.disabled}
              aria-labelledby={choice.label === "string" ? choice.label : null}
              aria-label={choice.label === "string" ? null : choice.value}
            />
            <span className={`${classNs}_text`} disabled={choice.disabled}>
              {choice.label}
            </span>
          </label>
        );
      })}
    </div>
  );
};

ChoiceGroup.propTypes = {
  classNs: PropTypes.string,
  choices: PropTypes.array,
  chosen: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  groupName: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(["radio", "checkbox"]),
  inversed: PropTypes.bool,
};

export default ChoiceGroup;

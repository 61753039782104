// TODO: Rename this to transform.js instead
import { isNumber } from "utils/js-helpers";
import { guideTypeHierarchy, guideSectionTypes } from "constants/guide-types";
import { getRandomInteger } from "utils/random";

export const ID_SEPERATOR = "_";

/**
 * Converts the guide string id to a number by extracting the number.
 * The id is expected to be a string that contains a number. The number is
 * extracted and converted to a number.
 *
 * @param {String} id - The id to convert.
 * @returns {Number} The converted id. If the id could not be converted,
 *   null is returned.
 */
export const guideTypeIdConverter = (id) => {
  try {
    return id.match(/-?\d+(\.\d+)?/g)[0] - 0;
  } catch (error) {
    console.error(`Could not convert id: ${id}`);
    return null;
  }
};

const idConversion = function id(block) {
  const { id } = block;
  return isNumber(id) ? guideIdConverter(block) : guideTypeIdConverter(id);
};

const parentIdConversion = function parentId(block) {
  const { parentId } = block;
  return isNumber(parentId) ? guideParentIdConverter(block) : guideTypeIdConverter(parentId);
};

export const guideTempIdConverter = (block) => {
  return getGuideBlockIdFromType(block.type, 0 - getRandomInteger(1, 10000));
};
const tempIdConversion = function tempId(block) {
  // Server required a negative tempId.
  return block?.tempId ? guideTypeIdConverter(block.tempId) : guideTempIdConverter(block);
};

export const getGuideSchema = () => {
  const schema = new Map();
  schema.set("id", idConversion);
  schema.set("parentId", parentIdConversion);
  schema.set("tempId", tempIdConversion);
  return schema;
};

export const getGuideBlockIdFromType = (type, id) => {
  if (isNumber(id - 0) === false) {
    return id;
  }
  const signifier = type.slice(type.indexOf(ID_SEPERATOR) + 1);
  return `${signifier}${ID_SEPERATOR}${id}`;
};

export const guideIdConverter = (block) => getGuideBlockIdFromType(block.type, block.id);

export const guideParentIdConverter = (block, typeHierarchy = guideTypeHierarchy) => {
  if (block.type === guideSectionTypes.GUIDE_PART) return block.parentId; // first consider top type as loop below only considers children
  for (const item of typeHierarchy.children) {
    if (item.type === block.type) {
      return getGuideBlockIdFromType(typeHierarchy.type, block.parentId);
    } else if (item?.children?.length) {
      return guideParentIdConverter(block, item);
    }
  }
};

export const guideTypeIdToIdConverter = (blocks) => {
  return blocks.map((block) => ({
    ...block,
    id: guideTypeIdConverter(block.id),
    parentId: guideTypeIdConverter(block.parentId),
  }));
};

export const idToTypeId = (blocks, typeHierarchy, idFn, parentIdFn) => {
  return blocks.map((block) => ({
    ...block,
    id: idFn(block),
    parentId: parentIdFn(block, typeHierarchy),
  }));
};

export const typeIdToId = (blocks, typeHierarchy, idFn, parentIdFn) => {
  return blocks.map((block) => ({
    ...block,
    id: idFn(block),
    parentId: parentIdFn(block, typeHierarchy),
  }));
};

/**
 * normalizeToSchema - Normalizes array data according to a given schema, but omits any unrelated values
 *
 * @param {array}  [data=[]]   Array of objects
 * @param {object} [schema={}] Key-value pair object
 *
 * @returns {array} The normalised
 */
export const normalizeToSchema = (data = [], schema = {}) => {
  return data
    ? data.map((item) => {
        return Object.entries(schema).reduce((acc, [k, v]) => {
          let equivalent = item?.[v] ?? false;
          if (equivalent) {
            acc[k] = equivalent;
          }
          return acc;
        }, {});
      })
    : [];
};

// TODO: DEPRECATTED. Remove.
/**
 * transformBlockIdsFromHierarchy - Transforms the ids and parentIds of an array of blocks in an object litteral.
 * Uses a decimal to the the power of the block depth for signifying the depth.
 *
 * @param {array}   blocks             Blocks containing ids and parent ids to be transformed
 * @param {array}  [typeHierarchy=[]] The hierarchy of section types, top first
 * @param {number} [base=100]         Base from which to start the ids from
 *
 * @returns {object} Returns an object litteral with ids as keys
 */
export const transformBlockIdsFromHierarchy = (blocks, typeHierarchy = [], base = 100) => {
  if (typeof base !== "number") {
    console.error(`Base has to be a number. You set ${base} as the base parameter.`);
  }
  if (base % 10 !== 0) {
    console.error(`Base has to be a multiple of 10. You set ${base} as the base parameter.`);
  }
  return blocks.reduce((acc, block) => {
    const depth = typeHierarchy.indexOf(block.type) + 1 || typeHierarchy.length; // Assuming an unknown type is a leaf node of the furthest node
    const typeBase = (base / 10) * Math.pow(10, depth);
    const decimal = typeBase / (typeBase * typeBase);
    // const id = block.id + decimal;
    acc = acc.concat([
      {
        ...block,
        id: block.id + decimal,
        parentId: block.parentId + decimal * 10,
      },
    ]);
    return acc;
  }, []);
};

import { useState } from "react";

/**
 * makes it possible to writ to the form inputs
 */

export default function useForm(initial = {}) {
  const [inputs, setInputs] = useState(initial);

  function handleChange(e) {
    let { value, name, type } = e.target ?? e;
    if (type === "checkbox") {
      value = !inputs[name];
    }
    if (type === "file") {
      [value] = e.target.files;
    }
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(Object.entries(inputs).map(([key, value]) => [key, ""]));
    setInputs(blankState);
  }

  function reInit(newValues) {
    setInputs({ ...newValues });
  }

  return {
    inputs,
    handleChange,
    resetForm,
    clearForm,
    reInit,
  };
}

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { updateSectionDetails, setSectionIsValid } from '../../actions/section-actions';

import useValidate from '../../hooks/use-validate';

const CLASS_NS = 'module-heading';

// TODO: add CSS styling to radio-group
// TODO: Change classes to module-heading specific
// TODO: Change Header label to something coming from a config file or passed prop.
const ModuleHeading = ({ classNs = CLASS_NS, module = {}, headerLabel = 'Rubrik' }) => {
  const dispatch = useDispatch();
  const { errorMessages } = useSelector((state) => state.sectionState);
  const [validate, getErrorForField] = useValidate(
    errorMessages,
    setSectionIsValid.bind(null, module.id)
  );

  const onChange = (e) => {
    const { value, name } = e.target;

    if (getErrorForField(e.target.name)) {
      validate(e);
    }

    dispatch(
      updateSectionDetails(module.id, {
        [name]: value
      })
    );
  };

  return (
    <div className="module-form-row form-lineup">
      <label className="form-lineup_form-group_label" htmlFor="title">
        {headerLabel}
      </label>
      <input
        className="inputfield inputfield--text  inputfield--moduleHeading_name"
        name="title"
        value={module.title}
        onChange={onChange}
        onBlur={validate}
        required
        noValidate={true}
        data-haserror={getErrorForField('title')}
      />
      <p className="input-error-message">{getErrorForField('title')}</p>
    </div>
  );
};

ModuleHeading.propTypes = {
  classNs: PropTypes.string,
  module: PropTypes.object // Todo replace with moduleDataType
};

export default ModuleHeading;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLibraryDocument } from "actions/librarydocuments-actions";

import { WAITING, LOADING } from "constants/network-states";

/**
 * Provides a document by id
 */
const useLibraryDocument = (id) => {
  const isValidId = Number.isInteger(parseInt(id));
  const dispatch = useDispatch();
  const { libraryDocument, libraryDocumentNetworkState } = useSelector((state) => state.librarydocumentsState);

  const fetchLibraryDocument = () => {
    if (
      (isValidId && libraryDocument?.id !== id) ||
      (isValidId && !libraryDocument === 0 && libraryDocumentNetworkState === WAITING)
    )
      dispatch(getLibraryDocument(id));
  };

  const libraryDocumentIsLoading = libraryDocumentNetworkState === WAITING || libraryDocumentNetworkState === LOADING;

  useEffect(() => {
    fetchLibraryDocument();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [libraryDocument, libraryDocumentIsLoading];
};

export default useLibraryDocument;

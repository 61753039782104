import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useCompanies from "hooks/use-companies";
import useLoggedInUser from "hooks/use-logged-in-user";

import { getCompanyFromUser } from "utils/user-helper";

import { setActiveCompany } from "actions/companies-actions";

/**
 * Set a company as active on load if possible
 */
const useInitCompany = () => {
  const dispatch = useDispatch();

  const { activeCompany, companiesMap } = useCompanies();

  const { user, isCompanyUser } = useLoggedInUser();

  useEffect(() => {
    if (!activeCompany && user) {
      const company = getCompanyFromUser(isCompanyUser, companiesMap, user, activeCompany);
      if (company) {
        dispatch(setActiveCompany(company));
      }
    }
  }, [companiesMap, user, activeCompany, isCompanyUser, dispatch]);

  return !!activeCompany;
};

export default useInitCompany;

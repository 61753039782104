import useAllUsers from "hooks/use-all-users";
import useCompanies from "hooks/use-companies";
import { USER_TYPES } from "constants/user-types";
import { useSelector } from "react-redux";
import { searchArray } from "utils/search-helper";
/**
 * Provides the supervisors list
 */
const useSupervisors = () => {
  const { users, usersIsLoading, userRoles } = useAllUsers();
  const { activeCompany } = useCompanies();

  const { searchQuerySupervisors } = useSelector((state) => state.usersState);
  const supervisorRole = userRoles.find((role) => role.name === USER_TYPES.SUPERVISOR.name);
  const supervisors = usersIsLoading ? [] : users.filter((user) => user.role === supervisorRole.id);
  const activeSupervisor = supervisors.find((supervisor) => supervisor.id === activeCompany?.supervisorUserId);

  if (searchQuerySupervisors === "") {
    return {
      supervisors,
      supervisorsAreLoading: usersIsLoading,
      supervisorRoles: userRoles,
      filteredSupervisors: supervisors,
      activeSupervisor,
    };
  }

  const filteredSupervisors = searchArray(supervisors, ["firstName", "lastName", "userName"], searchQuerySupervisors);

  return {
    supervisors,
    supervisorsAreLoading: usersIsLoading,
    supervisorRoles: userRoles,
    filteredSupervisors,
    activeSupervisor,
  };
};

export default useSupervisors;

import React from "react";
import { Link } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { notificationTypes } from "constants/notification-types";
import ListBox from "components/ListBox/ListBox";
import __ from "constants/static-texts";
import TypographyHeadingMeta from "components/TypographyHeadingMeta/TypographyHeadingMeta";

import useTodos from "hooks/use-todos";

import { formatDateInput, getDateDiff } from "utils/date-helpers";

import { ReactComponent as SquareDocument } from "icons/SquareDocument.svg";
import { ReactComponent as NextChapterIcon } from "icons/Next-chapter.svg";

/**
 * Component will fetch all todos (assigned).
 * Cut the list to the 2 first
 * Render an array with a list element for each todo.
 *
 * @returns {React.FC} A React function component
 */
const CLASS_NS = "my-todos";

const MyTodos = ({ classNs = CLASS_NS }) => {
  const { assigned, assignedIsLoading } = useTodos();

  return !assignedIsLoading ? (
    <div className={classNs}>
      <ListBox title={__.myToDos.title} NodeType="ol">
        <>
          {assigned.map((todo) => (
            <TodoItem todo={todo} key={todo.id} />
          ))}
        </>
      </ListBox>
    </div>
  ) : (
    <Loading />
  );
};

const TodoItem = ({ todo, classNs = CLASS_NS }) => {
  const isSectionType =
    todo.type === notificationTypes.SECTION_ASSIGNED || todo.type === notificationTypes.SECTION_ASSIGNED_DATE_CHANGED;
  const detailsKey = isSectionType ? "section" : "requirement";
  const deadline = todo.details[detailsKey].assignmentDeadline;
  const timeVariant = (dateTime) => {
    if (!dateTime) return "";
    const now = new Date().toISOString();
    const deadline = new Date(dateTime).toISOString();
    const diff = Math.floor(getDateDiff(deadline, now).days);
    return diff <= 0 ? "passed" : diff <= 4 ? "soon" : "far";
  };

  return (
    <li className={`${classNs}_item`}>
      <div className={`${classNs}_container`}>
        <div className={`${classNs}_text`}>
          <TypographyHeadingMeta
            headingLevel={3}
            heading={
              isSectionType
                ? __.myToDos.sectionAssigned(todo.details[detailsKey].title)
                : __.myToDos.requirementAssigned(
                    todo.details[detailsKey].title,
                    todo.details[detailsKey].parentSection.title
                  )
            }
            meta={
              <span className={`${classNs}_meta`}>
                {__.myToDos.assignedBy(`${todo.details.byUser.fullName}${deadline ? " - " : ""}`)}
                {deadline && (
                  <span className={`${classNs}_deadline`} data-variant={timeVariant(deadline)}>
                    {__.myToDos.deadlineBy}
                    <time className={`${classNs}_time`}>{formatDateInput(deadline)}</time>
                  </span>
                )}
              </span>
            }
          />
        </div>
        <Link to={todo.path} className={`${classNs}_button`}>
          {isSectionType ? (
            <>
              {__.myToDos.sectionLink} <NextChapterIcon />
            </>
          ) : (
            <>
              {__.myToDos.requirementLink} <SquareDocument />
            </>
          )}
        </Link>
      </div>
    </li>
  );
};

export default MyTodos;

import React from "react";

function CoreImage(props) {
  const { block } = props;
  const cls =
    "Fact-block--CoreImage" +
    (block.attributes.align
      ? ` Fact-block--CoreImage--align-${block.attributes.align}`
      : "");
  return (
    <div
      className={cls}
      dangerouslySetInnerHTML={{ __html: block.originalContent }}
    />
  );
}

export default CoreImage;

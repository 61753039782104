import __ from "constants/static-texts";

export const PRODUCT_TYPES = {
  ORDNING: {
    name: __.productTypes.ordning,
    id: "Ordning",
    workbook_slugs: [],
    idNumber: 1,
  },
  CERTIFIERING: {
    name: __.productTypes.certifiering,
    id: "Certifiering",
    workbook_slugs: ["arbetsboken"],
    idNumber: 2,
  },
  ISO: {
    name: __.productTypes.iso,
    id: "ISO",
    workbook_slugs: ["arbetsboken-iso"],
    idNumber: 3,
  },
};

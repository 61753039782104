import * as types from '../actions/action-types'

const initialState = {
  userWillSignout: false,
}

const authReducer = function(state = initialState, action) {
  switch (action.type) {
    case types.USER_WILL_SIGNOUT:
      return { ...state, userWillSignout: true }
    default:
      return state
  }
}

export default authReducer

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { updateSectionDetails, setSectionIsValid } from '../../actions/section-actions';

import useValidate from '../../hooks/use-validate';

const CLASS_NS = 'module-external-link';

// TODO: Change classes to module-external-link specific
const ModuleExternalLink = ({
  classNs = CLASS_NS,
  module = {},
  labelName = '',
  labelLink = ''
}) => {
  const dispatch = useDispatch();
  const { errorMessages } = useSelector((state) => state.sectionState);
  const [validate, getErrorForField] = useValidate(
    errorMessages,
    setSectionIsValid.bind(null, module.id)
  );

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    if (getErrorForField(e.target.name)) {
      validate(e);
    }
    dispatch(
      updateSectionDetails(module.id, {
        [name]: value
      })
    );
  };

  return (
    <div className="module-form-row form-lineup">
      <div className="form-lineup_form-group">
        <label className="form-lineup_form-group_label" htmlFor="name">
          {labelName}
        </label>
        <input
          className="inputfield inputfield--text"
          name="name"
          value={module.name}
          onChange={onChangeHandler}
          onBlur={validate}
          required
          noValidate={true}
          data-haserror={getErrorForField('name')}
        />
        <p className="input-error-message">{getErrorForField('name')}</p>
      </div>
      <div className="form-lineup_form-group">
        <label className="form-lineup_form-group_label" htmlFor="url">
          {labelLink}
        </label>
        <input
          className="inputfield inputfield--text"
          name="url"
          value={module.url}
          onChange={onChangeHandler}
          onBlur={validate}
          required
          noValidate={true}
          data-haserror={getErrorForField('url')}
        />
        <p className="input-error-message">{getErrorForField('url')}</p>
      </div>
    </div>
  );
};

ModuleExternalLink.propTypes = {
  classNs: PropTypes.string,
  module: PropTypes.object // Todo replace with moduleDataType
};

export default ModuleExternalLink;

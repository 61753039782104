import React, { useMemo } from "react";
import PropTypes from "prop-types";

import useLibraryDocuments from "hooks/use-library-documents";

import { normalizeToSchema } from "utils/normalize";
import { sortArray } from "utils/js-helpers";

import Dropdown from "components/Dropdown/Dropdown";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";
import { useDispatch } from "react-redux";
import { updateSectionDetails } from "react-draft-editor";

const CLASS_NS = "GuideResponseSlotEditor";

const GuideResponseSlotEditor = ({ classNs = CLASS_NS, block: slotBlock, onChange }) => {
  const dispatch = useDispatch();

  const handleDocChange = (e) => {
    dispatch(
      updateSectionDetails(slotBlock.id, {
        libraryDocumentId: e.target.value,
      })
    );
  };

  const handleUploadSlotChange = (e) => {
    dispatch(
      updateSectionDetails(slotBlock.id, {
        hasUploadSlot: !slotBlock.hasUploadSlot,
      })
    );
  };

  const [librarydocuments] = useLibraryDocuments();
  const docSelectorSchema = {
    id: "id",
    text: "title",
  };

  const emptyDocs = [{
    id: 0,
    text: 'Inget dokument valt'
  }];
  const docs = emptyDocs.concat(useMemo(
    () => normalizeToSchema(librarydocuments, docSelectorSchema).sort(sortArray("text", true, true)),
    [librarydocuments] // eslint-disable-line react-hooks/exhaustive-deps
  ));

  return (
    /* As preventDefault is called on details element in block.js, we need to stop the event bubbeling here in order for checkboxes and radios to work as expected */
    <form className={`${classNs}_section-form`} onClick={(e) => e.stopPropagation()}>
      <Dropdown
        id={`libraryDocumentId`}
        value={`${slotBlock.libraryDocumentId}`}
        options={docs}
        label="Välj relaterade dokument"
        labelPos="top"
        onChange={handleDocChange}
        classNs={`${classNs}_slot_dropdown`}
      />
      <ChoiceGroup
        onChange={handleUploadSlotChange}
        classNs={`${classNs}_slot_checkbox`}
        chosen={slotBlock.hasUploadSlot ? "1" : "0"}
        type="checkbox"
        inversed={false}
        description="Ska företag kunna ladda upp dokument som svar till detta dokument?"
        choices={[
          {
            name: `${slotBlock.id}-hasUploadSlot`,
            value: "1",
            label: "Uppladdningsfält",
          },
        ]}
      />
      {slotBlock.numberOfSlotInstancesWithUploadedDocuments > 0 && (
        <div className={`${classNs}_footer`}>
          <div className={`${classNs}_footer_text`}>
            Det finns {slotBlock.numberOfSlotInstancesWithUploadedDocuments} uppladdade företagsdokument för detta
            kravdokument
          </div>
        </div>
      )}
    </form>
  );
};

GuideResponseSlotEditor.blockType = "guide-responseSlot";

GuideResponseSlotEditor.propTypes = {
  classNs: PropTypes.string,
  block: PropTypes.object,
  titleLabel: PropTypes.string,
  getValidationErrorForField: PropTypes.func,
  validate: PropTypes.func,
  onChange: PropTypes.func,
};

export default GuideResponseSlotEditor;

import React from "react";
import { useDispatch } from "react-redux";
import { deleteUser } from "actions/users-actions";
import LayoutColumns from "components/LayoutColumns/LayoutColumns";
import __ from "constants/static-texts";

import { useParams, useHistory, useLocation } from "react-router-dom";

import { Overlay } from "react-draft-editor";

const CLASS_NS = "form_overlay";
const DeleteSupervisor = ({ cancelUrl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const { supervisorId } = useParams();

  const clickDelete = (e) => {
    e.preventDefault();
    dispatch(deleteUser({ id: parseInt(supervisorId) }));

    history.push(state.cancelUrl || cancelUrl, { disableScroll: true });
  };

  const clickCancel = (e) => {
    e.preventDefault();

    history.push(state.cancelUrl || cancelUrl, { disableScroll: true });
  };
  const fullName = `${state.currentSupervisor.firstName} ${state.currentSupervisor.lastName}`;

  return (
    <Overlay cancelUrl={state.cancelUrl || cancelUrl} disableScroll={true}>
      <div className={CLASS_NS}>
        <h2>{__.supervisors.deleteSupervisor}</h2>
        <div className={`${CLASS_NS}_form-row`}>{__.supervisors.deleteConfirmText(fullName)}</div>
        <LayoutColumns>
          <button className={`button-inverted`} onClick={clickCancel}>
            {__.supervisors.deleteCancel}
          </button>
          <button className={`button-warning`} onClick={clickDelete}>
            {__.supervisors.deleteConfirm}
          </button>
        </LayoutColumns>
      </div>
    </Overlay>
  );
};

export default DeleteSupervisor;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setWorkbook } from "actions/workbook-actions";

import { useQuery as useApolloQuery } from "@apollo/client";
import { GET_WORKBOOK } from "../apollo/queries";

/**
 * Provides the workbook from the wp-api
 */
const useWorkbook = (WORKBOOK_SLUG) => {
  const dispatch = useDispatch();
  const { workbook /* , workbookNetworkState */ } = useSelector((state) => state.workbookState);

  const apolloQueryObj = useApolloQuery(GET_WORKBOOK, {
    variables: { courseSlug: WORKBOOK_SLUG },
    skip: !WORKBOOK_SLUG,
  });

  const data = apolloQueryObj.data;
  const loading = apolloQueryObj.loading;

  useEffect(() => {
    if (data) {
      dispatch(setWorkbook(data.course));
    }
  }, [data, dispatch]);

  return [workbook, loading];
};

export default useWorkbook;

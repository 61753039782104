export const USER_TYPES = {
  SERVICEADMIN: {
    name: "ServiceAdmin",
    id: "ServiceAdmin",
    privilege: 1,
  },
  COMPANYADMIN: {
    name: "CompanyAdmin",
    id: "CompanyAdmin",
    privilege: 2,
  },
  SUPERVISOR: {
    name: "Supervisor",
    id: "Supervisor",
    privilege: 3,
  },
  COMPANYEDITOR: {
    name: "CompanyEditor",
    id: "CompanyEditor",
    privilege: 4,
  },
  COMPANYREADER: {
    name: "CompanyReader",
    id: "CompanyReader",
    privilege: 5,
  },
  BLOCKED: {
    name: "Blocked",
    id: "Blocked",
    // As this is not an actual role, privilage is not applicable.
    // Using this role is handled separately in EditUser.js
  },
};

import * as types from './action-types';
import { getSectionsTransformed } from '../utils/section';

// TODO: Deal with this later
// import { saveImageData } from '../../actions/image-actions';
// import { saveFileData } from '../../actions/file-actions';

import { createActionSequence } from './action-helpers';

export const setSection = (section, schema) => (dispatch /*, getState*/) => {
  // dispatch(resetSectionsState());
  dispatch({
    type: types.SET_SECTION,
    payload: {
      section: section,
      schema: schema
    }
  });
};

export const addNewSectionBlock = (data) => ({
  type: types.ADD_NEW_SECTION_BLOCK,
  payload: data
});

// TODO: Rename to updateModuleRichContent
export const updateSectionModule = (id, content) => ({
  type: types.UPDATE_SECTION_MODULE,
  payload: {
    id: id,
    content: content
  }
});

export const deleteSectionModule = (id) => ({
  type: types.DELETE_SECTION_MODULE,
  payload: id
});

export const repositionSectionModule = (id, index) => ({
  type: types.REPOSITION_SECTION_MODULE,
  payload: {
    id: id,
    index: index
  }
});

// TODO: Rename as this can be applied to modules as well.
export const updateSectionDetails = (id, data, append) => ({
  type: types.UPDATE_SECTION_DETAILS,
  payload: {
    id: id,
    data: data,
    append: append
  }
});

export const setSectionIsValid = (id, data) => ({
  type: types.SET_SECTION_IS_VALID,
  payload: {
    id: id,
    data: data
  }
});

export const saveSectionImage = (moduleId, image) => ({
  type: types.SAVE_SECTION_IMAGE,
  payload: {
    id: moduleId,
    upload: image
  }
});

export const saveImage = (/*moduleId*/) => async (/*dispatch, getState*/) => {
  // TODO: Deal with this later
  // const store = getState();
  // const { fileData, tempFile } = store.sectionState.blocks[moduleId];
  //
  // const storedImageFile = await dispatch(
  //   saveImageData({
  //     data: fileData,
  //     file: tempFile
  //   })
  // );
  // dispatch(saveSectionImage(moduleId, storedImageFile));
};

export const saveSectionFile = (moduleId, file) => ({
  type: types.SAVE_SECTION_FILE,
  payload: {
    id: moduleId,
    upload: file
  }
});

export const saveFile = (/*moduleId*/) => async (/*dispatch, getState*/) => {
  // TODO: Deal with this later
  // const store = getState();
  // const { fileData, tempFile } = store.sectionState.blocks[moduleId];
  //
  // const storedImageFile = await dispatch(
  //   saveFileData({
  //     data: fileData,
  //     file: tempFile
  //   })
  // );
  // dispatch(saveSectionFile(moduleId, storedImageFile));
};

export const removeSectionImage = (id) => ({
  type: types.REMOVE_SECTION_IMAGE,
  payload: id
});

const resetSaveSectionNetworkState = () => ({
  type: types.RESET_SAVE_SECTION_NETWORK_STATE
});

/**
 * saveSection - Saves the section to the designation.
 * This function is called by the implemeting component as both the schema and api function are context specific.
 *
 * @param {object} transformationSchema Key-value pairs of field names matching server-draft equivalents.
 * @param {function} apiFn The api function which to be called
 *
 * @returns {object} Server response
 */
export const saveSection =
  (transformationSchema, apiFn, extraParams) => async (dispatch, getState) => {
    const store = getState();
    // Send updated section to get transformed back to format for the server.
    const blocks = JSON.parse(JSON.stringify(store.sectionState.blocks)); // Make deep copy, otherwise the transformed blocks will mutate the store.
    const transformedSection = getSectionsTransformed(blocks, transformationSchema);
    const saveSectionPayload = await dispatch(
      createActionSequence(
        'SAVE_SECTION',
        apiFn
      )({
        transformedSection,
        schema: transformationSchema,
        ...extraParams
      })
    );
    dispatch(resetSaveSectionNetworkState());
    return saveSectionPayload;
  };

export const saveBlocks =
  (blockIds, transformationSchema, apiFn, extraParams) => async (dispatch, getState) => {
    const store = getState();
    const blocks = store.sectionState.blocks;
    let currentBlocks = Object.entries(blocks).reduce((acc, [k, v]) => {
      if (blockIds.includes(k)) {
        acc[k] = v;
      }
      return acc;
    }, {});
    currentBlocks = JSON.parse(JSON.stringify(currentBlocks)); // Make deep copy, otherwise the transformed blocks will mutate the store.

    const transformedBlock = getSectionsTransformed(currentBlocks, transformationSchema);
    const serverBlockData = await dispatch(
      createActionSequence(
        'SAVE_BLOCKS',
        apiFn
      )({
        transformedBlock,
        schema: transformationSchema,
        ...extraParams
      })
    );
    dispatch(resetSaveSectionNetworkState());
    return serverBlockData;
  };

export const resetSectionsState = () => ({
  type: types.RESET_SECTIONS_STATE
});

// This is the responsibility of the implementing component
// export const deleteCurrentSection = id =>
//   createActionSequence(
//     'DELETE_SECTION',
//     sectionsApi.deleteSection
//   )({
//     id: id
//   });

export const resetDeleteSectionNetworkState = {
  type: types.RESET_DELETE_SECTION_NETWORK_STATE
};

export const setErrorMessages = (data) => ({
  type: types.SET_ERROR_MESSAGES,
  payload: data
});

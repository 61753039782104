import { SUCCESS } from "constants/network-states";
import { PRODUCT_TYPES } from "constants/product-types";

const initialState = {
  productTypes: Object.values(PRODUCT_TYPES).map((productType) => {
    return {
      ...productType,
    };
  }),
  productTypesNetworkState: SUCCESS,
  error: null,
};

const productTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default productTypeReducer;

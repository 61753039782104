import React from "react";

import RichListItem from "../RichListItem/RichListItem";

import stripHtml from "utils/stripHtml";

function BucLmsBuilderRichListItem(props) {
  const { block } = props;
  const { body, image, imageAlt, imageSize } = block.attributes;
  if (!stripHtml(body)) return null;
  return (
    <li className="Fact-block--BucLmsBuilderRichListItem">
      <RichListItem body={body} image={image} imageAlt={imageAlt} imageSize={imageSize} />
    </li>
  );
}

export default BucLmsBuilderRichListItem;

import React from "react";

const CLASS_NS = "dropdown";

const Dropdown = ({ classNs = CLASS_NS, label, labelPos = "left", options, value, onChange, id, disabled }) => {
  return (
    <label className={`${classNs}_label`} data-labelpos={labelPos}>
      {label}
      <div className={`${classNs}`}>
        <select value={value} onChange={onChange} id={id} name={id} disabled={disabled}>
          {options.map((option) => {
            return (
              <option key={option.id} value={option.id}>
                {`${option.text}`}
              </option>
            );
          })}
        </select>
      </div>
    </label>
  );
};

export default Dropdown;

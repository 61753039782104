import React from "react";
import { useDispatch } from "react-redux";

import useLoggedInUser from "hooks/use-logged-in-user";

import { favouriteDocument } from "actions/librarydocuments-actions";
import { favouriteCompanyDocument } from "actions/companydocuments-actions";

import LibraryDocListItem from "./LibraryDocListItem";

function LibraryDocList({ documents, isCompanyDocuments, classNs, users }) {
  const dispatch = useDispatch();

  const loggedInUser = useLoggedInUser();

  const toggleFavourite = (doc) => {
    const { id, isFavourite } = doc;
    if (isCompanyDocuments) {
      dispatch(favouriteCompanyDocument({ id, isFavourite: !isFavourite }));
    } else {
      dispatch(favouriteDocument({ id, isFavourite: !isFavourite }));
    }
  };

  const items = (
    <>
      {documents.map((doc, index) => (
          <LibraryDocListItem
            key={doc.id}
            doc={doc}
            classNs={classNs}
            users={users}
            isCompanyDocument={isCompanyDocuments}
            loggedInUser={loggedInUser}
            toggleFavourite={toggleFavourite}
          />
      ))}
    </>
  );
  return <ul className={`${classNs}_list`}>{items}</ul>;
}

export default LibraryDocList;

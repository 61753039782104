const iconsConfigDefault = {
  block: {
    delete:
      '<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M2.25 6v11.25c0 .414.335.75.75.75h12a.75.75 0 0 0 .75-.75V6H2.25zM.75 4.5h16.5a.75.75 0 1 0 0-1.5h-4.5V.75A.75.75 0 0 0 12 0H6a.75.75 0 0 0-.75.75V3H.75a.75.75 0 1 0 0 1.5zm6-3h4.5V3h-4.5V1.5z"/></svg>',
    move_up:
      '<svg width="17" height="9" xmlns="http://www.w3.org/2000/svg"><path d="M8.586.162c.18 0 .359.068.496.205l7.504 7.504-.991.991-7.009-7.008-7.009 7.008-.991-.99L8.09.366a.699.699 0 0 1 .496-.205z"/></svg>',
    move_down:
      '<svg width="25" height="10" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg"><path d="M17 2.267 10.267 9 3.429 2.267" stroke-width="2" stroke="#979797" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>'
  }
};

export default iconsConfigDefault;

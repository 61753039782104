import * as types from "actions/action-types";

const initialState = {
  statusMessage: {
    text: "",
    status: "",
    isOpen: false,
    keepOpen: true,
  },
};

const layoutReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.STATUS_MESSAGE:
      return {
        ...state,
        statusMessage: {
          ...action.payload,
          isOpen: true,
        },
      };

    case types.CLOSE_STATUS_MESSAGE:
      const clearStatusMessage = {
        statusMessage: {
          ...initialState.statusMessage,
        },
      };
      return { ...state, ...clearStatusMessage };

    default:
      return state;
  }
};

export default layoutReducer;

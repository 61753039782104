import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ModuleUpload from '../module-upload/module-upload';

import { saveFile } from '../../actions/section-actions';
import { getUploads, removeSectionFile } from '../../actions/file-actions';

const ModuleDownload = ({ module = {} }) => {
  const dispatch = useDispatch();
  const { uploads } = useSelector((state) => state.uploadState);
  const previousRef = useRef({ uploads }).current;

  const availableUploads = useMemo(
    () => Object.values(uploads).filter((file) => file.container === 'files'),
    [uploads]
  );

  useEffect(() => {
    if (
      Object.keys(previousRef.uploads).length === Object.keys(uploads).length &&
      availableUploads().length <= 0
    ) {
      dispatch(getUploads());
    }
  }, [uploads, dispatch, availableUploads, previousRef.uploads]);

  const onSubmit = () => {
    dispatch(saveFile(module.id));
  };

  const onRemoveFile = () => dispatch(removeSectionFile(module.id));

  return (
    <ModuleUpload
      module={module}
      containerId={module.files.length ? module.files[0] : null}
      storeFileRefKey={'files'}
      textContext="download"
      availableUploads={availableUploads()}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
      acceptedFileTypes=""
    />
  );
};

ModuleDownload.propTypes = {
  classNs: PropTypes.string,
  module: PropTypes.object // Todo replace with moduleDataType,
};

export default ModuleDownload;

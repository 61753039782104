import React from "react";
import PropTypes from "prop-types";
import "./_List.scss";

function List({ type, variant, children }) {
  const ListType = type;
  return (
    <ListType className="list" data-variant={variant}>
      {children}
    </ListType>
  );
}

List.propTypes = {
  type: PropTypes.oneOf(["ul", "ol"]),
  variant: PropTypes.oneOf(["standard"]),
};

List.defaultProps = {
  type: "ul",
  variant: "standard",
};

export default List;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "actions/users-actions";

import { WAITING } from "constants/network-states";

/**
 * Provides a user by id
 */
const useUser = (id) => {
  const dispatch = useDispatch();
  const { user, userNetworkState } = useSelector((state) => state.usersState);

  const fetchUser = () => {
    if (user?.id !== id || (!user === 0 && userNetworkState === WAITING)) dispatch(getUser({ id: id }));
  };

  const userIsLoading = userNetworkState === WAITING;

  useEffect(() => {
    fetchUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { user, userIsLoading };
};

export default useUser;

export const loadCookieBot = (callback) => {
  const existingScript = document.getElementById("Cookiebot");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/uc.js";
    script.id = "Cookiebot";
    script.type = "text/javascript";
    script.setAttribute("data-cbid", "6cdb3aae-0599-4726-8560-520bccfa9bbc");
    script.setAttribute("data-blockingmode", "auto");

    const head = document.head;
    head.insertBefore(script, head.firstChild);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};
import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

import { sortArray } from "utils/js-helpers";

const initialState = {
  guides: [],
  guidesNetworkState: WAITING,
  addGuideNetworkState: WAITING,
  updateGuideNetworkState: WAITING,
  deleteGuideNetworkState: WAITING,

  error: null,
};

const guidesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_GUIDES_LOADING:
      return {
        ...state,
        guidesNetworkState: LOADING,
      };
    case types.GET_GUIDES_SUCCESS:
      return {
        ...state,
        guides: payload.sort(sortArray("title")),
        guidesNetworkState: SUCCESS,
      };
    case types.GET_GUIDES_ERROR:
      return {
        ...state,
        error: payload,
        guidesNetworkState: ERROR,
      };

    case types.ADD_GUIDE_LOADING:
      return {
        ...state,
        addGuideNetworkState: LOADING,
      };
    case types.ADD_GUIDE_SUCCESS:
      return {
        ...state,
        guides: state.guides.concat(action.payload).sort(sortArray("title")),
        addGuideNetworkState: SUCCESS,
      };
    case types.ADD_GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        addGuideNetworkState: ERROR,
      };

    case types.UPDATE_GUIDE_LOADING:
      return {
        ...state,
        updateGuideNetworkState: LOADING,
      };
    case types.UPDATE_GUIDE_SUCCESS:
      return {
        ...state,
        guides: state.guides.map((guide) => (guide.id === action.payload.id ? action.payload : guide)),
        updateGuideNetworkState: SUCCESS,
      };
    case types.UPDATE_GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        updateGuideNetworkState: ERROR,
      };

    case types.DELETE_GUIDE_LOADING:
      return {
        ...state,
        deleteGuideNetworkState: LOADING,
      };
    case types.DELETE_GUIDE_SUCCESS:
      return {
        ...state,
        guides: state.guides.filter((guide) => guide.id !== action.payload),
        deleteGuideNetworkState: SUCCESS,
      };
    case types.DELETE_GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        deleteGuideNetworkState: ERROR,
      };

    case types.ADD_COMPANY_GUIDE_SUCCESS:
      return {
        ...state,
        guides: state.guides.concat(action.payload),
      };

    default:
      return state;
  }
};

export default guidesReducer;

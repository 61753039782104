import * as types from '../actions/action-types';
import { WAITING, LOADING, ERROR, SUCCESS, NOT_FOUND } from '../constants/network-states';
import __ from '../constants/error-messages';

import {
  getTransformedSectionData,
  // getTransformedBlock,
  deleteModuleFromSection,
  repositionSectionModule,
  addNewSectionBlock
} from '../utils/section';
import { getInitialUploadData } from '../constants/reused-initial-states';
import { TEMP_ID } from '../constants/schema';
import { isObject } from '../utils/js-helpers';
const initialState = {
  blocks: {}, // A key-value pair object
  isSectionSet: false, // Used by hooks
  // Setting specific network and error states to each action so that they can be addressed seperately
  saveSectionNetworkState: WAITING,
  saveSectionError: null,
  publishSectionNetworkState: WAITING,
  publishSectionError: null,
  deleteSectionNetworkState: WAITING,
  deleteSectionError: null,
  errorMessages: __
};

export const sectionReducer = (state = initialState, action) => {
  const { payload, type, params } = action;
  switch (type) {
    case types.SET_SECTION:
      // Populate the blocks object with by id
      if (!payload) {
        console.warn('WARNING: undefined or null section was dispatched to document');
        return state;
      }
      if (!payload.schema) {
        console.warn(
          `No app schema provided for ${action.type}. The data will be mapped according to DraftEditor internal structure.`
        );
      }

      return {
        ...state,
        isSectionSet: true,
        blocks: getTransformedSectionData(payload.section, payload?.schema)
      };

    case types.ADD_NEW_SECTION_BLOCK:
      return {
        ...state,
        blocks: addNewSectionBlock(payload, state.blocks, payload)
      };

    case types.UPDATE_SECTION_MODULE:
      let { id, content } = payload;
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [id]: {
            ...state.blocks[id],
            richContent: content
          }
        }
      };

    case types.DELETE_SECTION_MODULE:
      // TODO: recursively remove all modules which are in a straight relationship with the modules to be deleted.
      return {
        ...state,
        blocks: deleteModuleFromSection(payload, state.blocks)
      };

    case types.REPOSITION_SECTION_MODULE:
      return {
        ...state,
        blocks: repositionSectionModule(payload.id, payload.index, state.blocks)
      };

    case types.UPDATE_SECTION_DETAILS:
      let newData = payload.data;
      const dataKey = Object.keys(payload.data)[0];
      const dataValue = Object.values(payload.data)[0];
      const targetItem = state.blocks[payload.id][dataKey];

      if (Array.isArray(targetItem)) {
        const hasValue = targetItem.includes(dataValue);
        newData = {
          [dataKey]: hasValue
            ? targetItem.filter((val) => val !== dataValue)
            : targetItem.concat([dataValue])
        };
      }

      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.id]: {
            ...state.blocks[payload.id],
            ...newData
          }
        }
      };

    case types.SET_SECTION_IS_VALID:
      // Safeguard that no undefined blocks are created.
      return state.blocks[payload.id]
        ? {
            ...state,
            blocks: {
              ...state.blocks,
              [payload.id]: {
                ...state.blocks[payload.id],
                isValid: payload.data
              }
            }
          }
        : { ...state };

    case types.SAVE_BLOCKS_LOADING:
      return {
        ...state,
        saveSectionNetworkState: LOADING,
        saveSectionError: initialState.saveSectionError
      };
    case types.SAVE_BLOCKS_ERROR:
      return {
        ...state,
        saveSectionNetworkState: ERROR,
        saveSectionError: payload
      };
    case types.SAVE_BLOCKS_NOT_FOUND:
      return {
        ...state,
        saveSectionNetworkState: NOT_FOUND,
        saveSectionError: payload
      };
    case types.SAVE_BLOCKS_SUCCESS:
      if (!payload) {
        console.warn('WARNING: undefined or null section was dispatched to document');
        return state;
      }
      if (!params.schema) {
        console.warn(
          `No app schema provided for ${action.type}. The data will be mapped according to DraftEditor internal structure.`
        );
      }

      return {
        ...state,
        blocks: {
          ...Object.entries(state.blocks).reduce((acc, [k, v]) => {
            // Filter out blocks with temp ids
            if (typeof v?.[TEMP_ID] === 'undefined') {
              acc[k] = v;
            }
            return acc;
          }, {}),
          ...getTransformedSectionData(payload, params?.schema)
        },
        saveSectionNetworkState: SUCCESS,
        saveSectionError: initialState.saveSectionError
      };

    case types.SAVE_SECTION_LOADING:
      return {
        ...state,
        saveSectionNetworkState: LOADING,
        saveSectionError: initialState.saveSectionError
      };
    case types.SAVE_SECTION_ERROR:
      return {
        ...state,
        saveSectionNetworkState: ERROR,
        saveSectionError: payload
      };
    case types.SAVE_SECTION_NOT_FOUND:
      return {
        ...state,
        saveSectionNetworkState: NOT_FOUND,
        saveSectionError: payload
      };
    case types.SAVE_SECTION_SUCCESS:
      if (!payload) {
        console.warn('WARNING: undefined or null section was dispatched to document');
        return state;
      }
      if (!Array.isArray(payload) && !isObject(payload)) {
        console.log("payload isn't array or object");
        // server isn't returning the new or updated section.
        return state;
      }
      if (!params.schema) {
        console.warn(
          `No app schema provided for ${action.type}. The data will be mapped according to DraftEditor internal structure.`
        );
      }
      // This doesn't work now as the array which is returned from the server doesn' have matching ids.
      const updatedBlocksIds = params.transformedSection.map((block) => block.id);
      const updatedBlocksParentIds = params.transformedSection.map((block) => block.parentId);
      const updatedSections = payload.filter(
        (block) =>
          updatedBlocksIds.includes(block.id) && updatedBlocksParentIds.includes(block.parentId)
      );
      return {
        ...state,
        blocks: {
          ...getTransformedSectionData(updatedSections, params?.schema)
        },
        saveSectionNetworkState: SUCCESS,
        saveSectionError: initialState.saveSectionError
      };

    case types.RESET_SAVE_SECTION_NETWORK_STATE:
      return {
        ...state,
        saveSectionNetworkState: WAITING
      };

    case types.DELETE_SECTION_LOADING:
      return {
        ...state,
        deleteSectionNetworkState: LOADING,
        deleteSectionError: initialState.deleteSectionError
      };
    case types.DELETE_SECTION_ERROR:
      return {
        ...state,
        deleteSectionNetworkState: ERROR,
        deleteSectionError: payload
      };
    case types.DELETE_SECTION_NOT_FOUND:
      return {
        ...state,
        deleteSectionNetworkState: NOT_FOUND,
        deleteSectionError: payload
      };
    case types.DELETE_SECTION_SUCCESS:
      return {
        ...state,
        blocks: initialState.blocks,
        deleteSectionNetworkState: SUCCESS,
        deleteSectionError: initialState.saveSectionError
      };

    case types.RESET_DELETE_SECTION_NETWORK_STATE:
      return {
        ...state,
        deleteSectionNetworkState: initialState.deleteSectionNetworkState
      };

    case types.PUBLISH_SECTION_LOADING:
      return {
        ...state,
        publishSectionNetworkState: LOADING,
        publishSectionError: initialState.publishSectionError
      };
    case types.PUBLISH_SECTION_ERROR:
      return {
        ...state,
        publishSectionNetworkState: ERROR,
        publishSectionError: payload
      };
    case types.PUBLISH_SECTION_NOT_FOUND:
      return {
        ...state,
        publishSectionNetworkState: NOT_FOUND,
        publishSectionError: payload
      };
    case types.PUBLISH_SECTION_SUCCESS:
      return {
        ...state,
        publishSectionNetworkState: SUCCESS,
        publishSectionError: initialState.publishSectionError
      };
    // TODO: SAVE_SECTION_IMAGE and SAVE_SECTION_FILE should be the same.
    case types.SAVE_SECTION_IMAGE:
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.id]: {
            ...state.blocks[payload.id],
            ...payload.data,
            images: [payload.upload.id],
            tempFile: payload.upload
          }
        }
      };
    case types.SAVE_SECTION_FILE:
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.id]: {
            ...state.blocks[payload.id],
            ...payload.data,
            files: [payload.upload.id],
            tempFile: payload.upload
          }
        }
      };
    case types.REMOVE_SECTION_IMAGE:
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.id]: {
            ...state.blocks[payload.id],
            description: '',
            images: [],
            fileData: getInitialUploadData(),
            tempFile: null
          }
        }
      };
    case types.REMOVE_SECTION_FILE:
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.id]: {
            ...state.blocks[payload.id],
            description: '',
            files: [],
            fileData: getInitialUploadData(),
            tempFile: null
          }
        }
      };
    case types.RESET_SECTIONS_STATE:
      return {
        ...initialState
      };
    case types.SET_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: payload
      };

    default:
      return state;
  }
};

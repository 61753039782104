import React from "react";
import { NavLink } from "react-router-dom";

import { Button, Wrapper, Menu, MenuItem, closeMenu } from "react-aria-menubutton";

import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { ReactComponent as PrimaryArrow } from "icons/PrimaryArrow.svg";
import { ReactComponent as Lock } from "icons/Lock.svg";
import { ReactComponent as Tick } from "icons/Tick2.svg";
import { ReactComponent as ChevronDown } from "icons/chevron-down1.svg";

import { guideSectionTypes } from "constants/guide-types";
import __ from "constants/static-texts";
import { StartRoute } from "routes/Start";

const CLASS_NS = "menu-component";

const MenuComponent = ({
  classNs = CLASS_NS,
  title,
  menuItems,
  selectedItem,
  hideProgress = false,
  activeById = false,
}) => {
  const handleSelection = (item) => {
    if (item.onClick) {
      item.onClick(item);
    }
    closeMenu("menu");
  };

  const mainIndex = menuItems.findIndex((item) => item.id === selectedItem.id);

  const renderSubMenuItem = (item, index, showTick = false) => {
    return (
      <>
        <div className={`${classNs}_linktext`}>
          <div className={`${classNs}_title`}>{item.label}</div>
          {!hideProgress ? (
            item.childCount > 0 ? (
              <div className={`${classNs}_progress`}>
                {item.type !== guideSectionTypes.GUIDE_PART ? `${__.navigation.section} ${index} - ` : ""}
                <span className={`${classNs}_count`}>
                  {`${item.isDoneCount} ${__.navigation.of} ${item.childCount}`}{" "}
                </span>
                {item.type === guideSectionTypes.GUIDE_PART
                  ? __.navigation.sectionsDone
                  : __.navigation.requirementsDone}
              </div>
            ) : (
              <div className={`${classNs}_progress`} data-disabled="true">
                {item.type !== guideSectionTypes.GUIDE_PART ? `${__.navigation.section} ${index} - ` : ""}
                {item.type === guideSectionTypes.GUIDE_PART
                  ? __.navigation.sectionsMissing
                  : __.navigation.requirementsMissing}
              </div>
            )
          ) : null}
        </div>
        {!hideProgress && showTick && item.isDoneCount === item.childCount && item.childCount > 0 ? (
          <div className="icon-tick">
            <Tick />
          </div>
        ) : null}
      </>
    );
  };

  const renderMenuItem = (item, index, showTick) => {
    if (item.type) {
      return renderSubMenuItem(item, index, showTick);
    } else {
      return (
        <div className={`${classNs}_linktext`}>
          <div className={`${classNs}_title`}>{item.label}</div>
          {item.subLabel && (
            <div className={`${classNs}_progress`} data-type="italic">
              {item.subLabel}
            </div>
          )}
          {item.progress && !hideProgress ? <ProgressBar progress={item.progress} /> : null}
        </div>
      );
    }
  };

  //TODO: Select active company in company selector
  const isActive = (match, location, route) => {
    // console.log("route", route);
    // console.log("match", match);
    // console.log("location", location);
    // console.log("selectedItem", selectedItem);
    // console.log("------");
    if (activeById) {
      return selectedItem.id === route.id;
    } else {
      return match;
    }
  };

  const menuItemsList = menuItems.map((route, index) => {
    return (
      <MenuItem
        //disabled={route.locked}
        key={index}
        //tag="li"
        value={route.label}
        text={route.label}
        className={`${classNs}_menu_item`}>
        {route.pretitle ? <span className={`${classNs}_pretitle`}>{route.pretitle}</span> : null}
        {route.locked ? (
          <div className={`${classNs}_link disabled`}>
            <div className={`${classNs}_linktext`}>
              <div className={`${classNs}_title`}>{route.label}</div>
            </div>
            <div className="icon-lock">
              <Lock />
            </div>
          </div>
        ) : (
          <NavLink
            exact={route.path === StartRoute().path}
            onClick={() => handleSelection(route)}
            to={route.path}
            className={`${classNs}_link`}
            activeClassName="active"
            isActive={(match, location) => isActive(match, location, route)}>
            {renderMenuItem(route, index + 1, true)}
          </NavLink>
        )}
      </MenuItem>
    );
  });

  return (
    <Wrapper id="menu" className={classNs}>
      <Button className={`${classNs}_menu_button`}>
        <PrimaryArrow className={`${classNs}_primary_arrow`} />
        {renderMenuItem(selectedItem, mainIndex + 1)}
        <div className="icon-chevron">
          <ChevronDown className={`${classNs}_chevron_down`} />
        </div>
      </Button>
      <Menu className={`${classNs}_menu`}>{menuItemsList}</Menu>
    </Wrapper>
  );
};

export default MenuComponent;

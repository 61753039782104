import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDocuments, getDocumentTypes } from "actions/librarydocuments-actions";

import { WAITING, LOADING } from "constants/network-states";
import { searchArray } from "utils/search-helper";

import { getCalculatedDocumentTypes } from "utils/document-helpers";

/**
 * Conditionally loads the library documents and returns the list.
 */
const useLibraryDocuments = (documentId = null) => {
  const dispatch = useDispatch();
  const { documents, documentsNetworkState, documentTypes, documentTypesNetworkState, searchQuery, filterCategory } =
    useSelector((state) => state.librarydocumentsState);

  const fetchDocuments = useCallback(() => {
    documents.length === 0 && documentsNetworkState === WAITING && dispatch(getDocuments());
  }, [dispatch, documents.length, documentsNetworkState]);

  const fetchDocumentTypes = useCallback(() => {
    documentTypes.length === 0 && documentTypesNetworkState === WAITING && dispatch(getDocumentTypes());
  }, [dispatch, documentTypes.length, documentTypesNetworkState]);

  const isLoading =
    documentsNetworkState === WAITING ||
    documentsNetworkState === LOADING ||
    documentTypesNetworkState === WAITING ||
    documentTypesNetworkState === LOADING;

  useEffect(() => {
    fetchDocuments();
    fetchDocumentTypes();
  }, [fetchDocuments, fetchDocumentTypes]);

  const categoriesWithCount = getCalculatedDocumentTypes(documents, documentTypes);

  if (documentId) {
    return [documents, categoriesWithCount, isLoading, documents.filter((doc) => doc.id === parseInt(documentId))];
  }

  if (searchQuery === "" && filterCategory === null) {
    return [documents, categoriesWithCount, isLoading, documents];
  }

  const filteredDocuments =
    searchQuery.length <= 2 ? documents : searchArray(documents, ["title", "description", "literation"], searchQuery);
  const filteredDocumentByCategory = filteredDocuments.filter((resultItem) => {
    return filterCategory === null || filterCategory === resultItem.documentType;
  });

  return [documents, categoriesWithCount, isLoading, filteredDocumentByCategory];
};

export default useLibraryDocuments;

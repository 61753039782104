import { useMemo /* , useEffect */ } from "react";
import { useSelector } from "react-redux";
import { /* useRouteMatch, */ useParams } from "react-router-dom";
// import useGuide from "hooks/use-guide";

import { guideSectionTypes } from "constants/guide-types";

/**
 * Provides the requirements and responseSlots for the current guide section
 */
const useGuideRequirements = () => {
  const { sectionId } = useParams();

  const { guide } = useSelector((state) => state.guideState);
  const guideMainRequirements = useMemo(
    () =>
      guide
        ?.filter((block) => block.type === guideSectionTypes.GUIDE_MAIN_REQUIREMENT && block.parentId === sectionId)
        .map((mainRequirement) => {
          const requirements = guide.filter((block) => {
            return block.type === guideSectionTypes.GUIDE_REQUIREMENT && block.parentId === mainRequirement.id;
          }).map((requirement) => {
            const responseSlots = guide.filter((block) => {
              return block.type === guideSectionTypes.GUIDE_RESPONSE_SLOT && block.parentId === requirement.id;
            });

            return {
              ...requirement,
              responseSlots,
            }
          });
          return {
            ...mainRequirement,
            requirements,
          };
        }),
    [guide, sectionId]
  );

  return { guideMainRequirements };
};

export default useGuideRequirements;

import React /* , { useState } */ from "react";
// import { useTranslation } from "react-i18next";

import "./ChapterFactsSection.scss";

import { BLOCK_GROUP_NAME } from "constants/course";

// import useWindowSize from "hooks/useWindowSize";

import FactBlock from "./FactBlock/FactBlock";
import SoundPlayer from "./SoundPlayer/SoundPlayer";

// import __ from "constants/static-texts";

// Maybe TODO - this could be refactored
// to be part of FactBlock,
// it was just made earlier before the concept
// expanded to be more flexible.
function ChapterFactsSection(props) {
  // const { t } = useTranslation();
  const { block, isLast, isFirst } = props;
  // const { isDesktop } = useWindowSize();
  const [isExpanded] = "true";

  if (block.name !== BLOCK_GROUP_NAME) return null;

  const cls =
    "Chapter-facts-section" +
    (isExpanded ? " Chapter-facts-section--isExpanded" : " Chapter-facts-section--isCollapsed") +
    (isLast ? " Chapter-facts-section--isLast" : "") +
    (isFirst ? " Chapter-facts-section--isFirst" : "");

  // TODO accessability
  return (
    <section className={cls}>
      <header className="Chapter-facts-section__header">
        <h1
          className="Chapter-facts-section__heading"
          dangerouslySetInnerHTML={{ __html: block.attributes.title }}></h1>
      </header>
      {isExpanded ? (
        <div className="Chapter-facts-section__innerblocks">
          {block.attributes.audioUrl ? (
            <div className="Chapter-facts-section__listen">
              <SoundPlayer sourceUrl={block.attributes.audioUrl} />
            </div>
          ) : null}
          {block.innerBlocks.map((innerBlock, i) => {
            return <FactBlock key={i} block={innerBlock} />;
          })}
        </div>
      ) : null}
    </section>
  );
}

export default ChapterFactsSection;

import fetchClient from "fetch-client";

export const trigger500 = (getState) => {
  return fetchClient({
    path: `/debug/500`,
    getState,
  });
};

export const trigger403 = (getState) => {
  return fetchClient({
    path: `/debug/403`,
    getState,
  });
};

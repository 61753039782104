import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import useWorkbook from "hooks/use-workbook";
import useCurrentPart from "hooks/useCurrentPart";
import useCurrentChapter from "hooks/useCurrentChapter";
import useLoggedInUser from "hooks/use-logged-in-user";
import useTextByRole from "hooks/use-text-by-role";

import CourseSectionChapterNav from "./course/CourseSectionChapterNav";
import Chapter from "./course/Chapter";
import SubHeader from "components/SubHeader/SubHeader";
import Loading from "components/Loading/Loading";
import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";

import __ from "constants/static-texts";

const CLASS_NS = "workbook";

const Workbook = ({ location, match, route, classNs = CLASS_NS }) => {
  const [currentWorkbook, setCurrentWorkbook] = useState(null);
  const { isServiceAdmin } = useLoggedInUser();

  const [workbook, workbookLoading] = useWorkbook(currentWorkbook);
  const { currentPart } = useCurrentPart();
  const { currentChapter } = useCurrentChapter();
  const params = useParams();
  const { workbookSlug, partId } = params;
  const { isCompanyReader } = useLoggedInUser();
  const { activeSection } = useSelector((state) => state.guideState);

  const workbookTitle = useTextByRole(__.workbook.title);
  const workbookIntro = useTextByRole(__.workbook.intro);

  useEffect(() => {
    setCurrentWorkbook(workbookSlug);
  }, [match, workbookSlug]);

  if (workbookLoading) {
    return <Loading />;
  }

  // TODO: isServiceAdmin query: är en tillfällig lösning ock ska tas bort när copyn för arbetsbökerna är klar.
  return isServiceAdmin ? (
    <>
      {!partId && workbook?.blbGroupCourseSections?.blbCourseSections ? (
        <SubHeader>
          <h1 className={`${classNs}_title`}>{workbookTitle}</h1>
          <div className={`${classNs}_description`}>
            {workbookIntro.map((text, i) => (
              <p key={i}>{text}</p>
            ))}
          </div>
        </SubHeader>
      ) : null}
      <div className={`${classNs}`}>
        {!partId && workbook?.blbGroupCourseSections?.blbCourseSections ? (
          <div className={`${classNs}_buttons`}>
            {workbook?.blbGroupCourseSections.blbCourseSections?.map((section) => (
              <Link
                to={`${workbookSlug}/${section.blbCourseSectionId}`}
                className={`${classNs}_button`}
                key={section.blbCourseSectionId}>
                {section.blbCourseSectionTitle}
              </Link>
            ))}
          </div>
        ) : null}
        {activeSection ? (
          <Link to={`${activeSection}`} className={`${classNs}_back`}>
            <span className={`${classNs}_back_icon`}>
              <ArrowLeft />
            </span>
            <span className={`${classNs}_back_label`}>
              {__.workbook.backTo} {isCompanyReader ? __.navigation.manual : __.navigation.guide}
            </span>
          </Link>
        ) : null}
        <div className={`${classNs}_inner`}>
          {currentPart && <CourseSectionChapterNav />}
          {currentChapter && <Chapter />}
        </div>
      </div>
    </>
  ) : null;
};

export default Workbook;

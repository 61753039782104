import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ContentEditor from '../content-editor/content-editor';
import { updateSectionDetails, setSectionIsValid } from '../../actions/section-actions';

import useValidate from '../../hooks/use-validate';

const CLASS_NS = 'module-tip';

/*
 * Provides a rich text content editor to edit the context dependent content.
 */
const ModuleTip = ({
  classNs = CLASS_NS,
  module,
  updateModuleContent = () => {},
  labelHeader = '',
  tools = []
}) => {
  const dispatch = useDispatch();
  const { errorMessages } = useSelector((state) => state.sectionState);
  const [validate, getErrorForField] = useValidate(
    errorMessages,
    setSectionIsValid.bind(null, module.id)
  );

  const onEditorChange = (editorStateData) => updateModuleContent(editorStateData, module);

  const onChangeHandler = (e) => {
    const { value, name } = e.target;

    if (getErrorForField(name)) {
      validate(e);
    }

    dispatch(
      updateSectionDetails(module.id, {
        [name]: value
      })
    );
  };

  return (
    <>
      <div className="module-form-row form-lineup">
        <div className="form-lineup_form-group">
          <label className="form-lineup_form-group_label" htmlFor="name">
            {labelHeader}
          </label>
          <input
            className="inputfield inputfield--text"
            name="name"
            value={module.name}
            onChange={onChangeHandler}
            onBlur={validate}
            required
            noValidate={true}
            data-haserror={getErrorForField('name')}
          />
          <p className="input-error-message">{getErrorForField('name')}</p>
        </div>
      </div>
      <ContentEditor
        editorStateData={module?.richContent}
        onChange={onEditorChange}
        tools={tools}
      />
    </>
  );
};

ModuleTip.propTypes = {
  classNs: PropTypes.string,
  module: PropTypes.object, // Todo replace with moduleDataType,
  updateModuleContent: PropTypes.func
};

export default ModuleTip;

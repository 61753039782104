import React from "react";

import CarouselItem from "../CarouselItem/CarouselItem";

function BucLmsBuilderSliderItem(props) {
  const { block } = props;
  return (
    <div className="Fact-block--BucLmsBuilderSliderItem">
      <CarouselItem innerBlocks={block.innerBlocks} image={block.attributes.image} />
    </div>
  );
}

export default BucLmsBuilderSliderItem;

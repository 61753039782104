import React, { useState } from "react";

import useUsers from "hooks/use-users";
import { defaultDate, formatDateInput } from "utils/date-helpers";
import __ from "constants/static-texts";
import Dropdown from "components/Dropdown/Dropdown";
import { isCompanyReader, getUserName } from "utils/user-helper";
const CLASS_NS = "assign";

const Assign = ({ classNs = CLASS_NS, description = "section", assignmentData, onAssign }) => {
  const { usersFilteredByCompany } = useUsers();

  const { assignedToUserId, assignmentDeadline } = assignmentData;

  const assignmentDate = assignmentDeadline ? formatDateInput(assignmentDeadline) : defaultDate();

  const [values, setValues] = useState({
    assignedToUserId,
    assignmentDeadline: assignmentDate,
  });

  const onUserChange = (e) => {
    setValues({ ...values, assignedToUserId: e.target.value ? parseInt(e.target.value) : null });
  };

  const onDateChange = (e) => {
    setValues({ ...values, assignmentDeadline: e.target.value });
  };

  const onAssignClick = () => {
    onAssign(values.assignedToUserId, values.assignmentDeadline);
  };

  const users = usersFilteredByCompany
    .map((user) => ({ id: user.id, role: user.role, text: getUserName(user) }))
    .filter((user) => !isCompanyReader(user));

  const userOptions = !assignedToUserId ? [{ id: "", text: __.assign.choose_user }].concat(users) : users;

  const disabledButton =
    values.assignedToUserId === null ||
    (assignedToUserId === values.assignedToUserId && formatDateInput(assignmentDeadline) === values.assignmentDeadline);

  return (
    <div className={`${classNs}`}>
      <div className={`${classNs}_description`}>{description}</div>
      <Dropdown
        value={values.assignedToUserId || ""}
        options={userOptions}
        label={__.assign.responsible}
        onChange={onUserChange}
        classNs={`${classNs}_row`}
      />
      <label className="label">
        {__.assign.deadline}
        <div className={`${classNs}_row`}>
          <input value={values.assignmentDeadline} onChange={onDateChange} type="date" />
        </div>
      </label>
      <div className={`${classNs}_button`}>
        <button data-size="XS" className={"button"} onClick={onAssignClick} disabled={disabledButton}>
          {assignedToUserId !== values.assignedToUserId || assignedToUserId === null
            ? __.assign.button_assign
            : __.assign.button_update}
        </button>
      </div>
    </div>
  );
};

export default Assign;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import WorkbookSectionPicker from "routes/Workbook/components/WorkbookSectionPicker";
import { InputField, updateSectionDetails } from "react-draft-editor";
import RichTextEditor, {
  RichTextEditorVariant,
  RichTextEditorInitValue,
} from "components/RichTextEditor/RichTextEditor";

const CLASS_NS = "GuideSectionHeader";

const GuideSectionHeader = ({
  block,
  updateModuleContent,
  getValidationErrorForField,
  validate,
  onChange,
  customBlocks,
  blocksSelectorConfig,
  titleLabel,
  descriptionLabel,
  routineLabel,
}) => {
  const classNs = CLASS_NS;
  const dispatch = useDispatch();

  const [resetDescription, setResetDescription] = useState(false);
  const [resetRoutine, setResetRoutine] = useState(false);
  const onChangeHandler = (e) => {
    e.persist(); // NB! Not needed as of React v.17. See: https://reactjs.org/docs/events.html#overview
    if (getValidationErrorForField(e.target.name)) {
      validate(e);
    }
    onChange(e);
  };

  const handleChangeDescription = (value) => {
    dispatch(
      updateSectionDetails(block.id, {
        description: value,
      })
    );
  };

  const handleChangeRoutine = (value) => {
    dispatch(
      updateSectionDetails(block.id, {
        routine: {
          ...block?.routine,
          description: value,
        },
      })
    );
  };

  const updateWorkbookUrl = (obj) => {
    const { workbook, part, chapter } = obj;
    const url = `${workbook}/${part}/${chapter}`;
    dispatch(
      updateSectionDetails(block.id, {
        workbookReference: url,
      })
    );
  };

  const getWorkbookRefs = () => {
    const refs = block?.workbookReference?.split("/");
    return refs?.length === 3
      ? {
          workbookRef: refs[0],
          partRef: refs[1],
          chapterRef: refs[2],
        }
      : {};
  };

  const [descriptionInitValue, setDescriptionInitValue] = useState(null);
  const [routineInitValue, setRoutineInitValue] = useState(null);
  useEffect(() => {
    if (!block.id) return;

    //Need to reset the description field to make the slate-editor rerender
    if (!block.routine?.description && resetRoutine) {
      setResetRoutine(false);
    } else {
      setResetRoutine(true);
      if (!block.routine?.description) {
        dispatch(
          updateSectionDetails(block.id, {
            routine: {
              subTitle: block?.routine?.subTitle ? block.routine.subTitle : routineLabel,
              description: JSON.stringify(RichTextEditorInitValue),
              companyDocumentIds: [],
            },
          })
        );
      } else {
        setRoutineInitValue(block?.routine?.description);
      }
    }

    //Need to reset the routine field to make the slate-editor rerender
    if (!block.description && resetDescription) {
      setResetDescription(false);
    } else {
      setResetDescription(true);
      if (!block.description) {
        dispatch(
          updateSectionDetails(block.id, {
            description: JSON.stringify(RichTextEditorInitValue),
          })
        );
      } else {
        setDescriptionInitValue(block?.description);
      }
    }
  }, [block, dispatch, resetDescription, resetRoutine, routineLabel]);

  return (
    <div className={`${classNs}`}>
      <form>
        <label htmlFor="title" className={`${classNs}_label`}>
          {titleLabel}
        </label>
        <InputField
          value={block.title}
          type="text"
          name="title"
          onChange={onChangeHandler}
          onBlur={validate}
          maxLength="50"
          required
          data-errormessage={getValidationErrorForField("title")}
        />
        <label htmlFor="description" className={`${classNs}_label`}>
          {descriptionLabel}
        </label>
        {descriptionInitValue && resetDescription ? (
          <RichTextEditor
            initValue={descriptionInitValue}
            variant={RichTextEditorVariant.TERTIARY}
            onChange={handleChangeDescription}
          />
        ) : null}
        {!block.isIntroduction ? (
          <>
            <label htmlFor="routine" className={`${classNs}_label`}>
              {routineLabel}
            </label>
            {routineInitValue && resetRoutine ? (
              <RichTextEditor
                initValue={routineInitValue}
                variant={RichTextEditorVariant.TERTIARY}
                onChange={handleChangeRoutine}
                disableDocumentLink={true}
              />
            ) : null}
          </>
        ) : null}
      </form>
      <WorkbookSectionPicker classNs={"GuideWorkbookSelector"} onComplete={updateWorkbookUrl} {...getWorkbookRefs()} />
    </div>
  );
};

GuideSectionHeader.propTypes = {
  classNs: PropTypes.string,
  titleLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  block: PropTypes.object,
  updateModuleContent: PropTypes.func,
  getValidationErrorForField: PropTypes.func,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  customBlocks: PropTypes.array,
  blocksSelectorConfig: PropTypes.array,
};
export default GuideSectionHeader;

import React, { useState } from "react";
import RichText from "components/RichText/RichText";
import Loading from "components/Loading/Loading";
import __ from "constants/static-texts";
import Tooltip from "components/Tooltip/Tooltip";
import TypographyHeadingMeta from "components/TypographyHeadingMeta/TypographyHeadingMeta";
import ListBox from "components/ListBox/ListBox";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";

import useNews from "hooks/use-news";
import { formatDateInput } from "utils/date-helpers";

import { ReactComponent as Plus } from "icons/plus.svg";
import { ReactComponent as Minus } from "icons/Minus.svg";

/**
 * Component will fetch all news.
 * Cut the list to the 4 newest news
 * Render an array with a list element for each news.
 *
 * @returns {React.FC} A React function component
 */
const CLASS_NS = "my-news";

const MyNews = ({ classNs = CLASS_NS }) => {
  const { filteredNews, newsIsLoading } = useNews();
  const newsCutoffs = filteredNews.slice(0, 4);

  return !newsIsLoading ? (
    <div className={classNs}>
      <ListBox title={__.myNews.title} NodeType="ol">
        {newsCutoffs.map((newsItem) => (
          <NewsItem item={newsItem} key={newsItem.id} id={`${classNs}-${newsItem.id}`} classNs={classNs} />
        ))}
      </ListBox>
    </div>
  ) : (
    <Loading />
  );
};

const NewsItem = ({ item, id, classNs = CLASS_NS }) => {
  const [isActive, toggleActive] = useState(false);

  return (
    <li className={`${classNs}_item`}>
      <div className={`${classNs}_header`}>
        <TypographyHeadingMeta
          headingLevel={3}
          heading={<span id={`${id}-heading`}>{item.title}</span>}
          meta={<time>{formatDateInput(item.createdAt)}</time>}
        />
        <ButtonIcon
          onClick={() => toggleActive(!isActive)}
          aria-expanded={isActive}
          aria-controls={`${id}-region`}
          aria-label={isActive ? __.myNews.toggleSectionClose : __.myNews.toggleSectionOpen}
          icon={isActive ? <Minus aria-hidden /> : <Plus aria-hidden />}
        />
      </div>
      <div
        className={`${classNs}_region`}
        role="region"
        aria-labelledby={`${id}-heading`}
        id={`${id}-region`}
        hidden={!isActive}>
        <RichText nodes={item.description} headingLevel={4} />
      </div>
      <Tooltip theme={"dark"} id={`${id}-tooltip`} />
    </li>
  );
};

export default MyNews;

import * as companyDocsApi from "api/companyDoc-api";
import { createActionSequence } from "./action-helpers";
import * as types from "actions/action-types";

export const getCompanyDocuments = createActionSequence("GET_COMPANY_DOCUMENTS", companyDocsApi.getDocuments);

export const getCompanyDocument = (id) =>
  createActionSequence("GET_COMPANY_DOCUMENT", companyDocsApi.getCompanyDocument)(id);

export const addCompanyDocument = createActionSequence("ADD_COMPANY_DOCUMENT", companyDocsApi.addDocument);

export const updateCompanyDocument = createActionSequence(
  "UPDATE_COMPANY_DOCUMENT",
  companyDocsApi.updateCompanyDocument
);

export const deleteCompanyDocument = createActionSequence("DELETE_COMPANY_DOCUMENT", companyDocsApi.deleteDocument);

export const favouriteCompanyDocument = createActionSequence(
  "FAVOURITE_COMPANY_DOCUMENT",
  companyDocsApi.favouriteDocument
);

const setActiveDownload = (file) => ({
  type: types.SET_ACTIVE_COMPANY_DOWNLOAD,
  file,
});
const startDownload = createActionSequence("DOWNLOAD_COMPANY_FILE", companyDocsApi.downloadFile);

export const downloadCompanyFile = (file) => (dispatch) => {
  dispatch(setActiveDownload(file));
  dispatch(startDownload(file.id));
};

export const deleteCompanyDocumentVersion = (documentId, versionId) =>
  createActionSequence("DELETE_COMPANY_DOCUMENT_VERSION", companyDocsApi.deleteCompanyDocumentVersion)(
    documentId,
    versionId
  );

const setActiveCompanyVersionDownload = (filename) => ({
  type: types.SET_ACTIVE_COMPANY_VERSION_DOWNLOAD,
  filename,
});

export const downloadCompanyDocumentVersion = (documentId, versionId, filename) => (dispatch) => {
  dispatch(setActiveCompanyVersionDownload(filename));
  dispatch(startDownloadCompanyDocumentVersion(documentId, versionId));
};

const startDownloadCompanyDocumentVersion = (documentId, versionId) =>
  createActionSequence("DOWNLOAD_COMPANY_DOCUMENT_VERSION", companyDocsApi.downloadCompanyDocumentVersion)(
    documentId,
    versionId
  );

export const exportGuideById = createActionSequence("EXPORT_GUIDE", companyDocsApi.exportZipArchiveForCompany);

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setWorkbooks } from "actions/workbooks-actions";
import { useQuery as useApolloQuery } from "@apollo/client";
import { GET_WORKBOOKS } from "../apollo/queries";
import { getCompanyFromUser } from "utils/user-helper";

import useLoggedInUser from "hooks/use-logged-in-user";
import useCompanies from "hooks/use-companies";

import { PRODUCT_TYPES } from "constants/product-types";
import { SUCCESS } from "constants/network-states";
/**
 * Provides the workbooks from the wp-api
 */
const useWorkbooks = (WORKBOOK_SLUG) => {
  const dispatch = useDispatch();
  const { workbooks, workbooksNetworkState } = useSelector((state) => state.workbooksState);
  const { user, isCompanyUser } = useLoggedInUser();
  const { activeCompany, companiesMap } = useCompanies();

  const apolloQueryObj = useApolloQuery(GET_WORKBOOKS, {
    skip: workbooksNetworkState === SUCCESS,
  });

  const data = apolloQueryObj.data;
  const loading = apolloQueryObj.loading;

  useEffect(() => {
    if (data) {
      const company = isCompanyUser
        ? getCompanyFromUser(isCompanyUser, companiesMap, user, activeCompany)
        : activeCompany;
      const pt = Object.values(PRODUCT_TYPES).find((p) => p.id === company?.product);
      dispatch(
        setWorkbooks(data.courses.edges.filter((course) => !pt || pt.workbook_slugs.includes(course.node.slug)))
      );
    }
  }, [data, dispatch, activeCompany, companiesMap, isCompanyUser, user]);

  return [workbooks, loading];
};

export default useWorkbooks;

import { useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { BLOCK_PARAMS } from '../constants/navigation';

/**
 * useBlockParams - Gets the section ID and block IDs from the current url search parameters
 * Use the push function from react-router-dom's history.push or connected-react-router's push.
 * Native push doesn't work with useLocation.
 *
 * @returns {array} The section id and n-amount of block ids.
 */
const useBlockParams = () => {
  const location = useLocation();
  const { search } = location;
  const prevSearch = useRef();
  const [sectionId, setSectionId] = useState(0); // Trigger a render with state change
  let blockIds = useMemo(() => [], []);

  useEffect(() => {
    if (prevSearch.current === search) {
      // console.log('=> returning prev search : ', search);
      return;
    }
    prevSearch.current = search;

    const url = new URL(window.location);
    url.searchParams.forEach((v, k) => {
      if (k === BLOCK_PARAMS.BLOCK) {
        blockIds.push(v);
      }
    });

    setSectionId(url.searchParams.get(BLOCK_PARAMS.SECTION));
  }, [search, blockIds]);

  return [sectionId, blockIds];
};

export default useBlockParams;

import Users from "./components/Users";
import EditUser from "./components/EditUser";
import EditNonValidatedUser from "./components/EditNonValidatedUser";
import ShowCompanies from "./components/ShowCompanies";

import { UserIsAuthenticated } from "utils/auth-helpers";

const UsersRoute = () => ({
  path: "/users",
  component: UserIsAuthenticated(Users),
  routes: [
    {
      path: "/users/:userId/edit",
      exact: true,
      component: EditUser,
    },
    {
      path: "/users/:userId/edit-non-validated",
      exact: true,
      component: EditNonValidatedUser,
    },
    {
      path: "/users/:userId/companies",
      exact: true,
      component: ShowCompanies,
    },
  ],
});

export default UsersRoute;

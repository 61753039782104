import { BLOCK_PARAMS } from '../constants/navigation';
import { navigationSectionSchema } from '../constants/schema';

const getIdChain = (id, hashmap, ids = []) => {
  let parentId = hashmap?.[id]?.parentId ?? null;
  if (parentId === null || parentId === id) {
    return ids; // Skip the last ID which is the section id.
  }
  return getIdChain(parentId, hashmap, ids.concat([id]));
};

export const getUrlForModuleId = (moduleId, hashmap) => {
  let blockIds = getIdChain(moduleId, hashmap).reverse();
  blockIds.shift(); // First id is the section
  let url = new URL(window.location);
  url.searchParams.delete([BLOCK_PARAMS.BLOCK]);
  blockIds.forEach((id, i) => {
    url.searchParams.append([BLOCK_PARAMS.BLOCK], id);
  });

  return `${url.pathname}${url.search}`;
};

export const hasIdInParams = (id) => {
  let url = new URL(window.location);
  const idStr = id + '';
  return url.searchParams.has(BLOCK_PARAMS.BLOCK)
    ? url.searchParams.getAll(BLOCK_PARAMS.BLOCK).includes(idStr)
    : false;
};

export const normalizeSectionsData = (
  data,
  schema = navigationSectionSchema,
  linkFn = (id) => `${window.location.origin}${window.location.pathname}/section/${id}`
) => {
  return data
    ? data.map((section) => {
        return Object.entries(section).reduce((acc, [k, v]) => {
          let equivalent = schema?.[k] ?? false; // TODO: Figure out how to use a function to create a composite value
          if (equivalent) {
            acc[equivalent] = v;
            if (equivalent === 'id') {
              acc.link = linkFn(v);
            }
          }
          return acc;
        }, {});
      })
    : [];
};

export const getSectionIdFromUrl = () => {
  const url = new URL(window.location);
  const sectionId = url.searchParams.get([BLOCK_PARAMS.SECTION]);
  return sectionId ? sectionId : null;
};

export const getSectionIdFromSearchParams = (searchParams) => {
  const params = new URLSearchParams(searchParams);
  return params.get([BLOCK_PARAMS.SECTION]);
};

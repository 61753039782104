import React from "react";
import PropTypes from "prop-types";

const CLASS_NS = "GuideBlockBarLabel";

const GuideBlockBarLabel = ({
  block,
  classNs = { CLASS_NS },
  label
}) => {
  return <h2 className={`${classNs}_block_header-bar_title`}>{block.title || label }</h2>;
};

GuideBlockBarLabel.propTypes = {
  classNs: PropTypes.string,
  block: PropTypes.object,
  labelPrefix: PropTypes.string,
  numFn: PropTypes.func,
};
export default GuideBlockBarLabel;
import RootLayout from "layouts/root-layout";
import StyleguideRoute from "routes/Styleguide";
import { StartRoute, StartRedirectRoute } from "routes/Start/index";
import GuidesRoute from "routes/Guides/index";
import LibraryDocsRoute from "routes/LibraryDocs/index";
import CompaniesRoute from "routes/Companies";
import ToolsRoute from "routes/Tools";

import LoginRoute from "routes/Login";
import CallbackRoute from "./Callback";
import UsersRoute from "./Users";
import SupervisorsRoute from "./Supervisors";
import NewsRoute from "./News";

import SupervisorsOverviewRoute from "./SupervisorsOverview";
import WorkbookRoute from "routes/Workbook";
import CalendarsRoute from "routes/Calendars";

const createRoutes = [
  {
    component: RootLayout,
    routes: [
      LoginRoute(),
      CallbackRoute(),
      StartRedirectRoute(),
      StartRoute(),
      LibraryDocsRoute(),
      CompaniesRoute(),
      UsersRoute(),
      SupervisorsRoute(),
      NewsRoute(),
      SupervisorsOverviewRoute(),
      GuidesRoute(),
      WorkbookRoute(),
      ToolsRoute(),
      CalendarsRoute(),
      StyleguideRoute(),
    ],
  },
];

export default createRoutes;

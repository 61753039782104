import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { exportGuideById } from "actions/companydocuments-actions";

import { Link } from "react-router-dom";
import { ReactComponent as NextChapterIcon } from "icons/Next-chapter.svg";

import useLoggedInUser from "hooks/use-logged-in-user";
import useGuideNavigation from "hooks/use-guide-navigation";
import useCompanyDocuments from "../../../hooks/use-company-documents";

import { isCompanyUser, getCompanyFromUser } from "utils/user-helper";

import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { Overlay } from "react-draft-editor";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";
import Loading from "components/Loading/Loading";

import __ from "constants/static-texts";

const CLASS_NS = "start-guide-button";

const StartGuideButton = ({ classNs = CLASS_NS, todo }) => {
  const { nextPartNotDone, nextSectionNotDone, progress, guideId } = useGuideNavigation();

  const { isCompanyReader } = useLoggedInUser();
  const dispatch = useDispatch();

  const processDone = !nextPartNotDone && !nextSectionNotDone;

  const path = processDone
    ? `/guides/${guideId}/guide`
    : nextSectionNotDone
    ? nextSectionNotDone.path
    : nextPartNotDone?.path;

  let [documentModalOpen, setDocumentModalOpen] = useState(false);
  const openModal = () => {
    setDocumentModalOpen(true);
  };

  const closeModal = () => {
    setDocumentModalOpen(false);
  };

  const [libraryDocs, setLibraryDocs] = useState([]);

  const updateLibraryDocs = (e) => {
    const docIndex = libraryDocs.indexOf(e.target.ariaLabel);
    if (docIndex > -1) {
      libraryDocs.splice(docIndex, 1);
      setLibraryDocs([...libraryDocs]);
      return;
    }
    setLibraryDocs([...libraryDocs, e.target.ariaLabel]);
  };

  const { companiesMap, activeCompany } = useSelector((state) => state.companiesState);
  const { user } = useSelector((state) => state.loggedInUserState);
  const { isServiceAdmin, isSupervisor } = useLoggedInUser();

  let company = {};
  company =
    ((isServiceAdmin || isSupervisor) && { ...activeCompany }) || {
      ...getCompanyFromUser(isCompanyUser, companiesMap, user, activeCompany),
    } ||
    null;

  const { archiveDownloadIsLoading } = useCompanyDocuments();

  const downloadGuide = () => {
    if (archiveDownloadIsLoading) return;
    // TODO: show error message here
    const companyId = company["id"];
    if (libraryDocs.length === 0 && companyId) return;

    const docTypes = libraryDocs.length > 1 ? "DocumentsAndRoutines" : libraryDocs[0];
    dispatch(exportGuideById({ companyId, docTypes }));
  };

  return (
    <div>
      <div className={`${classNs}_dark_wrapper`}>
        <div className={`${classNs}_header`}>
          <h2 className={`${classNs}_heading`}>Certifieringsguiden</h2>
          {!isCompanyReader && !!nextPartNotDone && (
            <p className={`${classNs}_sub_header`}>
              <span className={`${classNs}_green`}>
                {progress.doneParts} av {progress.totalParts} {""}
              </span>
              avsnitt klara
            </p>
          )}
        </div>
        <ProgressBar progress={progress} variant="secondary" />
        <Link to={path} className={`${classNs}_button`}>
          <>
            {nextPartNotDone && nextSectionNotDone
              ? `Gå till ${nextPartNotDone?.title || ""} - ${nextSectionNotDone?.title || ""}`
              : "Gå till certifieringsguiden"}
            <NextChapterIcon />
          </>
        </Link>
      </div>
      <button className={`${classNs}_button button-secondary`} onClick={openModal}>
        {__.start.startButton.openExportDocsDialog}
      </button>
      <Overlay open={documentModalOpen}>
        <h2>{__.start.startButton.exportDocsHeading}</h2>
        <h3 className={`${classNs}_company-name`}>{company.marketName}</h3>
        <p className={`${classNs}_choices-description`}>{__.start.startButton.chooseDocsForExport}</p>
        <ChoiceGroup
          classNs={`${classNs}_document-choices`}
          onChange={updateLibraryDocs}
          chosen={libraryDocs}
          type="checkbox"
          inversed={true}
          description="Välj vilket innehåll som ska ingå"
          choices={[
            {
              name: "Documents",
              value: "Documents",
              label: "Företagets uppladdade dokument",
            },
            {
              name: "Routines",
              value: "Routines",
              label: "Rutintexter",
            },
          ]}
        />
        {archiveDownloadIsLoading ? (
          <Loading />
        ) : (
          <div className={`${classNs}_button_row`}>
            <button type="button" className="button-inverted" onClick={closeModal}>
              {__.general.cancel}
            </button>
            <button className="button" onClick={downloadGuide}>
              {__.start.startButton.exportDocsButton}
            </button>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default StartGuideButton;

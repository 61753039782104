import React, { useReducer } from 'react';
import useForm from 'hooks/use-form';
import { useDispatch } from 'react-redux';
import { updateUserRole, deleteUser } from 'actions/users-actions';

import { useParams, useHistory } from 'react-router-dom';
import __ from 'constants/static-texts';
import { Overlay } from 'react-draft-editor';
import { USER_TYPES } from 'constants/user-types';
import Dropdown from 'components/Dropdown/Dropdown';
import LayoutColumns from 'components/LayoutColumns/LayoutColumns';
import useUser from 'hooks/use-user';
import { getColleagues, getUserName } from 'utils/user-helper';
import TypographyMeta from 'components/TypographyMeta/TypographyMeta';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import { ReactComponent as TrashIcon } from 'icons/trash.svg';

const CLASS_NS = 'form_overlay';
const EditUser = ({ cancelUrl, users, userRoles }) => {
  const actionTypes = {
    IS_EDITING: 'IS_EDITING',
    NEW_ASSIGNMENT_NOT_ALLOWED: 'NEW_ASSIGNMENT_NOT_ALLOWED',
    SET_NEW_ASSIGNEE: 'SET_NEW_ASSIGNEE',
    IS_DELETING: 'IS_DELETING',
  };

  const localReducer = (currentState, action) => {
    switch (action.type) {
      case actionTypes.IS_EDITING:
        return { ...currentState, editState: action.type };
      case actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED:
        return { ...currentState, editState: action.type };
      case actionTypes.SET_NEW_ASSIGNEE:
        return { ...currentState, editState: action.type };
      case actionTypes.IS_DELETING:
        return { ...currentState, deleteState: action.type };
      case actionTypes.EXIT_DELETING:
        return { ...currentState, deleteState: null };
      default:
        return { ...currentState };
    }
  };

  const [localState, dispatchLocalState] = useReducer(localReducer, {
    editState: actionTypes.IS_EDITING,
    deleteState: null,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const { user } = useUser(parseInt(userId));
  const currentUser = users.find((user) => user.id === parseInt(userId));

  // Form hook for edit user form
  const { inputs, handleChange } = useForm({
    ...currentUser,
    role: currentUser.role,
    newAssigneeUserId: null,
  });

  // Form hook for delete user form
  const { inputs: deleteInputs, handleChange: deleteHandleChange } = useForm({
    ...currentUser,
    newAssigneeUserId: null,
  });

  const dispatchAndClose = (payload) => {
    dispatch(updateUserRole({ ...payload }));
    history.push(cancelUrl, { disableScroll: true });
  };

  const dispatchDeletionAndClose = (payload) => {
    dispatch(deleteUser({ ...payload }));
    history.push(cancelUrl, { disableScroll: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    switch (localState.editState) {
      case actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED:
        return;
      case actionTypes.SET_NEW_ASSIGNEE:
        const parsedInputs = {
          ...inputs,
          newAssigneeUserId: inputs.newAssigneeUserId
            ? parseInt(inputs.newAssigneeUserId)
            : parseInt(newAssigneeOptions[0].id),
        };
        dispatchAndClose(parsedInputs);
        return;
      case actionTypes.IS_EDITING:
        const { newAssigneeUserId, ...strippedInputs } = inputs;
        dispatchAndClose(strippedInputs);
        return;
      default:
        return;
    }
  };

  const usableRolesOptions = userRoles
    .filter((role) => {
      const currentRole = Object.values(USER_TYPES).find((roleItem) => {
        return role.id === roleItem.id;
      });
      return (
        role.id !== USER_TYPES.SUPERVISOR.id &&
        role.id !== USER_TYPES.SERVICEADMIN.id &&
        role.privilege >= currentRole.privilege
      );
    })
    .map((role) => ({
      id: role.id,
      text: __.usertypes[role.name],
    }));

  const newAssigneeOptions = user
    ? getColleagues(user, users)
        .filter(
          (colleague) => colleague.role === USER_TYPES.COMPANYADMIN.id || colleague.role === USER_TYPES.COMPANYEDITOR.id
        )
        .map((colleague) => ({
          id: colleague.id,
          text: getUserName(colleague),
        }))
    : null;

  const multipleCompanies = currentUser.companyNames.length > 1;
  const hasAssignments = user?.assignments?.sectionIds.length > 0 || user?.assignments?.requirementIds.length > 0;

  const handleChangeRole = (e) => {
    const newValueIsCompanyReader = e.target.value === USER_TYPES.COMPANYREADER.id;
    if (newValueIsCompanyReader && multipleCompanies && hasAssignments) {
      dispatchLocalState({ type: actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED });
    } else if (newValueIsCompanyReader && hasAssignments) {
      handleChange(e);
      dispatchLocalState({ type: actionTypes.SET_NEW_ASSIGNEE });
    } else {
      handleChange(e);
      dispatchLocalState({ type: actionTypes.IS_EDITING });
    }
  };

  const clickDelete = () => {
    dispatchLocalState({ type: actionTypes.IS_DELETING });
  };

  const clickCancelDelete = () => {
    dispatchLocalState({ type: actionTypes.EXIT_DELETING });
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    if (hasAssignments) {
      dispatchDeletionAndClose({
        ...inputs,
        newAssigneeUserId: deleteInputs.newAssigneeUserId
          ? parseInt(deleteInputs.newAssigneeUserId)
          : parseInt(newAssigneeOptions[0].id),
      });
    } else {
      const { newAssigneeUserId, ...strippedDeleteInputs } = deleteInputs;
      dispatchDeletionAndClose(strippedDeleteInputs);
    }
  };

  const EditUserIsEditing = () => (
    <>
      <div className={`${CLASS_NS}_header`}>
        <h2>{__.users.editUser(getUserName(currentUser))}</h2>
      </div>
      {multipleCompanies && <TypographyMeta as="p">{__.users.multipleCompanies}</TypographyMeta>}
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        <Dropdown
          value={inputs.role || ''}
          options={usableRolesOptions}
          label={__.document.productType}
          onChange={handleChangeRole}
          classNs={`${CLASS_NS}_dropdown`}
          labelPos="top"
          id={`role`}
        />
        {localState.editState === actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED && (
          <p>{__.users.multipleAssignmentToReader}</p>
        )}
        {localState.editState === actionTypes.SET_NEW_ASSIGNEE && (
          <Dropdown
            value={inputs.newAssigneeUserId || ''}
            options={newAssigneeOptions}
            label={__.users.newAssigneeLabel}
            onChange={handleChange}
            classNs={`${CLASS_NS}_dropdown`}
            id={`newAssigneeUserId`}
            labelPos="top"
          />
        )}
        <button
          type="submit"
          className={`${CLASS_NS}_button`}
          disabled={localState.editState === actionTypes.NEW_ASSIGNMENT_NOT_ALLOWED}>
          {__.users.editUserSubmit}
        </button>
        {!multipleCompanies && (
          <ButtonLink
            icon={<TrashIcon aria-hidden />}
            variant="action"
            size="small"
            alignment="center"
            onClick={clickDelete}>
            {__.users.deleteUser}
          </ButtonLink>
        )}
      </form>
    </>
  );

  const EditUserIsDeleting = () => (
    <>
      <div className={`${CLASS_NS}_header`}>
        <h2>{__.users.deleteUserHeading(getUserName(currentUser))}</h2>
      </div>
      <form onSubmit={handleSubmitDelete} className={CLASS_NS}>
        {hasAssignments && (
          <Dropdown
            value={deleteInputs.newAssigneeUserId || ''}
            options={newAssigneeOptions}
            label={__.users.newAssigneeLabel}
            onChange={deleteHandleChange}
            classNs={`${CLASS_NS}_form-row`}
            id={`newAssigneeUserId`}
          />
        )}
        <LayoutColumns>
          <button className={`button-inverted`} type="button" onClick={clickCancelDelete}>
            {__.document.deleteCancel}
          </button>
          <button className={`button-warning`} type="submit">
            {__.document.deleteConfirm}
          </button>
        </LayoutColumns>
      </form>
    </>
  );

  return user?.id === parseInt(userId) ? (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      {localState.deleteState === null ? <EditUserIsEditing /> : <EditUserIsDeleting />}
    </Overlay>
  ) : null;
};

export default EditUser;

import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useBlocksValidity } from './use-blocks-validity';
import useBlockParams from './use-block-params';
import { removeAttributesFromBlocks } from '../utils/module';

import { SUCCESS } from '../constants/network-states';
import { isEqualReact as equal } from '@react-hookz/deep-equal';
/**
 * useBlocksSaveable - Tests the current saveable state of the blocks in redux section state
 *
 * @returns {array} An array containing parameters with the current savable state
 */
export const useBlocksSaveable = (blockId) => {
  const {
    blocks: sectionBlocks,
    isSectionSet,
    saveSectionNetworkState
  } = useSelector((state) => state.sectionState);
  const [sectionId] = useBlockParams();
  const previousBlocks = useRef();
  const [isValid] = useBlocksValidity();
  const [isDiff, setIsDiff] = useState(false);
  const [initiated, setInitiated] = useState(false);

  //If blockId is specified, filter out block and child blocks otherwise go with all sectionBlocks
  const blocks = blockId
    ? Object.entries(sectionBlocks).reduce((acc, [key, value]) => {
        if (!blockId) {
          acc[key] = value;
        } else if (value.id === blockId || value.parentId === blockId) {
          acc[key] = value;
        }
        return acc;
      }, {})
    : sectionBlocks;

  useEffect(() => {
    if (!initiated && isSectionSet) {
      previousBlocks.current = blocks;
      setInitiated(true);
    }
    return () => {
      setInitiated(false);
    };
  }, [isSectionSet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isSectionSet) {
      return;
    }
    const blocksSansVisible = removeAttributesFromBlocks(blocks, ['isVisible']);
    const prevBlocksSansVisible = removeAttributesFromBlocks(previousBlocks.current, ['isVisible']);
    const isDifferent = !equal(blocksSansVisible, prevBlocksSansVisible);

    setIsDiff(isDifferent);
  }, [blocks, isSectionSet, previousBlocks]);

  useEffect(() => {
    // Flush the previous blocks when networkstate has returned SUCCESS
    if (saveSectionNetworkState === SUCCESS) {
      previousBlocks.current = blocks; // New blocks are updated from the server data
      setIsDiff(false);
    }
  }, [saveSectionNetworkState, blocks, previousBlocks]);

  useEffect(() => {
    // Reset the previous blocks reference when the section changes
    // console.log('resetting blocks : ', sectionId);
    previousBlocks.current = blocks;
    setIsDiff(false);
  }, [sectionId]); // eslint-disable-line
  const isBlocksSaveable = isValid && isDiff;
  return [isBlocksSaveable];
};

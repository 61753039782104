import React, { useState } from 'react';
import useForm from 'hooks/use-form';
import { useDispatch } from 'react-redux';
import { addLibraryDocument, updateLibraryDocument, downloadFile } from 'actions/librarydocuments-actions';
import { useParams, useLocation, Link } from 'react-router-dom';
import __ from 'constants/static-texts';
import { Overlay } from 'react-draft-editor';
import Dropdown from 'components/Dropdown/Dropdown';
import LibraryDocsRoute from 'routes/LibraryDocs';
import useLibraryDocument from 'hooks/use-library-document';
import LayoutColumns from 'components/LayoutColumns/LayoutColumns';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import { ReactComponent as TrashIcon } from 'icons/trash.svg';
import { useEffect } from 'react';

const CLASS_NS = 'form_overlay';
const EditDocument = ({ productTypes, cancelUrl }) => {
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [libraryDocument] = useLibraryDocument(id);

  const isNew = id === 'new';

  const initialDocument = {
    title: '',
    description: '',
    file: '',
    product: productTypes[0].id,
    literation: '',
    // price: 230....
  };
  const currentDocument = isNew ? initialDocument : libraryDocument;

  const { inputs, handleChange, reInit } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...currentDocument,
    }
  );

  useEffect(() => {
    reInit({
      ...currentDocument,
    });
  }, [libraryDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFileChange = (e) => {
    const fileName = e.target.value.split('\\').pop().split('.').shift();
    inputs.title = fileName;
    handleChange(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isNew) {
      dispatch(addLibraryDocument({ ...inputs }, cancelUrl));
    } else {
      dispatch(updateLibraryDocument({ ...inputs }, cancelUrl));
    }
  };

  const downloadClick = () => {
    dispatch(downloadFile(currentDocument));
  };
  const showUpload = () => {
    view ? setView(false) : setView(true);
  };
  const prodTypesOptions = productTypes.map((pt) => ({ id: pt.id, text: pt.name }));

  return (isNew && currentDocument) || (!isNew && libraryDocument) ? (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <h1>{isNew ? __.document.newDoc : __.document.editDoc}</h1>
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        {!isNew && (
          <label className={`${CLASS_NS}_form-row`} htmlFor="title">
            <span className="label">{__.document.title_label}</span>
            <input
              type="text"
              id="title"
              name="title"
              placeholder={__.placeholder.documentName}
              value={inputs.title}
              onChange={handleChange}
            />
          </label>
        )}
        <label className={`${CLASS_NS}_form-row`} htmlFor="description">
          <span className="label">{__.document.description_label}</span>
          <textarea
            type="text"
            id="description"
            name="description"
            placeholder={__.placeholder.documentDescription}
            value={inputs.description}
            onChange={handleChange}
          />
        </label>
        <label className={`${CLASS_NS}_form-row`} htmlFor="literation">
          <span className="label">{__.document.literation}</span>
          <input
            type="text"
            id="literation"
            name="literation"
            placeholder={__.placeholder.documentLiteration}
            value={inputs.literation}
            onChange={handleChange}
          />
        </label>
        {isNew ? (
          <>
            <label className={`${CLASS_NS}_form-row`} htmlFor="file">
              <span className="label">{__.document.addFile}</span>
              <input required={isNew} type="file" id="file" name="file" onChange={onFileChange} />
            </label>
          </>
        ) : view ? (
          <label className={`${CLASS_NS}_form-row`} htmlFor="replaceFile">
            <span className="label">{__.document.newVersion}</span>
            <input required={isNew} type="file" id="replaceFile" name="file" onChange={onFileChange} />
          </label>
        ) : (
          <>
            <div className={`${CLASS_NS}_form-row`}>
              <span className="label">
                {__.document.file}{' '}
                <Link
                  to={{
                    pathname: `${LibraryDocsRoute().path}/${currentDocument.id}/versions`,
                    state: { disableScroll: true, cancelUrl: pathname },
                  }}
                  className={`${CLASS_NS}_version-link`}>
                  {__.document.versions(currentDocument.versions.length)}
                </Link>
              </span>
              <LayoutColumns maxWidth="max-content">
                <ButtonLink size="small" variant="neutral" type="button" onClick={downloadClick}>
                  {currentDocument.versions[0].fileName}
                </ButtonLink>
                <ButtonLink size="small" variant="neutral" onClick={showUpload}>
                  ({__.document.newVersionButton})
                </ButtonLink>
              </LayoutColumns>
            </div>
          </>
        )}
        <Dropdown
          value={inputs.product || ''}
          options={prodTypesOptions}
          label={__.document.productType}
          onChange={handleChange}
          classNs={`${CLASS_NS}_dropdown`}
          id={`product`}
          labelPos="top"
        />
        {!isNew && (
          <div className={`${CLASS_NS}_form-row`} data-align="right">
            <Link
              to={{
                pathname: `${LibraryDocsRoute().path}/${currentDocument.id}/delete`,
                state: { disableScroll: true, documentTitle: currentDocument.title },
              }}
              className={`${CLASS_NS}_button-delete`}>
              <TrashIcon aria-hidden /> {__.document.delete}
            </Link>
          </div>
        )}
        <button type="submit" className={`${CLASS_NS}_button`}>
          {isNew ? `Lägg till` : `Uppdatera`}
        </button>
      </form>
    </Overlay>
  ) : null;
};

export default EditDocument;

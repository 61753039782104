import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as Tick } from "icons/Tick2.svg";
import ReactTooltip from "react-tooltip";
import Tooltip from "components/Tooltip/Tooltip";

// const CLASS_NS = "guide-viewer-toolbar";

const GuideViewerToolDone = ({
  classNs,
  checked,
  onClick,
  text,
  tooltip = {
    id: "",
    text: "",
    theme: "",
  },
  disabled = false,
  Icon = Tick,
  iconClass = "tick",
}) => {
  const tooltipEl = useRef(null);

  const [buttonChecked, setButtonChecked] = useState(checked);

  useEffect(() => {
    //Hide and show tooltip to trigger text change
    if (checked !== buttonChecked) {
      setButtonChecked(checked);
      ReactTooltip.hide(tooltipEl.current);
      setTimeout(() => {
        ReactTooltip.show(tooltipEl.current);
      }, 200);
    }
  }, [checked, buttonChecked]);

  return (
    <>
      <button
        className={`${classNs}_button`}
        data-icon="done"
        data-checked={buttonChecked}
        data-tip={tooltip.text}
        data-for={tooltip.id}
        onClick={onClick}
        disabled={disabled}
        ref={tooltipEl}>
        <div className="icon-tick">
          <Icon className={iconClass} />
        </div>
        {text}
      </button>
      <Tooltip theme={tooltip.theme} id={tooltip.id} />
    </>
  );
};

export default GuideViewerToolDone;

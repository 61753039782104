import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import GuideViewerSection from "./GuideViewerSection";

import { setActiveCompany } from "actions/companies-actions";

import { SUCCESS } from "constants/network-states";
import Loading from "components/Loading/Loading";

import useGuide from "hooks/use-guide";
import useCompanyDocuments from "hooks/use-company-documents";
import useCompanies from "hooks/use-companies";
import useCommments from "hooks/use-comments";


const CLASS_NS = "guide-viewer";

const GuideViewer = ({ location, match, route, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();

  const params = useParams();

  const { companyDocumentsIsLoading } = useCompanyDocuments();
  const { commentsIsLoading } = useCommments();

  const [guideBlocks, guideNetworkState, guideMeta] = useGuide(parseInt(params.guideId), true);

  const { companies, activeCompany } = useCompanies();

  useEffect(() => {
    if (
      guideMeta &&
      companies.length &&
      (!activeCompany || (activeCompany && activeCompany.id !== guideMeta.companyId))
    ) {
      const company = companies.find((company) => company.id === guideMeta.companyId);

      dispatch(setActiveCompany(company));
    }
  }, [guideMeta, companies, activeCompany, dispatch]);

  const isLoading = guideNetworkState !== SUCCESS || companyDocumentsIsLoading || commentsIsLoading;

  return (
    <>
      {!isLoading ? (
        <>
          <GuideViewerSection guideBlocks={guideBlocks} guideMeta={guideMeta} />
        </>
      ) : <Loading />}
    </>
  );
};

export default GuideViewer;

import GuidePartEditor from "./components/GuidePartEditor";
import GuideEditor from "./components/GuideEditor";
import Guides from "./components/Guides";
import GuideViewer from "./components/GuideViewer";
import GuideSettings from "./components/GuideSettings";
import GuideInstances from "./components/GuideInstances";
import Passthrough from "routes/Passthrough";
import { UserIsAuthenticated } from "utils/auth-helpers";
import { guideSectionTypes } from "constants/guide-types";

const rootPath = "/guides";

const GuidesRoute = () => ({
  path: `${rootPath}`,
  component: Passthrough,
  routes: [
    {
      path: `${rootPath}/:guideId/guide-part-editor/:guidePartId/guide-editor/:guideSectionId?`, // include the additional slug for testing current route
      component: UserIsAuthenticated(GuideEditor),
      exact: true,
    },
    {
      path: `${rootPath}/:guideId/guide-part-editor/:guidePartId?`,
      component: UserIsAuthenticated(GuidePartEditor),
      exact: false,
    },
    {
      path: `${rootPath}/:guideId/guide/:partId?/:sectionId?`,
      component: UserIsAuthenticated(GuideViewer),
      exact: true,
    },
    {
      path: `${rootPath}`,
      component: UserIsAuthenticated(Guides),
      routes: [
        {
          path: `${rootPath}/guide/:guideId?`,
          component: GuideSettings,
          exact: true,
        },
        {
          path: `${rootPath}/guide/:guideId/instances`,
          component: GuideInstances,
          exact: true,
        },
      ],
    },
  ],
});

//** Guide helpers */
export const getGuidePath = (guide, guideMetaId, sectionId, requirementId) => {
  if (!guide) throw Error('"guide" must be defined');
  if (!guideMetaId) throw Error('"guideMetaId" must be defined');
  const matchingSectionBlock = guide.find((block) => block.id === `${guideSectionTypes.GUIDE_SECTION}_${sectionId}`);
  const suffix = requirementId ? `#${guideSectionTypes.GUIDE_REQUIREMENT}_${requirementId}` : "";
  const path = matchingSectionBlock
    ? `/guides/${guideMetaId}/guide/${matchingSectionBlock.parentId}/${matchingSectionBlock.id}${suffix}`
    : "";
  return path;
};

export default GuidesRoute;

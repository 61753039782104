import React from "react";
import { ReactComponent as SquareDocument } from "icons/SquareDocument.svg";
import { ReactComponent as DownloadIcon } from "icons/Download.svg";
import { downloadCompanyFile } from "actions/companydocuments-actions";
import { downloadFile } from "actions/librarydocuments-actions";
import { useDispatch } from "react-redux";
import ButtonLink from "components/ButtonLink/ButtonLink";

const DownloadDocumentButton = ({
  document,
  onClick,
  size,
  isCompanyDocument = false,
  children,
  downloadIcon = false,
}) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
      return;
    }

    if (isCompanyDocument) {
      dispatch(downloadCompanyFile(document));
    } else {
      dispatch(downloadFile(document));
    }
  };

  return (
    <ButtonLink
      icon={downloadIcon ? <DownloadIcon aria-hidden /> : <SquareDocument aria-hidden />}
      onClick={handleClick}
      size={size}>
      {children || document?.label}
    </ButtonLink>
  );
};

export default DownloadDocumentButton;

import React from "react";
import PropTypes from "prop-types";
import "./_LayoutColumns.scss";

const LayoutColumns = ({ minWidth, maxWidth, columnGap, rowGap, children }) => {
  return (
    <div
      className="layout-columns"
      style={{
        "--layout-columns--column-gap": columnGap,
        "--layout-columns--row-gap": rowGap,
        "--layout-columns--min-width": minWidth,
        "--layout-columns--max-width": maxWidth,
      }}>
      {children}
    </div>
  );
};

LayoutColumns.propTypes = {
  minWidth: PropTypes.string,
  columnGap: PropTypes.string,
  rowGap: PropTypes.string,
};

export default LayoutColumns;

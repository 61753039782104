import { /*WAITING,*/ LOADING, ERROR, SUCCESS, NOT_FOUND } from 'constants/network-states';
import * as interceptors from '../utils/interceptors';
// Based upon https://medium.com/@machadogj/async-action-creators-with-redux-thunk-83af81994250
export const createActionSequence = (type, apiFn, callback) => {
  return (...args) =>
    async (dispatch, getState) => {
      const params = args.length > 0 ? args[0] : {};

      dispatch({
        type: `${type}_${LOADING}`,
        params: params
      });

      let result;
      try {
        result = await apiFn(...args, getState);

        dispatch({
          type: `${type}_${SUCCESS}`,
          payload: result,
          params: params
        });

        if (callback) {
          callback(result, params, dispatch);
        }

        return result;
      } catch (error) {
        console.error('Error in createActionSequence : ', error);

        const errorType = interceptors.responseErrorInterceptor(error);
        const errorCode = error.status;

        if (errorCode === 404) {
          dispatch({
            type: `${type}_${NOT_FOUND}`,
            payload: error,
            params: params,
            errorType: errorType
          });
        } else {
          dispatch({
            type: `${type}_${ERROR}`,
            payload: error,
            params: params,
            errorType: errorType
          });
        }

        if (params.surpressStatusMessage) {
          return;
        }
        // Optional according to project
        // dispatch(
        // 	statusMessage({
        // 		keepOpen: false,
        // 		errorType: errorType,
        // 		error: error,
        // 		message: getMessageFromPseudoMessage(errorType)
        // 	})
        // )
      }
    };
};

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import __ from "constants/static-texts";
import { ReactComponent as Pencil } from "icons/Pencil.svg";
import { ReactComponent as Tick } from "icons/Tick2.svg";
import { ReactComponent as Plus } from "icons/plus.svg";

import { updateBlockValue } from "actions/guide-actions";

import { guideSectionTypes } from "constants/guide-types";

import useLoggedInUser from "hooks/use-logged-in-user";
import useCompanyDocuments from "hooks/use-company-documents";
import useForm from "hooks/use-form";

import GuideViewerRoutineEdit from "./GuideViewerRoutineEdit";
import Tooltip from "components/Tooltip/Tooltip";
import RichText from "components/RichText/RichText";
import { RichTextEditorInitValue } from "components/RichTextEditor/RichTextEditor";
import DownloadDocumentButton from "components/DownloadDocumentButton/DownloadDocumentButton";

import ReactTooltip from "react-tooltip";

const CLASS_NS = "guide-viewer-routine";

const GuideViewerRoutine = ({ section, guideId, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();

  const { companyDocumentsMap } = useCompanyDocuments();
  const { routine } = section;

  const { isCompanyUser, isCompanyReader } = useLoggedInUser();

  const [isEditing, setIsEditing] = useState(false);

  const changeEdit = (val) => {
    setIsEditing(val);
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 300);
  };

  const saveRoutine = () => {
    dispatch(
      updateBlockValue({
        guideId,
        blocks: [
          {
            ...section,
            parentId: section.parentId.replace(`${guideSectionTypes.GUIDE_PART}_`, ""),
            id: section.id.replace(`${guideSectionTypes.GUIDE_SECTION}_`, ""),
            routine: {
              ...inputs,
            },
          },
        ],
      })
    ).then(() => {
      changeEdit(false);
    });
  };

  const { inputs, handleChange, reInit } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...routine,
      subTitle: !routine?.subTitle ? __.guide.routine.subtitle : routine.subTitle,
    }
  );

  const startEditing = () => {
    if (!routine) {
      reInit({
        subTitle: __.guide.routine.subtitle,
        description: JSON.stringify(RichTextEditorInitValue),
        companyDocumentIds: [],
      });
    }
    changeEdit(true);
  };

  const routineFiles =
    routine?.companyDocumentIds
      .map((companyDocumentId) => {
        const companyDoc = companyDocumentsMap[companyDocumentId];
        return companyDoc
          ? {
              ...companyDoc,
            }
          : null;
      })
      .filter((doc) => doc) || [];

  const missingDescription = !routine || routine?.description === JSON.stringify(RichTextEditorInitValue);

  return (
    <div className={`${classNs}_wrapper`} data-editing={isEditing}>
      <h2 className={`${classNs}_title`}>{__.guide.routine.title}</h2>
      <div className={`${classNs}`}>
        <div className={`${classNs}_topbar`}>
          <div className={`${classNs}_subtitle`}>
            {isEditing ? (
              <>
                <label className={`${classNs}_subtitle_edit`} htmlFor="subTitle">
                  <Pencil />
                </label>
                <input
                  type="text"
                  id="subTitle"
                  name="subTitle"
                  placeholder={__.guide.routine.placeholderTitle}
                  value={inputs.subTitle}
                  onChange={handleChange}
                />
              </>
            ) : (
              `${routine?.subTitle || __.guide.routine.subtitle}`
            )}
          </div>
          {!isCompanyReader && (
            <div className={`${classNs}_tools`}>
              {isEditing ? (
                <>
                  <button
                    className={`${classNs}_button`}
                    onClick={() => changeEdit(false)}
                    data-tip={__.guide.routine.cancel}
                    data-for={"routine_tooltip"}>
                    <div className={`icon-plus`}>
                      <Plus />
                    </div>
                  </button>
                  <button
                    className={`${classNs}_button`}
                    onClick={saveRoutine}
                    data-tip={__.guide.routine.save}
                    data-for={"routine_tooltip"}>
                    <div className={`icon-tick`}>
                      <Tick />
                    </div>
                  </button>
                </>
              ) : (
                <button
                  className={`${classNs}_button`}
                  onClick={startEditing}
                  data-tip={__.guide.routine.edit}
                  data-for={"routine_tooltip"}
                  disabled={!isCompanyUser}>
                  <div className={`icon-pencil`}>
                    <Pencil />
                  </div>
                </button>
              )}
              <Tooltip theme={"dark"} id={"routine_tooltip"} />
            </div>
          )}
        </div>
        <div className={`${classNs}_content`}>
          {isEditing ? (
            <>
              <GuideViewerRoutineEdit
                routine={routine}
                classNs={`${classNs}`}
                inputs={inputs}
                initValue={routine?.description}
                handleChange={handleChange}
                routineFiles={routineFiles}
              />
            </>
          ) : (
            <>
              <div className={`${classNs}_content_description`} data-missingtext={missingDescription}>
                {missingDescription ? (
                  __.guide.routine.descriptionMissing
                ) : (
                  <RichText nodes={routine?.description} headingLevel={4} />
                )}
              </div>
              <div className={`${classNs}_content_files`}>
                {routineFiles.map((file, index) => (
                  <DownloadDocumentButton downloadIcon={true} isCompanyDocument={true} document={file} key={index}>{`${
                    file.title
                  }${file.fileName ? ` - ${file.fileName}` : ""}`}</DownloadDocumentButton>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuideViewerRoutine;

import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";
import { createMapFromArray } from "utils/js-helpers";

const initialState = {
  users: [],
  user: null,
  usersMap: null,
  userNetworkState: WAITING,
  usersNetworkState: WAITING,
  addUserNetworkState: WAITING,
  deleteUserNetworkState: WAITING,
  updateUserNetworkState: WAITING,
  searchQueryUsers: "",
  searchQuerySupervisors: "",
  error: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_LOADING:
      return {
        ...state,
        usersNetworkState: LOADING,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersMap: createMapFromArray(action.payload),
        usersNetworkState: SUCCESS,
      };
    case types.GET_USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        usersNetworkState: ERROR,
      };
    case types.GET_USER_LOADING:
      return {
        ...state,
        userNetworkState: LOADING,
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userNetworkState: SUCCESS,
      };
    case types.GET_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        userNetworkState: ERROR,
      };

    case types.ADD_USER_LOADING:
      return {
        ...state,
        addUserNetworkState: LOADING,
      };
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        users: state.users.concat(action.payload),
        addUserNetworkState: SUCCESS,
      };
    case types.ADD_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        addUserNetworkState: ERROR,
      };

    case types.SET_SEARCH_QUERY_USERS:
      return {
        ...state,
        searchQueryUsers: action.value,
      };

    case types.SET_SEARCH_QUERY_SUPERVISORS:
      return {
        ...state,
        searchQuerySupervisors: action.value,
      };

    case types.UPDATE_USER_ROLE_LOADING:
      return {
        ...state,
        updateUserNetworkState: LOADING,
      };
    case types.UPDATE_USER_ROLE_SUCCESS:
      const updatedUsers = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        } else {
          return user;
        }
      });
      return {
        ...state,
        users: updatedUsers,
        usersMap: createMapFromArray(updatedUsers),
        updateUserNetworkState: SUCCESS,
      };
    case types.UPDATE_USER_ROLE_ERROR:
      return {
        ...state,
        error: action.payload,
        updateUserNetworkState: ERROR,
      };
    case types.DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserNetworkState: LOADING,
      };
    case types.DELETE_USER_SUCCESS:
      const updatedUsersAfterDeletion = state.users.filter((user) => user.id !== action.payload.id);
      return {
        ...state,
        users: updatedUsersAfterDeletion,
        usersMap: createMapFromArray(updatedUsersAfterDeletion),
        user: state.user?.id === action.payload.id ? null : state.user,
        deleteUserNetworkState: SUCCESS,
      };
    case types.DELETE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        deleteUserNetworkState: ERROR,
      };

    case types.UPDATE_USER_DETAILS_LOADING:
      return {
        ...state,
        updateUserNetworkState: LOADING,
      };

    case types.UPDATE_USER_DETAILS_SUCCESS:
      const updatedUsersWithDetails = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        } else {
          return user;
        }
      });
      return {
        ...state,
        users: updatedUsersWithDetails,
        usersMap: createMapFromArray(updatedUsersWithDetails),
        updateUserNetworkState: SUCCESS,
      };
    case types.UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        updateUserNetworkState: ERROR,
      };

    case types.GET_COMPANY_USERS_SUCCESS:
      const companyUserIds = action.payload.map((user) => user.id);
      const updatedUserList = state.users.filter((user) => !companyUserIds.includes(user.id)).concat(action.payload);

      return {
        ...state,
        users: updatedUserList,
        usersMap: createMapFromArray(updatedUserList),
        usersNetworkState: SUCCESS,
      };
    case types.GET_COMPANY_USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        usersNetworkState: ERROR,
      };

    default:
      return state;
  }
};

export default usersReducer;

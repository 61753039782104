import fetchClient from "fetch-client";

export const getUsers = (getState) => {
  return fetchClient({
    path: `/users`,
    getState,
  });
};

export const getUser = (data, getState) =>
  fetchClient({
    path: `/users/${data.id}/`,
    getState,
  });

export const addUser = (data, getState) => {
  return fetchClient({
    path: `/users`,
    getState,
    method: `POST`,
    data,
  });
};

export const updateUserRole = (data, getState) =>
  fetchClient({
    path: `/users/${data.id}/role`,
    getState,
    method: `PUT`,
    data,
  });

export const deleteUser = (data, getState) =>
  fetchClient({
    path: `/users/${data.id}/`,
    getState,
    method: `DELETE`,
    data,
  });

export const updateUserDetails = (data, getState) =>
  fetchClient({
    path: `/users/${data.id}/details`,
    getState,
    method: `PUT`,
    data,
  });

export const updateUserProfileImage = (data, getState) =>
  fetchClient({
    path: `/users/${data.id}/profileimage`,
    getState,
    method: `POST`,
    data: { file: data.image },
    contentType: "form",
  });

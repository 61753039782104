import React from "react";
import { useSelector } from "react-redux";
import SubHeader from "components/SubHeader/SubHeader";
import Loading from "components/Loading/Loading";
import { NON_VALIDATED_USER_STATUSES } from "constants/non-validated-user-statuses";
import StartGuideButton from "./StartGuideButton";
import StartIntro from "./StartIntro";
import MyFavourites from "./MyFavourites";
import MyNews from "routes/News/components/MyNews";
import MyToDos from "routes/News/components/MyToDos";
import useNotifications from "hooks/use-notifications";
import useCalendars from "hooks/use-calendars";
import useAllNonValidatedUsers from "hooks/use-all-non-validated-users";
import useCurrentNonValidatedUser from "hooks/use-current-non-validated-user";

import { WAITING, LOADING } from "constants/network-states";
import {
  isCompanyAdmin,
  isCompanyReader,
  isCompanyUser,
  isBaseProduct,
  getCompanyFromUser,
} from "utils/user-helper";
import MyActivities from "components/MyActivities/MyActivities";
import CertifiedBadge from "components/CertifiedBadge/CertifiedBadge";
import LayoutColumns from "components/LayoutColumns/LayoutColumns";
import NewUsers from "./NewUsers";

const Start = (props) => {
  const { calendars } = useCalendars();
  const { activeCompany, companiesNetworkState, companiesMap } = useSelector((state) => state.companiesState);
  const { user, loggedInUserNetworkState } = useSelector((state) => state.loggedInUserState);
  const companiesIsLoading = companiesNetworkState === WAITING || companiesNetworkState === LOADING;
  const userIsLoading = loggedInUserNetworkState === WAITING || loggedInUserNetworkState === LOADING;

  const { nonValidatedUsers, nonValidatedUsersIsLoading } = useAllNonValidatedUsers(user);
  const pendingNonValidatedUsersExist = !companiesIsLoading && !nonValidatedUsersIsLoading && activeCompany
    && !!nonValidatedUsers?.filter(nonValidatedUser => nonValidatedUser.companyValidationStatuses?.find(validationStatus => validationStatus.companyId === activeCompany.id && validationStatus.status === NON_VALIDATED_USER_STATUSES.PENDING)).length;
  
  const { currentNonValidatedUser } = useCurrentNonValidatedUser();
  const currentNonValidatedUserIsMultiCompany = user && currentNonValidatedUser && !!currentNonValidatedUser.companyValidationStatuses.length;
  const currentNonValidatedUserIsBlocked = currentNonValidatedUser && currentNonValidatedUser.companyValidationStatuses.find(validationStatus => validationStatus.status === NON_VALIDATED_USER_STATUSES.BLOCKED);

  const credentialsLoading = userIsLoading || companiesIsLoading;
  const { notificationsIsLoading } = useNotifications();
  const companyAdmin = isCompanyAdmin(user);
  const companyReader = isCompanyReader(user);
  const companyUser = isCompanyUser(user);

  const company = getCompanyFromUser(companyUser, companiesMap, user, activeCompany);

  const baseProduct = isBaseProduct(company);

  let role = user?.role;
  if(baseProduct) role = "baseProduct";
  if(currentNonValidatedUser) {
    role = 'pendingNonValidatedUser';
    if(currentNonValidatedUserIsMultiCompany) role = "pendingNonValidatedMultiCompanyUser";
    if(currentNonValidatedUserIsBlocked) role = "blockedNonValidatedUser";
  }

  return (
    <>
      {!credentialsLoading && user && companyUser ? (
        <>
          <div className="start_content">
            {baseProduct ? (
              <LayoutColumns>
                <StartIntro user={user} role={role} companyName={currentNonValidatedUserIsMultiCompany && company && company.name} />
                <MyFavourites />
                {companyAdmin && !nonValidatedUsersIsLoading && !!nonValidatedUsers.length && <NewUsers />}
                <MyNews />
              </LayoutColumns>
            ) : (
                <LayoutColumns>
                  <StartIntro user={user} role={role} companyName={currentNonValidatedUserIsMultiCompany && company && company.name} />
                  
                  {company && company.certification ? (
                    <CertifiedBadge company={company} />
                  ) : (
                    company?.isGuideOpen && <StartGuideButton />
                  )}
                  {!companyReader && company && !company.certification && !notificationsIsLoading ? <MyToDos /> : null}
                  {companyAdmin && !nonValidatedUsersIsLoading && pendingNonValidatedUsersExist && <NewUsers />}
                  {calendars?.length > 0 ? <MyActivities calendarId={calendars[0].id} /> : null}
                  <MyNews />
                </LayoutColumns>
            )}
          </div>
          <MyFavourites />
        </>
      ) : user ? (
        <SubHeader>
          <StartIntro classNs="start-banner" user={user} role={role} />
        </SubHeader>
      ): currentNonValidatedUser ? (
        <SubHeader>
          <StartIntro classNs="start-banner" user={currentNonValidatedUser} role={role} />
        </SubHeader>
      ): (
        <Loading />
      )}
    </>
  );
};

export default Start;

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import TableEditorContainer from './table-editor.container';

import { convertRawContentToTableState, convertTableStateToRawContent } from '../../utils/editor';

import { updateSectionDetails } from '../../actions/section-actions';

const CLASS_NS = 'module-table';

const ModuleTable = ({ classNs = CLASS_NS, module = {} }) => {
  const dispatch = useDispatch();
  const onChange = (table, prevChange) => {
    if (!prevChange) {
      return;
    }
    dispatch(
      updateSectionDetails(module.id, {
        tableContent: convertTableStateToRawContent(table)
      })
    );
  };

  return (
    <div className={`${classNs}`}>
      <TableEditorContainer
        tableContent={convertRawContentToTableState(module.tableContent)}
        onChange={onChange}
        onContentHeightChanged={() => {}}
        isSubmitted={false}
      />
    </div>
  );
};

ModuleTable.propTypes = {
  classNs: PropTypes.string,
  module: PropTypes.object // Todo replace with moduleDataType,
};

export default ModuleTable;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { loadGuide } from "utils/guide";
import { getGuideMeta } from "actions/guide-actions";

const useGuideById = (id) => {
  const dispatch = useDispatch();

  const [guide, setGuide] = useState({
    guide: null,
    guideLoaded: false,
  });

  useEffect(() => {
    if (!id) {
      setGuide({
        guide: null,
        guideLoaded: true,
      });
    } else {
      const fetchGuide = async () => {
        const guideMetaData = await dispatch(getGuideMeta(id));
        setGuide({
          guide: guideMetaData,
          guideLoaded: true,
        });
      };
      fetchGuide();
    }
  }, [id, dispatch]);

  return guide;
};

export default useGuideById;

import Workbook from "./components/Workbook";
import Workbooks from "./components/Workbooks";
import Passthrough from "routes/Passthrough";

import { UserIsAuthenticated } from "utils/auth-helpers";

const WorkbooksRoute = () => ({
  path: "/workbooks",
  component: Passthrough,
  routes: [
    {
      path: "/workbooks/:workbookSlug/:partId?/:chapterId?", // include the additional slug for testing current route
      component: UserIsAuthenticated(Workbook),
      exact: true,
    },
    // {
    //   path: "/workbooks/:id", // include the additional slug for testing current route
    //   component: UserIsAuthenticated(Workbook),
    //   exact: true,
    // },
    {
      path: "/workbooks", // include the additional slug for testing current route
      component: UserIsAuthenticated(Workbooks),
      exact: true,
    },
  ],
});

export default WorkbooksRoute;

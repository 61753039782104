import React, { useState, useEffect } from "react";
import __ from "constants/static-texts";
import { renderRoutes } from "react-router-config";
import { Link } from "react-router-dom";
import SubHeader from "components/SubHeader/SubHeader";
import CalendarList from "./CalendarList";
import { calendarType } from "constants/calendar-types";
import CalendarsRoute from "routes/Calendars";
import useLoggedInUser from "hooks/use-logged-in-user";
import useCompanies from "hooks/use-companies";
import useCalendars from "hooks/use-calendars";

const CLASS_NS = "calendars";

const Calendars = ({ route, history, classNs = CLASS_NS }) => {
  const { isServiceAdmin } = useLoggedInUser();
  const { activeCompany } = useCompanies();

  const { calendars, calendarsIsLoading } = useCalendars();
  const [filteredCalendars, setFilteredCalendars] = useState([]);

  useEffect(() => {
    if (activeCompany) {
      setFilteredCalendars(calendars.filter((calendar) => calendar.companyId === activeCompany.id || false));
    } else if (isServiceAdmin) {
      setFilteredCalendars(calendars.filter((calendar) => calendar.calendarType === calendarType.bluePrint));
    } else {
      setFilteredCalendars(calendars);
    }
  }, [calendars]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filteredCalendars?.length === 1 && activeCompany) {
      history.push(`${CalendarsRoute().path}/calendar/${filteredCalendars[0].id}`);
    }
  }, [filteredCalendars]); // eslint-disable-line react-hooks/exhaustive-deps

  return !calendarsIsLoading ? (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>
          {isServiceAdmin && !activeCompany ? __.calendars.titleAdmin : __.calendars.title}
        </h1>
        <p className={`${classNs}_description`}>
          {isServiceAdmin && !activeCompany ? __.calendars.textAdmin : __.calendars.text}
        </p>
      </SubHeader>
      <div className={`${classNs}`}>
        {(isServiceAdmin && !activeCompany) || filteredCalendars?.length ? (
          <>
            {isServiceAdmin && !activeCompany && (
              <Link to={`${route.path}/edit/new`} className={`${classNs}_add`}>
                {__.calendars.createNew}
              </Link>
            )}
            <CalendarList classNs={classNs} calendars={filteredCalendars} editable={isServiceAdmin && !activeCompany} />
            {renderRoutes(route.routes, {
              cancelUrl: route.path,
            })}
          </>
        ) : (
          <p>{__.calendars.noCalendars}</p>
        )}
      </div>
    </>
  ) : null;
};

export default Calendars;

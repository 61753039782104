import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useSelector, useDispatch } from 'react-redux';

import { getMatrixIndex } from '../../utils/editor';

import ContentEditor from '../content-editor/content-editor';
// import { typeOfRichContent, getPropsListByContext } from '../../utils/editor';

const arrowIcon = (
  <svg width="16" height="9" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7.733 7.733 1l6.838 6.733"
      stroke-width="2"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const TableEditorCell = ({
  tools = [], // TODO: Consider passing this down as a context which is set in DraftEditor and is configurable
  tableContent = {},
  tableMatrix = [],
  cellContent = '',
  onChange = () => {},
  merge = () => {},
  isSubmitted = false,
  editTableMode = false,
  rowIndex = 0,
  colIndex = 0
}) => {
  //Akward case when creating empty string and stringify and parsing it
  const [editorData] = useState(cellContent === '""' ? '' : cellContent);

  if (!editTableMode) {
    return (
      <ContentEditor
        editorStateData={editorData}
        onChange={onChange}
        tools={tools}
        showCountBar={false}
        stickyToolbar={false}
      />
    );
  } else {
    const matrixIndex = getMatrixIndex(rowIndex, colIndex, tableMatrix);
    const currentItem = tableContent.rows[rowIndex].cols[colIndex];
    const currentRowspan = currentItem.rowspan ? currentItem.rowspan : 1;
    const currentColspan = currentItem.colspan ? currentItem.colspan : 1;

    const canMergeUp = () => {
      if (rowIndex > 0) {
        const cellAbove = tableMatrix[matrixIndex.rowId - 1][matrixIndex.colId];
        const aboveItem =
          tableContent.rows[cellAbove.tableContentRow].cols[cellAbove.tableContentCol];
        const matrixIndexAbove = getMatrixIndex(
          cellAbove.tableContentRow,
          cellAbove.tableContentCol,
          tableMatrix
        );
        if (aboveItem && currentItem) {
          const colSpanAbove = aboveItem.colspan ? aboveItem.colspan : 1;
          return colSpanAbove === currentColspan && matrixIndex.colId === matrixIndexAbove.colId;
        }
      }
      return false;
    };
    const canMergeDown = () => {
      if (rowIndex < tableMatrix.length - 1) {
        const cellIndexBelow = matrixIndex.rowId + currentRowspan;
        if (cellIndexBelow > tableMatrix.length - 1) {
          return false;
        }
        const cellBelow = tableMatrix[cellIndexBelow][matrixIndex.colId];
        const belowItem =
          tableContent.rows[cellBelow.tableContentRow].cols[cellBelow.tableContentCol];
        const matrixIndexBelow = getMatrixIndex(
          cellBelow.tableContentRow,
          cellBelow.tableContentCol,
          tableMatrix
        );
        if (belowItem && currentItem) {
          const colSpanBelow = belowItem.colspan ? belowItem.colspan : 1;
          return colSpanBelow === currentColspan && matrixIndex.colId === matrixIndexBelow.colId;
        }
      }
      return false;
    };
    const canMergeLeft = () => {
      if (colIndex > 0) {
        const cellLeft = tableMatrix[matrixIndex.rowId][matrixIndex.colId - 1];
        const leftItem = tableContent.rows[cellLeft.tableContentRow].cols[cellLeft.tableContentCol];
        const matrixIndexLeft = getMatrixIndex(
          cellLeft.tableContentRow,
          cellLeft.tableContentCol,
          tableMatrix
        );
        if (leftItem && currentItem) {
          const rowSpanLeft = leftItem.rowspan ? leftItem.rowspan : 1;
          return rowSpanLeft === currentRowspan && matrixIndex.rowId === matrixIndexLeft.rowId;
        }
      }
      return false;
    };
    const canMergeRight = () => {
      if (colIndex < tableMatrix[0].length - 1) {
        const cellIndexRight = matrixIndex.colId + currentColspan;
        if (cellIndexRight > tableMatrix[0].length - 1) {
          return false;
        }
        const cellRight = tableMatrix[matrixIndex.rowId][cellIndexRight];
        const rightItem =
          tableContent.rows[cellRight.tableContentRow].cols[cellRight.tableContentCol];
        const matrixIndexRight = getMatrixIndex(
          cellRight.tableContentRow,
          cellRight.tableContentCol,
          tableMatrix
        );
        if (rightItem && currentItem) {
          const rowSpanLeft = rightItem.rowspan ? rightItem.rowspan : 1;
          return rowSpanLeft === currentRowspan && matrixIndex.rowId === matrixIndexRight.rowId;
        }
      }
      return false;
    };
    return (
      <div className="cell-editor_edit-table-mode">
        <div className="cell-editor_edit-table-mode_center">
          {canMergeUp() && (
            <div
              className="cell-editor_edit-table-mode_arrow cell-editor_edit-table-mode_arrow_up"
              onClick={merge.bind(null, 'up')}
            >
              {arrowIcon}
            </div>
          )}
        </div>
        <div className="cell-editor_edit-table-mode_sides">
          {canMergeLeft() && (
            <div
              className="cell-editor_edit-table-mode_arrow cell-editor_edit-table-mode_arrow_left"
              onClick={merge.bind(null, 'left')}
            >
              {arrowIcon}
            </div>
          )}
          {canMergeRight() && (
            <div
              className="cell-editor_edit-table-mode_arrow cell-editor_edit-table-mode_arrow_right"
              onClick={merge.bind(null, 'right')}
            >
              {arrowIcon}
            </div>
          )}
        </div>
        <div className="cell-editor_edit-table-mode_center">
          {canMergeDown() && (
            <div
              className="cell-editor_edit-table-mode_arrow cell-editor_edit-table-mode_arrow_down"
              onClick={merge.bind(null, 'down')}
            >
              {arrowIcon}
            </div>
          )}
        </div>
      </div>
    );
  }
};
TableEditorCell.propTypes = {
  tableContent: PropTypes.object,
  tableMatrix: PropTypes.array,
  cellContent: PropTypes.string,
  onChange: PropTypes.func,
  merge: PropTypes.func,
  isSubmitted: PropTypes.bool,
  editTableMode: PropTypes.bool,
  rowIndex: PropTypes.number,
  colIndex: PropTypes.number
};

export default TableEditorCell;

import React from "react";
import __ from "constants/static-texts";

const CLASS_NS = "filter";

const Filter = ({
  classNs = CLASS_NS,
  onChange,
  filterAlternatives = [],
  active,
  defaultAlternative = __.filter.all,
}) => {
  return (
    <div className={`${classNs}`}>
      <button onClick={() => onChange(null)} className={`${classNs}_alternative`} data-active={active === null}>
        {defaultAlternative}
      </button>
      {filterAlternatives.map((alternative) => (
        <button
          onClick={() => onChange(alternative.id)}
          className={`${classNs}_alternative`}
          key={alternative.id}
          data-active={active === alternative.id}>
          {alternative.name}
        </button>
      ))}
    </div>
  );
};

export default Filter;

import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Overlay } from "react-draft-editor";

import SupervisorsRoute from "routes/Supervisors";

import { addUser } from "actions/users-actions";

import useForm from "hooks/use-form";
import useUsers from "hooks/use-users";

import { USER_TYPES } from "constants/user-types";
import __ from "constants/static-texts";

const CLASS_NS = "form_overlay";
const AddSupervisor = ({ cancelUrl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { supervisorId } = useParams();

  const { userRoles } = useUsers();
  const supervisorRole = userRoles.find((role) => role.name === USER_TYPES.SUPERVISOR.name);

  const isNew = supervisorId === "new";

  const initialValue = {
    userName: "",
    role: supervisorRole.id,
  };
  const currentSupervisor = initialValue;

  const { inputs, handleChange } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...currentSupervisor,
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    let user;
    if (isNew) {
      user = await dispatch(addUser({ ...inputs }));
    }

    !!user ? history.push(`${SupervisorsRoute().path}/${user.id}/edit`) : history.push(cancelUrl);
  };

  return (
    <Overlay cancelUrl={cancelUrl}>
      <h2>{__.supervisors.add}</h2>
      <form onSubmit={handleSubmit} className={CLASS_NS}>
        <label className={`${CLASS_NS}_form-row`} htmlFor="userName">
          <span className="label">{__.supervisors.email}</span>
          <input
            type="text"
            id="userName"
            name="userName"
            placeholder={__.placeholder.email}
            value={inputs.userName}
            onChange={handleChange}
          />
        </label>
        <button type="submit" className={`${CLASS_NS}_button`}>
          {isNew ? `Lägg till` : `Uppdatera`}
        </button>
      </form>
    </Overlay>
  );
};

export default AddSupervisor;

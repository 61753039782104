export const INLINE_TOOLS = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  LINK: 'link'
};

export const LIST_TOOLS = {
  UNORDERED_LIST: 'unordered-list',
  UNORDERED_LIST_DASH: 'unordered-list-dash',
  ORDERED_LIST: 'ordered-list',
  ORDERED_LIST_ABC: 'ordered-list-abc'
};

export const BLOCK_TOOLS = {
  HEADER_TWO: 'header-two',
  HEADER_THREE: 'header-three',
  ...LIST_TOOLS
};

export const ATOMIC_TOOLS = {
  FORMULA: 'formula',
  VIDEO: 'video',
  IMAGE: 'image'
};

export const AUXILIARY_TOOLS = {
  UNDO: 'undo',
  COMMENT: 'comment'
};

export const EDITOR_TOOLS = {
  ...INLINE_TOOLS,
  ...BLOCK_TOOLS,
  ...ATOMIC_TOOLS,
  ...AUXILIARY_TOOLS
};

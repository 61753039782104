import fetchClient from "fetch-client";

export const getBucEvents = (getState) =>
  fetchClient({
    path: `/api/events/get/?show=bkma`,
    apiBase: `https://dev.buc.se`,
    contentType: "form",
    skipAuthorization: true,
    getState,
  });

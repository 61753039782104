import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

import { saveAs } from "file-saver";

const initialState = {
  documents: [],
  documentsMap: {},
  documentsNetworkState: WAITING,
  documentAddNetworkState: WAITING,
  companyDocument: null,
  companyDocumentNetworkState: WAITING,
  companyDocumentFavouriteNetworkState: WAITING,
  error: null,
  activeDownload: null,
  activeCompanyVersionDownloadFilename: `untitled.unknown`,
  documentDownloadNetworkState: WAITING,
  archiveDownloadNetworkState: WAITING,
  companyDocumentVersionDeleteNetworkState: WAITING,
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPANY_DOCUMENTS_LOADING:
      return {
        ...state,
        documentsNetworkState: LOADING,
      };
    case types.GET_COMPANY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        documentsMap: action.payload.reduce((acc, doc) => {
          acc[doc.id] = doc;
          return acc;
        }, {}),
        documentsNetworkState: SUCCESS,
      };
    case types.GET_COMPANY_DOCUMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        documentsNetworkState: ERROR,
      };

    case types.GET_COMPANY_DOCUMENT_LOADING:
      return {
        ...state,
        companyDocumentNetworkState: LOADING,
      };
    case types.GET_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        companyDocument: action.payload,
        companyDocumentNetworkState: SUCCESS,
      };
    case types.GET_COMPANY_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        companyDocumentNetworkState: ERROR,
      };
    case types.ADD_COMPANY_DOCUMENT_LOADING:
      return {
        ...state,
        documentAddNetworkState: LOADING,
      };
    case types.ADD_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.concat(action.payload),
        documentsMap: { ...state.documentsMap, [action.payload.id]: action.payload },
        documentAddNetworkState: SUCCESS,
      };
    case types.ADD_COMPANY_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentAddNetworkState: ERROR,
      };
    case types.UPDATE_COMPANY_DOCUMENT_LOADING:
      return {
        ...state,
        documentAddNetworkState: LOADING,
      };
    case types.UPDATE_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.map((doc) => {
          if (doc.id === action.payload.id) {
            return action.payload;
          } else {
            return doc;
          }
        }),
        companyDocument:
          state.companyDocument?.id === action.payload.id
            ? { ...state.companyDocument, ...action.payload }
            : state.companyDocument,
        documentsMap: { ...state.documentsMap, [action.payload.id]: action.payload },
        documentAddNetworkState: SUCCESS,
      };
    case types.UPDATE_COMPANY_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentAddNetworkState: ERROR,
      };
    case types.DELETE_COMPANY_DOCUMENT_LOADING:
      return {
        ...state,
        documentDeleteNetworkState: LOADING,
      };
    case types.DELETE_COMPANY_DOCUMENT_SUCCESS:
      const newDocumentsMap = { ...state.documentsMap };
      delete newDocumentsMap[action.payload.id];

      return {
        ...state,
        documents: state.documents.filter((document) => document.id !== action.payload.id),
        documentsMap: newDocumentsMap,
        documentDeleteNetworkState: SUCCESS,
      };
    case types.DELETE_COMPANY_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        documentDeleteNetworkState: ERROR,
      };
    case types.DELETE_COMPANY_DOCUMENT_VERSION_LOADING:
      return {
        ...state,
        companyDocumentVersionDeleteNetworkState: LOADING,
      };
    case types.DELETE_COMPANY_DOCUMENT_VERSION_SUCCESS:
      return {
        ...state,
        companyDocument: {
          ...state.companyDocument,
          versions: state.companyDocument.versions.filter((version) => version.id !== action.payload),
        },
        companyDocumentVersionDeleteNetworkState: SUCCESS,
      };
    case types.DELETE_COMPANY_DOCUMENT_VERSION_ERROR:
      return {
        ...state,
        error: action.payload,
        companyDocumentVersionDownloadNetworkState: ERROR,
      };

    case types.FAVOURITE_COMPANY_DOCUMENT_LOADING:
      return {
        ...state,
        companyDocumentFavouriteNetworkState: LOADING,
      };
    case types.FAVOURITE_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.map((doc) => {
          if (doc.id === action.payload.id) {
            return action.payload;
          } else {
            return doc;
          }
        }),
        companyDocumentFavouriteNetworkState: SUCCESS,
      };
    case types.FAVOURITE_COMPANY_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        companyDocumentFavouriteNetworkState: ERROR,
      };

    case types.SET_ACTIVE_COMPANY_DOWNLOAD:
      return {
        ...state,
        activeDownload: action.file,
      };
    case types.SET_ACTIVE_COMPANY_VERSION_DOWNLOAD:
      return {
        ...state,
        activeCompanyVersionDownloadFilename: action.filename,
      };

    case types.DOWNLOAD_COMPANY_FILE_LOADING:
      return {
        ...state,
        documentDownloadNetworkState: LOADING,
      };
    case types.DOWNLOAD_COMPANY_FILE_SUCCESS:
      const fileName = !state.activeDownload ? `untitled.unknown` : state.activeDownload?.fileName;
      saveAs(action.payload, fileName);
      return {
        ...state,
        documentDownloadNetworkState: SUCCESS,
      };
    case types.DOWNLOAD_COMPANY_FILE_ERROR:
      return {
        ...state,
        error: action.payload,
        documentDownloadNetworkState: ERROR,
      };

    case types.DOWNLOAD_COMPANY_DOCUMENT_VERSION_LOADING:
      return {
        ...state,
        companyDocumentVersionDownloadNetworkState: LOADING,
      };
    case types.DOWNLOAD_COMPANY_DOCUMENT_VERSION_SUCCESS:
      saveAs(action.payload, state.activeCompanyVersionDownloadFilename);
      return {
        ...state,
        companyDocumentVersionDownloadNetworkState: SUCCESS,
        activeCompanyVersionDownloadFilename: "untitled.unknown",
      };
    case types.DOWNLOAD_COMPANY_DOCUMENT_VERSION_ERROR:
      return {
        ...state,
        error: action.payload,
        companyDocumentVersionDownloadNetworkState: ERROR,
        activeCompanyVersionDownloadFilename: "untitled.unknown",
      };

    case types.EXPORT_GUIDE_LOADING:
      return {
        ...state,
        archiveDownloadNetworkState: LOADING,
      };
    case types.EXPORT_GUIDE_SUCCESS:
      const zipName = `${action.params.filename}.zip`;
      saveAs(action.payload, zipName);
      return {
        ...state,
        archiveDownloadNetworkState: SUCCESS,
      };
    case types.EXPORT_GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        archiveDownloadNetworkState: ERROR,
      };

    default:
      return state;
  }
};

export default documentsReducer;

import * as calendarApi from "api/calendar-api";
import { createActionSequence } from "./action-helpers";

export const getCalendars = createActionSequence("GET_CALENDARS", calendarApi.getCalendars);

export const getCalendarInstance = (calendarId) =>
  createActionSequence("GET_CALENDAR_INSTANCE", calendarApi.getCalendarInstance)(calendarId);

export const addCalendarInstance = (data) =>
  createActionSequence("ADD_CALENDAR_INSTANCE", calendarApi.addCalendarInstance)(data);

export const addCalendarActivity = (data) =>
  createActionSequence("ADD_CALENDAR_ACTIVITY", calendarApi.addCalendarActivity)(data);

export const updateCalendarActivity = (data) =>
  createActionSequence("UPDATE_CALENDAR_ACTIVITY", calendarApi.updateCalendarActivity)(data);

export const deleteCalendarActivity = createActionSequence(
  "DELETE_CALENDAR_ACTIVITY",
  calendarApi.deleteCalendarActivity
);

export const addCalendar = (data) => createActionSequence("ADD_CALENDAR", calendarApi.addCalendar)(data);
export const updateCalendar = (data) => createActionSequence("UPDATE_CALENDAR", calendarApi.updateCalendar)(data);
export const deleteCalendar = createActionSequence("DELETE_CALENDAR", calendarApi.deleteCalendar);

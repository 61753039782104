import React from 'react';
import PropTypes from 'prop-types';
import './_ButtonLink.scss';

const ButtonLink = ({ icon, type, children, size, variant, state, alignment, ...props }) => {
  return (
    <button
      className="button-link"
      type={type}
      {...props}
      data-size={size}
      data-variant={variant}
      data-state={state}
      data-alignment={alignment}>
      {icon}
      {children}
    </button>
  );
};

ButtonLink.propTypes = {
  icon: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'dynamic']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  state: PropTypes.oneOf(['idle']),
  variant: PropTypes.oneOf(['link', 'action', 'signal', 'neutral', 'dynamic']),
  idNs: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
};

ButtonLink.defaultProps = {
  type: 'button',
  size: 'medium',
  variant: 'link',
  alignment: 'left',
};

export default ButtonLink;

import React from "react";
import "./_ProgressBar.scss";

const CLASS_NS = "progress-bar";

export const ProgressBar = ({ classNs = CLASS_NS, progress, variant = "primary" }) => {
  return (
    <div className={`${classNs}`} data-variant={variant}>
      <div className={`${classNs}_percent`} style={{ width: `${(progress.done / progress.total) * 100 || 1}%` }}></div>
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import __ from 'constants/static-texts';

import Users from 'routes/Users';
import { ReactComponent as Pencil } from 'icons/Pencil.svg';

import { USER_TYPES } from 'constants/user-types';

import useCompanies from 'hooks/use-companies';
import useLoggedInUser from 'hooks/use-logged-in-user';

const UserListItem = ({ classNs, user, userRoles, type }) => {
  const userRole =
    userRoles &&
    userRoles.find((userRole) => {
      return userRole.id === user.role;
    });

  const { isMultiCompanyUser, isServiceAdmin, isCompanyAdmin } = useLoggedInUser();
  const { activeCompany } = useCompanies();
  const multipleCompanies = user.companyNames?.length > 1;
  const isNonVAlidatedUsers = !!user.companyValidationStatuses;
  const editPath = `${Users().path}/${user.id}/edit${isNonVAlidatedUsers ? '-non-validated' : ''}`;
  const pending = type === 'pending';

  const companyNames = (companies) => {
    return isMultiCompanyUser && activeCompany ? (
      <span>
        {activeCompany.name}
        {companies.length > 1 && ` (+ ${companies.length - 1} företag )`}
      </span>
    ) : companies.length > 1 ? (
      <span>
        {companies[0].name} (+{' '}
        <Link className={`link`} to={`${Users().path}/${user.id}/companies`}>
          {companies.length - 1} företag
        </Link>
        )
      </span>
    ) : companies.length === 1 ? (
      companies[0].name
    ) : (
      ''
    );
  };

  return (
    <li className={`${classNs}_list_item${isNonVAlidatedUsers ? '_nonvalidated' : ''}`}>
      <div>
        {!user.firstName && !user.lastName ? (
          <span className="not-set">{__.users.neverLoggedIn}</span>
        ) : (
          `${user.firstName} ${user.lastName}`
        )}
      </div>
      <div>{user.userName}</div>
      <div>{user.companyNames && companyNames(user.companyNames)}</div>
      {!isNonVAlidatedUsers && <div>{__.usertypes[userRole?.name]}</div>}
      <div className={`${classNs}_edit`}>
        {(multipleCompanies && isServiceAdmin) ||
        (!multipleCompanies && isCompanyAdmin) ||
        (!multipleCompanies && user.role !== USER_TYPES.SERVICEADMIN.id) ? (
          <Link
            className={pending ? `${classNs}_button` : null}
            to={{ pathname: editPath, state: { disableScroll: true } }}>
            {!pending ? <Pencil className="icon pencil" /> : __.users.stateRole}
          </Link>
        ) : null}
      </div>
    </li>
  );
};

export default UserListItem;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CONTEXT_TYPES = ['page', 'inline'];
const CLASS_NS = 'module-selector';

// TODO: Convert the list to a selector and send an event instead of a bound key type
const ModuleSelector = ({
  classNs = CLASS_NS,
  onAddModule = () => {},
  blockTypes = [],
  contextType = CONTEXT_TYPES[0],
  buttonText,
  sectionDepth,
  disabled = false // TODO: implement disabling of button
}) => {

  const [isActive, setIsActive] = useState(false);

  const onToggle = () => {
    setIsActive(!isActive);
  };

  const getModuleIconElement = (module) => {
    const ModuleIcon = module.icon;
    return (
      <span className={`${classNs}_module_icon`}>
        <ModuleIcon />
      </span>
    );
  };

  return (
    <div className={`${classNs}`} data-active={isActive} data-context={contextType} data-depth={sectionDepth}>
      <div className={`${classNs}_overlay`} onClick={onToggle} data-active={isActive} />
      <button
        type="button"
        className={`${classNs}_toggle-button`}
        onClick={onToggle}
        aria-label="Lägg till modul"
      >
        <span className={`${classNs}_toggle-button_icon`} />
        {buttonText}
      </button>
      <div className={`${classNs}_module-list`} onClick={onToggle} data-active={isActive}>
        {blockTypes.map((moduleType) => (
          <button
            key={moduleType.key}
            className={`${classNs}_module`}
            onClick={onAddModule.bind(null, moduleType)}
          >
            <span className={`${classNs}_module_label`}>{moduleType.name}</span>
            {module.icon === 'string' ? (
              <span
                className={`${classNs}_module_icon`}
                dangerouslySetInnerHTML={{ __html: module.icon }}
              ></span>
            ) : (
              getModuleIconElement(moduleType)
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

ModuleSelector.propTypes = {
  classNs: PropTypes.string,
  onAddModule: PropTypes.func,
  blockTypes: PropTypes.array,
  contextType: PropTypes.string,
  buttonText: PropTypes.string
};
export default ModuleSelector;

import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  comments: [],
  commentsNetworkState: WAITING,
  addCommentNetworkState: WAITING,
  deleteCommentNetworkState: WAITING,

  error: null,
};

const commentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_COMMENTS_LOADING:
      return {
        ...state,
        commentsNetworkState: LOADING,
      };
    case types.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: payload,
        commentsNetworkState: SUCCESS,
      };
    case types.GET_COMMENTS_ERROR:
      return {
        ...state,
        error: payload,
        commentsNetworkState: ERROR,
      };

    case types.ADD_COMMENT_LOADING:
      return {
        ...state,
        addCommentNetworkState: LOADING,
      };
    case types.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        comments: state.comments.concat(action.payload),
        addCommentNetworkState: SUCCESS,
      };
    case types.ADD_COMMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        addCommentNetworkState: ERROR,
      };

    case types.DELETE_COMMENT_LOADING:
      return {
        ...state,
        deleteCommentNetworkState: LOADING,
      };
    case types.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: state.comments.filter((guide) => guide.id !== action.payload),
        deleteCommentNetworkState: SUCCESS,
      };
    case types.DELETE_COMMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        deleteCommentNetworkState: ERROR,
      };

    default:
      return state;
  }
};

export default commentsReducer;

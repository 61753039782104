export const TEMP_ID = 'tempId';
// Base key-values which need to be present in a block
export const baseSchema = {
  id: 'id',
  parentId: 'parentId',
  type: 'type',
  index: 'index',
  sections: 'sections',
  [TEMP_ID]: TEMP_ID
};

// Incoming=>Outgoing
export const navigationSectionSchema = {
  id: 'id',
  index: 'index',
  title: 'label'
};

import * as types from "actions/action-types";
import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";
const initialState = {
    nonValidatedUsers: [],
    nonValidatedUser: null,
    currentNonValidatedUser: null,
    nonValidatedUsersNetworkState: WAITING,
    nonValidatedUserNetworkState: WAITING,
    updateNonValidatedUserNetworkState: WAITING,
    blockedNonValidatedUserNetworkState: WAITING,
    currentNonValidatedUserNetworkState: WAITING,
    
    error: null,
};

const nonValidatedUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        // GET ALL NON VALIDATED USERS
        case types.GET_NON_VALIDATED_USERS_LOADING:
            return {
                ...state,
                nonValidatedUsersNetworkState: LOADING,
            };

        case types.GET_NON_VALIDATED_USERS_SUCCESS:
            return {
                ...state,
                nonValidatedUsers: action.payload,
                nonValidatedUsersNetworkState: SUCCESS,
            };

        case types.GET_NON_VALIDATED_USERS_ERROR:
            return {
                ...state,
                error: action.payload,
                nonValidatedUsersNetworkState: ERROR,
            };

        // GET A NON VALIDATED USER
        case types.GET_NON_VALIDATED_USER_LOADING:
            return {
                ...state,
                nonValidatedUserNetworkState: LOADING
            };

        case types.GET_NON_VALIDATED_USER_SUCCESS:
            return {
                ...state,
                nonValidatedUser: action.payload,
                nonValidatedUserNetworkState: SUCCESS
            };

        case types.GET_NON_VALIDATED_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                nonValidatedUserNetworkState: ERROR
            };

        // UPDATE A NON VALIDATED USERS
        case types.APPROVE_NON_VALIDATED_USER_LOADING:
            return {
                ...state,
                updateNonValidatedUserNetworkState: LOADING
            };

        case types.APPROVE_NON_VALIDATED_USER_SUCCESS:
            return {
                ...state,
                updateNonValidatedUserNetworkState: SUCCESS
            };

        case types.APPROVE_NON_VALIDATED_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                updateNonValidatedUserNetworkState: ERROR
            };

        // BLOCK A NON VALIDATED USER
       case types.BLOCK_NON_VALIDATED_USER_LOADING:
            return {
                ...state,
                blockedNonValidatedUserNetworkState: LOADING
            };
        case types.BLOCK_NON_VALIDATED_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                blockedNonValidatedUserNetworkState: ERROR,
              };
        case types.BLOCK_NON_VALIDATED_USER_SUCCESS: 
            return {
                ...state,
                blockedNonValidatedUserNetworkState: SUCCESS,
            }; 

        // GET CURRENT NON VALIDATED USER
        case types.GET_CURRENT_NON_VALIDATED_USER_LOADING:
            return {
                ...state,
                currentNonValidatedUserNetworkState: LOADING,
            };
        case types.GET_CURRENT_NON_VALIDATED_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                currentNonValidatedUserNetworkState: ERROR,
            };
        case types.GET_CURRENT_NON_VALIDATED_USER_SUCCESS:
            return {
                ...state,
                currentNonValidatedUser: action.payload,
                currentNonValidatedUserNetworkState: SUCCESS,
            };
        
        default:
            return state;
    }
}

export default nonValidatedUsersReducer;

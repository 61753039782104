export const guideType = {
  bluePrint: "Blueprint",
  instance: "Instance",
};

export const guideSectionTypes = {
  GUIDE_PART: "guide-part",
  GUIDE_SECTION: "guide-section",
  GUIDE_MAIN_REQUIREMENT: "guide-mainRequirement",
  GUIDE_REQUIREMENT: "guide-requirement",
  GUIDE_RESPONSE_SLOT: "guide-responseSlot",
};

export const guideTypeHierarchy = {
  type: guideSectionTypes.GUIDE_PART,
  children: [
    {
      type: guideSectionTypes.GUIDE_SECTION,
      children: [
        {
          type: guideSectionTypes.GUIDE_MAIN_REQUIREMENT,
          children: [
            {
              type: guideSectionTypes.GUIDE_REQUIREMENT,
              children: [
                {
                  type: guideSectionTypes.GUIDE_RESPONSE_SLOT,
                  children: []
                }
              ]
            }
          ]
        },
      ]
    }
  ]
};
import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  bucEvents: [],
  bucNetworkState: WAITING,
  error: null,
};

const bucReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_BUC_EVENTS_SUCCESS:
      return {
        ...state,
        bucEvents: payload
          .filter((event) => event.time_start)
          .map((event) => {
            event.bkmaType = "buc";
            return event;
          }),
        bucNetworkState: SUCCESS,
      };
    case types.GET_BUC_EVENTS_LOADING:
      return {
        ...state,
        bucNetworkState: LOADING,
      };
    case types.GET_BUC_EVENTS_ERROR:
      return {
        ...state,
        error: payload,
        bucNetworkState: ERROR,
      };
    default:
      return state;
  }
};

export default bucReducer;

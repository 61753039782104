import { useDispatch } from "react-redux";
import { userWillSignout } from "actions/auth-actions";
import { userManager } from "store";

const useSession = () => {
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(userWillSignout());
    userManager
      .signoutRedirect()
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  return { logoutHandler };
};

export default useSession;

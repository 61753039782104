import useLoggedInUser from "./use-logged-in-user";

const useTextByRole = (text) => {
  const { isServiceAdmin, isCompanyAdmin, isCompanyEditor, isCompanyReader, isSupervisor } = useLoggedInUser();

  const mapText = (role) => text[role] || text["default"] || "";

  if (isServiceAdmin) {
    return mapText("serviceAdmin");
  }

  if (isCompanyAdmin) {
    return mapText("companyAdmin");
  }

  if (isCompanyEditor) {
    return mapText("companyEditor");
  }

  if (isCompanyReader) {
    return mapText("companyReader");
  }

  if (isSupervisor) {
    return mapText("supervisor");
  }

  return mapText("default");
};

export default useTextByRole;

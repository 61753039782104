import * as librarydocumentsApi from "api/librarydocuments-api";
import { createActionSequence } from "./action-helpers";
import * as types from "actions/action-types";
import { push } from "connected-react-router";

export const getDocuments = createActionSequence("GET_DOCUMENTS", librarydocumentsApi.getDocuments);

export const getLibraryDocument = (id) =>
  createActionSequence("GET_LIBRARY_DOCUMENT", librarydocumentsApi.getLibraryDocument)(id);

export const addLibraryDocument = (data, url) => (dispatch) => {
  return dispatch(createActionSequence("ADD_DOCUMENT", librarydocumentsApi.addDocument)(data)).then((res) => {
    if (res) {
      dispatch(push(url, { disableScroll: true }));
    }
  });
};

export const updateLibraryDocument = (data, url) => (dispatch) => {
  return dispatch(createActionSequence("UPDATE_DOCUMENT", librarydocumentsApi.updateDocument)(data)).then((res) => {
    if (res) {
      dispatch(push(url, { disableScroll: true }));
    }
  });
};

export const deleteDocument = createActionSequence("DELETE_DOCUMENT", librarydocumentsApi.deleteDocument);
export const deleteLibraryDocumentVersion = (documentId, versionId) =>
  createActionSequence("DELETE_LIBRARY_DOCUMENT_VERSION", librarydocumentsApi.deleteLibraryDocumentVersion)(
    documentId,
    versionId
  );

export const favouriteDocument = createActionSequence("FAVOURITE_DOCUMENT", librarydocumentsApi.favouriteDocument);

export const setSearchQuery = (value) => ({
  type: types.SET_SEARCH_QUERY,
  value,
});

export const setFilterCategory = (value) => ({
  type: types.SET_FILTER_CATEGORY,
  value,
});

const setActiveDownload = (file) => ({
  type: types.SET_ACTIVE_DOWNLOAD,
  file,
});

const startDownload = createActionSequence("DOWNLOAD_FILE", librarydocumentsApi.downloadFile);

export const downloadFile = (file) => (dispatch) => {
  dispatch(setActiveDownload(file));
  dispatch(startDownload(file.id));
};

const setActiveVersionDownload = (filename) => ({
  type: types.SET_ACTIVE_VERSION_DOWNLOAD,
  filename,
});

export const downloadLibraryDocumentVersion = (documentId, versionId, filename) => (dispatch) => {
  dispatch(setActiveVersionDownload(filename));
  dispatch(startDownloadLibraryDocumentVersion(documentId, versionId));
};

const startDownloadLibraryDocumentVersion = (documentId, versionId) =>
  createActionSequence("DOWNLOAD_LIBRARY_DOCUMENT_VERSION", librarydocumentsApi.downloadLibraryDocumentVersion)(
    documentId,
    versionId
  );

export const getDocumentTypes = createActionSequence("GET_DOCUMENT_TYPES", librarydocumentsApi.getDocumentTypes);

import React from "react";
// import { useTranslation } from "react-i18next";

import "./Chapter.scss";
import __ from "constants/static-texts";

import useCurrentChapter from "hooks/useCurrentChapter";
// import useCurrentSection from "hooks/useCurrentSection";

import ChapterFacts from "./ChapterFacts";
// import ChapterIntersection from "components/ChapterIntersection/ChapterIntersection";

const cls = "Chapter";

function Chapter() {
  const { currentChapter } = useCurrentChapter();

  return (
    <article className={cls}>
      <div className={`${cls}__inner`}>
        <header className={`${cls}__intro`}>
          {/* <p className={`${cls}__chapternumber`}>{`${__.workbook.chapter} ${currentChapter.chapterNumber}`}</p> */}
          <h1 className={`${cls}__title`}>{currentChapter.titleRendered}</h1>
          {currentChapter.blbGroupChapterIntro.blbChapterIntroIcon ? (
            <div className={`${cls}__icon`}>
              <img
                src={currentChapter.blbGroupChapterIntro.blbChapterIntroIcon.sourceUrl}
                alt={currentChapter.blbGroupChapterIntro.blbChapterIntroIcon.altText}
              />
            </div>
          ) : null}
          <p className={`${cls}__description`}>{currentChapter.blbGroupChapterIntro.blbChapterIntroContent}</p>
        </header>
        <h2 className={`${cls}__subheading`}>{`${__.workbook.facts_and_reading}`}</h2>

        <div className={`${cls}__facts`}>{<ChapterFacts />}</div>
      </div>
    </article>
  );
}

export default Chapter;

import React from "react";
import __ from "constants/static-texts";

import useTextByRole from "hooks/use-text-by-role";

import SubHeader from "components/SubHeader/SubHeader";
import ToolsList from "./ToolsList";

const CLASS_NS = "tools";

const Users = ({ classNs = CLASS_NS }) => {
  const toolsTitle = useTextByRole(__.tools.servicesAndTools);
  const toolsText = useTextByRole(__.tools.introduction);

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{toolsTitle}</h1>
        <div className={`${classNs}_intro`}>
          {toolsText.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </SubHeader>
      <div className={`${classNs}`}>
        <ToolsList classNs={classNs} />
      </div>
    </>
  );
};

export default Users;

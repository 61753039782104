import * as types from "actions/action-types";

export function statusMessage(data) {
  return {
    type: types.STATUS_MESSAGE,
    payload: data,
  };
}

export function closeStatusMessage() {
  return {
    type: types.CLOSE_STATUS_MESSAGE,
  };
}

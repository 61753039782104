import React, { useState, useEffect } from "react";

import RichText from "../../../components/RichText/RichText";

import { ReactComponent as Plus } from "icons/plus.svg";
import { ReactComponent as Minus } from "icons/Minus.svg";

import __ from "constants/static-texts";

const SupervisorOverviewListItem = ({ classNs, user }) => {
  const localClassNs = `${classNs}_list_item`;

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const [description, setDescription] = useState();
  useEffect(() => {
    setDescription(user.details?.description);
  }, [user.details]);

  return (
    <div className={localClassNs} data-highlight={user.companySupervisor}>
      <div className={`${localClassNs}_top_wrapper`}>
        <div className={`${localClassNs}_wrapper`}>
          <div className={`${localClassNs}_wrapper_image`}>
            {user.details?.hasProfileImage && (
              <img
                src={`${process.env.REACT_APP_API_BASE}/users/${user.id}/profileimage`}
                alt={`${user.firstName} ${user.lastName}`}
              />
            )}
          </div>
          <div className={`${localClassNs}_wrapper_content`}>
            <p className={`${localClassNs}_name`}>
              {user.firstName + " " + user.lastName ?? __.supervisorsOverview.notAvailable}
            </p>
            <p className={`${localClassNs}_company`}>
              {user.supervisorCompany?.legalName ?? __.supervisorsOverview.noCompany}
            </p>
            <p className={`${localClassNs}_link`}>
              <span className={`${localClassNs}_pretext`}>Tel:</span>{" "}
              {user.details?.phoneNumber ?? __.supervisorsOverview.notAvailable}
            </p>
            <a className={`${localClassNs}_link`} href={`mailto:${user.userName}`}>
              <span className={`${localClassNs}_pretext`}>Mail:</span>{" "}
              {user.userName ?? __.supervisorsOverview.notAvailable}
            </a>
          </div>
        </div>
        <div className={`${localClassNs}_wrapper`} data-is-expanded={isExpanded}>
          <div className={`${localClassNs}_wrapper_description`}>
            <RichText classNs={`${localClassNs}_text`} nodes={description ?? __.supervisorsOverview.noDescription} />
          </div>
        </div>
      </div>
      <button className={`${localClassNs}_button_wrapper`} onClick={toggleExpanded}>
        <p className={`${localClassNs}_button_text`}>Läs mer om {user.firstName} </p>
        {isExpanded ? (
          <Minus className={`${localClassNs}_button_icon`} />
        ) : (
          <Plus className={`${localClassNs}_button_icon`} />
        )}
      </button>
    </div>
  );
};

export default SupervisorOverviewListItem;

import React from "react";

import GaAnalytics from "components/GaAnalytics/GaAnalytics";
import MainLayout from "./main-layout";

/**
 * Switcher component to choose layout depending on URL.
 * Curerntly only one layout is needed.
 */
const RootLayout = ({ route }) => {
  return (
    <GaAnalytics>
      <MainLayout routes={route.routes} />
    </GaAnalytics>
  );
};
export default RootLayout;

import SupervisorsOverview from "./components/SupervisorsOverview";

import { UserIsAuthenticated } from "utils/auth-helpers";

const rootPath = "/supervisors-overview";

const SupervisorsOverviewRoute = () => ({
  path: `${rootPath}`,
  component: UserIsAuthenticated(SupervisorsOverview),
});

export default SupervisorsOverviewRoute;

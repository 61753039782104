import React from "react";
import { useDispatch } from "react-redux";

import __ from "constants/static-texts";
import { formatDate } from "utils/date-helpers";
import { ReactComponent as PaperPlane } from "icons/PaperPlane.svg";
import { addComment } from "actions/comments-actions";

import useUsers from "hooks/use-users";
import useCommments from "hooks/use-comments";
import useForm from "hooks/use-form";

const CLASS_NS = "comment";

const Comment = ({ classNs = CLASS_NS, type = "section", commentData }) => {
  const dispatch = useDispatch();

  const { inputs, handleChange, resetForm } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      commentContent: "",
    }
  );

  const id = commentData.id.replace(`guide-${type}_`, ``);
  const { commentIds } = commentData;
  const { filteredComments } = useCommments(commentIds);
  const { usersMap } = useUsers();

  const onCommentSend = (e) => {
    e.preventDefault();

    dispatch(addComment({ ...inputs, [`${type}Id`]: id }));
    resetForm();
  };

  const disabledButton = inputs.commentContent === "";

  const noCommentsExist = !filteredComments.length;

  return (
    <div className={`${classNs}`}>
      <form className={`${classNs}_form`} onSubmit={onCommentSend}>
        <input
          className={`${classNs}_input`}
          value={inputs.commentContent}
          id="commentContent"
          name="commentContent"
          onChange={handleChange}
          placeholder="Skriv en kommentar"
        />
        <button className={`${classNs}_submit`} data-disabled={disabledButton} disabled={disabledButton} type="submit">
          <div className={`${classNs}_submit_icon`}>
            <PaperPlane />
          </div>
        </button>
      </form>
      {noCommentsExist && (
        <div className={`${classNs}_no-comments`}>
          <label>{__.comment.no_comments}</label>
        </div>
      )}

      {filteredComments.map((comment, index) => {
        const user = usersMap[comment.createdByUserId];
        return (
          <div className={`${classNs}_row`} key={index}>
            <label className={`${classNs}_row_label`}>
              {`${user?.firstName || ""} ${user?.lastName || ""} `}
              <span className="date">{`${formatDate(comment.createdAt)}`}</span>
            </label>
            <div className={`${classNs}_row_comment`}>{`${comment.commentContent}`}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Comment;

import { blockTypes } from './block-types';
import { randomDecimal } from '../utils/math';

export const TEMPID_KEY = 'tempId';
export const sectionAdditionalEntries = {
  isValid: true, // default value for validation
  isVisible: true // when wanting to control visibility, i.e. in Subjects
};

export const getSectionData = ({ parentId = null, index = 0, id = randomDecimal() }) => ({
  id: id,
  [TEMPID_KEY]: id,
  parentId: parentId,
  type: blockTypes.SECTION,
  title: '',
  indexed: true,
  label: '',
  index: index,
  ...sectionAdditionalEntries
});

// export const getSubSectionData = ({
//   parentId = null,
//   index = 0,
//   draftId,
//   id = randomDecimal()
// }) => ({
//   id: id,
//   [TEMPID_KEY]: id,
//   title: '',
//   parentId: parentId,
//   index: index,
//   type: blockTypes.SUBSECTION,
//   ...sectionAdditionalEntries
// });

export const getModuleData = ({ parentId, index = 0, type, id = randomDecimal() }) => ({
  id: id,
  [TEMPID_KEY]: id,
  parentId: parentId,
  type: type,
  index: index,
  isValid: true,
  ...sectionAdditionalEntries
});

// TODO: Consider deprecating this.
export const getInitialUploadData = function () {
  return {
    id: 0, // Important that this stays as zero when setting image container.
    name: '',
    description: '',
    originalImageName: '',
    contentType: '',
    size: 0,
    key: '',
    sha1: '',
    blobStore: '',
    container: '',
    createdAt: '',
    updatedAt: '',
    uploadedAt: '',
    imageUrl: ''
  };
};

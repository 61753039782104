import React from 'react';
import PropTypes from 'prop-types';

import { navigationSectionPropTypes } from '../../constants/reused-proptypes';

const CLASS_NS = 'draft-navigation-item';

const DraftNavigationItem = ({
  classNs = CLASS_NS,
  section,
  onLinkClick,
  onMoveSection,
  isActive = false,
  isLast = false,
  upButtonIcon,
  downButtonIcon
}) => {
  const onLinkClickHandler = (e) => {
    if (isActive) {
      e.preventDefault();
      return e;
    }
    if (onLinkClick) {
      // The event is going to be handled elsewhere.
      onLinkClick(e);
    }
  };
  const onMoveSectionHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target.closest('[data-direction]');
    onMoveSection &&
      onMoveSection({
        ...section,
        direction: target.dataset.direction
      });
  };

  return (
    <li className={`${classNs}_item`}>
      <a
        href={section.link}
        className={`${classNs}_link`}
        onClick={onLinkClickHandler}
        disabled={isActive}
        data-active={isActive}
      >
        <span className={`${classNs}_label`}>{section.label}</span>
        <button
          className={`${classNs}_button-move`}
          dangerouslySetInnerHTML={{ __html: upButtonIcon }}
          onClick={onMoveSectionHandler}
          disabled={section.index === 0}
          data-direction="up"
          data-id={section.id}
        ></button>
        <button
          className={`${classNs}_button-move`}
          dangerouslySetInnerHTML={{ __html: downButtonIcon }}
          onClick={onMoveSectionHandler}
          disabled={isLast}
          data-direction="down"
          data-id={section.id}
        ></button>
      </a>
    </li>
  );
};

DraftNavigationItem.propTypes = {
  classNs: PropTypes.string,
  section: navigationSectionPropTypes,
  onLinkClick: PropTypes.func,
  onMoveSection: PropTypes.func,
  isActive: PropTypes.bool,
  isLast: PropTypes.bool
};

export default DraftNavigationItem;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import DraftNavigationItem from './draft-navigation-item';

import { sectionPropTypes } from '../../constants/reused-proptypes';
import { useSectionsChange } from '../../hooks/use-sections-change';
import { getDistinctItems } from '../../utils/js-helpers';

// Base set of overridable icons
const iconAdd =
  '<svg width="151" height="152" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" fill-rule="evenodd"><ellipse fill="#98bd9e" cx="75.5" cy="76" rx="75.5" ry="76"/><path class="svg-lineplus" d="M112 71.8v10H82v30.4H71V81.8H41v-10h30V41.4h11v30.4"/></g></svg>';
const iconArrowUp =
  '<svg width="17" height="9" xmlns="http://www.w3.org/2000/svg"><path d="M8.586.162c.18 0 .359.068.496.205l7.504 7.504-.991.991-7.009-7.008-7.009 7.008-.991-.99L8.09.366a.699.699 0 0 1 .496-.205z"/></svg>';
const iconArrowDown =
  '<svg width="25" height="10" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg"><path d="M17 2.267 10.267 9 3.429 2.267" stroke-width="2" stroke="#979797" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>';

const CLASS_NS = 'draft-navigation';

// Use the url as the single source of truth.
/**
 *
 * The implementing component has the follwing responsibilities:
 * Saving of the setion and providing the appropriate transformation schema and api function
 */
export const DraftNavigation = ({
  classNs = CLASS_NS,
  sections = [],
  sectionId = 0,
  sectionHasChanges = false,
  onNewSection,
  onSectionChange,
  onSectionsChange = () => {},
  onMoveSection,
  AddButtonIcon = iconAdd,
  addButtonLabel = 'Lägg till sektion',
  upButtonIcon = iconArrowUp,
  downButtonIcon = iconArrowDown
}) => {
  const location = useLocation();
  const history = useHistory();
  const prevSections = useRef([]);
  const prevChangedSectionIds = useRef(); // Can't iniitalize with an empty array here as the value will always be an empty array.
  const [changedSectionIds] = useSectionsChange(sections);

  useEffect(() => {
    // External hook memo doesn't seem to work here so add extra check.
    const prevIds = prevChangedSectionIds?.current ?? [];
    // console.log('changedSectionIds : ', changedSectionIds);
    // console.log('prevChangedSectionIds.current : ', prevChangedSectionIds.current);
    if (getDistinctItems(changedSectionIds, prevIds)?.length === 0) {
      return;
    }
    prevChangedSectionIds.current = changedSectionIds;
    onSectionsChange(changedSectionIds);
  }, [changedSectionIds, onSectionsChange]);

  // const setUrlToSectionId = id => {
  //   if (!sections.find(s => s.id == id)) {
  //     return;
  //   }
  //   const sectionUrl = getUrlWithNewSectionId(id, route.path, routeParams);
  //   if (!sectionUrl || sectionUrl === location.pathname) {
  //     // React-router will reload if the push parameter is the same as the current location.
  //     return;
  //   }
  //   dispatch(push(sectionUrl));
  //   window.scrollTo(0, 0);
  // };

  // useEffect(() => {
  //   // Set the first section by default if needed.
  //   if (sectionId && sections.find(s => s.id === sectionId)) {
  //     return; // No need for setting anything.
  //   }
  //   sections?.[0]?.id && setUrlToSectionId(sections[0].id);
  // }, [sections]);
  useEffect(() => {
    // Change the url to match the current sectionId
    // This is only needed when the sections are manipulated like when adding/removing them.
    if (!sections.length) {
      return; // Sektions not loaded
    }
    if (sections.length === prevSections.current.length) {
      return; // Regular navigation.
    }

    let linkUrl = sections.find((section) => section.id === sectionId)?.link;
    if (!linkUrl) {
      return; // No lnk
    }
    if (linkUrl === location.pathname) {
      return; // Same
    }

    linkUrl = location.search?.includes(sectionId) ? linkUrl + location.search : linkUrl;
    prevSections.current = sections;
    history.push(linkUrl);
  }, [sections, history, location, sectionId]);

  const onNewSectionHandler = () => {
    const maxIndex = sections.reduce((acc, item) => (acc = Math.max(acc, item.index)), -Infinity);
    const nextIndex = maxIndex < 0 ? 0 : maxIndex - 0 + 1;
    onNewSection(nextIndex);
  };

  // Two sections should change place, the fromSection should swap with the toSection.
  // The back-end stores a 'weight' attribute for the position of each section.
  // The front-end uses a sorted array from the store.
  // const handleMoveSection = (e, fromSection, value) => {
  //   e.preventDefault();
  //   const fromIndex = sections.indexOf(fromSection);
  //   const canMoveDown = value === 'down' && fromIndex < sections.length - 1;
  //   const canMoveUp = value === 'up' && fromIndex > 0;
  //
  //   if (!canMoveUp && !canMoveDown) return;
  //
  //   const toIndex = canMoveDown ? fromIndex + 1 : fromIndex - 1;
  //   const toSection = sections[toIndex];
  //
  //   if (toSection === 'undefined') return;
  //
  //   onMoveSection(fromIndex, toIndex);
  // };

  // const handleSubmitAndSelectNextSection = async id => {
  //   const serverSectionData = await dispatch(saveSection());
  //   onAfterSave && onAfterSave(serverSectionData);
  //   setUrlToSectionId(id);
  // };

  // TODO: Consider if this can be expressed as an async function instead of using callbacks.
  // const showConfirmSubmitSectionOverlay = id => {
  //   dispatch(
  //     loadOverlayContent({
  //       instance: ConfirmDialog,
  //       passedProps: {
  //         isInOverlay: true,
  //         confirm: handleSubmitAndSelectNextSection.bind(null, id),
  //         discard: setUrlToSectionId.bind(null, id),
  //         overlayInnerClass: 'overlay_inner overlay_inner_confirm',
  //         dialogText: 'Vill du spara förändringarna i denna sektion?',
  //         confirmText: 'Spara',
  //         cancelText: 'Kassera ändringar'
  //       }
  //     })
  //   );
  // };

  // const selectSection = id => {
  //   if (!sections.find(s => s.id == id)) {
  //     return;
  //   }
  //   sectionHasChanges ? showConfirmSubmitSectionOverlay(id) : setUrlToSectionId(id);
  // };

  // TODO: Change css names.
  return (
    <aside className={`${classNs}`}>
      <nav className={`${classNs}_wrapper`}>
        <ul className={`${classNs}_list`}>
          {sections
            .sort((a, b) => {
              if (a.index < b.index) {
                return -1;
              }
              if (a.index > b.index) {
                return 1;
              }
              return 0;
            })
            .map((section, index, arr) => {
              const isActive = section.id === (sectionId ?? -1);
              const isLast = index === arr.length - 1;
              return (
                <DraftNavigationItem
                  classNs={classNs}
                  key={section.id}
                  section={section}
                  onLinkClick={onSectionChange}
                  onMoveSection={onMoveSection}
                  isActive={isActive}
                  isLast={isLast}
                  sections={sections}
                  upButtonIcon={upButtonIcon}
                  downButtonIcon={downButtonIcon}
                />
              );
            })}
        </ul>
      </nav>
      {typeof AddButtonIcon === 'string' && AddButtonIcon.startsWith('<svg') ? (
        <button
          className={`${classNs}_add-button`}
          onClick={onNewSectionHandler}
          dangerouslySetInnerHTML={{ __html: `${AddButtonIcon} ${addButtonLabel}` }}
        ></button>
      ) : (
        <button className={`${classNs}_add-button`} onClick={onNewSectionHandler}>
          {typeof AddButtonIcon === 'string' ? (
            <img
              src={AddButtonIcon}
              alt="add button icon"
              className={`${classNs}_add-button_icon`}
            />
          ) : (
            <AddButtonIcon className={`${classNs}_add-button_icon`} />
          )}
          {addButtonLabel}
        </button>
      )}
    </aside>
  );
};

DraftNavigation.propTypes = {
  classNs: PropTypes.string,
  sections: PropTypes.arrayOf(sectionPropTypes),
  sectionId: PropTypes.string,
  sectionHasChanges: PropTypes.bool,
  onNewSection: PropTypes.func.isRequired,
  onSectionChange: PropTypes.func.isRequired,
  onSectionsChange: PropTypes.func,
  onMoveSection: PropTypes.func.isRequired,
  AddButtonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  addButtonLabel: PropTypes.string,
  upButtonIcon: PropTypes.string,
  downButtonIcon: PropTypes.string
};

import React from "react";
import { useParams, Link } from "react-router-dom";

import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "icons/Arrow-Right.svg";
import useGuideNavigation from "hooks/use-guide-navigation";
import useLoggedInUser from "hooks/use-logged-in-user";

const CLASS_NS = "guide-viewer-section-buttons";

const GuideViewerSectionButtons = ({ guideBlocks, guideMeta, classNs = CLASS_NS }) => {
  const { sectionId } = useParams();
  const { previousSection, nextSection, nextParentPart } = useGuideNavigation(sectionId);
  const { isCompanyReader } = useLoggedInUser();

  const nextSectionTitle = `${isCompanyReader ? "Nästa avsnitt" : "Fortsätt med nästa avsnitt"} - ${
    nextParentPart?.title
  }`;

  return (
    <div className={`${classNs}`} data-flex={!previousSection ? "end" : !nextSection ? "start" : ""}>
      {previousSection && (
        <Link to={previousSection?.path} className={`${classNs}_button`}>
          <span className={`${classNs}_button_icon`}>
            <ArrowLeft />
          </span>
        </Link>
      )}
      {nextSection && (
        <Link to={nextSection?.path} className={`${classNs}_button`}>
          <span className={`${classNs}_button_label`}>
            {nextSectionTitle}
            {isCompanyReader ? (
              <span className={`${classNs}_button_sublabel`}>{`${nextSection.title}`}</span>
            ) : nextSection.childCount === 0 ? (
              <span className={`${classNs}_button_sublabel`}>{`${nextSection.title} - Krav saknas`}</span>
            ) : (
              <span
                className={`${classNs}_button_sublabel`}>{`${nextSection.title} - ${nextSection.isDoneCount} av ${nextSection.childCount} krav klara`}</span>
            )}
          </span>
          <span className={`${classNs}_button_icon`}>
            <ArrowRight />
          </span>
        </Link>
      )}
    </div>
  );
};

export default GuideViewerSectionButtons;

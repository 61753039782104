import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Search from 'components/Search/Search';
import __ from 'constants/static-texts';
import { setSearchQueryUsers } from 'actions/users-actions';

import UserList from './UserList';
import SubHeader from 'components/SubHeader/SubHeader';
import useUsers from 'hooks/use-users';
import useAllNonValidatedUsers from 'hooks/use-all-non-validated-users';
import useCompanies from 'hooks/use-companies';
import useTextByRole from 'hooks/use-text-by-role';

import { NON_VALIDATED_USER_STATUSES } from 'constants/non-validated-user-statuses';

const CLASS_NS = 'users';

const Users = ({ route, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { activeCompany } = useCompanies();
  const { users, usersIsLoading, userRoles, filteredUsers } = useUsers();
  const { user: loggedInUser } = useSelector((state) => state.loggedInUserState);
  const { nonValidatedUsersIsLoading, nonValidatedUsersWithCompanyNames } = useAllNonValidatedUsers(loggedInUser);
  const { searchQueryUsers } = useSelector((state) => state.usersState);
  const { companiesIsloading, companiesMap } = useCompanies();
  const userSearchChange = (value) => {
    dispatch(setSearchQueryUsers(value));
  };

  const usersTitle = useTextByRole(__.users.user);
  const usersText = useTextByRole(__.users.description);

  const userStatus = (status) =>
    nonValidatedUsersWithCompanyNames.filter((user) => {
      const validationStatus = user.companyValidationStatuses.filter(
        (comp) => comp.companyId === activeCompany?.id && comp.status === status
      );
      return validationStatus.length;
    });

  const pendingUsers = userStatus(NON_VALIDATED_USER_STATUSES.PENDING);
  const blockedUsers = userStatus(NON_VALIDATED_USER_STATUSES.BLOCKED);

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{usersTitle}</h1>
        <div className={`${classNs}_description`}>
          {usersText.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </SubHeader>
      <div className={`${classNs}`}>
        <Search onChange={userSearchChange} searchQuery={searchQueryUsers} placeholder={__.users.search} />
        {!nonValidatedUsersIsLoading && pendingUsers.length ? (
          <>
            <h2>{__.users.pending}</h2>
            <UserList users={pendingUsers} classNs={classNs} type="pending" />
          </>
        ) : null}
        {!usersIsLoading && !companiesIsloading && companiesMap ? (
          <>
            <h2>{__.users.regular}</h2>
            <UserList users={filteredUsers} userRoles={userRoles} classNs={classNs} />
          </>
        ) : null}
        {!nonValidatedUsersIsLoading && blockedUsers.length ? (
          <>
            <h2>{__.users.blocked}</h2>
            <UserList users={blockedUsers} classNs={classNs} type="blocked" />
          </>
        ) : null}
        {!usersIsLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
              users,
              userRoles,
              nonValidatedUsersWithCompanyNames,
            })
          : null}
      </div>
    </>
  );
};

export default Users;

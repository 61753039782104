import React, { useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import PropTypes from "prop-types";

import GuidePartHeader from "./GuidePartHeader";
import GuideEditorSavebar from "./GuideEditorSavebar";
import DraftEditor, {
  DraftNavigation,
  useBlocksSaveable,
  normalizeSectionsData,
  resetSectionsState,
} from "react-draft-editor";
import Loading from "components/Loading/Loading";
// import DraftNavigation from "submodules/react-draft-editor/components/draft-navigation/draft-navigation";
// import { moduleTypes } from "submodules/react-draft-editor/constants/module-types";
// import * as guideApi from "api/guide-api";
// import { saveSection } from "react-draft-editor";
import {
  swapSectionIndexes,
  addNewGuidePart,
  updateSectionBlocks,
  updateSections,
  deleteGuideBlocks,
} from "actions/guide-actions";
import useStateWithLabel from "hooks/use-state-with-label";

import GuidesRoute from "routes/Guides";

import { ReactComponent as PlusIcon } from "icons/plus.svg";

import { loadGuide } from "utils/guide";
import { /*WAITING, */ LOADING /*, ERROR, SUCCESS */ } from "constants/network-states";
import { guideSectionTypes } from "constants/guide-types";
import __ from "constants/static-texts";

const iconArrowUp =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10 8 5l-5 5"/></svg>';
const iconArrowDown =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m3 6 5 5 5-5"/></svg>';
/**
 *
 * The hierarchy for the guide is
 * guide-part
 * guide-section
 * guide-requirement
 * guide-responseSlot
 */
// TODO: Abstrakt this and GuideEditor and use one component to handle the same/similar actions
/**
 * Entry point for editing guide parts
 *
 */
const GuidePartEditor = ({ match, location }) => {
  const classNs = "GuideEditor";
  const dispatch = useDispatch();
  const guideId = useMemo(() => {
    return match.params?.guideId ?? null;
  }, [match]);
  const guidePartId = useMemo(() => {
    return match.params?.guidePartId ?? null;
  }, [match]);
  const [isSaveable] = useBlocksSaveable();
  const [reindexedIds, setReindexedIds] = useStateWithLabel([], "reindexedIds");
  const prevGuideParts = useRef([]);
  const { guide, guideNetworkState } = useSelector((state) => state.guideState);
  const getGuidesUrl = () => `${GuidesRoute().path}`;
  const getPartLinkFn = useCallback(
    (id) => {
      return `${GuidesRoute().path}/${guideId}/guide-part-editor/${id}`;
    },
    [guideId]
  );
  const getCurrentSectionUrl = (id) => {
    return `${GuidesRoute().path}/${guideId}/guide-part-editor/${guidePartId}/guide-editor/`;
  };
  const getPublishedUrl = () => `${GuidesRoute().path}/${guideId}/guide/${guidePartId}`;

  useEffect(() => {
    // Load guide in init.
    loadGuide(guideId, guide, dispatch);
    return () => {
      dispatch(resetSectionsState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const guideParts = useMemo(() => guide?.filter((block) => block.type === guideSectionTypes.GUIDE_PART), [guide]);

  const getCurrentPart = useCallback(() => {
    // Choose either the first part if navigating to the page,
    // the last part if adding a new part
    // or the part according to the url.
    const currentPart = guideParts.find((block) => block.id === guidePartId);
    const prevPartsLength = prevGuideParts.current.length;
    const currentPartsLength = guideParts.length;

    let nextPart = null;

    if (prevPartsLength === currentPartsLength || prevPartsLength === 0) {
      nextPart = currentPart ?? guideParts?.[0] ?? null; // current or first.
    } else if (currentPartsLength > prevPartsLength) {
      nextPart = guideParts?.[currentPartsLength - 1]; // Last section as that's where the new sections are placed.
    } else {
      // Choose previous section if length is less
      const currentIds = guideParts.map((section) => section.id);
      const deletedPart = prevGuideParts.current.find((section) => currentIds.includes(section.id) === false);
      const nextIndex = deletedPart?.index > 0 ? deletedPart?.index - 1 : 0; // Next index above.
      nextPart = guideParts?.[nextIndex] ?? null;
    }
    prevGuideParts.current = guideParts;
    return nextPart;
  }, [guideParts, guidePartId]);

  const [currentPart, setCurrentPart] = useStateWithLabel(null, "currentPart");

  useEffect(() => {
    setCurrentPart(getCurrentPart());
  }, [guide, setCurrentPart, getCurrentPart]);

  const onNewPart = (nextIndex) => {
    if (typeof nextIndex !== "number") {
      console.error(`Index was not an number : ${nextIndex}`);
    }
    dispatch(
      addNewGuidePart({
        guideId: guideId,
        parentId: guideId,
        index: nextIndex,
      })
    );
  };

  const onMove = (event) => {
    const { index, direction, id } = event;
    const toIndex = direction === "up" ? index - 1 : index + 1;
    dispatch(swapSectionIndexes(toIndex, index, id, currentPart.type));
  };

  const onPartChange = async (e) => {
    e.preventDefault();
    const link = e.target.closest("a[href]");
    const { pathname, search } = link;
    // Auto save for now.
    if (isSaveable) {
      await onSave();
    }
    dispatch(push(`${pathname}${search}`));

    // TODO: set confirmation dialog here.
    // const serverSectionData = await dispatch(saveSection());
    // dispatch(
    //   loadOverlayContent({
    //     instance: ConfirmDialog,
    //     passedProps: {
    //       isInOverlay: true,
    //       confirm: handleSubmitAndSelectNextSection.bind(null, id),
    //       discard: setUrlToSectionId.bind(null, id),
    //       overlayInnerClass: 'overlay_inner overlay_inner_confirm',
    //       dialogText: 'Vill du spara förändringarna i denna sektion?',
    //       confirmText: 'Spara',
    //       cancelText: 'Kassera ändringar'
    //     }
    //   })
    // );
  };

  const onSectionsChange = (ids) => {
    setReindexedIds(ids);
  };

  const onBack = (e) => {
    // TODO: set confirmation dialog here whereafter take over the navigation programatically.
    // e.preventDefault();
    // history.push(getGuidesUrl());
  };

  const onSave = async () => {
    if (reindexedIds.length) {
      await dispatch(updateSections(reindexedIds, guideId));
    }
    await dispatch(updateSectionBlocks(guideId));
  };
  const onDelete = () => {
    // TODO: Consider if the whole guide tree has to be travered and pick up all the related block ids.
    dispatch(deleteGuideBlocks([currentPart.id], guideId));
  };

  // const onPublish = () => {};
  const normalizedSections = useMemo(() => {
    return normalizeSectionsData(guideParts, undefined, getPartLinkFn);
  }, [guideParts, getPartLinkFn]);
  return (
    <div className={`${classNs}_page`}>
      <GuideEditorSavebar
        currentBlock={currentPart}
        currentSections={normalizedSections}
        classNs={`${classNs}_savebar`}
        backUrl={getGuidesUrl()}
        reviewLink={getPublishedUrl()}
        onBack={onBack}
        onSave={onSave}
        onDelete={onDelete}
        disableDeleteButton={!!normalizedSections.length}
        //onPublish={onPublish}
      />
      <DraftNavigation
        classNs="DocEditorNav"
        sectionId={currentPart?.id}
        sections={normalizedSections}
        onNewSection={onNewPart}
        onSectionChange={onPartChange}
        onSectionsChange={onSectionsChange}
        onMoveSection={onMove}
        addButtonLabel={__.guide.part.add_part}
        AddButtonIcon={PlusIcon}
        upButtonIcon={iconArrowUp}
        downButtonIcon={iconArrowDown}
      />
      {guideNetworkState === LOADING ? (
        <Loading />
      ) : currentPart ? (
        <>
          <DraftEditor
            classNs={classNs}
            section={currentPart}
            schema={{}}
            LeadContent={<GuidePartHeader titleLabel="Rubrik" descriptionLabel="Ingress" />}
            TrailingContent={
              <Link to={getCurrentSectionUrl()} className="button">
                Redigera innehåll
              </Link>
            }
            moduleTypesByDepth={{}}
            blocksSelectorConfig={[]}
            LoadingComponent={Loading}
          />
        </>
      ) : null}
    </div>
  );
};
//

GuidePartEditor.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};
export default GuidePartEditor;

import fetchClient from "fetch-client";

export const getDocuments = (getState) =>
  fetchClient({
    path: `/companydocuments`,
    getState,
  });

export const getCompanyDocument = (id, getState) =>
  fetchClient({
    path: `/companydocuments/${id}`,
    getState,
  });

export const addDocument = (data, getState) => {
  return fetchClient({
    path: `/companydocuments`,
    getState,
    method: `POST`,
    contentType: `form`,
    data,
  });
};

export const updateCompanyDocument = (data, getState) =>
  fetchClient({
    path: `/companydocuments/${data.id}`,
    getState,
    method: `PUT`,
    contentType: `form`,
    data,
  });

export const deleteDocument = (data, getState) =>
  fetchClient({
    path: `/companydocuments/${data.id}`,
    getState,
    method: `DELETE`,
    data: data,
  });

export const favouriteDocument = (data, getState) =>
  fetchClient({
    path: `/companydocuments/${data.id}/favourite`,
    getState,
    method: `PUT`,
    data: data,
  });

export const downloadFile = (id, getState) =>
  fetchClient({
    path: `/companydocuments/${id}/download`,
    getState,
    contentType: `file`,
  });

export const exportZipArchive = (filename, getState) =>
  fetchClient({
    path: `/companydocuments/archive/download`,
    getState,
    contentType: `file`,
  });

export const exportZipArchiveForCompany = (guide, getState) =>
  fetchClient({
    path: `/companydocuments/${guide.companyId}/archive/download?includeInExport=${guide.docTypes}`,
    getState,
    contentType: `file`,
  });

export const deleteCompanyDocumentVersion = (documentId, versionId, getState) =>
  fetchClient({
    path: `/companydocuments/${documentId}/versions/${versionId}`,
    getState,
    method: `DELETE`,
    data: versionId,
  });

export const downloadCompanyDocumentVersion = (documentId, versionId, getState) =>
  fetchClient({
    path: `/companydocuments/${documentId}/versions/${versionId}/download`,
    getState,
    contentType: `file`,
  });

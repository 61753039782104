import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { CallbackComponent } from "redux-oidc";
import { push } from "connected-react-router";
// import { connect } from "react-redux";
import { userManager } from "store";

// class Callback extends React.Component {
const Callback = (props) => {
  const dispatch = useDispatch();

  // constructor(props) {
  //   super(props);
  //   this.state = { hasError: false, errorMessage: "" };
  // }
  const [state, setState] = useState({ hasError: false, errorMessage: "" });

  // define a success callback which receives the signed in user & handles redirection
  // NOTE: this example uses react-router-redux,
  // but any other routing library should work the same
  const successCallback = (user) => {
    setState({ ...state, hasError: false, errorMessage: "" });

    // the user object gets the browser's URL before
    // redirection was triggered passed into its state
    // when triggerAuthFlow is set to `true`
    //axios.defaults.headers.common['Authorization'] = "Bearer " + user.access_token;

    dispatch(push(user.state.redirectUrl));
  };

  const errorCallback = (error) => {
    let message = error.message;
    console.error("Error in errorCallback :: ", error);
    if (message.includes("iat is in the future") || message.includes("exp is in the past")) {
      message =
        "Inloggningen misslyckades, det verkar vara problem med tidsinställningen på enheten som du försöker logga in ifrån, kontrollera att klockan är rätt inställd.";
    } else {
      message = "Inloggningen misslyckades.";
    }
    setState({ ...state, hasError: true, errorMessage: message });
    console.log(error);
  };

  return (
    <CallbackComponent userManager={userManager} successCallback={successCallback} errorCallback={errorCallback}>
      <div>{state.hasError ? state.errorMessage : "Omdirigerar..."}</div>
    </CallbackComponent>
  );
};

Callback.propTypes = {
  dispatch: PropTypes.func,
};

// function mapDispatchToProps(dispatch) {
//   return {
//     dispatch,
//   };
// }

export default Callback;

import React from "react";
import { renderRoutes } from "react-router-config";
import { Link } from "react-router-dom";

import __ from "constants/static-texts";

import SubHeader from "components/SubHeader/SubHeader";
import NewsList from "./NewsList";

import useLibraryDocuments from "hooks/use-library-documents";

import useNews from "hooks/use-news";
import useProductTypes from "hooks/use-productTypes";

const CLASS_NS = "news";

const News = ({ route, classNs = CLASS_NS }) => {
  const { filteredNews, newsIsLoading } = useNews();

  const { productTypesIsLoading } = useProductTypes();
  const [document, documentCount, documentsIsLoading] = useLibraryDocuments(); // eslint-disable-line

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{__.news.news}</h1>
        <p className={`${classNs}_description`}>{__.news.description}</p>
      </SubHeader>
      <div className={`${classNs}`}>
        <Link to={`${route.path}/item`} className={`${classNs}_add`}>
          {__.news.createNew}
        </Link>
        {/* <Search onChange={companySearchChange} searchQuery={searchQuery} placeholder={__.companies.search} /> */}
        {!newsIsLoading && <NewsList news={filteredNews} classNs={classNs} />}
        {!productTypesIsLoading && !newsIsLoading && !documentsIsLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
            })
          : null}
      </div>
    </>
  );
};

export default News;

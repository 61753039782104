import React from "react";
import { useDispatch } from "react-redux";

import { exportGuideById } from "actions/companydocuments-actions";
import useCompanyDocuments from "../../hooks/use-company-documents";
import ButtonLink from "components/ButtonLink/ButtonLink";
import Loading from "../Loading/Loading";

import { ReactComponent as Download } from "icons/Download.svg";

import __ from "constants/static-texts";

const CLASS_NS = "guide-export";

const GuideExport = ({
  classNs = CLASS_NS,
  companyId,
  filename = "Dokumentarkiv",
  text = __.guide.export,
  textProgress = __.guide.exportProgress,
}) => {
  const { archiveDownloadIsLoading } = useCompanyDocuments();
  const dispatch = useDispatch();

  const downloadGuide = () => {
    if (archiveDownloadIsLoading) return;
    dispatch(exportGuideById({ companyId, filename }));
  };

  return (
    <ButtonLink
      icon={archiveDownloadIsLoading ? <Loading text={null} variant="inline" /> : <Download />}
      state={archiveDownloadIsLoading ? "idle" : null}
      onClick={downloadGuide}>
      {archiveDownloadIsLoading ? textProgress : text}
    </ButtonLink>
  );
};

export default GuideExport;

////////////// DOCUMENTS ///////////////////////////////////////////
export const GET_DOCUMENTS_LOADING = "GET_DOCUMENTS_LOADING";
export const GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";

export const GET_LIBRARY_DOCUMENT_LOADING = "GET_LIBRARY_DOCUMENT_LOADING";
export const GET_LIBRARY_DOCUMENT_ERROR = "GET_LIBRARY_DOCUMENT_ERROR";
export const GET_LIBRARY_DOCUMENT_SUCCESS = "GET_LIBRARY_DOCUMENT_SUCCESS";

export const ADD_DOCUMENT_LOADING = "ADD_DOCUMENT_LOADING";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";

export const UPDATE_DOCUMENT_LOADING = "UPDATE_DOCUMENT_LOADING";
export const UPDATE_DOCUMENT_ERROR = "UPDATE_DOCUMENT_ERROR";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";

export const DELETE_DOCUMENT_LOADING = "DELETE_DOCUMENT_LOADING";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";

export const FAVOURITE_DOCUMENT_LOADING = "FAVOURITE_DOCUMENT_LOADING";
export const FAVOURITE_DOCUMENT_ERROR = "FAVOURITE_DOCUMENT_ERROR";
export const FAVOURITE_DOCUMENT_SUCCESS = "FAVOURITE_DOCUMENT_SUCCESS";

export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_FILTER_CATEGORY = "SET_FILTER_CATEGORY";

export const SET_ACTIVE_DOWNLOAD = "SET_ACTIVE_DOWNLOAD";
export const DOWNLOAD_FILE_LOADING = "DOWNLOAD_FILE_LOADING";
export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";

export const GET_DOCUMENT_TYPES_LOADING = "GET_DOCUMENT_TYPES_LOADING";
export const GET_DOCUMENT_TYPES_ERROR = "GET_DOCUMENT_TYPES_ERROR";
export const GET_DOCUMENT_TYPES_SUCCESS = "GET_DOCUMENT_TYPES_SUCCESS";

export const DELETE_LIBRARY_DOCUMENT_VERSION_LOADING = "DELETE_LIBRARY_DOCUMENT_VERSION_LOADING";
export const DELETE_LIBRARY_DOCUMENT_VERSION_ERROR = "DELETE_LIBRARY_DOCUMENT_VERSION_ERROR";
export const DELETE_LIBRARY_DOCUMENT_VERSION_SUCCESS = "DELETE_LIBRARY_DOCUMENT_VERSION_SUCCESS";

export const SET_ACTIVE_VERSION_DOWNLOAD = "SET_ACTIVE_VERSION_DOWNLOAD";
export const DOWNLOAD_LIBRARY_DOCUMENT_VERSION_LOADING = "DOWNLOAD_LIBRARY_DOCUMENT_VERSION_LOADING";
export const DOWNLOAD_LIBRARY_DOCUMENT_VERSION_ERROR = "DOWNLOAD_LIBRARY_DOCUMENT_VERSION_ERROR";
export const DOWNLOAD_LIBRARY_DOCUMENT_VERSION_SUCCESS = "DOWNLOAD_LIBRARY_DOCUMENT_VERSION_SUCCESS";

////////////// COMPANY DOCUMENTS ///////////////////////////////////////////
export const GET_COMPANY_DOCUMENTS_LOADING = "GET_COMPANY_DOCUMENTS_LOADING";
export const GET_COMPANY_DOCUMENTS_ERROR = "GET_COMPANY_DOCUMENTS_ERROR";
export const GET_COMPANY_DOCUMENTS_SUCCESS = "GET_COMPANY_DOCUMENTS_SUCCESS";

export const GET_COMPANY_DOCUMENT_LOADING = "GET_COMPANY_DOCUMENT_LOADING";
export const GET_COMPANY_DOCUMENT_ERROR = "GET_COMPANY_DOCUMENT_ERROR";
export const GET_COMPANY_DOCUMENT_SUCCESS = "GET_COMPANY_DOCUMENT_SUCCESS";

export const ADD_COMPANY_DOCUMENT_LOADING = "ADD_COMPANY_DOCUMENT_LOADING";
export const ADD_COMPANY_DOCUMENT_ERROR = "ADD_COMPANY_DOCUMENT_ERROR";
export const ADD_COMPANY_DOCUMENT_SUCCESS = "ADD_COMPANY_DOCUMENT_SUCCESS";

export const DELETE_COMPANY_DOCUMENT_LOADING = "DELETE_COMPANY_DOCUMENT_LOADING";
export const DELETE_COMPANY_DOCUMENT_ERROR = "DELETE_COMPANY_DOCUMENT_ERROR";
export const DELETE_COMPANY_DOCUMENT_SUCCESS = "DELETE_COMPANY_DOCUMENT_SUCCESS";

export const FAVOURITE_COMPANY_DOCUMENT_LOADING = "FAVOURITE_COMPANY_DOCUMENT_LOADING";
export const FAVOURITE_COMPANY_DOCUMENT_ERROR = "FAVOURITE_COMPANY_DOCUMENT_ERROR";
export const FAVOURITE_COMPANY_DOCUMENT_SUCCESS = "FAVOURITE_COMPANY_DOCUMENT_SUCCESS";

export const UPDATE_COMPANY_DOCUMENT_LOADING = "UPDATE_COMPANY_DOCUMENT_LOADING";
export const UPDATE_COMPANY_DOCUMENT_ERROR = "UPDATE_COMPANY_DOCUMENT_ERROR";
export const UPDATE_COMPANY_DOCUMENT_SUCCESS = "UPDATE_COMPANY_DOCUMENT_SUCCESS";

export const SET_ACTIVE_COMPANY_DOWNLOAD = "SET_ACTIVE_COMPANY_DOWNLOAD";
export const DOWNLOAD_COMPANY_FILE_LOADING = "DOWNLOAD_COMPANY_FILE_LOADING";
export const DOWNLOAD_COMPANY_FILE_ERROR = "DOWNLOAD_COMPANY_FILE_ERROR";
export const DOWNLOAD_COMPANY_FILE_SUCCESS = "DOWNLOAD_COMPANY_FILE_SUCCESS";

export const EXPORT_GUIDE_LOADING = "EXPORT_GUIDE_LOADING";
export const EXPORT_GUIDE_ERROR = "EXPORT_GUIDE_ERROR";
export const EXPORT_GUIDE_SUCCESS = "EXPORT_GUIDE_SUCCESS";

export const DELETE_COMPANY_DOCUMENT_VERSION_LOADING = "DELETE_COMPANY_DOCUMENT_VERSION_LOADING";
export const DELETE_COMPANY_DOCUMENT_VERSION_ERROR = "DELETE_COMPANY_DOCUMENT_VERSION_ERROR";
export const DELETE_COMPANY_DOCUMENT_VERSION_SUCCESS = "DELETE_COMPANY_DOCUMENT_VERSION_SUCCESS";

export const SET_ACTIVE_COMPANY_VERSION_DOWNLOAD = "SET_ACTIVE_COMPANY_VERSION_DOWNLOAD";
export const DOWNLOAD_COMPANY_DOCUMENT_VERSION_LOADING = "DOWNLOAD_COMPANY_DOCUMENT_VERSION_LOADING";
export const DOWNLOAD_COMPANY_DOCUMENT_VERSION_ERROR = "DOWNLOAD_COMPANY_DOCUMENT_VERSION_ERROR";
export const DOWNLOAD_COMPANY_DOCUMENT_VERSION_SUCCESS = "DOWNLOAD_COMPANY_DOCUMENT_VERSION_SUCCESS";

////////////// GUIDES ///////////////////////////////////////////
export const GET_GUIDES_LOADING = "GET_GUIDES_LOADING";
export const GET_GUIDES_ERROR = "GET_GUIDES_ERROR";
export const GET_GUIDES_SUCCESS = "GET_GUIDES_SUCCESS";

export const ADD_GUIDE_LOADING = "ADD_GUIDE_LOADING";
export const ADD_GUIDE_ERROR = "ADD_GUIDE_ERROR";
export const ADD_GUIDE_SUCCESS = "ADD_GUIDE_SUCCESS";

export const DELETE_GUIDE_LOADING = "DELETE_GUIDE_LOADING";
export const DELETE_GUIDE_ERROR = "DELETE_GUIDE_ERROR";
export const DELETE_GUIDE_SUCCESS = "DELETE_GUIDE_SUCCESS";

////////////// GUIDE ///////////////////////////////////////////
export const GET_GUIDE_LOADING = "GET_GUIDE_LOADING";
export const GET_GUIDE_ERROR = "GET_GUIDE_ERROR";
export const GET_GUIDE_SUCCESS = "GET_GUIDE_SUCCESS";

export const GET_GUIDE_META_LOADING = "GET_GUIDE_META_LOADING";
export const GET_GUIDE_META_ERROR = "GET_GUIDE_META_ERROR";
export const GET_GUIDE_META_SUCCESS = "GET_GUIDE_META_SUCCESS";

export const UPDATE_GUIDE_LOADING = "UPDATE_GUIDE_LOADING";
export const UPDATE_GUIDE_ERROR = "UPDATE_GUIDE_ERROR";
export const UPDATE_GUIDE_SUCCESS = "UPDATE_GUIDE_SUCCESS";

export const ADD_NEW_GUIDE_PART_LOADING = "ADD_NEW_GUIDE_PART_LOADING";
export const ADD_NEW_GUIDE_PART_ERROR = "ADD_NEW_GUIDE_PART_ERROR";
export const ADD_NEW_GUIDE_PART_SUCCESS = "ADD_NEW_GUIDE_PART_SUCCESS";

export const ADD_NEW_GUIDE_SECTION_LOADING = "ADD_NEW_GUIDE_SECTION_LOADING";
export const ADD_NEW_GUIDE_SECTION_ERROR = "ADD_NEW_GUIDE_SECTION_ERROR";
export const ADD_NEW_GUIDE_SECTION_SUCCESS = "ADD_NEW_GUIDE_SECTION_SUCCESS";

export const SWAP_SECTION_INDEXES = "SWAP_SECTION_INDEXES";

export const SAVE_GUIDE_BLOCKS_LOADING = "SAVE_GUIDE_BLOCKS_LOADING";
export const SAVE_GUIDE_BLOCKS_ERROR = "SAVE_GUIDE_BLOCKS_ERROR";
export const SAVE_GUIDE_BLOCKS_SUCCESS = "SAVE_GUIDE_BLOCKS_SUCCESS";

export const UPDATE_GUIDE_BLOCKS_LOADING = "UPDATE_GUIDE_BLOCKS_LOADING";
export const UPDATE_GUIDE_BLOCKS_ERROR = "UPDATE_GUIDE_BLOCKS_ERROR";
export const UPDATE_GUIDE_BLOCKS_SUCCESS = "UPDATE_GUIDE_BLOCKS_SUCCESS";

export const DELETE_GUIDE_BLOCKS_LOADING = "DELETE_GUIDE_BLOCKS_LOADING";
export const DELETE_GUIDE_BLOCKS_ERROR = "DELETE_GUIDE_BLOCKS_ERROR";
export const DELETE_GUIDE_BLOCKS_SUCCESS = "DELETE_GUIDE_BLOCKS_SUCCESS";

export const FAVOURITE_GUIDE_BLOCKS_LOADING = "FAVOURITE_GUIDE_BLOCKS_LOADING";
export const FAVOURITE_GUIDE_BLOCKS_ERROR = "FAVOURITE_GUIDE_BLOCKS_ERROR";
export const FAVOURITE_GUIDE_BLOCKS_SUCCESS = "FAVOURITE_GUIDE_BLOCKS_SUCCESS";

export const UPDATE_BLOCK_VALUE_LOADING = "UPDATE_BLOCK_VALUE_LOADING";
export const UPDATE_BLOCK_VALUE_ERROR = "UPDATE_BLOCK_VALUE_ERROR";
export const UPDATE_BLOCK_VALUE_SUCCESS = "UPDATE_BLOCK_VALUE_SUCCESS";

export const RESET_GUIDE = "RESET_GUIDE";

export const UPDATE_ROUTINE_LOADING = "UPDATE_ROUTINE_LOADING";
export const UPDATE_ROUTINE_ERROR = "UPDATE_ROUTINE_ERROR";
export const UPDATE_ROUTINE_SUCCESS = "UPDATE_ROUTINE_SUCCESS";

export const CREATE_ROUTINE_LOADING = "CREATE_ROUTINE_LOADING";
export const CREATE_ROUTINE_ERROR = "CREATE_ROUTINE_ERROR";
export const CREATE_ROUTINE_SUCCESS = "CREATE_ROUTINE_SUCCESS";

export const SET_ACTIVE_COMMENT = "SET_ACTIVE_COMMENT";

export const OPEN_REQUIREMENT = "OPEN_REQUIREMENT";
export const CLOSE_REQUIREMENT = "CLOSE_REQUIREMENT";

export const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION";

export const SET_REQUIREMENT_DIRTY = "SET_REQUIREMENT_DIRTY";
export const RESET_ALL_REQUIREMENTS_DIRTY = "RESET_ALL_REQUIREMENTS_DIRTY";

////////////// COMPANIES ///////////////////////////////////////////
export const GET_COMPANIES_LOADING = "GET_COMPANIES_LOADING";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";

export const SET_SEARCH_QUERY_COMPANIES = "SET_SEARCH_QUERY_COMPANIES";
export const SET_ACTIVE_COMPANY = "SET_ACTIVE_COMPANY";

export const ADD_COMPANY_GUIDE_LOADING = "ADD_COMPANY_GUIDE_LOADING";
export const ADD_COMPANY_GUIDE_ERROR = "ADD_COMPANY_GUIDE_ERROR";
export const ADD_COMPANY_GUIDE_SUCCESS = "ADD_COMPANY_GUIDE_SUCCESS";

export const GET_SSO_COMPANY_USERS_LOADING = "GET_SSO_COMPANY_USERS_LOADING";
export const GET_SSO_COMPANY_USERS_ERROR = "GET_SSO_COMPANY_USERS_ERROR";
export const GET_SSO_COMPANY_USERS_SUCCESS = "GET_SSO_COMPANY_USERS_SUCCESS";
export const RESET_SSO_COMPANY_USERS = "RESET_SSO_COMPANY_USERS";

export const GET_COMPANY_USERS_LOADING = "GET_COMPANY_USERS_LOADING";
export const GET_COMPANY_USERS_ERROR = "GET_COMPANY_USERS_ERROR";
export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";

export const UPDATE_COMPANY_INFO_LOADING = "UPDATE_COMPANY_INFO_LOADING";
export const UPDATE_COMPANY_INFO_ERROR = "UPDATE_COMPANY_INFO_ERROR";
export const UPDATE_COMPANY_INFO_SUCCESS = "UPDATE_COMPANY_INFO_SUCCESS";

////////////// AUTH ///////////////////////////////////////////
export const USER_WILL_SIGNOUT = "USER_WILL_SIGNOUT";

////////////// LOGGED IN USER ///////////////////////////////////////////
export const GET_LOGGED_IN_USER_LOADING = "GET_LOGGED_IN_USER_LOADING";
export const GET_LOGGED_IN_USER_ERROR = "GET_LOGGED_IN_USER_ERROR";
export const GET_LOGGED_IN_USER_NOT_FOUND = "GET_LOGGED_IN_USER_NOT_FOUND";
export const GET_LOGGED_IN_USER_FORBIDDEN = "GET_LOGGED_IN_USER_FORBIDDEN";
export const GET_LOGGED_IN_USER_SUCCESS = "GET_LOGGED_IN_USER_SUCCESS";

////////////// USERS ///////////////////////////////////////////
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const UPDATE_USER_ROLE_LOADING = "UPDATE_USER_ROLE_LOADING";
export const UPDATE_USER_ROLE_ERROR = "UPDATE_USER_ROLE_ERROR";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";

export const UPDATE_USER_DETAILS_LOADING = "UPDATE_USER_DETAILS_LOADING";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";

export const SET_SEARCH_QUERY_USERS = "SET_SEARCH_QUERY_USERS";
export const SET_SEARCH_QUERY_SUPERVISORS = "SET_SEARCH_QUERY_SUPERVISORS";

export const DOWNGRADE_USERS_TO_EDITORS = "DOWNGRADE_USERS_TO_EDITORS";
export const UPGRADE_USERS_TO_ADMINS = "UPGRADE_USERS_TO_ADMINS";

////////////// NON-VALIDATED USERS ///////////////////////////////////////////
export const GET_NON_VALIDATED_USERS_LOADING = "GET_NON_VALIDATED_USERS_LOADING";
export const GET_NON_VALIDATED_USERS_ERROR = "GET_NON_VALIDATED_USERS_ERROR";
export const GET_NON_VALIDATED_USERS_SUCCESS = "GET_NON_VALIDATED_USERS_SUCCESS";

export const GET_NON_VALIDATED_USER_LOADING = "GET_NON_VALIDATED_USER_LOADING";
export const GET_NON_VALIDATED_USER_ERROR = "GET_NON_VALIDATED_USER_ERROR";
export const GET_NON_VALIDATED_USER_SUCCESS = "GET_NON_VALIDATED_USER_SUCCESS";

export const APPROVE_NON_VALIDATED_USER_LOADING = "UPDATE_NON_VALIDATED_USER_ROLE_LOADING";
export const APPROVE_NON_VALIDATED_USER_ERROR = "UPDATE_NON_VALIDATED_USER_ROLE_ERROR";
export const APPROVE_NON_VALIDATED_USER_SUCCESS = "UPDATE_NON_VALIDATED_USER_ROLE_SUCSSES";

export const BLOCK_NON_VALIDATED_USER_LOADING = "BLOCK_NON_VALIDATED_USER_LOADING";
export const BLOCK_NON_VALIDATED_USER_ERROR = "BLOCK_NON_VALIDATED_USER_ERROR";
export const BLOCK_NON_VALIDATED_USER_SUCCESS = "BLOCK_NON_VALIDATED_USER_SUCSSES";

export const GET_CURRENT_NON_VALIDATED_USER_LOADING = "GET_CURRENT_NON_VALIDATED_USER_LOADING";
export const GET_CURRENT_NON_VALIDATED_USER_ERROR = "GET_CURRENT_NON_VALIDATED_USER_ERROR";
export const GET_CURRENT_NON_VALIDATED_USER_SUCCESS = "GET_CURRENT_NON_VALIDATED_USER_SUCCESS";

///////// WORKBOOK //////
export const GET_WORKBOOK_LOADING = "GET_WORKBOOK_LOADING";
export const GET_WORKBOOK_ERROR = "GET_WORKBOOK_ERROR";
export const GET_WORKBOOK_SUCCESS = "GET_WORKBOOK_SUCCESS";

export const GET_WORKBOOKS_LOADING = "GET_WORKBOOKS_LOADING";
export const GET_WORKBOOKS_ERROR = "GET_WORKBOOKS_ERROR";
export const GET_WORKBOOKS_SUCCESS = "GET_WORKBOOKS_SUCCESS";

////////////// COMMENTS ///////////////////////////////////////////
export const GET_COMMENTS_LOADING = "GET_COMMENTS_LOADING";
export const GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";

export const ADD_COMMENT_LOADING = "ADD_COMMENT_LOADING";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";

export const DELETE_COMMENT_LOADING = "DELETE_COMMENT_LOADING";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";

////////////// NOTIFICATIONS ///////////////////////////////////////////
export const GET_NOTIFICATIONS_LOADING = "GET_NOTIFICATIONS_LOADING";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";

export const UPDATE_NOTIFICATION_LOADING = "UPDATE_NOTIFICATION_LOADING";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

////////////// NEWS ///////////////////////////////////////////
export const GET_NEWS_LOADING = "GET_NEWS_LOADING";
export const GET_NEWS_ERROR = "GET_NEWS_ERROR";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";

export const ADD_NEWS_LOADING = "ADD_NEWS_LOADING";
export const ADD_NEWS_ERROR = "ADD_NEWS_ERROR";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";

export const UPDATE_NEWS_LOADING = "UPDATE_NEWS_LOADING";
export const UPDATE_NEWS_ERROR = "UPDATE_NEWS_ERROR";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";

export const DELETE_NEWS_LOADING = "DELETE_NEWS_LOADING";
export const DELETE_NEWS_ERROR = "DELETE_NEWS_ERROR";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";

////////////// STATUS MESSAGE ///////////////////////////////////////////
export const STATUS_MESSAGE = "STATUS_MESSAGE";
export const CLOSE_STATUS_MESSAGE = "CLOSE_STATUS_MESSAGE";

////////////// DEBUG ///////////////////////////////////////////
export const TRIGGER_500_LOADING = "TRIGGER_500_LOADING";
export const TRIGGER_500_ERROR = "TRIGGER_500_ERROR";
export const TRIGGER_500_SUCCESS = "TRIGGER_500_SUCCESS";

export const TRIGGER_403_LOADING = "TRIGGER_403_LOADING";
export const TRIGGER_403_ERROR = "TRIGGER_403_ERROR";
export const TRIGGER_403_SUCCESS = "TRIGGER_403_SUCCESS";

////////////// CALENDARS ///////////////////////////////////////////
export const GET_CALENDARS_LOADING = "GET_CALENDARS_LOADING";
export const GET_CALENDARS_ERROR = "GET_CALENDARS_ERROR";
export const GET_CALENDARS_SUCCESS = "GET_CALENDARS_SUCCESS";

export const ADD_CALENDAR_LOADING = "ADD_CALENDAR_LOADING";
export const ADD_CALENDAR_ERROR = "ADD_CALENDAR_ERROR";
export const ADD_CALENDAR_SUCCESS = "ADD_CALENDAR_SUCCESS";

export const UPDATE_CALENDAR_LOADING = "UPDATE_CALENDAR_LOADING";
export const UPDATE_CALENDAR_ERROR = "UPDATE_CALENDAR_ERROR";
export const UPDATE_CALENDAR_SUCCESS = "UPDATE_CALENDAR_SUCCESS";

export const DELETE_CALENDAR_LOADING = "DELETE_CALENDAR_LOADING";
export const DELETE_CALENDAR_ERROR = "DELETE_CALENDAR_ERROR";
export const DELETE_CALENDAR_SUCCESS = "DELETE_CALENDAR_SUCCESS";

////////////// CALENDAR ///////////////////////////////////////////
export const ADD_CALENDAR_INSTANCE_LOADING = "ADD_CALENDAR_INSTANCE_LOADING";
export const ADD_CALENDAR_INSTANCE_ERROR = "ADD_CALENDAR_INSTANCE_ERROR";
export const ADD_CALENDAR_INSTANCE_SUCCESS = "ADD_CALENDAR_INSTANCE_SUCCESS";

export const GET_CALENDAR_INSTANCE_LOADING = "GET_CALENDAR_INSTANCE_LOADING";
export const GET_CALENDAR_INSTANCE_ERROR = "GET_CALENDAR_INSTANCE_ERROR";
export const GET_CALENDAR_INSTANCE_SUCCESS = "GET_CALENDAR_INSTANCE_SUCCESS";

export const ADD_CALENDAR_ACTIVITY_LOADING = "ADD_CALENDAR_ACTIVITY_LOADING";
export const ADD_CALENDAR_ACTIVITY_ERROR = "ADD_CALENDAR_ACTIVITY_ERROR";
export const ADD_CALENDAR_ACTIVITY_SUCCESS = "ADD_CALENDAR_ACTIVITY_SUCCESS";

export const UPDATE_CALENDAR_ACTIVITY_LOADING = "UPDATE_CALENDAR_ACTIVITY_LOADING";
export const UPDATE_CALENDAR_ACTIVITY_ERROR = "UPDATE_CALENDAR_ACTIVITY_ERROR";
export const UPDATE_CALENDAR_ACTIVITY_SUCCESS = "UPDATE_CALENDAR_ACTIVITY_SUCCESS";

export const DELETE_CALENDAR_ACTIVITY_LOADING = "DELETE_CALENDAR_ACTIVITY_LOADING";
export const DELETE_CALENDAR_ACTIVITY_ERROR = "DELETE_CALENDAR_ACTIVITY_ERROR";
export const DELETE_CALENDAR_ACTIVITY_SUCCESS = "DELETE_CALENDAR_ACTIVITY_SUCCESS";

////////////// EXTERNAL ACTIVITIES ///////////////////////////////////////////
export const GET_BUC_EVENTS_LOADING = "GET_BUC_EVENTS_LOADING";
export const GET_BUC_EVENTS_ERROR = "GET_BUC_EVENTS_ERROR";
export const GET_BUC_EVENTS_SUCCESS = "GET_BUC_EVENTS_SUCCESS";

export const GET_BYGGFORETAGEN_EVENTS_LOADING = "GET_BYGGFORETAGEN_EVENTS_LOADING";
export const GET_BYGGFORETAGEN_EVENTS_ERROR = "GET_BYGGFORETAGEN_EVENTS_ERROR";
export const GET_BYGGFORETAGEN_EVENTS_SUCCESS = "GET_BYGGFORETAGEN_EVENTS_SUCCESS";

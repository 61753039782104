import React, { useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router-dom";

import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";
import StatusMessage from "components/StatusMessage/StatusMessage";

import useCompanies from "hooks/use-companies";
import useLoggedInUser from "hooks/use-logged-in-user";
import useUsers from "hooks/use-users";
import useSession from "hooks/use-session";

const MainLayout = ({ routes, match }) => {
  const { userIsLoading, userIsUnauthorized } = useLoggedInUser();
  const { isLoading: companiesIsLoading } = useCompanies();
  const { usersIsLoading } = useUsers();
  const { logoutHandler } = useSession();

  const credentialsLoading = userIsLoading || companiesIsLoading || usersIsLoading;
  const { pathname, hash, state } = useLocation();

  useEffect(() => {
    if (state?.disableScroll) {
      return;
    }

    // if not a hash link or an 'edit' path, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, state]); // do this on route change

  return userIsUnauthorized ? (
    <>
      <div className="main main-center">
        <div className="main-unauthorized">
          <div>Ej tillåten användare. Vänligen logga ut och försök igen.</div>
          <button onClick={logoutHandler}>Logga ut</button>
        </div>
      </div>
    </>
  ) : (
    <div className="main">
      {!credentialsLoading && <MainHeader />}
      <main className="main-container">{renderRoutes(routes)}</main>
      {!credentialsLoading && <Footer />}
      <StatusMessage />
    </div>
  );
};

export default MainLayout;

import React, { useEffect, useState } from "react";

import useSupervisors from "hooks/use-supervisors";
import useTextByRole from "hooks/use-text-by-role";

import __ from "constants/static-texts";
import SubHeader from "components/SubHeader/SubHeader";
import SupervisorOverviewList from "./SupervisorsOverviewList";

const CLASS_NS = "supervisors-overview";

const SupervisorsOverview = ({ classNs = CLASS_NS }) => {
  const { supervisorsAreLoading, filteredSupervisors, activeSupervisor } = useSupervisors();

  const [supervisorList, setSupervisorList] = useState([]);
  useEffect(() => {
    if (!activeSupervisor) {
      return;
    }
    const sortedSupervisors = filteredSupervisors.reduce((arr, sv) => {
      if (sv.id === activeSupervisor.id) {
        arr.unshift({ ...sv, companySupervisor: true });
      } else {
        arr.push(sv);
      }
      return arr;
    }, []);
    setSupervisorList(sortedSupervisors);
  }, [activeSupervisor]); // eslint-disable-line react-hooks/exhaustive-deps

  const supervisorsOverviewTitle = useTextByRole(__.supervisorsOverview.title);
  const supervisorsOverviewText = useTextByRole(__.supervisorsOverview.description);

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{supervisorsOverviewTitle}</h1>
        <div className={`${classNs}_description`}>
          {supervisorsOverviewText.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </SubHeader>
      <div className={`${classNs}`}>
        {!supervisorsAreLoading ? (
          <SupervisorOverviewList users={!activeSupervisor ? filteredSupervisors : supervisorList} classNs={classNs} />
        ) : null}
      </div>
    </>
  );
};

export default SupervisorsOverview;

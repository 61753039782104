import React, { useRef /* , useEffect */ } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Wrapper, Button, Menu, MenuItem } from "react-aria-menubutton";
import { formatDate } from "utils/date-helpers";
import { notificationTypes } from "constants/notification-types";
import { guideSectionTypes } from "constants/guide-types";
import { /* getNotifications ,*/ setNotificationRead } from "actions/notifications-actions";
import { formatDateInput } from "utils/date-helpers";

import { setActiveComment } from "actions/guide-actions";
import Tooltip from "components/Tooltip/Tooltip";
import ReactTooltip from "react-tooltip";
import { ReactComponent as Megaphone } from "icons/Megaphone.svg";

const CLASS_NS = "notifications";

const Notifications = ({ classNs = CLASS_NS, notifications, newNotifications }) => {
  const dispatch = useDispatch();

  const tooltipEl = useRef(null);

  const newExist = newNotifications.length > 0;

  const onNotificationClick = (notification) => {
    const isSectionComment = notification.type === notificationTypes.SECTION_COMMENT_CREATED;
    const isRequirementComment = notification.type === notificationTypes.REQUIREMENT_COMMENT_CREATED;
    if (isSectionComment || isRequirementComment) {
      dispatch(
        setActiveComment(
          isSectionComment
            ? `${guideSectionTypes.GUIDE_SECTION}_${notification.details.section.id}`
            : `${guideSectionTypes.GUIDE_REQUIREMENT}_${notification.details.requirement.id}`
        )
      );
    }
    dispatch(setNotificationRead(notification.id));
  };

  const hideTooltip = () => {
    ReactTooltip.hide(tooltipEl.current);
  };

  const notificationTemplates = {
    [notificationTypes.SECTION_COMMENT_CREATED]: (notification) => (
      <>
        {notification.details.byUser.fullName} har lagt en kommentar på avsnittet{" "}
        <span className="name">{notification.details.section.title}</span>
      </>
    ),
    [notificationTypes.REQUIREMENT_COMMENT_CREATED]: (notification) => (
      <>
        {notification.details.byUser.fullName} har lagt en kommentar på kravet{" "}
        <span className="name">{notification.details.requirement.title}</span> i avsnittet{" "}
        <span className="name">{notification.details.requirement.parentSection.title}</span>
      </>
    ),
    [notificationTypes.SECTION_ASSIGNED]: (notification) => (
      <>
        {notification.details.byUser.fullName} tilldelade {notification.details.toUser.fullName} avsnittet{" "}
        <span className="name">{notification.details.section.title}</span>
      </>
    ),
    [notificationTypes.REQUIREMENT_ASSIGNED]: (notification) => (
      <>
        {notification.details.byUser.fullName} tilldelade {notification.details.toUser.fullName} kravet{" "}
        <span className="name">{notification.details.requirement.title}</span> i avsnittet{" "}
        <span className="name">{notification.details.requirement.parentSection.title}</span>
      </>
    ),
    [notificationTypes.SECTION_ASSIGNED_DATE_CHANGED]: (notification) => (
      <>
        Slutdatumet för avsnittet <span className="name">{notification.details.section.title}</span> ändrades av{" "}
        {notification.details.byUser.fullName} till{" "}
        <span className="name">{formatDateInput(notification.details.section.assignmentDeadline)}</span>
      </>
    ),
    [notificationTypes.REQUIREMENT_ASSIGNED_DATE_CHANGED]: (notification) => (
      <>
        Slutdatumet för kravet <span className="name">{notification.details.requirement.title}</span> i avsnittet{" "}
        <span className="name">{notification.details.requirement.parentSection.title}</span> ändrades av{" "}
        {notification.details.byUser.fullName} till{" "}
        <span className="name">{formatDateInput(notification.details.requirement.assignmentDeadline)}</span>
      </>
    ),
  };
  const suffix = newNotifications.length === 1 ? "oläst" : "olästa";
  const toolTipText = `Mina notiser ${newExist ? "(" + newNotifications.length + " " + suffix + ")" : ""}`;
  return (
    <>
      <Wrapper
        className={`${classNs}`}
        data-tip={toolTipText}
        data-for={"notifications"}
        ref={tooltipEl}
        onClick={hideTooltip}>
        <Button className={`${classNs}_button`} data-new={newExist}>
          <div className="icon-megaphone">
            <Megaphone />
          </div>
        </Button>
        <Menu className={`${classNs}_menu`}>
          <div className={`${classNs}_menu_top`}>Notifikationer</div>
          {notifications.length > 0 && (
            <div className={`${classNs}_menu_content`}>
              {notifications.map((notification, index) => {
                return (
                  <MenuItem key={index} className={`${classNs}_menu_item`} data-read={notification.isRead}>
                    <Link
                      to={notification.path}
                      onClick={() => onNotificationClick(notification)}
                      className={`${classNs}_menu_item_row`}
                      key={index}>
                      <label className={`${classNs}_menu_item_row_label`}>
                        <span className="date">{`${formatDate(notification.createdAt)}`}</span>
                      </label>
                      <div className={`${classNs}_menu_item_row_text`}>
                        {notificationTemplates[notification.type](notification)}
                      </div>
                    </Link>
                  </MenuItem>
                );
              })}
            </div>
          )}
        </Menu>
        <div className={`${classNs}`}></div>
      </Wrapper>
      <Tooltip id={"notifications"} />
    </>
  );
};

export default Notifications;

import {useState, useDebugValue} from "react";
/**
 * A wrapper for the useState hook which displays the hook label
 * in dev tools.
 * https://stackoverflow.com/questions/57659640/is-there-any-way-to-see-names-of-fields-in-react-multiple-state-with-react-dev/58579953#58579953
 * https://reactjs.org/docs/hooks-reference.html#usedebugvalue
 */
const useStateWithLabel= (initialValue, label)=>{
    const [value, setValue] = useState(initialValue);
    useDebugValue(`${label}: ${value}`);
    return [value, setValue];
}
export default useStateWithLabel;

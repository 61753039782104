import React from "react";
import { Link } from "react-router-dom";

const CLASS_NS = "new-users";

const NewUsers = ({ classNs = CLASS_NS }) => {
    return (
        <section className={`${classNs}`}>
            <header className={`${classNs}_header`}>
                <h2 className={`${classNs}_title`}>Nya användare</h2>
            </header>
            <div className={`${classNs}_content`}>
                <p>Du har nya användare att behandla</p>
                <Link to="/users">Behandla nya användare</Link>
            </div>
        </section>
    );
}

export default NewUsers;
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getNotifications } from "actions/notifications-actions";

import { WAITING, LOADING } from "constants/network-states";
import { notificationTypes } from "constants/notification-types";

import useLoggedInUser from "hooks/use-logged-in-user";

import { getGuidePath } from "routes/Guides";

/**
 * Provides the guides
 */
const useNotifications = () => {
  const dispatch = useDispatch();
  const { user: oidcUser } = useSelector((state) => state.oidc);
  const { notifications, notificationsNetworkState } = useSelector((state) => state.notificationsState);

  const { isServiceAdmin } = useLoggedInUser();

  const { guide, guideMeta } = useSelector((state) => state.guideState);

  const fetchNotifications = () => {
    notifications.length === 0 && notificationsNetworkState === WAITING && dispatch(getNotifications());
  };

  const notificationsIsLoading = notificationsNetworkState === WAITING || notificationsNetworkState === LOADING;

  useEffect(() => {
    if (oidcUser && guideMeta && !isServiceAdmin) {
      fetchNotifications();
    }
  }, [oidcUser, guideMeta]); // eslint-disable-line react-hooks/exhaustive-deps
  const existingNotifications = guideMeta?.id
    ? notifications
        .map((notification) => {
          let path = null;
          switch (notification.type) {
            case notificationTypes.SECTION_COMMENT_CREATED:
            case notificationTypes.SECTION_ASSIGNED:
            case notificationTypes.SECTION_ASSIGNED_DATE_CHANGED:
              path = getGuidePath(guide, guideMeta?.id, notification.details.section?.id);
              break;
            case notificationTypes.REQUIREMENT_COMMENT_CREATED:
            case notificationTypes.REQUIREMENT_ASSIGNED:
            case notificationTypes.REQUIREMENT_ASSIGNED_DATE_CHANGED:
              path = getGuidePath(
                guide,
                guideMeta?.id,
                notification.details.requirement?.parentSection?.id,
                notification.details.requirement.id
              );
              break;

            default:
              break;
          }
          return path
            ? {
                ...notification,
                path,
              }
            : null;
        })
        .filter((notification) => notification)
    : [];

  const unReadNotifications = existingNotifications.filter((notification) => !notification.isRead);

  return { notifications: existingNotifications, notificationsIsLoading, unReadNotifications };
};

export default useNotifications;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCompanies } from "actions/companies-actions";

import { WAITING, LOADING, SUCCESS } from "constants/network-states";
// import Fuse from "fuse.js";
import { searchArray } from "utils/search-helper";

/**
 * Provides the documents list
 */
const useCompanies = () => {
  const dispatch = useDispatch();
  const {
    companies,
    companiesMap,
    companiesNetworkState,
    searchQuery,
    activeCompany,
    ssoCompanyUsers,
    ssoCompanyUsersNetworkState,
  } = useSelector((state) => state.companiesState);
  const { user, loggedInUserNetworkState } = useSelector((state) => state.loggedInUserState);

  const fetchCompanies = () => {
    companiesNetworkState === WAITING && dispatch(getCompanies());
  };

  const companiesIsLoading = companiesNetworkState === WAITING || companiesNetworkState === LOADING;

  const ssoCompanyUsersLoading = ssoCompanyUsersNetworkState === WAITING || ssoCompanyUsersNetworkState === LOADING;

  useEffect(() => {
    if (loggedInUserNetworkState === SUCCESS && user) {
      fetchCompanies();
    }
  }, [loggedInUserNetworkState, user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (searchQuery === "") {
    return {
      companies,
      companiesMap,
      companiesIsLoading,
      filteredCompanies: companies,
      activeCompany,
      ssoCompanyUsers,
      ssoCompanyUsersLoading,
    };
  }

  const filteredCompanies = searchArray(companies, ["companyRegistrationNumber", "name"], searchQuery);
  return {
    companies,
    companiesMap,
    companiesIsLoading,
    filteredCompanies,
    activeCompany,
    ssoCompanyUsers,
    ssoCompanyUsersLoading,
  };
};

export default useCompanies;

import fetchClient from "fetch-client";

export const getByggforetagenEvents = (getState) =>
  fetchClient({
    path: `/app/cache/ungapped/Events-ost.json`,
    apiBase: `https://byggforetagen.se`,
    contentType: "form",
    skipAuthorization: true,
    getState,
  });

import React from "react";
const Styleguide = (props) => {
  return (
    <>
      <div className="styleguide" style={{ marginBottom: "2rem" }}>
        <div style={{ padding: "10px" }}>
          <p>
            <button className="button">.button</button>
            <button disabled className="button">
              disabled .button
            </button>
          </p>
          <p>
            <button className="button-secondary">.button-secondary</button>
            <button disabled className="button-secondary">
              disabled .button-secondary
            </button>
          </p>
          <p>
            <button className="button-squareicon">
              <span className="button-squareicon_label">.button-squareicon</span>
              <span className="button-squareicon_icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    d="M8 1v14M1 8h14"
                  />
                </svg>
              </span>
            </button>
            <button className="button-squareicon" disabled>
              <span className="button-squareicon_label">.button-squareicon disabled</span>
              <span className="button-squareicon_icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    d="M8 1v14M1 8h14"
                  />
                </svg>
              </span>
            </button>
          </p>
          <h4>.button-iconbutton [default/active/disabled]</h4>
          <p>
            <button className="button-iconbutton">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="inherit"
                  stroke="inherit"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M5.83 9.974h3.828c2.115 0 3.83 1.929 3.83 4.308V15H2v-.718c0-2.38 1.714-4.308 3.83-4.308zM4.513 4.23a3.23 3.23 0 1 1 6.46.001 3.23 3.23 0 0 1-6.46-.001z"
                />
              </svg>
            </button>
            <button className="button-iconbutton" data-active="true">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="inherit"
                  stroke="inherit"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M5.83 9.974h3.828c2.115 0 3.83 1.929 3.83 4.308V15H2v-.718c0-2.38 1.714-4.308 3.83-4.308zM4.513 4.23a3.23 3.23 0 1 1 6.46.001 3.23 3.23 0 0 1-6.46-.001z"
                />
              </svg>
            </button>
            <button className="button-iconbutton" disabled>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="inherit"
                  stroke="inherit"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M5.83 9.974h3.828c2.115 0 3.83 1.929 3.83 4.308V15H2v-.718c0-2.38 1.714-4.308 3.83-4.308zM4.513 4.23a3.23 3.23 0 1 1 6.46.001 3.23 3.23 0 0 1-6.46-.001z"
                />
              </svg>
            </button>
            <button
              className="button-iconbutton"
              data-notification="true"
              style={{ width: "32px", height: "36px", display: "inline-block" }}>
              <svg version="1.1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="clipPath64">
                    <path
                      d="m0 0v30h30v-30h-30zm24 1.3c2.9 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2c0.1-2.9 2.3-5.2 5.2-5.2z"
                      fill="green"
                      fill-opacity=".915"
                    />
                  </clipPath>
                </defs>
                <path
                  d="m12.1 18h5.7c3.2 0 5.7 2.9 5.7 6.5v1.1h-17.1v-1.1c0-3.6 2.6-6.5 5.7-6.5v0zm-1.9-8.7c0-2.7 2.2-4.8 4.8-4.8 2.7 0 4.8 2.2 4.8 4.8s-2.2 4.8-4.8 4.8c-2.7 0.1-4.8-2.1-4.8-4.8v0z"
                  clip-path="url(#clipPath64)"
                  fill="inherit"
                  stroke="inherit"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
                <circle id="indicator" cx="24" cy="6.5" r="4" fill="#f00" strokeWidth="0" />
              </svg>
            </button>
            <button
              className="button-iconbutton"
              data-notification="true"
              style={{ width: "32px", height: "36px", display: "inline-block" }}>
              <svg version="1.1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="clipPath64">
                    <path
                      d="m0 0v30h30v-30h-30zm24 1.3c2.9 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2c0.1-2.9 2.3-5.2 5.2-5.2z"
                      fill="green"
                      fillOpacity=".915"
                    />
                  </clipPath>
                </defs>
                <path
                  d="m5.8 5.1c-0.7 0-1.3 0.6-1.3 1.3v0 13.2c0 0.7 0.5 1.4 1.2 1.4h0.1 5.4l3.3 3.9 3.3-3.9h6.3c0.2 0 0.3 0 0.5-0.1h0.1c0.1-0.1 0.3-0.2 0.4-0.3v0c0.1-0.1 0.2-0.2 0.2-0.4v-0.1c0.1-0.1 0.1-0.3 0.1-0.4v-9.2 0-4.1c0-0.7-0.6-1.3-1.3-1.3v0h-18.3z"
                  clip-path="url(#clipPath64)"
                  fill="inherit"
                  stroke="inherit"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <circle id="indicator" cx="24" cy="6.5" r="4" fill="#f00" strokeWidth="0" />
              </svg>
            </button>
            <button
              className="button-iconbutton"
              data-notification="true"
              style={{ width: "32px", height: "36px", display: "inline-block" }}>
              <svg version="1.1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="clipPath1177">
                    <path
                      d="m0 0v30h30v-30zm24 1.3c2.9 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2c0.1-2.9 2.3-5.2 5.2-5.2z"
                      fill="green"
                      fillOpacity=".915"
                    />
                  </clipPath>
                </defs>
                <path
                  d="m25 10s1 0.77199 1 3-1 3-1 3m-3.9043-10.357-0.15039 0.037109-6.6582 2.6836-0.08984 0.037109h-6.0703l-0.48828 0.025391-0.45117 0.074219-0.43359 0.11914-0.38086 0.14844-0.4082 0.21289-0.0097657 0.0058593-0.33789 0.22266-0.33984 0.27539-0.31641 0.31055-0.26172 0.31055-0.00781 0.01172-0.25977 0.37891-0.00781 0.01172-0.20117 0.36133-0.18555 0.41602-0.14453 0.4375-0.10742 0.44922-0.0625 0.43164v0.0078l-0.021484 0.48828 0.023437 0.48242 0.060547 0.42578 0.00195 0.0098 0.11328 0.45703 0.1543 0.44336 0.00391 0.0098 0.17969 0.39453 0.2168 0.37891 0.26172 0.36914 0.28711 0.33984 0.31055 0.30274 0.33984 0.27148 0.36719 0.24219 0.38867 0.20508 0.38477 0.15039 0.44141 0.12305 0.40625 0.06836 0.48438 0.02734h1.4395l0.14844 0.2207 3.6855 5.4805 0.0059 0.0078 0.0078 0.01172 0.08008 0.10742 0.06641 0.05664 0.08594 0.04883 0.07227 0.02148 0.09961 0.0098 0.10156-0.0098 0.10547-0.0293 0.11719-0.05859 0.08594-0.0625 0.05664-0.06641 0.04883-0.08594 0.02149-0.07227 0.0098-0.09961-0.0098-0.10156-0.0293-0.10547-0.0625-0.12695-2.8809-4.3711-0.50976-0.77539h3.4238l0.08984 0.03711 6.6562 2.6816 0.16211 0.03711 0.1582 0.0039 0.04102-0.0039 0.02734-0.0098 0.01953-0.0078 0.03906-0.03125 2e-3 -2e-3h2e-3l0.07617-0.05859 0.03516-0.03711 0.03516-0.04687 0.0039-0.0059 2e-3 -0.0039 0.04102-0.13281 0.01172-0.14258v-13.957l-0.0039-0.048828-0.01172-0.054687-0.01563-0.035156-0.02148-0.041016-0.09766-0.11719-0.13477-0.11133-0.05859-0.035156-0.05078-0.021484-0.03906-0.011719-0.04297-0.00586z"
                  clip-path="url(#clipPath1177)"
                  fill="none"
                  stroke="inherit"
                  stroke-linecap="round"
                  strokeWidth="2"
                />
                <circle id="indicator" cx="24" cy="6.5" r="4" fill="#f00" strokeWidth="0" />
              </svg>
            </button>
            <span style={{ fontSize: "10px", fontStyle: "italic" }}>(megaphone doesn't have a fill on hover)</span>
          </p>
        </div>
        <div style={{ backgroundColor: "#f6f8f9", padding: "10px" }}>
          <div>
            <button className="button-inverted">.button-inverted</button>
            <button disabled className="button-inverted">
              disabled .button-inverted
            </button>
          </div>
          <div style={{ backgroundColor: "#f6f8f9", marginBottom: "2rem" }}>
            <div>
              <button className="button-inverted">.button-inverted</button>
              <button disabled className="button-inverted">
                disabled .button-inverted
              </button>
            </div>
            <div>
              <button className="button-secondary-inverted">.button-secondary-inverted</button>
              <button disabled className="button-secondary-inverted">
                disabled .button-secondary-inverted
              </button>
            </div>
          </div>
        </div>
      </div>
      <section style={{ marginBottom: "2rem" }}>
        <h3>Text input</h3>
        <input type="text" value="input[type='text']" style={{ marginRight: "1rem" }} onChange={() => {}} />
        <input
          type="text"
          onChange={() => {}}
          value="input[type='text'][data-active='true']"
          data-active="true"
          size="28"
          style={{ marginRight: "1rem" }}
        />
        <input type="text" value="input[type='text'][disabled]" disabled size="20" onChange={() => {}} />
        <p>
          <textarea type="text" value="textarea" onChange={() => {}} />
        </p>
      </section>
      <div>
        <h3>Selector</h3>
        <div className="Selector" data-state-open="true" data-state-disabled="false" data-state-active="false">
          <span
            role="button"
            tabIndex="0"
            aria-haspopup="true"
            aria-expanded="true"
            aria-disabled="false"
            className="Selector_button"
            data-disabled="false">
            <span className="Selector_label">Välj arbetsbok</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
              className="Selector_icon">
              <path fill="none" viewBox="0 0 16 16" className="Selector_icon">
                fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m3 6 5
                5 5-5">
              </path>
            </svg>
          </span>
          <ul role="menu" tabIndex="-1" className="Selector_list" data-direction="vertical">
            <li
              role="menuitem"
              tabIndex="-1"
              className="Selector_option"
              label="Äventyrets testarbetsbok"
              data-active="false">
              Äventyrets testarbetsbok
            </li>
            <li
              role="menuitem"
              tabIndex="-1"
              className="Selector_option"
              label="Arbetsboken för BKMA ISO-certifiering"
              data-active="false">
              Arbetsboken för BKMA ISO-certifiering
            </li>
            <li
              role="menuitem"
              tabIndex="-1"
              className="Selector_option"
              label="Arbetsboken för BKMA Certifiering"
              data-active="false">
              Arbetsboken för BKMA Certifiering
            </li>
          </ul>
        </div>
      </div>
      )} */}
    </>
  );
};

export default Styleguide;

import states from "constants/status-states";
const getMessageFromStatusCode = function (status, defaultError) {
  let message = {
    status: states.ERROR,
    actionLabel: "Börja om",
    statusCode: status,
  };

  switch (status) {
    case 400:
      message.text = defaultError?.title;
      message.isOpen = true;
      message.keepOpen = false;
      break;
    case 403:
      message.text = "Du har inte rätt behörighet.";
      message.actionLabel = "Ladda om sidan";
      message.actionFn = () => {
        window.location.reload();
      };
      message.isOpen = true;
      message.keepOpen = true;
      break;
    case 404:
      message.text = "Api-anropet misslyckades.";
      message.actionLabel = "Ladda om sidan";
      message.actionFn = () => {
        window.location.reload();
      };
      message.isOpen = true;
      message.keepOpen = true;
      break;
    case 500:
      message.text = "Det uppstod problem när innehållet skulle laddas.";
      message.actionLabel = "Ladda om sidan";
      message.actionFn = () => {
        window.location.reload();
      };
      message.isOpen = true;
      message.keepOpen = true;
      break;
    default:
      return null;
  }
  return message;
};

export const getMessage = function (status, defaultError) {
  let message = getMessageFromStatusCode(status, defaultError);
  return message ? message : { text: "", status: "", isOpen: false, keepOpen: false };
};

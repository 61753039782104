import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { userManager } from "store";
import Loading from "components/Loading/Loading";

const Login = (props) => {
  const { userWillSignout } = useSelector((state) => state.authState);

  const [state, setState] = useState({
    isFetching: true,
    errorMessage: "",
    errorVisible: false,
  });

  useEffect(() => {
    // this prevents automatic signin while signout is in progress
    if (userWillSignout) return;

    /*
      The role of the login container is to trigger a signinRedirect flow if the route is ever accessed.
      This allows us to redirect to /login whenever we find that the user is not authenticated, and have login
      handle a signin appropriately, either show login page or do a refresh and redirect back to the requested url.
    */
    userManager
      .signinRedirect({ data: { redirectUrl: props.redirectPath } })
      .then(() => {})
      .catch((e) => {
        setState({ ...state, isFetching: false, errorMessage: e.message, errorVisible: true });
      });
  });

  /*
    The login component should never be rendered to the user, unless the redirect throws. So this code is just here as bolierplate,
    and acts as an error screen since if redirect throws we likely have a problem with the sso-server.
  */
  return (
    <div className="main">
      {state.isFetching ? (
        <div className="main-container-login">
          <Loading />
        </div>
      ) : (
        <div>{process.env.NODE_ENV === "development" ? state.errorMessage : ""}</div>
      )}
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.object,
  userWillSignout: PropTypes.bool,
};

export default Login;

import { useMemo } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";

import useGuide from "hooks/use-guide";
import useCompanies from "hooks/use-companies";

import { guideSectionTypes } from "constants/guide-types";

import { sortArray } from "utils/js-helpers";

/**
 * Provides the sub routes for the guide
 */
const useGuideParts = (companyGuideId) => {
  const guideUrl = "/guides/:guideId/guide/:partId?/:sectionId?";
  const routeMatch = useRouteMatch(guideUrl);
  const { pathname } = useLocation();

  const { activeCompany } = useCompanies();
  // const guideId = routeMatch ? parseInt(routeMatch.params.guideId) : null;
  // console.log("act", activeCompany);
  const guideId = activeCompany?.guideId || companyGuideId || null;

  const [guide] = useGuide(guideId, activeCompany?.isGuideOpen);
  const guideParts = useMemo(() => guide?.filter((block) => block.type === guideSectionTypes.GUIDE_PART), [guide]);
  const allPartRoutes =
    (guideParts.length &&
      guideParts
        .map((route) => ({
          ...route,
          label: route.title,
          path: `/guides/${route.parentId}/guide/${route.id}`,
          partId: route.id,
          isDoneCount: guide.filter((block) => block.parentId === route.id && block.isDone).length,
          childCount: guide.filter((block) => block.parentId === route.id).length,
        }))
        .sort(sortArray("index"))) ||
    [];

  const activePartRoute = allPartRoutes.find((route) => pathname.startsWith(route.path)) || allPartRoutes[0];

  const allSections = useMemo(() => guide?.filter((block) => block.type === guideSectionTypes.GUIDE_SECTION), [guide]);

  const allSectionRoutes = allSections
    .map((route) => {
      const mainRequirementsOnRoute = guide.filter((block) => block.parentId === route.id);

      return {
        ...route,
        label: route.title,
        path: `/guides/${activePartRoute.parentId}/guide/${route.parentId}/${route.id}`,
        sectionId: route.id,
        isDoneCount: guide.filter(block => 
          block.isDone && 
          block.isEnabled && 
          mainRequirementsOnRoute
            .filter(mainRequirement => block.parentId === mainRequirement.id).length
          ).length,
        childCount: guide.filter(block => 
          block.isEnabled && 
          mainRequirementsOnRoute
            .filter(mainRequirement => block.parentId === mainRequirement.id).length
          ).length,
      };
    })
    .sort((sectionA, sectionB) => {
      const partA = allPartRoutes.find((part) => part.id === sectionA.parentId);
      const partB = allPartRoutes.find((part) => part.id === sectionB.parentId);
      if (!partA || !partB) {
        return 0;
      }
      return partA.index < partB.index
        ? -1
        : partA.index > partB.index
        ? 1
        : sectionA.index < sectionB.index
        ? -1
        : sectionA.index > sectionB.index
        ? 1
        : 0;
    });

  const currentSectionRoutes = allSectionRoutes.filter((section) => section.parentId === activePartRoute?.partId);

  const activeSectionRoute = currentSectionRoutes.find((route) => route.path === pathname) || currentSectionRoutes[0];

  const progressParts = allPartRoutes.reduce(
    (acc, part) => {
      acc.done = acc.done + part.isDoneCount;
      acc.total = acc.total + part.childCount;
      return acc;
    },
    {
      done: 0,
      total: 0,
    }
  );

  const progressSections = allSectionRoutes.reduce(
    (acc, part) => {
      acc.done = acc.done + part.isDoneCount;
      acc.total = acc.total + part.childCount;
      return acc;
    },
    {
      done: 0,
      total: 0,
    }
  );

  const progress = {
    done: progressParts.done + progressSections.done,
    total: progressParts.total + progressSections.total,
    doneParts: progressParts.done,
    totalParts: progressParts.total,
  };

  return {
    allPartRoutes,
    activePartRoute,
    allSectionRoutes,
    currentSectionRoutes,
    activeSectionRoute,
    routeMatch,
    progress,
  };
};

export default useGuideParts;

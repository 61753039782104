import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCompanies from "hooks/use-companies";
import __ from "constants/static-texts";

import { PRODUCT_TYPES } from "constants/product-types";

const CLASS_NS = "supervisor";

const Supervisor = ({ classNs = CLASS_NS }) => {
  const { companies, activeCompany } = useCompanies();
  const { users } = useSelector((state) => state.usersState);

  const [supervisor, setSupervisor] = useState(null);

  useEffect(() => {
    const company = activeCompany ? activeCompany : companies.length === 1 ? companies[0] : null;
    const sv = users.find((sp) => sp.id === company?.supervisorUserId);
    setSupervisor(sv);
  }, [users, activeCompany, companies]);

  return (
    (activeCompany && activeCompany.product !== PRODUCT_TYPES.ORDNING.id && (
      <div className={`${classNs}`}>
        <h3>{__.supervisors.selected}</h3>
        {supervisor ? (
          <>
            <div className={`${classNs}_row`}>{`${supervisor.firstName} ${supervisor.lastName}`}</div>
            <div className={`${classNs}_row`}>
              <span>{__.general.email}:</span>
              <a className={`sub-footer_anchor`} href={`mailto:${supervisor.userName}`}>
                {supervisor.userName}
              </a>
            </div>
          </>
        ) : (
          <div className={`${classNs}_row`}>{__.supervisors.missing}</div>
        )}
      </div>
    )) ||
    null
  );
};
export default Supervisor;

import LibraryDocs from "./components/LibraryDocs";
import EditLibraryDoc from "./components/EditLibraryDocument";
import DeleteLibraryDoc from "./components/DeleteLibraryDoc";
import LibraryDocumentVersions from "./components/LibraryDocumentVersions";

import { UserIsAuthenticated } from "utils/auth-helpers";
import Passthrough from "routes/Passthrough";

const LibraryDocsRoute = () => ({
  path: "/documents", // include the additional slug for testing current route
  component: UserIsAuthenticated(LibraryDocs),
  // exact: true,
  routes: [
    {
      path: "/documents/:documentId?",
      exact: true,
      component: Passthrough,
    },
    {
      path: "/documents/:id/edit",
      exact: true,
      component: EditLibraryDoc,
    },
    {
      path: "/documents/:id/versions",
      exact: true,
      component: LibraryDocumentVersions,
    },
    {
      path: "/documents/:id/delete",
      exact: true,
      component: DeleteLibraryDoc,
    },
  ],
});

export default LibraryDocsRoute;

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import guidesReducer from "reducers/guides-reducer";
import guideReducer from "reducers/guide-reducer";
import { sectionReducer } from "react-draft-editor";
import { reducer as oidcReducer } from "redux-oidc";
import authReducer from "reducers/auth-reducer";
// import searchReducer from "reducers/search-reducer"
// import annexReducer from "reducers/annex-reducer"
import librarydocumentsReducer from "reducers/librarydocuments-reducer";
import companyDocsReducer from "reducers/companyDocs-reducer";
import productTypesReducer from "reducers/productType-reducer";
import companiesReducer from "reducers/companies-reducer";
import loggedInUserReducer from "reducers/logged-in-user-reducer";
import usersReducer from "reducers/users-reducer";
import userRolesReducer from "reducers/userRoles-reducer";
import nonValidatedUsersReducer from "./non-validated-users-reducer";
import workbookReducer from "reducers/workbook-reducer";
import workbooksReducer from "reducers/workbooks-reducer";
import commentsReducer from "reducers/comments-reducer";
import notificationsReducer from "reducers/notifications-reducer";
import newsReducer from "reducers/news-reducer";
import layoutReducer from "reducers/layout-reducer";
import calendarsReducer from "reducers/calendars-reducer";
import calendarReducer from "reducers/calendar-reducer";
import bucReducer from "reducers/buc-reducer";
import byggforetagenReducer from "reducers/byggforetagen-reducer";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    sectionState: sectionReducer,
    guideState: guideReducer,
    guidesState: guidesReducer,
    companiesState: companiesReducer,
    oidc: oidcReducer,
    authState: authReducer,
    librarydocumentsState: librarydocumentsReducer,
    companyDocsState: companyDocsReducer,
    commentsState: commentsReducer,
    productTypesState: productTypesReducer,
    loggedInUserState: loggedInUserReducer,
    usersState: usersReducer,
    userRolesState: userRolesReducer,
    nonValidatedUsersState: nonValidatedUsersReducer,
    workbookState: workbookReducer,
    workbooksState: workbooksReducer,
    notificationsState: notificationsReducer,
    newsState: newsReducer,
    layoutState: layoutReducer,
    calendarsState: calendarsReducer,
    calendarState: calendarReducer,
    bucState: bucReducer,
    byggforetagenState: byggforetagenReducer,
    //   annexState: annexReducer,
    //   propertyState: propertyReducer
  });

export default reducers;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyDocuments } from "actions/companydocuments-actions";

import { WAITING, LOADING } from "constants/network-states";
import { searchArray } from "utils/search-helper";
import useCompanies from "hooks/use-companies";

/**
 * Provides the company documents list
 */
const useCompanyDocuments = () => {
  const dispatch = useDispatch();
  const { documents, documentsMap, documentsNetworkState, archiveDownloadNetworkState } = useSelector(
    (state) => state.companyDocsState
  );
  const { searchQuery } = useSelector((state) => state.librarydocumentsState);
  const { activeCompany } = useCompanies();

  const fetchDocuments = () => {
    documents.length === 0 && documentsNetworkState === WAITING && dispatch(getCompanyDocuments());
  };

  const isLoading = documentsNetworkState === WAITING || documentsNetworkState === LOADING;
  const archiveDownloadIsLoading = archiveDownloadNetworkState === LOADING;

  useEffect(() => {
    fetchDocuments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (searchQuery === "" && activeCompany === null) {
    return {
      companyDocuments: documents,
      companyDocumentsMap: documentsMap,
      companyDocumentsIsLoading: isLoading,
      filteredCompanyDocuments: documents,
      archiveDownloadIsLoading,
    };
  }

  const filteredDocuments =
    searchQuery.length <= 2 ? documents : searchArray(documents, ["title", "description"], searchQuery);

  const filteredByCompany = filteredDocuments.filter((resultItem) => {
    return !activeCompany || activeCompany.id === resultItem.companyId;
  });

  return {
    companyDocuments: documents,
    companyDocumentsMap: documentsMap,
    companyDocumentsIsLoading: isLoading,
    filteredCompanyDocuments: filteredByCompany,
    archiveDownloadIsLoading,
  };
};

export default useCompanyDocuments;

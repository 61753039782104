import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCalendars } from "actions/calendar-actions";

import { WAITING, LOADING, SUCCESS } from "constants/network-states";

/**
 * Provides the calendars
 */
const useCalendars = () => {
  const dispatch = useDispatch();
  const { user, loggedInUserNetworkState } = useSelector((state) => state.loggedInUserState);
  const { calendars, calendarsNetworkState } = useSelector((state) => state.calendarsState);

  const fetchCalendars = () => {
    calendars.length === 0 && calendarsNetworkState === WAITING && dispatch(getCalendars());
  };

  const calendarsIsLoading = calendarsNetworkState === WAITING || calendarsNetworkState === LOADING;

  useEffect(() => {
    if(loggedInUserNetworkState === SUCCESS && user) fetchCalendars();
  }, [loggedInUserNetworkState, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return { calendars, calendarsIsLoading };
};

export default useCalendars;

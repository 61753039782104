import React from "react";
import { useDispatch } from "react-redux";
import { deleteCompanyDocumentVersion, downloadCompanyDocumentVersion } from "actions/companydocuments-actions";
import __ from "constants/static-texts";
import ButtonLink from "components/ButtonLink/ButtonLink";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import { ReactComponent as TrashIcon } from "icons/trash.svg";
import { ReactComponent as BackIcon } from "icons/back.svg";
import { ReactComponent as DownloadIcon } from "icons/Download.svg";
import { formatDate } from "utils/date-helpers";
import TypographyHeadingMeta from "components/TypographyHeadingMeta/TypographyHeadingMeta";

const CLASS_NS = "form_overlay";

const EditCompanyDocumentVersions = ({ documentId, companyDocument, clickVersionsBack }) => {
  const dispatch = useDispatch();

  const handleDeleteVersion = (version) => {
    dispatch(deleteCompanyDocumentVersion(parseInt(documentId), version.id));
  };

  const handleDownloadVersion = (version) => {
    dispatch(downloadCompanyDocumentVersion(parseInt(documentId), version.id, version.fileName));
  };

  return (
    <div className={CLASS_NS}>
      <h2 className={`${CLASS_NS}_heading`}>{__.document.versionsHeading(companyDocument.title)}</h2>
      <List type="ol">
        {companyDocument.versions.map((version) => (
          <ListItem
            key={version.id}
            actions={
              <>
                <ButtonIcon
                  icon={<DownloadIcon />}
                  onClick={() => handleDownloadVersion(version)}
                  aria-label={__.document.download}
                />
                {companyDocument.versions.length > 1 && (
                  <ButtonIcon
                    icon={<TrashIcon />}
                    onClick={() => handleDeleteVersion(version)}
                    aria-label={__.document.delete}
                  />
                )}
              </>
            }>
            <TypographyHeadingMeta
              heading={version.fileName}
              headingLevel={2}
              meta={
                <span>
                  Uppladdad <time>{formatDate(version.uploadedAt)}</time> av {version.uploadedByUser.fullName}
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
      <ButtonLink icon={<BackIcon aria-hidden />} onClick={clickVersionsBack}>
        {__.document.versionsBack(companyDocument.title)}
      </ButtonLink>
    </div>
  );
};

export default EditCompanyDocumentVersions;

import React, { useEffect, useState } from "react";
import { formatMonthYear } from "utils/date-helpers";
import "./_CertifiedBadge.scss";

const classNs = "certified-badge";

const CertifiedBadge = ({ company }) => {
  const [texts, setTexts] = useState(null);
  const [certifiedYear, setCertifiedYear] = useState(2029);

  useEffect(() => {
    const date = new Date(company.certification.to);
    const year = date.getFullYear();
    setCertifiedYear(year);
    setTexts({
      line1: "Byggföretagen",
      line2_1: "KMA",
      line2_2: "certifierad",
      line3: `t.o.m. ${formatMonthYear(company.certification.to)}`,
    });
  }, [company]);

  // Get the year from the certification object and use the right mask in the svg, year2022, year2023 etc.
  // Populate with month and year text
  return (
    <div className={`${classNs}`}>
      <svg viewBox="0 0 177.77 54" xmlns="http://www.w3.org/2000/svg">
        <mask id="year2029">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <path d="M44.25,45.21c2.24,0,3.81,1.57,3.81,3.71,0,.75-.16,1.41-.82,2.43l-7.41,11.29h5.57l4.31-6.73c1.37-2.17,2.59-4.06,2.59-7.17,0-4.45-3.54-7.41-8.05-7.41-2.63,0-4.92,1-6.38,2.72l3.54,2.33c.68-.72,1.67-1.15,2.84-1.15Z"></path>
            <path d="M42.93,55.78l2.12-3.23c-.25,.05-.52,.07-.8,.07-2.24,0-3.81-1.57-3.81-3.71,0-.57,.11-1.11,.32-1.58l-3.58-2.35c-.63,1.07-.99,2.33-.99,3.75,0,3.92,2.7,7.03,6.73,7.05Z"></path>
          </g>
        </mask>
        <mask id="year2028">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <path d="M42.9,58.9c-1.57-.22-2.6-1.32-2.6-2.64s1.03-2.45,2.6-2.67v-3.72c-1.36-.21-2.23-1.18-2.23-2.35s.88-2.11,2.23-2.32v-3.87c-3.65,.18-6.56,2.07-6.56,5.67,0,2.24,1.32,3.93,3.61,4.57v.06c-2.56,.67-3.97,2.45-3.97,5.01,0,3.8,3.21,5.8,6.92,5.99v-3.72Z"></path>
            <path d="M47,51.62v-.06c2.29-.64,3.61-2.33,3.61-4.57,0-3.6-2.91-5.49-6.56-5.67v3.87c1.36,.21,2.23,1.17,2.23,2.32s-.88,2.14-2.23,2.35v3.72c1.57,.22,2.6,1.32,2.6,2.67s-1.03,2.42-2.6,2.64v3.72c3.71-.19,6.92-2.19,6.92-5.99,0-2.56-1.42-4.34-3.97-5.01Z"></path>
          </g>
        </mask>
        <mask id="year2027">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <polygon points="50.95 46.08 50.95 42.1 36 42.1 36.01 46.08 50.95 46.08"></polygon>
            <polygon points="45.58 47.23 37.74 62.64 42.82 62.64 50.39 47.23 45.58 47.23"></polygon>
          </g>
        </mask>
        <mask id="year2026">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <path d="M43.28,58.82c-2.2,0-3.74-1.54-3.74-3.64,0-.74,.15-1.39,.81-2.38l7.28-11.1h-5.48l-4.24,6.62c-1.35,2.13-2.55,3.99-2.55,7.04,0,4.38,3.48,7.28,7.91,7.28,2.58,0,4.84-.98,6.27-2.67l-3.48-2.29c-.66,.71-1.64,1.13-2.79,1.13Z"></path>
            <path d="M44.58,48.43l-2.08,3.17c.25-.05,.51-.07,.78-.07,2.2,0,3.74,1.54,3.74,3.64,0,.57-.11,1.09-.32,1.55l3.52,2.31c.62-1.05,.97-2.29,.97-3.68,0-3.85-2.65-6.9-6.62-6.92Z"></path>
          </g>
        </mask>
        <mask id="year2025">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <polygon points="42.44 45.51 48.98 45.51 48.98 41.69 43.26 41.69 42.44 45.51"></polygon>
            <path d="M44.15,48.08c-1.59,0-3.1,.69-3.69,1.13l.8-3.71,.83-3.81h-3.91l-2.18,9.99c.09,.06,3.99,1.3,4.07,1.33,.46-.58,1.38-1.48,2.89-1.48,2.25,0,3.66,1.23,3.66,3.69s-1.75,3.43-3.4,3.43c-1.32,0-3.23-1.1-3.23-3.45h-4.34c0,5.26,4,7.42,7.72,7.42,3.4,0,7.53-2.14,7.53-7.43s-4.07-7.12-6.76-7.12Z"></path>
          </g>
        </mask>
        <mask id="year2024">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <polygon points="43.82 54.7 40.1 54.7 43.82 48.92 43.82 41.9 35.66 54.7 35.66 58.43 43.82 58.43 43.82 54.7"></polygon>
            <polygon points="49.21 54.7 49.21 41.7 44.99 41.7 44.99 62.64 49.21 62.64 49.21 58.43 51.77 58.43 51.77 54.7 49.21 54.7"></polygon>
          </g>
        </mask>
        <mask id="year2023">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <polygon points="37.01 45.78 43.86 45.78 48.76 41.94 45.3 41.94 37.01 41.94 37.01 41.94 37.01 45.76 37.01 45.76 37.01 45.78"></polygon>
            <path d="M46.21,49.65l3.39-2.49v-4.43l-9.67,7.58,1.58,2.74c.52-.3,1.28-.58,2.01-.58,1.59,0,3.3,.66,3.3,3.02,0,1.82-1.29,3.42-3.4,3.42-1.94,0-3.26-1.48-3.52-3.39h-4.29c-.03,3.66,2.94,7.39,7.8,7.37,4.84-.02,7.72-3.4,7.72-7.17,0-3.38-2.15-5.6-4.9-6.07Z"></path>
          </g>
        </mask>
        <mask id="year2022">
          <g>
            <path d="M28.83,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="21.91 62.64 34.52 62.64 34.52 58.44 27.26 58.44 21.91 62.64"></polygon>
          </g>
          <g>
            <path d="M44.55,50.55c-.84,.68-7.76,6.38-7.76,6.38v4.87l11.38-8.91c1.56-1.37,2.69-2.61,2.69-5.27,0-4.2-2.89-6.31-6.7-6.31-4.11,0-7.06,2.1-7.33,6.42h4.48c.23-1.35,1.1-2.34,2.61-2.34,1.36,0,2.5,.86,2.5,2.28,0,1.27-1.03,2.18-1.86,2.86Z"></path>
            <polygon points="37.63 62.64 50.24 62.64 50.24 58.44 42.99 58.44 37.63 62.64"></polygon>
          </g>
        </mask>
        <g transform="matrix(1, 0, 0, 1, -9, -26)">
          <path fill="#00a5f5" d="M36,73.19c11.05,0,20-8.95,20-20s-8.95-20-20-20-20,8.95-20,20,8.95,20,20,20"></path>
          <path
            fill="#fff"
            d="M36,73.19c11.05,0,20-8.95,20-20s-8.95-20-20-20-20,8.95-20,20,8.95,20,20,20"
            mask={`url(#year${certifiedYear})`}></path>
          <text className="text" transform="translate(65.48 42.71)">
            <tspan x="0" y="0">
              {texts?.line1}
            </tspan>
            <tspan x="0" y="15.6">
              {texts?.line2_1}
            </tspan>
            <tspan x="30.42" y="16.1">
              -
            </tspan>
            <tspan x="35.23" y="15.6">
              {texts?.line2_2}
            </tspan>
            <tspan x="0" y="29.6">
              {texts?.line3}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default CertifiedBadge;

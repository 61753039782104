import React from "react";

function CoreList(props) {
  const { block } = props;
  return (
    <div className="Fact-block--CoreList" dangerouslySetInnerHTML={{ __html: block.originalContent }} />
  );
}

export default CoreList;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";

const GaAnalytics = ({ children }) => {
  const { location } = useSelector((state) => state.router);

  useEffect(() => {
    if (process.env?.REACT_APP_GA_ANALYTICS) {
      ReactGA.initialize(process.env?.REACT_APP_GA_ANALYTICS);
      // console.log("init");
    }
  }, []);

  useEffect(() => {
    if (process.env?.REACT_APP_GA_ANALYTICS) {
      const url = window.location.pathname + window.location.search;
      ReactGA.send({ hitType: "pageview", page: url });
      // console.log("change", url);
    }
  }, [location.pathname]);

  return children;
};

export default GaAnalytics;

import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor/visibility-sensor";

import "./Carousel.scss";

import useWindowSize from "hooks/useWindowSize";

import { LARGE_DESKTOP_LAYOUT_BREAKPOINT, DESKTOP_LAYOUT_BREAKPOINT, TABLET_LAYOUT_BREAKPOINT } from "constants/course";

import FactBlock from "../FactBlock/FactBlock";

import ReactMultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import debugLog from "utils/debugLog";

import { ReactComponent as SwipeleftIcon } from "icons/swipeleft.svg";
import { ReactComponent as BackIcon } from "icons/back.svg";

const ALLOWED_LARGE_DESKTOP_SLIDES = 4;
const ALLOWED_SMALL_DESKTOP_SLIDES = 3;
const ALLOWED_TABLET_SLIDES = 2;
const ALLOWED_MOBILE_SLIDES = 1;

const CustomRightArrow = ({ onClick }) => {
  return (
    <button className="Carousel__arrow Carousel__arrow--right" onClick={() => onClick()}>
      <BackIcon />
    </button>
  );
};
const CustomLeftArrow = ({ onClick }) => {
  return (
    <button className="Carousel__arrow Carousel__arrow--left" onClick={() => onClick()}>
      <BackIcon />
    </button>
  );
};

function Carousel(props) {
  const { block } = props;
  const { isLargeDesktop, isSmallDesktop, isDesktop, isTablet, isMobile } = useWindowSize();
  const [isOnLastSlide, setIsOnLastSlide] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const slideCount = block.innerBlocks.length;

  const needsToSlideOnLargeDesktop = slideCount > ALLOWED_LARGE_DESKTOP_SLIDES;
  const needsToSlideOnSmallDesktop = slideCount > ALLOWED_SMALL_DESKTOP_SLIDES;
  const needsToSlideOnTablet = slideCount > ALLOWED_TABLET_SLIDES;
  const needsToSlideOnMobile = slideCount > ALLOWED_MOBILE_SLIDES;
  const gutter = 100;

  // After 3,5 sec, hide the swipe
  // instruction icon if the component
  // instance is in view
  useEffect(() => {
    let timer;
    if (isVisible && !hasInteracted) {
      timer = setTimeout(() => {
        setHasInteracted(true);
      }, 3500);
    }

    // If component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, hasInteracted, setHasInteracted]);

  if (!slideCount) return null;

  // Anymore than 2 'items' here seems to screw up the carousel :/
  const responsive = {
    largedesktop: {
      breakpoint: { max: 3000, min: LARGE_DESKTOP_LAYOUT_BREAKPOINT },
      items: needsToSlideOnLargeDesktop ? 2 : slideCount,
      partialVisibilityGutter: needsToSlideOnLargeDesktop ? gutter : 0,
    },
    smalldesktop: {
      breakpoint: {
        max: LARGE_DESKTOP_LAYOUT_BREAKPOINT - 1,
        min: DESKTOP_LAYOUT_BREAKPOINT,
      },
      items: needsToSlideOnSmallDesktop ? 2 : slideCount,
      partialVisibilityGutter: needsToSlideOnSmallDesktop ? gutter : 0,
    },
    tablet: {
      breakpoint: {
        max: DESKTOP_LAYOUT_BREAKPOINT - 1,
        min: TABLET_LAYOUT_BREAKPOINT,
      },
      items: needsToSlideOnTablet ? ALLOWED_TABLET_SLIDES : slideCount,
      partialVisibilityGutter: needsToSlideOnTablet ? gutter : 0,
    },
    mobile: {
      breakpoint: { max: TABLET_LAYOUT_BREAKPOINT - 1, min: 0 },
      items: needsToSlideOnMobile ? ALLOWED_MOBILE_SLIDES : slideCount,
      partialVisibilityGutter: needsToSlideOnMobile ? gutter : 0,
    },
  };

  const shouldRenderCarousel =
    (isLargeDesktop && needsToSlideOnLargeDesktop) ||
    (isSmallDesktop && needsToSlideOnSmallDesktop) ||
    (isTablet && needsToSlideOnTablet) ||
    (isMobile && needsToSlideOnMobile);

  const cls =
    "Carousel" +
    (shouldRenderCarousel ? " Carousel--shouldRenderCarousel" : "") +
    (isOnLastSlide ? " Carousel--isOnLastSlide" : "") +
    (hasInteracted ? " Carousel--hasInteracted" : "");

  function renderBlocks(block) {
    return block.innerBlocks.map((innerBlock, i) => {
      return <FactBlock key={i} block={innerBlock} allowedBlocks={["BucLmsBuilderSliderItem"]} />;
    });
  }

  return (
    <div className={cls}>
      {shouldRenderCarousel ? (
        <VisibilitySensor
          onChange={(_isVisible) => {
            setIsVisible(_isVisible);
          }}>
          <ReactMultiCarousel
            partialVisbile={true}
            responsive={responsive}
            swipeable={true}
            draggable={false}
            arrows={isDesktop}
            showDots={false}
            autoPlay={false}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            beforeChange={(nextSlide, obj) => {
              // debugLog("Carousel change", `nextSlide: ${nextSlide}`, obj, responsive);
              const { slidesToShow, totalItems } = obj;
              setIsOnLastSlide(totalItems === nextSlide + slidesToShow);
              if (!hasInteracted) {
                setHasInteracted(true);
              }
            }}>
            {renderBlocks(block)}
          </ReactMultiCarousel>
        </VisibilitySensor>
      ) : (
        renderBlocks(block)
      )}
      {shouldRenderCarousel && !isSmallDesktop ? (
        <div className="Carousel__swipeicon">
          <SwipeleftIcon />
        </div>
      ) : null}
    </div>
  );
}

export default Carousel;

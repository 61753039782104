import React from "react";
import __ from "constants/static-texts";

import CalendarListItem from "./CalendarListItem";

function CalendarList({ calendars, classNs, editable = false }) {
  return (
    <ul className={`${classNs}_list`}>
      <li className={`${classNs}_list_item table-header`}>
        <div aria-disabled></div>
        <div>{__.table.name}</div>
        <div>{__.table.product}</div>
        <div data-align="center">{__.table.activeCalendars}</div>
        <div>{__.table.edit}</div>
      </li>
      {calendars.map((calendar) => (
        <CalendarListItem key={calendar.id} classNs={classNs} calendar={calendar} editable={editable} />
      ))}
    </ul>
  );
}

export default CalendarList;

import { SUCCESS } from "constants/network-states";
import { USER_TYPES } from "constants/user-types";

const initialState = {
  userRoles: Object.values(USER_TYPES).map((userRole) => {
    return {
      ...userRole,
    };
  }),
  userRolesNetworkState: SUCCESS,
  error: null,
};

const userRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default userRolesReducer;

import { useEffect, useState, useRef /*, useCallback*/ } from 'react';
import { useSelector } from 'react-redux';
import { TEMP_ID } from 'react-draft-editor';

import useBlockParams from './use-block-params';

import { SUCCESS } from '../constants/network-states';
// var equal = require('fast-deep-equal');

const getNonTempBlocks = (blocks) =>
  Object.values(blocks).filter((block) => typeof block?.[TEMP_ID] === 'undefined');
/**
 * useBlocksDeleted - Tests the current state of section blocks to see if any have been deleted
 *
 * @returns {array} An array containing parameters with the current savable state
 */
export const useBlocksDeleted = () => {
  const { blocks, isSectionSet, saveSectionNetworkState } = useSelector(
    (state) => state.sectionState
  );
  const [sectionId] = useBlockParams();
  const previousBlocks = useRef({});
  const [deletedBlockIds, setDeletedBlockIds] = useState([]);

  useEffect(() => {
    if (!isSectionSet || !blocks) {
      return;
    }
    const blocksSansTemp = getNonTempBlocks(blocks);
    const prevBlocksSansTemp = getNonTempBlocks(previousBlocks.current);
    if (blocksSansTemp.length >= prevBlocksSansTemp.length) {
      return;
    }
    const currentIds = blocksSansTemp.map((block) => block.id);
    const deletedIds = prevBlocksSansTemp
      .map((block) => block.id)
      .filter((id) => !currentIds.includes(id));
    setDeletedBlockIds(deletedIds);
  }, [blocks, isSectionSet, previousBlocks]);

  const reset = () => {
    previousBlocks.current = blocks; // New blocks are updated from the server data
    setDeletedBlockIds([]);
  };

  useEffect(() => {
    // Flush the previous blocks when networkstate has returned SUCCESS
    if (saveSectionNetworkState === SUCCESS) {
      reset();
    }
  }, [saveSectionNetworkState]); // eslint-disable-line

  useEffect(() => {
    if (!sectionId) {
      return;
    }
    // Reset the previous blocks reference when the section changes
    reset();
  }, [sectionId]); // eslint-disable-line

  useEffect(() => {
    if (!isSectionSet) {
      return;
    }
    reset();
  }, [isSectionSet]); // eslint-disable-line

  return [deletedBlockIds];
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  InputField,
  SectionEditor,
  useBlocksSaveable,
  updateSectionDetails,
} from "react-draft-editor";

import GuideResponseSlotEditor from "./GuideResponseSlotEditor";
import RichTextEditor, {
  RichTextEditorVariant,
  RichTextEditorInitValue,
} from "components/RichTextEditor/RichTextEditor";

import { setRequirementDirty } from "actions/guide-actions";

import __ from "constants/static-texts";

const CLASS_NS = "GuideRequirementEditor";

const GuideRequirementEditorContent = ({
  classNs = CLASS_NS,
  block,
  getValidationErrorForField,
  validate,
  onChange,
  titleLabel,
  descriptionLabel,
}) => {
  const dispatch = useDispatch();

  const onChangeHandler = (e) => {
    e.persist(); // NB! Not needed as of React v.17. See: https://reactjs.org/docs/events.html#overview
    if (getValidationErrorForField(e.target.name)) {
      validate(e);
    }
    onChange(e, block.id);
  };

  const handleChangeDescription = (value) => {
    dispatch(
      updateSectionDetails(block.id, {
        description: value,
      })
    );
  };

  const [descriptionInitValue, setDescriptionInitValue] = useState(null);
  useEffect(() => {
    if (!block.id) return;

    if (!block.description) {
      dispatch(
        updateSectionDetails(block.id, {
          description: JSON.stringify(RichTextEditorInitValue),
        })
      );
    } else {
      setDescriptionInitValue(block?.description);
    }
  }, [block, dispatch]);

  return (
    <form className={`${classNs}_section-form`}>
      <label htmlFor="title" className={`${classNs}_section-label`}>
        {titleLabel}
      </label>
      <InputField
        className={`${classNs}_section_textfield`}
        value={block?.title ?? ""}
        type="text"
        name="title"
        onChange={onChangeHandler}
        onBlur={validate}
        maxLength="100"
        required
        data-errormessage={getValidationErrorForField("title")}
      />
      <label htmlFor="description" className={`${classNs}_section-label`}>
        {descriptionLabel}
      </label>
      {descriptionInitValue ? (
        <RichTextEditor
          initValue={descriptionInitValue}
          variant={RichTextEditorVariant.TERTIARY}
          onChange={handleChangeDescription}
        />
      ) : null}
    </form>
  );
};

const GuideRequirementEditor = ({ classNs = CLASS_NS, block, blocksSelectorConfig, descriptionComponent, onSave }) => {
  const dispatch = useDispatch();
  const { requirementsDirty } = useSelector((state) => state.guideState);
  
  const [isSaveable, setIsSaveable] = useState(false);

  const [isBlocksSaveable] = useBlocksSaveable(block.id);

  useEffect(() => {
    setIsSaveable(isBlocksSaveable);

    if (isBlocksSaveable) {
      dispatch(setRequirementDirty(block.id));
    } else if (requirementsDirty.includes(block.id)) {
      setIsSaveable(true);
    }
  }, [isBlocksSaveable]); // eslint-disable-line react-hooks/exhaustive-deps

  const requirementInstances = block?.companyIdsHavingAnInstanceRequirementOriginatingFromThisRequirement?.length;

  return (
    <>
      <SectionEditor
        classNs={classNs}
        blockId={block.id}
        blocksSelectorConfig={blocksSelectorConfig}
        LeadContent={<GuideRequirementEditorContent titleLabel="Rubrik" descriptionLabel="Beskrivning" />}
        displayHeaderControlsInline={true}
        nonExpandedComponents={[<GuideResponseSlotEditor titleLabel="Rubrik" />]}
      />
      <div className={`${classNs}_footer`}>
        <div className={`${classNs}_footer_text`} data-disabled={!isSaveable}>
          {requirementInstances > 0 ? __.guide.saveWarning(requirementInstances) : __.guide.onlyInGuideTemplate}
        </div>
      </div>
    </>
  );
};

GuideRequirementEditor.blockType = "guide-requirement";

GuideRequirementEditor.propTypes = {
  classNs: PropTypes.string,
  block: PropTypes.object,
  titleLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  updateModuleContent: PropTypes.func,
  getValidationErrorForField: PropTypes.func,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  customBlocks: PropTypes.array,
  blocksSelectorConfig: PropTypes.array,
};
export default GuideRequirementEditor;

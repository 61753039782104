import React, { useEffect, /*useState,*/ useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SectionEditor } from '../section-editor/section-editor';

import { setSection, setErrorMessages } from '../../actions/section-actions';

import useBlockParams from '../../hooks/use-block-params';

import { IconProvider } from '../../contexts/icon-context';
import { transformBlockFromSchema } from '../../utils/section';
import { blocksSelectorDefault } from '../../constants/block-types';
import iconsConfigDefault from '../../constants/icons-config-default';
import __ from '../../constants/error-messages';
import { baseSchema } from '../../constants/schema';
import { BLOCK_PARAMS } from '../../constants/navigation';
import { LOADING } from '../../constants/network-states';
// import { equal } from 'fast-deep-equal/es6/react';
// import { equal } from 'fast-deep-equal';
import { isEqualReact as equal } from '@react-hookz/deep-equal';

const CLASS_NS = 'draft-editor';
const Loading = () => {
  return <p>Loading......</p>;
};

/**
 *
 * Sets the new section according to the given id and translation schema
 */
// Use the url as the single source of truth.
const DraftEditor = ({
  classNs = CLASS_NS,
  section,
  schema = baseSchema, // Schema must be empty for pre-transformed section blocks.
  isLoading,
  LoadingComponent = Loading,
  blocksSelectorConfig = blocksSelectorDefault,
  LeadContent,
  TrailingContent,
  customBlocks,
  togglerComponents,
  nonExpandedComponents,
  showBackgroundOverlay,
  emptyLabel = 'Det finns inga moduler. Skapa ditt eget.',
  iconsConfigCustom,
  customErrorMessages = __
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { saveSectionNetworkState } = useSelector((state) => state.sectionState);
  const { blocks } = useSelector((state) => state.sectionState);
  const { errorMessages } = useSelector((state) => state.sectionState);
  const [sectionId] = useBlockParams();
  const prevSection = useRef({});
  const iconConfig = useMemo(
    () => ({
      ...iconsConfigDefault,
      ...iconsConfigCustom
    }),
    [iconsConfigCustom]
  );

  useEffect(() => {
    if (!section || equal(section, prevSection.current)) {
      // Something is wrong in how react sees (non)changes in section and causes an infinite loop here.
      // Have to compare the section by stringifing it.
      return;
    }
    prevSection.current = section;
    dispatch(setSection(section, schema));
  }, [section, dispatch, schema]);

  const getTopBlock = useCallback((sect) => {
    return sect.reduce((acc, block, index, sectionArray) => {
      const { parentId } = block;
      acc = sectionArray.some((blk) => blk.id === parentId) ? acc : block; // return the block which doesn't have a parent id in the original section array
      return acc;
    }, {});
  }, []);

  const currentBlockId = useMemo(() => {
    // NB! For this to work with pre-transformed blocks the schema must be empty.
    const topSection = Array.isArray(section) ? getTopBlock(section) : section;
    const mSectionBlock = transformBlockFromSchema(topSection, schema);
    const top_id = mSectionBlock?.id;
    const top_parent_id = mSectionBlock?.parentId;

    const id =
      Object.values(blocks).find(
        (block) => Math.floor(block.id) === top_id && block.parentId === top_parent_id
      )?.id ?? top_id;

    return id;
  }, [blocks, section, getTopBlock, schema]);

  useEffect(() => {
    // Wait unitl blocks have changed to set the section search parameter.
    // The sectionId could not be used directly as the uniqueness of the id cannot be guarantied by the backend.
    if (!currentBlockId) {
      return;
    }
    let url = new URL(window.location);
    url.searchParams.set([BLOCK_PARAMS.SECTION], currentBlockId);
    // window.history.pushState({}, '', url); // Didn't play well with hooks
    history.push(`${url.pathname}${url.search}`);
  }, [currentBlockId, history]);

  useEffect(() => {
    if (equal(customErrorMessages, errorMessages)) {
      return;
    }
    dispatch(
      setErrorMessages({
        ...__,
        ...customErrorMessages
      })
    );
  }, [customErrorMessages, errorMessages, dispatch]);

  return (
    <article className={`${classNs}`}>
      <IconProvider value={iconConfig}>
        {isLoading || saveSectionNetworkState === LOADING ? (
          <div className={`${CLASS_NS}_loading-container`}>
            <LoadingComponent />
          </div>
        ) : section && sectionId ? (
          <SectionEditor
            classNs={classNs}
            blockId={sectionId}
            blocksSelectorConfig={blocksSelectorConfig}
            customBlocks={customBlocks}
            togglerComponents={togglerComponents}
            LeadContent={LeadContent}
            TrailingContent={TrailingContent}
            showBackgroundOverlay={showBackgroundOverlay}
            nonExpandedComponents={nonExpandedComponents}
          />
        ) : (
          <div className={`${classNs}_nomodules`}>{emptyLabel}</div>
        )}
      </IconProvider>
    </article>
  );
};

DraftEditor.propTypes = {
  classNs: PropTypes.string,
  section: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  schema: PropTypes.object,
  isLoading: PropTypes.bool,
  showSectionDetails: PropTypes.bool,
  blockTypesByDepth: PropTypes.object,
  emptyLabel: PropTypes.string,
  customBlocks: PropTypes.array
};

export default DraftEditor;

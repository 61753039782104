import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getGuides } from "actions/guide-actions";

import { WAITING, LOADING } from "constants/network-states";

/**
 * Provides the guides
 */
const useGuides = () => {
  const dispatch = useDispatch();
  const { guides, guidesNetworkState } = useSelector((state) => state.guidesState);

  const fetchDocuments = () => {
    guides.length === 0 && guidesNetworkState === WAITING && dispatch(getGuides());
  };

  const isLoading = guidesNetworkState === WAITING || guidesNetworkState === LOADING;

  useEffect(() => {
    fetchDocuments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [guides, isLoading];
};

export default useGuides;

import React, { useEffect, useState } from "react";
import { Overlay } from "react-draft-editor";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCalendarActivity, updateCalendarActivity, deleteCalendarActivity } from "actions/calendar-actions";
import useCalendar from "hooks/use-calendar";
import useForm from "hooks/use-form";
import useLoggedInUser from "hooks/use-logged-in-user";
import CalendarsRoute from "routes/Calendars";
import __ from "constants/static-texts";
import { ReactComponent as TrashCan } from "icons/trash.svg";
import RichTextEditor, { RichTextEditorVariant } from "components/RichTextEditor/RichTextEditor";
import Loading from "components/Loading/Loading";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";
import ButtonLink from "components/ButtonLink/ButtonLink";

const CLASS_NS = "form_overlay";

const EditCalendarActivity = ({ history }) => {
  const { isCompanyReader, isCompanyEditor } = useLoggedInUser();
  const dispatch = useDispatch();
  const params = useParams();
  const [showRemove, setShowRemove] = useState(false);
  const [calendar, calendarIsLoading] = useCalendar(parseInt(params.calendarId));
  const activity = calendar?.activities?.find((activity) => {
    return activity.id === parseInt(params.activityId);
  });
  const [description, setDescriptionValue] = useState(activity?.description);

  const [canEditVisibilty] = useState(!isCompanyEditor);

  const redirectToCalendar = () => {
    history.push(`${CalendarsRoute().path}/calendar/${params.calendarId}`, { disableScroll: true });
  };

  useEffect(() => {
    if (isCompanyReader) {
      redirectToCalendar();
    }
  });

  const initialState = {
    title: activity?.title || "",
    description: description || "",
    weekNumber: activity?.weekNumber || 1,
    isForManagementOnly: activity?.isForManagementOnly || false,
  };

  const { inputs, handleChange } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...initialState,
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const activityData = {
      title: inputs.title || activity?.title,
      description: description,
      weekNumber: inputs.weekNumber || activity?.weekNumber,
      isForManagementOnly: inputs.isForManagementOnly || activity?.isForManagementOnly,
    };

    activity
      ? dispatch(
          updateCalendarActivity({
            ...activityData,
            calendar: params.calendarId,
            activity: activity.id,
          })
        )
      : dispatch(
          addCalendarActivity({
            ...activityData,
            calendar: params.calendarId,
          })
        );

    redirectToCalendar();
  };

  const handleDelete = () => {
    dispatch(
      deleteCalendarActivity({
        calendarId: parseInt(params.calendarId),
        activityId: activity.id,
      })
    );
    redirectToCalendar();
  };

  const handleChangeDescription = (currentDescriptionValue) => {
    setDescriptionValue(currentDescriptionValue);
  };

  const handleCancel = () => {
    redirectToCalendar();
  };

  return isCompanyReader ? null : calendarIsLoading ? (
    <Loading />
  ) : (
    <Overlay cancelUrl={`${CalendarsRoute().path}/calendar/${params.calendarId}`} disableScroll={true}>
      <h1>
        {showRemove
          ? __.calendar.editCalendarFormLabelRemoveLink
          : activity
          ? __.calendar.editActivity
          : __.calendar.addActivity}
      </h1>
      <form class="form_overlay" onSubmit={handleSubmit}>
        {showRemove ? (
          <>
            <div className={`${CLASS_NS}_form-row`}>{__.calendar.editActivityConfirmDeletion}</div>
          </>
        ) : (
          <>
            <label className={`${CLASS_NS}_form-row`} htmlFor="title">
              <span className="label">{__.calendar.editActivityFormLabelTitle}</span>
              <input
                type="text"
                id="title"
                name="title"
                placeholder={__.calendar.editActivityFormLabelTitle}
                value={inputs.title}
                onChange={handleChange}
              />
            </label>
            <label className={`${CLASS_NS}_form-row`} htmlFor="description">
              <span className="label">{__.calendar.editActivityFormLabelDescription}</span>
              <RichTextEditor
                initValue={inputs.description}
                variant={RichTextEditorVariant.SECONDARY}
                onChange={handleChangeDescription}
              />
            </label>
            <label className={`${CLASS_NS}_form-row`} htmlFor="text">
              <span className="label">{__.calendar.editActivityFormLabelWeekNumber}</span>
              <input
                type="number"
                min="1"
                max="52"
                step="1"
                id="weekNumber"
                name="weekNumber"
                placeholder={__.calendar.editActivityFormLabelWeekNumber}
                value={inputs.weekNumber}
                onChange={handleChange}
              />
            </label>
            {canEditVisibilty && (
              <div className={`${CLASS_NS}_form-row`}>
                <ChoiceGroup
                  onChange={handleChange}
                  chosen={inputs.isForManagementOnly ? "1" : "0"}
                  type="checkbox"
                  inversed={true}
                  groupName="isForManagementOnly"
                  choices={[
                    {
                      value: "1",
                      label: __.upload.managementOnly,
                      name: "isForManagementOnly",
                    },
                  ]}
                />
              </div>
            )}
          </>
        )}
        <div className="button-row">
          <button className="button-inverted" type="button" onClick={handleCancel}>
            {__.calendar.editActivityFormLabelCancel}
          </button>
          {showRemove ? (
            <button type="button" className="button-warning" onClick={handleDelete}>
              {__.general.confirmDelete}
            </button>
          ) : (
            <button className="button" type="submit">
              {__.calendar.editActivityFormLabelSubmit}
            </button>
          )}
        </div>
        {!showRemove && activity && (
          <ButtonLink
            icon={<TrashCan aria-hidden />}
            variant="action"
            size="small"
            alignment="center"
            onClick={() => setShowRemove(true)}>
            {__.calendar.editCalendarFormLabelRemoveLink}
          </ButtonLink>
        )}
      </form>
    </Overlay>
  );
};

export default EditCalendarActivity;

import React from "react";
import __ from "constants/static-texts";

import CompaniesListItem from "./CompaniesListItem";
import useSupervisors from "hooks/use-supervisors";
import useProductTypes from "hooks/use-productTypes";

function CompaniesList({ companies, guides, classNs }) {
  const sort = (e) => {
    console.log("SORTING!! ", e.target.textContent);
  };
  const { productTypes } = useProductTypes();
  const { supervisors } = useSupervisors();

  return (
    <ul className={`${classNs}_list`}>
      <li className={`${classNs}_list_item table-header`}>
        <div onClick={sort}>{__.table.orgNumber}</div>
        <div onClick={sort}>{__.table.name}</div>
        <div onClick={sort}>{__.table.supervisor}</div>
        <div onClick={sort}>{__.table.product}</div>
        <div className={`${classNs}_tools`} data-align="center" onClick={sort}>
          {__.table.guide}
        </div>
        <div className={`${classNs}_tools`} onClick={sort}>
          {__.table.version}
        </div>
        <div className={`${classNs}_tools`} data-align="center" onClick={sort}>
          {__.table.guideActive}
        </div>
        <div className={`${classNs}_tools`} data-align="center" onClick={sort}>
          {__.table.settings}
        </div>
      </li>

      <>
      {companies.map((company, index) => {
        const supervisor = company.supervisorUserId
          ? supervisors.find((supervisor) => supervisor.id === company.supervisorUserId)
          : null;
        const productType = productTypes.find((productType) => productType.id === company.product);
        
        let guideTitle;
        guides.forEach(guide => {
          if(guide.companyId && guide.companyId === company.id) guideTitle = guide.originGuideTitle;
        });

        return (
          <CompaniesListItem
            key={company.id}
            company={company}
            supervisor={supervisor}
            productType={productType}
            classNs={classNs}
            guideTitle={guideTitle}
          />
        );
      })}
      </>
    </ul>
  );
}

export default CompaniesList;

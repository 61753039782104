import React from "react";
import { Link } from "react-router-dom";
import Companies from "routes/Companies";
import Guides from "routes/Guides";
import texts from "constants/static-texts";

import { ReactComponent as Pencil } from "icons/Pencil.svg";
import { ReactComponent as ExternalLink } from "icons/External-Link.svg";
import { ReactComponent as IconCheck } from "icons/check.svg";
import { ReactComponent as IconCog } from "icons/Cog.svg";
import Tooltip from "components/Tooltip/Tooltip";
import LayoutIcons from "components/LayoutIcons/LayoutIcons";
import { isBaseProduct } from "utils/user-helper";

const CompaniesListItem = ({ classNs, company, supervisor, productType, guideTitle }) => {
  const { guideId, isGuideOpen } = company;

  const baseProduct = isBaseProduct(company);

  let supervisorClassName = '';
  if(!supervisor && guideId) supervisorClassName = `${classNs}_guide_text`;
  if(supervisor) supervisorClassName = `${classNs}_supervisor`;

  return (
    <li className={`${classNs}_list_item`}>
      <div>{company.companyRegistrationNumber}</div>
      <div>{company.name}</div>
      {(
        <div className={`${supervisorClassName}`}>
          {
            supervisor ? (
              supervisor.firstName && supervisor.lastName ? (
                `${supervisor.firstName} ${supervisor.lastName}`
              ) : (
                `${supervisor.userName}`
              )
            ) : (
              guideId ? `${texts.companies.nonChosen}` : '' 
            )
          }
        </div>
      )}
      <div>{productType.name}</div>
      <LayoutIcons justify="center">
        {!baseProduct ? (
          !guideId ? (
            <div className={`${classNs}_guide_text_small`}>
              {texts.companies.nonChosen}
            </div>
          ) : (
            <Link to={`${Guides().path}/${guideId}/guide-part-editor`} className={`${classNs}_tool`}>
              <div
                className={`${classNs}_icon`}
                data-tip={texts.companies.editGuide}
                data-for={`company_list_${company.id}`}>
                <Pencil />
              </div>
              </Link>
          )
        ) : null}
        {!baseProduct
          ? guideId && (
              <Link to={`${Guides().path}/${guideId}/guide`} className={`${classNs}_tool`}>
                <div
                  className={`${classNs}_icon`}
                  data-tip={texts.companies.showGuide}
                  data-for={`company_list_${company.id}`}>
                  <ExternalLink />
                </div>
              </Link>
            )
          : null}
      </LayoutIcons>
      <div className={`${classNs}_guide_text_small`}>{guideTitle}</div>
      <LayoutIcons justify="center">
        {
        isGuideOpen && 
          <div 
            className={`${classNs}_icon`}
            data-tip={guideTitle}
            data-for={`company_list_${company.id}`}>
            <IconCheck />
          </div>
          }
      </LayoutIcons>
      <LayoutIcons justify="center">
        <Link
          to={{
            pathname: `${Companies().path}/${company.id}/guide${guideId ? '/' + guideId : ''}`,
            state: { disableScroll: true },
          }}
          className={`${classNs}_tool`}>
          <div
            className={`${classNs}_icon`}
            data-tip={texts.companies.editGuideSettings}
            data-for={`company_list_${company.id}`}>
            <IconCog />
          </div>
        </Link>
      </LayoutIcons>
      <Tooltip theme={"dark"} id={`company_list_${company.id}`} />
    </li>
  );
};

export default CompaniesListItem;

import fetchClient from "fetch-client";
import { guidePartData, guideSectionData } from "utils/reused-initial-states";

export const getGuide = (id, getState) =>
  fetchClient({
    path: `/guides/${id}/blocks`,
    getState,
  });

export const getGuideMeta = (id, getState) =>
  fetchClient({
    path: `/guides/${id}`,
    getState,
  });

export const updateGuide = (data, getState) =>
  fetchClient({
    path: `/guides/${data.id}`,
    getState,
    method: "PUT",
    data: data,
  });

export const getGuides = (getState) =>
  fetchClient({
    path: `/guides`,
    getState,
  });

export const addGuide = (data, getState) =>
  fetchClient({
    path: `/guides`,
    getState,
    method: `POST`,
    data,
  });
export const deleteGuide = (id, getState) =>
  fetchClient({
    path: `/guides/${id}`,
    getState,
    method: `DELETE`,
    data: id,
  });

export const addGuidePart = (data, getState) =>
  fetchClient({
    path: `/guides/${data.guideId}/blocks`,
    getState,
    data: [
      {
        ...guidePartData(),
        ...data,
      },
    ],
    method: "POST",
  });

export const addGuideSection = (data, getState) =>
  fetchClient({
    path: `/guides/${data.guideId}/blocks`,
    getState,
    data: [
      {
        ...guideSectionData(),
        ...data,
      },
    ],
    method: "POST",
  });

export const saveGuideBlocks = (data, getState) => {
  const blocks = Object.values(data).find((val) => Array.isArray(val)); // Extract the blocks array from the data
  return fetchClient({
    path: `/guides/${data.guideId}/blocks`,
    getState,
    data: blocks,
    method: "POST",
  });
};

export const updateSectionBlocks = (data, getState) => {
  const blocks = Object.values(data).find((val) => Array.isArray(val)); // Extract the blocks array from the data
  return fetchClient({
    path: `/guides/${data.guideId}/blocks`,
    getState,
    data: blocks,
    method: "PUT",
  });
};

export const deleteGuideBlocks = (data, getState) =>
  fetchClient({
    path: `/guides/${data.guideId}/blocks`,
    getState,
    data: data.blocks,
    method: "DELETE",
  });

export const createRoutine = (data, getState) =>
  fetchClient({
    path: `/routines`,
    getState,
    data: data,
    method: "POST",
  });

export const updateRoutine = (data, getState) =>
  fetchClient({
    path: `/routines/${data.routineId}`,
    getState,
    data: data,
    method: "PUT",
  });

export const favouriteSectionBlock = (data, getState) =>
  fetchClient({
    path: `/guides/${data.guideId}/sections/${data.sectionId}/favourite`,
    getState,
    data: { isFavourite: data.isFavourite },
    method: "PUT",
  });

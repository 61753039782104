import React from "react";
import { useDispatch } from "react-redux";

import __ from "constants/static-texts";
import UploadFiles from "components/UploadFiles/UploadFiles";
import RichTextEditor, { RichTextEditorVariant } from "components/RichTextEditor/RichTextEditor";

import { addCompanyDocument, deleteCompanyDocument } from "actions/companydocuments-actions";

import useLoggedInUser from "hooks/use-logged-in-user";
import useCompanies from "hooks/use-companies";

const GuideViewerRoutineEdit = ({ routine, inputs, handleChange, classNs, routineFiles, initValue }) => {
  const { isCompanyUser, isCompanyEditor } = useLoggedInUser();
  const { activeCompany } = useCompanies();

  const dispatch = useDispatch();

  const uploadRoutine = (inputs) => {
    dispatch(addCompanyDocument({ ...inputs, routineId: routine.id, companyId: activeCompany.id }));
  };

  const deleteDocument = (id) => {
    dispatch(deleteCompanyDocument({ id, routineId: routine.id }));
  };

  const handleChangeRoutine = (value) => {
    handleChange({
      value: value,
      name: "description",
      type: "text",
    });
  };

  return (
    <>
      <div className={`${classNs}_edit`}>
        <label className={`${classNs}_form-row`} htmlFor="description">
          <RichTextEditor
            initValue={initValue}
            variant={RichTextEditorVariant.TERTIARY}
            onChange={handleChangeRoutine}
            disableDocumentLink={true}
          />
        </label>
      </div>
      <UploadFiles
        title={__.guide.routine.document_title}
        files={routineFiles}
        canUploadAndEdit={isCompanyUser}
        uploadDocument={uploadRoutine}
        deleteDocument={deleteDocument}
        canEditVisibilty={!isCompanyEditor}
      />
    </>
  );
};

export default GuideViewerRoutineEdit;

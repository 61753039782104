import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";

import _ from "lodash";

import { getRandomInteger } from "utils/random";

import ImageMapTooltip from "../ImageMapTooltip/ImageMapTooltip";

import {
  BYGGFORETAGEN_C_LINK_INVERSE,
  BUC_C_LINK_INVERSE,
  BYGGFORETAGEN_C_ACTION_PRIMARY,
  BUC_C_ACTION_PRIMARY,
  BYGGFORETAGEN_C_BUTTON_DEFAULT_TEXT,
  BUC_C_BUTTON_DEFAULT_TEXT,
} from "constants/course";

const insidePoint = (mouseX, mouseY, circleX, circleY, circleSize) => {
  return Math.pow(mouseX - circleX, 2) + Math.pow(mouseY - circleY, 2) < Math.pow(circleSize, 2);
};

const POINT_SIZE = 8;
const PIXEL_RATIO = 2;

function BucLmsBuilderImageAreaMap(props) {
  const { image, size, alignment } = props.block.attributes;
  const points = JSON.parse(props.block.attributes.points);

  const canvas = useRef(null);
  const canvasWrap = useRef(null);
  const imageObj = useMemo(() => new Image(), []);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [highlightedPoint, setHighlightedPoint] = useState(-1);

  // const theme = useSelector((state) => state.layout.theme);
  const theme = "byggforetagen";

  const color = "#" + (theme === "byggforetagen" ? BYGGFORETAGEN_C_ACTION_PRIMARY : BUC_C_ACTION_PRIMARY);

  const highlightColor = "#" + (theme === "byggforetagen" ? BYGGFORETAGEN_C_LINK_INVERSE : BUC_C_LINK_INVERSE);

  const strokeColor =
    "#" + (theme === "byggforetagen" ? BYGGFORETAGEN_C_BUTTON_DEFAULT_TEXT : BUC_C_BUTTON_DEFAULT_TEXT);

  const drawImage = useCallback(
    (context) => {
      if (!canvas.current) return;
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
      // Clears the canvas
      canvas.current.width = canvas.current.width; // eslint-disable-line
      context.scale(PIXEL_RATIO, PIXEL_RATIO);
      context.drawImage(imageObj, 0, 0, canvas.current.width / PIXEL_RATIO, canvas.current.height / PIXEL_RATIO);
    },
    [canvas, imageObj]
  );
  const initImage = useCallback(
    (context) => {
      setImageLoaded(false);
      const canvasWidth = canvasWrap.current.clientWidth;
      if (!canvasWidth) return;
      imageObj.onload = function () {
        const height = (this.height / this.width) * canvasWidth;
        canvas.current.setAttribute("width", canvasWidth * PIXEL_RATIO);
        canvas.current.setAttribute("height", height * PIXEL_RATIO);
        canvas.current.style.width = canvasWidth + "px";
        canvas.current.style.height = height + "px";
        setImageLoaded(true);
      };
      imageObj.src = `${image}?rnd=${getRandomInteger(1, 999999)}`;
    },
    [image, canvas, canvasWrap, imageObj, setImageLoaded]
  );

  const drawPoints = useCallback(
    (context) => {
      const drawPoint = (point, i) => {
        context.lineJoin = "round";
        context.strokeStyle = i === highlightedPoint ? "#ffffff" : strokeColor;
        context.beginPath();
        context.arc(xFromPercent(point.x), yFromPercent(point.y), POINT_SIZE, 0, 2 * Math.PI, false);
        context.fillStyle = i === highlightedPoint ? highlightColor : color;
        context.fill();
        context.lineWidth = 3;
        context.stroke();
      };
      points.forEach((point, i) => {
        if (i !== highlightedPoint) drawPoint(point, i);
      });
      if (highlightedPoint > -1) {
        context.fillStyle = "#5c6d74";
        context.globalAlpha = 0.3;
        context.fillRect(0, 0, xFromPercent(100), yFromPercent(100));
        context.globalAlpha = 1.0;
        drawPoint(points[highlightedPoint], highlightedPoint);
      }
    },
    [points, highlightedPoint, color, highlightColor, strokeColor]
  );

  const onCanvasClick = (e) => {
    if (!imageLoaded) return;
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    let _highlightedPoint = -1;

    points.forEach((point, j) => {
      if (_highlightedPoint === -1 && insidePoint(x, y, xFromPercent(point.x), yFromPercent(point.y), POINT_SIZE)) {
        _highlightedPoint = j;
      }
    });

    if (highlightedPoint > -1 && highlightedPoint === _highlightedPoint) {
      _highlightedPoint = -1;
    }

    setHighlightedPoint(_highlightedPoint);
  };

  const xFromPercent = (val) => {
    return (val / 100) * (canvas.current.width / PIXEL_RATIO);
  };

  const yFromPercent = (val) => {
    return (val / 100) * (canvas.current.height / PIXEL_RATIO);
  };

  useEffect(() => {
    const debouncedHandleResize = _.debounce(() => {
      if (!canvas.current) return;
      const context = canvas.current.getContext("2d");
      initImage(context);
    }, 500);

    const context = canvas.current.getContext("2d");
    initImage(context);

    window.addEventListener("resize", debouncedHandleResize);

    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [canvas, initImage]);

  useEffect(() => {
    const context = canvas.current.getContext("2d");

    if (points.length && imageLoaded) {
      drawImage(context);
      drawPoints(context);
    }
  }, [canvas, points, imageObj, imageLoaded, drawImage, drawPoints]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (canvasWrap.current && !canvasWrap.current.contains(event.target)) {
        setHighlightedPoint(-1);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [canvasWrap, setHighlightedPoint]);

  const highlightedPointObj = highlightedPoint > -1 ? points[highlightedPoint] : null;

  const mainCls = "Fact-block--BucLmsBuilderImageAreaMap";
  const cls = `${mainCls} ${mainCls}--size${size} ${mainCls}--align${alignment}`;

  return (
    <div className={cls}>
      <div className="Fact-block--BucLmsBuilderImagePointMap__canvaswrap" ref={canvasWrap}>
        <canvas ref={canvas} onClick={onCanvasClick} />

        {highlightedPointObj ? (
          <ImageMapTooltip
            x={xFromPercent(highlightedPointObj.x)}
            y={yFromPercent(highlightedPointObj.y)}
            heading={highlightedPointObj.heading}
            text={highlightedPointObj.text}
            closeFn={() => setHighlightedPoint(-1)}
            canvasWidth={canvas.current.width / PIXEL_RATIO}
          />
        ) : null}
      </div>
    </div>
  );
}
export default BucLmsBuilderImageAreaMap;

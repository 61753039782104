import React from "react";
import PropTypes from "prop-types";

const CLASS_NS = "list-box";

/**
 * A list component whose children is the list elements.
 * The NodeType dictates what type of list to render.
 *
 * @param {Object}  props Component properties
 * @param {string}  props.title the header name
 * @param {string}  props.NodeType type of list element
 * @param {React.ReactNode}  [props.actions] Extra stuff for the header
 * @param {React.ReactNode}  [props.children] Children component
 *
 * @returns {React.FC} A React function component
 */
const ListBox = ({ title, NodeType = "ul", children, actions }) => {
  const classNs = CLASS_NS;

  return (
    <section className={`${classNs}`}>
      <header className={`${classNs}_header`}>
        <h2 className={`${classNs}_title`}>{title}</h2>
        {actions && <div className={`${classNs}_actions`}>{actions}</div>}
      </header>
      <div className={`${classNs}_content`}>
        <NodeType className={`${classNs}_items`}>{children}</NodeType>
      </div>
    </section>
  );
};
ListBox.propTyupes = {
  title: PropTypes.string,
  NodeType: PropTypes.oneOf(["ol", "ul"]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
export default ListBox;

import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";
import { createMapFromArray, sortArray } from "utils/js-helpers";

const initialState = {
  companies: [],
  companiesMap: null,
  companiesNetworkState: WAITING,
  companyGuideAddNetWorkState: WAITING,
  updateCompanyNetWorkState: WAITING,

  activeCompany: null,

  ssoCompanyUsers: [],
  ssoCompanyUsersNetworkState: WAITING,

  searchQuery: "",
  error: null,
};

const prepareCompanies = (companies) =>
  companies.map((company) => ({ ...company, name: company.marketName || company.legalName })).sort(sortArray("name"));

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPANIES_LOADING:
      return {
        ...state,
        companiesNetworkState: LOADING,
      };
    case types.GET_COMPANIES_SUCCESS:
      const companies = prepareCompanies(action.payload);
      return {
        ...state,
        companies: companies,
        companiesMap: createMapFromArray(companies),
        companiesNetworkState: SUCCESS,
      };
    case types.GET_COMPANIES_ERROR:
      return {
        ...state,
        error: action.payload,
        companiesNetworkState: ERROR,
      };
    case types.SET_SEARCH_QUERY_COMPANIES:
      return {
        ...state,
        searchQuery: action.value,
      };
    case types.SET_ACTIVE_COMPANY:
      return {
        ...state,
        activeCompany: action.company,
      };
    case types.ADD_COMPANY_GUIDE_LOADING:
      return {
        ...state,
        companyGuideAddNetWorkState: LOADING,
      };
    case types.ADD_COMPANY_GUIDE_SUCCESS:
      const addCompanyUpdatedCompaniesList = state.companies.map((company) => {
        return company.id === action.payload.companyId
          ? {
              ...company,
              guideId: action.payload.id,
              isGuideOpen: action.payload.isOpenForCompany,
            }
          : company;
      });
      return {
        ...state,
        companies: addCompanyUpdatedCompaniesList,
        companiesMap: createMapFromArray(addCompanyUpdatedCompaniesList),
        companyGuideAddNetWorkState: SUCCESS,
      };
    case types.ADD_COMPANY_GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        companyGuideAddNetWorkState: ERROR,
      };

    case types.ADD_CALENDAR_INSTANCE_SUCCESS:
      const addCalendarUpdatedCompaniesList = state.companies.map((company) => {
        return company.id === action.payload.companyId
          ? {
              ...company,
              calendarId: action.payload.id,
            }
          : company;
      });
      return {
        ...state,
        companies: addCalendarUpdatedCompaniesList,
        companiesMap: createMapFromArray(addCalendarUpdatedCompaniesList),
        companyGuideAddNetWorkState: SUCCESS,
      };
    case types.ADD_CALENDAR_INSTANCE_LOADING:
      return {
        ...state,
        companyGuideAddNetWorkState: LOADING,
      };
    case types.ADD_CALENDAR_INSTANCE_ERROR:
      return {
        ...state,
        error: action.payload,
        companyGuideAddNetWorkState: ERROR,
      };

    case types.UPDATE_GUIDE_LOADING:
      return {
        ...state,
        updateCompanyNetWorkState: LOADING,
      };
    case types.UPDATE_GUIDE_SUCCESS:
      const updatedGuideUpdatedCompaniesList = state.companies.map((company) =>
        company.guideId === action.payload.id
          ? {
              ...company,
              isGuideOpen: action.payload.isOpenForCompany,
              supervisorUserId: action.payload.supervisorId,
            }
          : company
      );
      return {
        ...state,
        companies: updatedGuideUpdatedCompaniesList,
        companiesMap: createMapFromArray(updatedGuideUpdatedCompaniesList),
        updateCompanyNetWorkState: SUCCESS,
      };
    case types.UPDATE_GUIDE_ERROR:
      return {
        ...state,
        error: action.payload,
        updateCompanyNetWorkState: ERROR,
      };

    case types.GET_SSO_COMPANY_USERS_LOADING:
      return {
        ...state,
        ssoCompanyUsersNetworkState: LOADING,
      };
    case types.GET_SSO_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        ssoCompanyUsers: action.payload,
        ssoCompanyUsersNetworkState: SUCCESS,
      };
    case types.GET_SSO_COMPANY_USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        ssoCompanyUsersNetworkState: ERROR,
      };
    case types.RESET_SSO_COMPANY_USERS:
      return {
        ...state,
        ssoCompanyUsers: [],
        ssoCompanyUsersNetworkState: WAITING,
      };

    case types.DELETE_GUIDE_SUCCESS:
      const deleteGuideUpdatedCompaniesList = state.companies.map((company) =>
        company.guideId === action.payload ? { ...company, guideId: null, isGuideOpen: null } : { ...company }
      );
      return {
        ...state,
        companies: deleteGuideUpdatedCompaniesList,
        companiesMap: createMapFromArray(deleteGuideUpdatedCompaniesList),
        companiesNetworkState: SUCCESS,
      };

    case types.UPDATE_COMPANY_INFO_LOADING:
      return {
        ...state,
        companyPutSupervisorNetworkState: LOADING,
      };

    case types.UPDATE_COMPANY_INFO_ERROR:
      return {
        ...state,
        companyPutSupervisorNetworkState: ERROR,
      };
    case types.UPDATE_COMPANY_INFO_SUCCESS:
      const updateCompanyUsersUpdatedCompaniesList = state.companies.map((company) =>
        company.id === parseInt(action.params.companyId)
          ? {
              ...company,
              supervisorUserId: action.params.supervisorUserId ? parseInt(action.params.supervisorUserId) : null,
              note: action.params.note,
            }
          : { ...company }
      );
      return {
        ...state,
        companies: updateCompanyUsersUpdatedCompaniesList,
        companiesMap: createMapFromArray(updateCompanyUsersUpdatedCompaniesList),
        companyPutSupervisorNetworkState: SUCCESS,
      };

    default:
      return state;
  }
};

export default companiesReducer;

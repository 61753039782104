import Styleguide from "./components/Styleguide";

import { UserIsAuthenticated } from "utils/auth-helpers";

const StyleguideRoute = () => ({
  path: "/styleguide",
  component: UserIsAuthenticated(Styleguide),
  exact: true,
});

export default StyleguideRoute;

import React, { useState } from 'react';
import useForm from 'hooks/use-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useProductTypes from 'hooks/use-productTypes';
import useGuides from 'hooks/use-guides';

import { ReactComponent as TrashIcon } from 'icons/trash.svg';
import { Overlay } from 'react-draft-editor';
import Dropdown from 'components/Dropdown/Dropdown';
import ChoiceGroup from 'components/ChoiceGroup/ChoiceGroup';
import ButtonLink from 'components/ButtonLink/ButtonLink';

import { addGuide, updateGuide, deleteGuide } from 'actions/guide-actions';

import __ from 'constants/static-texts';
import { isGuideBlueprint } from 'utils/guide';
import { isProductBas } from 'utils/product';

const CLASS_NS = 'form_overlay';

const GuideSettings = ({ cancelUrl, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { guideId } = useParams(); // id of selected guide
  const { productTypes } = useProductTypes();
  const [guides] = useGuides();
  const [showRemove, setShowRemove] = useState(false);
  const [copyGuide, setCopyGuide] = useState(false);

  const isNew = !guideId;

  const currentGuide = guides.find((guide) => guide.id === guideId - 0);

  const prodTypesOptions = productTypes
    .map((pt) => ({ id: pt.idNumber, text: pt.name }))
    .filter((pt) => !isProductBas(pt));

  const existingGuides = guides
    .filter((guide) => isGuideBlueprint(guide))
    .map((guide) => ({ id: guide.id, text: guide.title }));
  const initialGuide = {
    title: currentGuide?.title || '',
    product: currentGuide?.product || prodTypesOptions[0].id,
    originGuideId: existingGuides[0].id,
  };
  const { inputs, handleChange } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...initialGuide,
    }
  );

  const renderTitle = showRemove
    ? `${__.guide.removeGuideTitle} ${currentGuide.title}`
    : isNew
    ? `${__.guide.createNewTitle}`
    : `${__.guide.edit} ${currentGuide.title}`;

  const handleSubmit = (e) => {
    e.preventDefault();

    isNew
      ? dispatch(
          addGuide({
            ...inputs,
            product: parseInt(inputs.product),
            originGuideId: copyGuide ? inputs.originGuideId : null,
          })
        )
      : dispatch(updateGuide({ ...inputs, id: guideId }));

    history.push(cancelUrl, { disableScroll: true });
  };

  const handleCancel = () => {
    if (showRemove) {
      setShowRemove(false);
    } else {
      history.push(cancelUrl, { disableScroll: true });
    }
  };

  const handleDelete = () => {
    dispatch(deleteGuide(parseInt(guideId)));
    history.push(cancelUrl, { disableScroll: true });
  };

  return (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <h2>{renderTitle}</h2>
      <form className={classNs} onSubmit={handleSubmit}>
        {showRemove ? (
          <div className={`${classNs}_form-row`}>{__.guide.confirmDeletion}</div>
        ) : (
          <>
            <label className={`${classNs}_form-row`} htmlFor="title">
              <span className="label">{__.guide.title}</span>
              <input
                type="text"
                id="title"
                name="title"
                placeholder={__.placeholder.guideTitle}
                value={inputs.title}
                onChange={handleChange}
              />
            </label>
            {isNew && (
              <Dropdown
                value={inputs.product || ''}
                options={prodTypesOptions}
                label={__.guide.productType}
                onChange={handleChange}
                classNs={`${classNs}_dropdown`}
                id={`product`}
                labelPos="top"
              />
            )}
            {isNew && (
              <label className={`${classNs}_form-row`}>
                <span className="label">{__.guide.type}</span>
                <ChoiceGroup
                  classNs={`${classNs}_type-switch`}
                  onChange={() => setCopyGuide(!copyGuide)}
                  chosen={copyGuide ? '1' : '0'}
                  choices={[
                    {
                      value: '0',
                      label: 'Nej, skapa ny',
                    },
                    {
                      value: '1',
                      label: 'Ja, kopiera befintlig',
                    },
                  ]}
                />
              </label>
            )}
            {isNew && copyGuide && (
              <Dropdown
                value={inputs.originGuideId || ''}
                options={existingGuides}
                label={__.guide.existingGuide}
                onChange={handleChange}
                classNs={`${classNs}_form-row`}
                id={`originGuideId`}
              />
            )}
          </>
        )}
        <div className="button-row">
          <button type="button" className="button-inverted" onClick={handleCancel}>
            {__.general.cancel}
          </button>
          {showRemove ? (
            <button type="button" className="button-warning" onClick={handleDelete}>
              {__.general.confirmDelete}
            </button>
          ) : (
            <button type="submit" className={`button`} disabled={inputs.title === ''}>
              {isNew ? `Skapa` : `Uppdatera`}
            </button>
          )}
        </div>
        {!showRemove && !isNew && (
          <ButtonLink
            icon={<TrashIcon aria-hidden />}
            variant="action"
            size="small"
            alignment="center"
            onClick={() => setShowRemove(true)}>
            {__.guide.removeGuideTitle}
          </ButtonLink>
        )}
      </form>
    </Overlay>
  );
};

export default GuideSettings;

import React from "react";
import SupervisorList from "./SupervisorList";
import { useDispatch, useSelector } from "react-redux";
import Search from "components/Search/Search";
import useSupervisors from "hooks/use-supervisors";
import { Link } from "react-router-dom";
import { renderRoutes } from "react-router-config";

import __ from "constants/static-texts";
import SupervisorsRoute from "routes/Supervisors";
import SubHeader from "components/SubHeader/SubHeader";

import { setSearchQuerySupervisors } from "actions/users-actions";
// import ReactTooltip from "react-tooltip";

const CLASS_NS = "supervisors";

const Supervisors = ({ location, match, route, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { supervisorsAreLoading, supervisorRoles, filteredSupervisors } = useSupervisors();

  const { searchQuerySupervisors } = useSelector((state) => state.usersState);

  const userSearchChange = (value) => {
    dispatch(setSearchQuerySupervisors(value));
  };

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{__.supervisors.supervisor}</h1>
        <p className={`${classNs}_description`}>{__.supervisors.description}</p>
      </SubHeader>
      <div className={`${classNs}`}>
        {/* <p data-tip="hello world 2">Tooltip 2</p> */}
        <Search onChange={userSearchChange} searchQuery={searchQuerySupervisors} placeholder={__.supervisors.search} />
        <Link to={`${SupervisorsRoute().path}/new`} className={`${classNs}_add`}>
          {__.supervisors.add}
        </Link>
        {!supervisorsAreLoading ? (
          <SupervisorList users={filteredSupervisors} userRoles={supervisorRoles} classNs={classNs} />
        ) : null}
        {!supervisorsAreLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
            })
          : null}
        {/* <ReactTooltip effect="solid" place="bottom" backgroundColor="red" /> */}
      </div>
    </>
  );
};

export default Supervisors;

import { guideType } from "constants/guide-types";
import { getGuide, getGuideMeta } from "actions/guide-actions";

export const isGuideBlueprint = (guide) => guide?.guideType === guideType.bluePrint;
export const isGuideInstans = (guide) => guide?.guideType === guideType.instance;

let loadingGuide = false;

export const loadGuide = (id, guide, dispatch) => {
  let idNum = id;
  if (typeof id !== "number") {
    try {
      idNum = id - 0;
    } catch (e) {
      console.warn(`The id in useGuide hook must be of type number. Instead id of type ${typeof id} was given: ${id}.`);
    }
  }
  if (idNum < 1 || idNum === null || typeof idNum === undefined) {
    return;
  }
  // This is a temporal solution.
  // This doesn't eleviate the problem with multiple actions being called. It would be
  // better to use a generator to que and flush API calls.
  // Better to wait for React Suspense.
  const hasData = guide.some((block) => block.type === "guide-part" && block.parentId === idNum);
  if (hasData || loadingGuide) {
    return;
  }
  const fetchData = async () => {
    loadingGuide = true;
    const guideData = await dispatch(getGuide(idNum));
    const guideMetaData = await dispatch(getGuideMeta(idNum));
    loadingGuide = false;
    return [guideData, guideMetaData];
  };

  fetchData();
};

import React from 'react';
import PropTypes from 'prop-types';

import TableEditorCellContainer from './table-editor-cell.container';

const editIcon =
  '<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg"><g stroke="#4A4A4A" stroke-width="4" fill="none" fill-rule="evenodd" opacity=".5"><path d="M27.358 11.399a2.187 2.187 0 0 0 0-3.096l-5.661-5.661a2.187 2.187 0 0 0-3.096 0L2 19.243V28h8.757l16.601-16.601z"/><path d="M4.308 17.308 12 25" stroke-linecap="square"/></g></svg>';
const techIcon =
  '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m20.271 6.771-3.042-3.042L20.437.521A5.97 5.97 0 0 0 18 0a6 6 0 0 0-5.75 7.708l-10.789 8.73a4.335 4.335 0 0 0-1.459 3.106 4.335 4.335 0 0 0 1.264 3.19 4.325 4.325 0 0 0 6.296-.195l8.73-10.789A6 6 0 0 0 24 6c0-.869-.189-1.692-.521-2.438l-3.208 3.209z" fill-rule="nonzero" stroke="inherit"/></svg>';

const TableEditor = ({
  tableContent = {
    rows: []
  },
  tableMatrix,
  insertRow,
  insertCol,
  destroyRow,
  destroyCol,
  onCellChange,
  onTableChange,
  handleSwitchMode,
  table,
  colCount = 2,
  tableClassName = '',
  transitioningColIndex = -1,
  isSubmitted = false,
  editTableMode = false,
  iconEdit = editIcon,
  iconTech = techIcon,
  labelTableContent = 'Uppdatera tabellinnehåll',
  labelTableStructure = 'Uppdatera tabellstruktur'
}) => {
  const tableRow = (row, rowIndex) => (
    <tr key={'row-' + rowIndex}>
      {row.cols.map((col, colIndex) => {
        return (
          <td
            key={'row-' + rowIndex + '-col-' + colIndex}
            className={'table-editor_cell'}
            data-transitioningcol={colIndex === transitioningColIndex ? 'true' : 'false'}
            colSpan={col.colspan ? col.colspan : 1}
            rowSpan={col.rowspan ? col.rowspan : 1}
          >
            <TableEditorCellContainer
              rowIndex={rowIndex}
              colIndex={colIndex}
              cellContent={col.cellContent}
              tableContent={tableContent}
              onCellChange={onCellChange}
              onTableChange={onTableChange}
              destroyRow={destroyRow}
              isSubmitted={isSubmitted}
              editTableMode={editTableMode}
              tableMatrix={tableMatrix}
            />
          </td>
        );
      })}

      {tableContent.rows.length > 1 && editTableMode && (
        <td className="table-editor_cell table-editor_cell--remove-row">
          <button
            className="closebtn_cross"
            type="button"
            title="Ta bort rad"
            onClick={destroyRow.bind(null, rowIndex)}
          >
            &times;
          </button>
        </td>
      )}
    </tr>
  );

  return (
    <div className="table-editor">
      <div className={'table-editor-table ' + tableClassName} data-editmode={`${editTableMode}`}>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            {tableContent.rows.map((row, rowIndex) => {
              return tableRow(row, rowIndex);
            })}
            {colCount > 2 && editTableMode && (
              <tr>
                {new Array(colCount).fill('').map((col, colIndex) => {
                  return (
                    <td key={colIndex} className="table-editor_cell--remove-col">
                      <button
                        className="closebtn_cross"
                        type="button"
                        title="Ta bort kolumn"
                        onClick={destroyCol.bind(null, colIndex)}
                      >
                        &times;
                      </button>
                    </td>
                  );
                })}
                {tableContent.rows.length > 1 && (
                  <td className="table-editor_cell--remove-col">&nbsp;</td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {editTableMode && (
        <div className="table-editor_buttons form-lineup_actions">
          <button
            className="form-lineup_actions_btn form-lineup_actions_btn--secondary"
            type="button"
            onClick={insertRow}
          >
            <div className="form-lineup_actions_btn_wrapper">Lägg till rad</div>
          </button>
          {colCount < 8 && (
            <button
              className="form-lineup_actions_btn form-lineup_actions_btn--secondary"
              type="button"
              onClick={insertCol}
            >
              <div className="form-lineup_actions_btn_wrapper">Lägg till kolumn</div>
            </button>
          )}
        </div>
      )}
      {
        <div className="table-editor_buttons form-lineup_actions">
          <button
            className="form-lineup_actions_btn form-lineup_actions_btn--primary"
            type="button"
            onClick={handleSwitchMode}
          >
            <div className="form-lineup_actions_btn_wrapper">
              {editTableMode ? iconEdit : iconTech}
              {editTableMode ? labelTableContent : labelTableStructure}
            </div>
          </button>
        </div>
      }
    </div>
  );
};

TableEditor.propTypes = {
  tableContent: PropTypes.object,
  tableMatrix: PropTypes.array,
  insertRow: PropTypes.func,
  insertCol: PropTypes.func,
  destroyRow: PropTypes.func,
  destroyCol: PropTypes.func,
  onCellChange: PropTypes.func,
  handleSwitchMode: PropTypes.func,
  table: PropTypes.object,
  colCount: PropTypes.number,
  tableClassName: PropTypes.string,
  transitioningColIndex: PropTypes.number,
  isSubmitted: PropTypes.bool,
  editTableMode: PropTypes.bool
};

export default TableEditor;

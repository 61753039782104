import fetchClient from "fetch-client";

export const getComments = (getState) =>
  fetchClient({
    path: `/comments`,
    getState,
  });

export const addComment = (data, getState) =>
  fetchClient({
    path: `/comments`,
    getState,
    method: `POST`,
    data,
  });
export const deleteComment = (id, getState) =>
  fetchClient({
    path: `/comments/${id}`,
    getState,
    method: `DELETE`,
    data: id,
  });

import React from "react";

function CoreTable(props) {
  const { block } = props;
  return (
    <div className="Fact-block--CoreTable" dangerouslySetInnerHTML={{ __html: block.originalContent }} />
  );
}

export default CoreTable;

import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { OidcProvider } from "redux-oidc";
import { renderRoutes } from "react-router-config";

const AppContainer = ({ store, routes, history, userManager, children }) => {
  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ConnectedRouter history={history}>{renderRoutes(routes)}</ConnectedRouter>
      </OidcProvider>
    </Provider>
  );
};

export default AppContainer;

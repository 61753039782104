import React, { useState } from "react";
import __ from "constants/static-texts";
import Tooltip from "components/Tooltip/Tooltip";
import { getUniqueId } from "utils/random";
import { ReactComponent as Plus } from "icons/plus.svg";
import { ReactComponent as Minus } from "icons/Minus.svg";
import PropTypes from "prop-types";

const CLASS_NS = "calendar-section";

const CalendarSection = ({ classNs, idNs, id, region, heading, initOpen }) => {
  const [isActive, toggleActive] = useState(initOpen);

  return (
    <section
      id={id}
      data-hide-in-export={!region.length || null}
      className={`${classNs}${!region || !region.length ? ` ${classNs}--empty` : ``}${
        isActive ? ` ${classNs}--active` : ``
      }`}>
      <header className={`${classNs}_header`}>
        <h5 className={`${classNs}_heading`} id={`${idNs}_heading`}>
          {heading}
        </h5>
        <button
          data-hide-in-export
          disabled={!region.length}
          className={`${classNs}_toggle`}
          data-icon="plus"
          onClick={() => toggleActive(!isActive)}
          aria-expanded={isActive}
          aria-controls={`${idNs}_region`}
          aria-label={isActive ? __.calendar.toggleSectionClose : __.calendar.toggleSectionOpen}
          data-tip={isActive ? __.calendar.toggleSectionClose : __.calendar.toggleSectionOpen}
          data-for={`${idNs}_tooltip`}>
          {isActive ? <Minus className="minus" aria-hidden /> : <Plus className="plus" aria-hidden />}
        </button>
      </header>
      <div
        className={`${classNs}_region`}
        role="region"
        aria-labelledby={`${idNs}_heading`}
        id={`${idNs}_region`}
        hidden={!isActive}>
        {region}
      </div>
      <div data-hide-in-export>
        <Tooltip theme={"dark"} id={`${idNs}_tooltip`} data-hide-in-export />
      </div>
    </section>
  );
};

CalendarSection.propTypes = {
  classNs: PropTypes.string,
  idNs: PropTypes.string,
  region: PropTypes.node,
  heading: PropTypes.string.isRequired,
};

CalendarSection.defaultProps = {
  classNs: CLASS_NS,
  idNs: getUniqueId().toLowerCase(),
  initOpen: false,
};

export default CalendarSection;

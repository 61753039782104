import __ from "constants/static-texts";
import { StartRoute } from "routes/Start";
import GuidesRoute from "routes/Guides";
import LibraryDocs from "routes/LibraryDocs";
import CompaniesRoute from "routes/Companies";
import UsersRoute from "routes/Users";
import SupervisorsRoute from "routes/Supervisors";
import NewsRoute from "routes/News";
import SupervisorsOverviewRoute from "routes/SupervisorsOverview";
import WorkbooksRoute from "routes/Workbook";
import ToolsRoute from "routes/Tools";
import CalendarsRoute from "routes/Calendars";
import { USER_TYPES } from "constants/user-types";
import { PRODUCT_TYPES } from "constants/product-types";

export const MAIN_NAVIGATION_ROUTES = {
  MAIN: {
    label: __.navigation.main,
    path: StartRoute().path,
    locked: false,
    roles: [],
    products: [],
  },
  LIBRARYDOCS: {
    label: __.navigation.documents,
    path: LibraryDocs().path,
    locked: false,
    roles: [],
    products: [],
  },
  GUIDE: {
    label: __.navigation.guide,
    path: "/guides/:id/guide",
    locked: false,
    roles: [],
    products: [PRODUCT_TYPES.CERTIFIERING.id, PRODUCT_TYPES.ISO.id],
  },
  WORKBOOKS: {
    label: __.navigation.workbooks,
    path: WorkbooksRoute().path,
    locked: false,
    roles: [USER_TYPES.SERVICEADMIN.id], // TODO: rolen är tillfällig medans copyn för arbetsbökerna uppdateras
    products: [],
  },
  TOOLS: {
    label: __.navigation.servicesAndTools,
    path: ToolsRoute().path,
    locked: false,
    roles: [],
    products: [PRODUCT_TYPES.CERTIFIERING.id, PRODUCT_TYPES.ISO.id],
  },
  SUPERVISORSOVERVIEW: {
    label: __.navigation.supervisors,
    path: SupervisorsOverviewRoute().path,
    locked: false,
    roles: [
      USER_TYPES.SERVICEADMIN.id,
      USER_TYPES.COMPANYADMIN.id,
      USER_TYPES.SUPERVISOR.id,
      USER_TYPES.COMPANYEDITOR.id,
    ],
    products: [PRODUCT_TYPES.CERTIFIERING.id, PRODUCT_TYPES.ISO.id],
  },
  CALENDAR: {
    label: __.navigation.calendar,
    path: CalendarsRoute().path,
    locked: false,
    roles: [],
    products: [PRODUCT_TYPES.CERTIFIERING.id, PRODUCT_TYPES.ISO.id],
  },
  GUIDES: {
    label: __.navigation.guides,
    path: GuidesRoute().path,
    locked: false,
    pretitle: __.navigation.admin,
    roles: [USER_TYPES.SERVICEADMIN.id],
    products: [],
    hideIfCompanyActive: true,
  },
  CALENDARS: {
    label: __.navigation.calendars,
    path: CalendarsRoute().path,
    locked: false,
    roles: [USER_TYPES.SERVICEADMIN.id],
    products: [],
    hideIfCompanyActive: true,
  },
  COMPANIES: {
    label: __.navigation.companies,
    path: CompaniesRoute().path,
    locked: false,
    roles: [USER_TYPES.SERVICEADMIN.id],
    products: [],
    hideIfCompanyActive: true,
  },
  NEWS: {
    label: __.navigation.news,
    path: NewsRoute().path,
    locked: false,
    roles: [USER_TYPES.SERVICEADMIN.id],
    products: [],
    hideIfCompanyActive: true,
  },
  SUPERVISORS: {
    label: __.navigation.supervisors,
    path: SupervisorsRoute().path,
    locked: false,
    roles: [USER_TYPES.SERVICEADMIN.id],
    products: [],
    hideIfCompanyActive: true,
  },
  USERS: {
    label: __.navigation.users,
    path: UsersRoute().path,
    locked: false,
    roles: [USER_TYPES.SERVICEADMIN.id, USER_TYPES.COMPANYADMIN.id],
    products: [],
  },
};

export const MAIN_NAVIGATION_ROUTES_ARRAY = Object.values(MAIN_NAVIGATION_ROUTES).map((route) => route);

export const courseNavSchema = {
  blbChapterId: "id",
  titleRendered: "title",
};

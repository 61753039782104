import React from "react";
import __ from "constants/static-texts";
import { ReactComponent as Magnifier } from "icons/Magnifier.svg";

const CLASS_NS = "search";

const Search = ({
  classNs = CLASS_NS,
  onChange,
  placeholder = __.search.placeholder,
  searchQuery,
  compact = false,
}) => {
  return (
    <div className={`${classNs}`} data-compact={compact} onClick={(e) => e.stopPropagation()}>
      <input
        type="text"
        placeholder={placeholder}
        className={`${classNs}_input`}
        onChange={(e) => onChange(e.target.value)}
        value={searchQuery}
      />
      {!compact && (
        <div className={`${classNs}_icon_holder`}>
          <div className={`${classNs}_icon`}>
            <Magnifier />
          </div>
        </div>
      )}
      {/* {onClick && <input type="submit" className={`${classNs}_submit`} value="Sök" />} */}
    </div>
  );
};

export default Search;

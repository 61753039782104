import { Editor, Transforms, Text } from 'slate';
import { EDITOR_TOOLS } from '../constants/text-editor';

export const CustomEditor = {
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.bold === true,
      universal: true
    });
    return !!match;
  },
  isMarkerActive(marker, editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n?.[marker] === true,
      universal: true
    });
    return !!match;
  },

  isCodeBlockActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === 'code'
    });
    return !!match;
  },

  toggleBoldMark(editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true }
    );
  },

  toggleLinkMark(editor) {
    const isActive = CustomEditor.isMarkerActive('link', editor);
    Transforms.setNodes(
      editor,
      { link: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true }
    );
  },

  toggleCodeBlock(editor) {
    const isActive = CustomEditor.isCodeBlockActive(editor);
    Transforms.setNodes(
      editor,
      { type: isActive ? null : 'code' },
      { match: (n) => Editor.isBlock(editor, n) }
    );
  }
};

export const editorTools = {
  [EDITOR_TOOLS.BOLD]: {
    label: 'fet',
    icon: '',
    shortCut: 'b',
    fn: CustomEditor.toggleBoldMark
  },
  [EDITOR_TOOLS.ITALIC]: {
    label: 'kursiv',
    icon: '',
    shortCut: 'i'
  },
  [EDITOR_TOOLS.UNDERLINE]: {
    label: 'understrykning',
    icon: '',
    shortCut: 'u'
  },
  [EDITOR_TOOLS.UNORDERED_LIST]: {
    label: 'lista',
    icon: '',
    shortCut: 'l'
  },
  [EDITOR_TOOLS.UNORDERED_LIST_DASH]: {
    label: 'streck lista',
    icon: '',
    shortCut: '-'
  },
  [EDITOR_TOOLS.LINK]: {
    label: 'Länk',
    icon: '',
    shortCut: 'a',
    fn: CustomEditor.toggleLink
  }
};

export const convertRawContentToTableState = (str) => {
  let tableState = {};
  if (str) {
    const tableContent = JSON.parse(str);
    tableState = {
      rows: [...tableContent.rows]
    };
    tableState.rows.forEach((row, rowIndex) => {
      row.cols.forEach((col, colIndex) => {
        let contentEditor = null;
        try {
          contentEditor = JSON.stringify(col.cellContent);
        } catch (e) {
          contentEditor = '';
        }
        col.cellContent = contentEditor;
      });
    });
  } else {
    //we create the table object (2 columns) in case we don't have data
    tableState = {
      rows: [
        {
          cols: [
            {
              cellContent: ''
            },
            {
              cellContent: ''
            }
          ]
        }
      ]
    };
  }
  return tableState;
};

export const convertTableStateToRawContent = (tableState) => {
  let rawTable = null;
  //we parse it into a new object to avoid mutating tableState
  if (tableState) {
    let tableStateToSet = {
      rows: tableState.rows.map((row) => {
        return {
          cols: row.cols.map((col) => {
            const cell = {
              cellContent: col.cellContent
            };
            if (col.colspan) {
              cell.colspan = col.colspan;
            }
            if (col.rowspan) {
              cell.rowspan = col.rowspan;
            }
            return cell;
          })
        };
      })
    };
    tableStateToSet.rows.map((row) => {
      return row.cols.map((col) => {
        col.cellContent = col.cellContent ? JSON.parse(col.cellContent) : '';
        return col;
      });
    });
    rawTable = JSON.stringify(tableStateToSet);
  }
  return rawTable;
};

export const getMatrixIndex = (rowIndex, colIndex, tableMatrix) => {
  for (let rIndex = 0; rIndex < tableMatrix.length; rIndex++) {
    let row = tableMatrix[rIndex];
    for (let cIndex = 0; cIndex < row.length; cIndex++) {
      let col = row[cIndex];
      if (col.tableContentRow === rowIndex && col.tableContentCol === colIndex) {
        return {
          rowId: rIndex,
          colId: cIndex
        };
      }
    }
  }
};

export const getFirstEmpty = (tableMatrix) => {
  for (let rIndex = 0; rIndex < tableMatrix.length; rIndex++) {
    let row = tableMatrix[rIndex];
    for (let cIndex = 0; cIndex < row.length; cIndex++) {
      let col = row[cIndex];
      if (col.tableContentRow === null && col.tableContentCol === null) {
        return {
          rowId: rIndex,
          colId: cIndex
        };
      }
    }
  }
};

export const cleanTable = (editedTable) => {
  editedTable.rows = editedTable.rows.filter((row) => row.cols.length);

  editedTable.rows = editedTable.rows.map((row) => {
    let sameRowSpan = true;
    const firstColRowspan = row.cols[0].rowspan ? row.cols[0].rowspan : 1;
    row.cols = row.cols.map((col) => {
      const rowspan = col.rowspan ? col.rowspan : 1;

      if (rowspan !== firstColRowspan) {
        sameRowSpan = false;
      }
      return col;
    });

    if (sameRowSpan) {
      row.cols = row.cols.map((col) => {
        col.rowspan = 1;
        return col;
      });
    }
    return row;
  });

  return editedTable;
};

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useSupervisors from 'hooks/use-supervisors';
import useGuides from 'hooks/use-guides';
import useCalendars from 'hooks/use-calendars';
import useCompanies from 'hooks/use-companies';
import useForm from 'hooks/use-form';
import useUsers from 'hooks/use-users';
import useGuideById from 'hooks/use-guide-by-id';

import RichTextEditor, { RichTextEditorVariant } from 'components/RichTextEditor/RichTextEditor';
import { Overlay } from 'react-draft-editor';
import Dropdown from 'components/Dropdown/Dropdown';
import SearchableSelector from 'components/SearchableSelector/SearchableSelector';
import ChoiceGroup from 'components/ChoiceGroup/ChoiceGroup';
import Loading from 'components/Loading/Loading';
import GuideExport from 'components/GuideExport/GuideExport';
import ButtonLink from 'components/ButtonLink/ButtonLink';
import { ReactComponent as TrashCan } from 'icons/trash.svg';

import { getNewAndExistingUsers } from 'utils/user-helper';

import {
  addCompanyGuide,
  getSSOCompanyUsers,
  resetSSOCompanyUsers,
  updateCompanyinfo,
  getCompanyUsers,
} from 'actions/companies-actions';
import { addUsers } from 'actions/users-actions';
import { addCalendarInstance } from 'actions/calendar-actions';
import { updateGuide, deleteGuide } from 'actions/guide-actions';

import { isGuideBlueprint } from 'utils/guide';
import { isCalendarBlueprint } from 'utils/calendar';
import __ from 'constants/static-texts';
import { ValidEmail } from 'utils/string-helpers';
import { sortArray, removeObjectArrayDuplicates } from 'utils/js-helpers';

import { isCompanyAdmin, isCompanyUser } from 'utils/user-helper';

const CLASS_NS = 'form_overlay';

const CompanyGuideSettings = ({ cancelUrl, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { companyId, guideId } = useParams(); // id of selected company
  const [guides] = useGuides();
  const { calendars } = useCalendars();
  const { companies, ssoCompanyUsers, ssoCompanyUsersLoading } = useCompanies();
  const history = useHistory();
  const currentCompany = companies.find((company) => company.id === companyId - 0);
  const { users, usersIsLoading } = useUsers();

  const { guide } = useGuideById(guideId);

  const [bkmaUsers, setBkmaUsers] = useState([]);
  const [companyAdmins, setCompanyAdmins] = useState([]);
  const [SSOUsers, setSSOUsers] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [activateGuideDisabled, setActivateGuideDisabled] = useState(!currentCompany.guideId);
  const [activeGuide, setActiveGuide] = useState(!!currentCompany.isGuideOpen);
  const [showRemove, setShowRemove] = useState(false);

  const { supervisors } = useSupervisors();
  const hasLoggedIn = ({ firstName, lastName }) => [firstName, lastName].every((name) => name !== null);

  const filteredSupervisors = supervisors.filter((sup) => sup.details?.isOpenForNewAssignments && hasLoggedIn(sup));
  const isNew = !guideId;

  let updateAdmins = useRef(false);

  const noCalendarSet = calendars.filter((c) => c.companyId === currentCompany.id).length === 0;
  const getFormattedUser = (user, idName) => ({
    label: user.firstName ? `${user.firstName} ${user.lastName}` : `${user.userName}`,
    subLabel: `${user.userName}`,
    value: `${user.userName}`,
    firstName: `${user.firstName}`,
    lastName: `${user.lastName}`,
    id: user[idName],
  });

  useEffect(() => {
    dispatch(getSSOCompanyUsers(currentCompany.companyRegistrationNumber));

    const _bkmaUsers = users.filter((user) => user.companyIds.includes(currentCompany.id) && isCompanyUser(user));

    setBkmaUsers(
      _bkmaUsers.map((user) => {
        return getFormattedUser(user, 'id');
      })
    );

    const initialAdmins = _bkmaUsers
      .filter((user) => isCompanyAdmin(user))
      .map((user) => {
        return getFormattedUser(user, 'id');
      });

    setCompanyAdmins(initialAdmins);

    return () => {
      dispatch(resetSSOCompanyUsers());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSSOUsers(
      ssoCompanyUsers.map((user) => {
        return getFormattedUser(user, 'userId');
      })
    );
  }, [ssoCompanyUsers]);

  const availableUsers = removeObjectArrayDuplicates(
    SSOUsers.concat(bkmaUsers).sort(sortArray('label', true, true)),
    'value'
  );

  const supervisorsOptions = [
    {
      id: '-1',
      text: __.companies.chooseSupervisor,
    },
  ].concat(
    filteredSupervisors
      .map((user) => ({
        id: user.id,
        text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.userName}`,
      }))
      .sort(sortArray('text', true, true))
  );

  let guideOptions;
  if (!currentCompany.guideId) {
    guideOptions = guides
      .filter((guide) => isGuideBlueprint(guide) && guide.product === currentCompany.product)
      .map((guide) => {
        return { ...guide, id: guide.id, text: `${guide.title}` };
      });
    guideOptions = [{ id: 0, title: 'Välj en guidemall', text: 'Välj en guidemall' }, ...guideOptions];
  } else {
    guideOptions = guides
      .filter((guide) => guide.id === currentCompany.guideId)
      .map((guide) => {
        return {
          ...guide,
          id: guide.id,
          text: !guide.originGuideTitle ? `Guide för ${currentCompany.name}` : guide.originGuideTitle,
        };
      });
  }

  let calendarOptions = calendars
    .filter((calendar) => isCalendarBlueprint(calendar) && calendar.product === currentCompany.product)
    .map((calendar) => {
      return { ...calendar, id: calendar.id, text: calendar.title };
    });
  calendarOptions = [{ id: 0, title: 'Välj en kalendermall', text: 'Välj en kalendermall' }, ...calendarOptions];

  const initialState = {
    bluePrint: guideOptions[0].id,
    calendar: calendarOptions[0]?.id,
    supervisorUserId: currentCompany.supervisorUserId || '-1',
    companyId: parseInt(companyId),
    note: currentCompany.note || '',
  };

  const { inputs, handleChange } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...initialState,
    }
  );

  const handleGuideChange = (changeEvent) => {
    setActivateGuideDisabled(changeEvent.target.value === '0');
    handleChange(changeEvent);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { newUsersNotExist } = getNewAndExistingUsers(companyAdmins, users);

    setIsSubmitting(true);

    const { calendar } = inputs;
    const newSupervisorUserId = inputs.supervisorUserId === '-1' ? null : inputs.supervisorUserId;
    const updateUsers = currentCompany.supervisorUserId !== newSupervisorUserId || updateAdmins.current;
    const updateCompanyGuide =
      (guideId !== undefined && parseInt(guideId) !== inputs.bluePrint) ||
      (currentCompany.isGuideOpen !== null && currentCompany.isGuideOpen !== activeGuide);
    const updateCalendar = noCalendarSet && calendar !== 0;

    Promise.all(addUsers(newUsersNotExist, { companyId }, dispatch)).then((res) => {
      const newCompanyAdminsIds = res.map((user) => user.id);
      const promises = [];
      if (updateUsers || inputs.note !== currentCompany.note) {
        const adminIds = [];
        companyAdmins.forEach((admin) => {
          if (admin?.id) {
            adminIds.push(admin.id);
          }
        });

        const test = adminIds.concat(newCompanyAdminsIds);
        promises.push(
          dispatch(
            updateCompanyinfo({
              companyId,
              supervisorUserId: newSupervisorUserId,
              adminIds: test,
              note: inputs.note,
            })
          )
        );
      }

      if (isNew && inputs.bluePrint !== 0) {
        promises.push(
          dispatch(
            addCompanyGuide({
              bluePrint: inputs.bluePrint,
              companyId: inputs.companyId,
              isOpenForCompany: !!activeGuide,
            })
          )
        );
      } else if (updateCompanyGuide) {
        promises.push(
          dispatch(
            updateGuide({
              id: guideId,
              companyId: inputs.companyId,
              isOpenForCompany: !!activeGuide,
              title: `Guide för ${currentCompany?.name}`,
            })
          )
        );
      }

      if (updateCalendar) {
        promises.push(
          dispatch(
            addCalendarInstance({
              calendar: calendar,
              title: `${currentCompany.name} - kalender`,
              companyId: parseInt(currentCompany.id),
            })
          )
        );
      }

      Promise.all(promises).then(() => {
        dispatch(getCompanyUsers({ companyId }));
        setIsSubmitting(false);
        history.push(cancelUrl, { disableScroll: true });
      });
    });
  };

  const handleCancel = () => {
    if (showRemove) {
      setShowRemove(false);
    } else {
      history.push(cancelUrl, { disableScroll: true });
    }
  };

  const handleDelete = () => {
    dispatch(deleteGuide(parseInt(guideId)));
    history.push(cancelUrl, { disableScroll: true });
  };

  const onAddCompanyAdmin = (data) => {
    updateAdmins.current = true;
    setCompanyAdmins(data);
  };

  return (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <h2>{__.companies.companySettings}</h2>
      <div className={`${classNs}_form-row`}>{currentCompany.name}</div>
      {(ssoCompanyUsersLoading || usersIsLoading || isSubmitting) && <Loading /> ? (
        <Loading text={isSubmitting ? 'Sparar ...' : 'Laddar ...'} />
      ) : (
        <form onSubmit={handleSubmit} className={CLASS_NS}>
          {showRemove ? (
            <>
              {guide.hasContentAddedByCompany ? (
                <>
                  <div className={`${classNs}_form-row companies_export-text`}>
                    {__.companies.removeGuideConfirmExport}
                  </div>
                  <div className={`${classNs}_form-row`}>
                    <div className={`companies_export`}>
                      <GuideExport
                        companyId={guide.companyId}
                        filename={currentCompany.name}
                        text={__.companies.exportGuide}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className={`${classNs}_form-row`}>{__.companies.removeGuideConfirm}</div>
              )}
            </>
          ) : (
            <>
              <Dropdown
                label={__.companies.guideTemplates}
                value={inputs.bluePrint}
                options={guideOptions}
                onChange={handleGuideChange}
                classNs={`${classNs}_dropdown`}
                id={`bluePrint`}
                labelPos="top"
                disabled={currentCompany.guideId}
              />
              <div className={`${classNs}_form-row`}>
                <ChoiceGroup
                  onChange={() => setActiveGuide(!activeGuide)}
                  chosen={activeGuide ? '1' : '0'}
                  type="checkbox"
                  inversed={true}
                  description={__.companies.activeGuide}
                  choices={[
                    {
                      value: '1',
                      label: __.companies.activeGuide,
                      disabled: activateGuideDisabled,
                      readonly: activateGuideDisabled,
                    },
                  ]}
                />
              </div>
              {noCalendarSet ? (
                <Dropdown
                  label={__.companies.calendarTemplates}
                  value={inputs.calendar}
                  options={calendarOptions}
                  onChange={handleChange}
                  classNs={`${classNs}_dropdown`}
                  id={`calendar`}
                  labelPos="top"
                />
              ) : null}
              <Dropdown
                label={__.companies.supervisors}
                value={inputs.supervisorUserId}
                options={supervisorsOptions}
                onChange={handleChange}
                classNs={`${classNs}_form-row_select ${classNs}_dropdown`}
                id={`supervisorUserId`}
                labelPos="top"
              />
              <SearchableSelector
                label={__.companies.companyAdmins}
                classNs={`${classNs}_form-row`}
                selectText={__.companies.chooseCompanyAdmins}
                onChange={onAddCompanyAdmin}
                canAdd={true}
                options={availableUsers}
                validateAdded={ValidEmail}
                missingOptions={() => __.companies.noCompanyAdminMatch}
                selectedOptions={companyAdmins}
              />
              <label className={`${classNs}_form-row label-top`} htmlFor="note">
                <span className="label">{__.companies.notes}</span>
                <RichTextEditor
                  initValue={inputs.note}
                  variant={RichTextEditorVariant.SECONDARY}
                  onChange={(value) => handleChange({ value, name: 'note' })}
                />
              </label>
            </>
          )}
          <div className="button-row">
            <button type="button" className="button-inverted" onClick={handleCancel}>
              {__.general.cancel}
            </button>
            {showRemove ? (
              <button type="button" className="button-warning" onClick={handleDelete}>
                {__.general.confirmDelete}
              </button>
            ) : (
              <button className="button" type="submit">
                {isNew ? __.companies.saveGuide : __.companies.editCompanyButton}
              </button>
            )}
          </div>
          {!showRemove && !isNew && (
            <ButtonLink
              icon={<TrashCan aria-hidden />}
              variant="action"
              size="small"
              alignment="center"
              onClick={() => setShowRemove(true)}>
              {__.companies.removeGuideLink}
            </ButtonLink>
          )}
        </form>
      )}
    </Overlay>
  );
};

export default CompanyGuideSettings;

import React from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";

import useGuideParts from "hooks/use-guide-parts";

import SubHeader from "components/SubHeader/SubHeader";
import GuideViewerToolbar from "./GuideViewerToolbar";
import GuideViewerRequirements from "./GuideViewerRequirements";
import GuideViewerRoutine from "./GuideViewerRoutine";
import GuideViewerSectionButtons from "./GuideViewerSectionButtons";
import RichText from "components/RichText/RichText";

import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg";

import { isGuideBlueprint } from "utils/guide";
import __ from "constants/static-texts";

const CLASS_NS = "guide-viewer-section";

const GuideViewerSection = ({ guideBlocks, guideMeta, classNs = CLASS_NS }) => {
  const params = useParams();

  const { allPartRoutes, currentSectionRoutes, activeSectionRoute } = useGuideParts();

  if (!params.partId && allPartRoutes.length) {
    return <Redirect to={`/guides/${params.guideId}/guide/${allPartRoutes[0].partId}`} />;
  }
  if (!params.sectionId && currentSectionRoutes.length) {
    return <Redirect to={`/guides/${params.guideId}/guide/${params.partId}/${currentSectionRoutes[0].sectionId}`} />;
  }

  const backLink = `/guides/${params.guideId}/guide-part-editor/${params.partId}`;

  const isIntroduction = activeSectionRoute?.isIntroduction;

  return (
    <div className={`${classNs}`} data-introduction={isIntroduction}>
      {isGuideBlueprint(guideMeta) ? (
        <div className={`${classNs}_blueprint`}>
          <div className={`${classNs}_blueprint_text`}>
            <NavLink to={backLink} className={`${classNs}_blueprint_text_back`}>
              <ArrowLeft />
              {__.guide.previewBack}
            </NavLink>
            {__.guide.preview}
          </div>
        </div>
      ) : null}
      {activeSectionRoute && activeSectionRoute.id === params.sectionId ? (
        <>
          <SubHeader
            ToolsComponent={<GuideViewerToolbar guideId={params.guideId} activeSection={activeSectionRoute} />}>
            <h1 className={`${classNs}_title`}>{activeSectionRoute.label}</h1>
            <RichText nodes={activeSectionRoute.description} headingLevel={2} />
          </SubHeader>
          {!isIntroduction ? (
            <>
              <GuideViewerRoutine section={activeSectionRoute} guideId={params.guideId} />
              {guideBlocks ? <GuideViewerRequirements /> : null}
            </>
          ) : null}
        </>
      ) : (
        <SubHeader>
          <h1 className={`${classNs}_title`}>Avsnitt saknas</h1>
        </SubHeader>
      )}
      <GuideViewerSectionButtons />
    </div>
  );
};

export default GuideViewerSection;

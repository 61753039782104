import React from "react";
import ReactDOM from "react-dom";
// import App from './App';
// import store from './app/store';
// import { Provider } from 'react-redux';
// import * as serviceWorker from "./serviceWorker";
import AppContainer from "./containers/app-container";
import { store, userManager, history } from "./store";
import "./styles/index.scss";

import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apollo/client";

import { loadCookieBot } from "utils/load-cookiebot.js";

const routes = require("./routes/index").default;

if (process.env.NODE_ENV === "production") {
  loadCookieBot(() => {});
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AppContainer history={history} routes={routes} store={store} userManager={userManager} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentNonValidatedUser } from "actions/non-validated-users-actions";

import { WAITING, LOADING } from "constants/network-states";

/**
 * Provides all non validated users
 */
const useCurrentNonValidatedUser = () => {
  const dispatch = useDispatch();
  const { currentNonValidatedUser, currentNonValidatedUserNetworkState } = useSelector(state => state.nonValidatedUsersState);
  const { user: oidcUser } = useSelector((state) => state.oidc);
  const { loggedInUserNetworkState } = useSelector(state => state.loggedInUserState);

  const fetchCurrentNonValidatedUsers = () => {
    currentNonValidatedUserNetworkState === WAITING && dispatch(getCurrentNonValidatedUser());
  };

  const currentNonValidatedUserIsLoading = 
    currentNonValidatedUserNetworkState === WAITING ||
    currentNonValidatedUserNetworkState === LOADING;

  useEffect(() => {
    // only call the endpoint if state doesn't contain the current non validated user
    // the current non validated user state should already be up to date via the logged in user hook
     if (oidcUser &&
        (currentNonValidatedUserNetworkState !== WAITING || currentNonValidatedUserNetworkState !== LOADING) &&
        !currentNonValidatedUser) {
        fetchCurrentNonValidatedUsers();
    }
  }, [oidcUser, loggedInUserNetworkState, currentNonValidatedUserNetworkState, currentNonValidatedUser]);  // eslint-disable-line react-hooks/exhaustive-deps

  return { currentNonValidatedUserIsLoading, currentNonValidatedUser };
};

export default useCurrentNonValidatedUser;

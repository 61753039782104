import React from "react";
import { useDispatch } from "react-redux";

import useLibraryDocuments from "hooks/use-library-documents";
import useCompanyDocuments from "hooks/use-company-documents";
import useCompanies from "hooks/use-companies";
import useLoggedInUser from "hooks/use-logged-in-user";

import { addCompanyDocument, deleteCompanyDocument } from "actions/companydocuments-actions";

import { guideSectionTypes } from "constants/guide-types";
import __ from "constants/static-texts";

import DownloadDocumentButton from "components/DownloadDocumentButton/DownloadDocumentButton";
import Loading from "components/Loading/Loading";
import UploadFiles from "components/UploadFiles/UploadFiles";

const CLASS_NS = "guide-viewer-response-slot";

const GuideViewerResponseSlot = ({ responseSlot, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { companyDocumentsMap } = useCompanyDocuments();
  const [, , isLoading, [filteredDocuments]] = useLibraryDocuments(responseSlot.libraryDocumentId);
  const { isCompanyReader, isCompanyAdmin, isCompanyEditor } = useLoggedInUser();
  const { activeCompany } = useCompanies();
  const uploadDocument = (inputs) => {
    return dispatch(
      addCompanyDocument({
        ...inputs,
        responseSlotId: responseSlot.id.replace(`${guideSectionTypes.GUIDE_RESPONSE_SLOT}_`, ""),
        companyId: activeCompany.id,
        surpressStatusMessage: true,
      })
    );
  };

  const deleteDocument = (id) => {
    dispatch(deleteCompanyDocument({ id, responseSlotId: responseSlot.id }));
  };

  const companyDocs = responseSlot.companyDocumentIds.map(
    (companyDocumentId, index) => companyDocumentsMap[companyDocumentId]
  );

  if(isCompanyReader && !companyDocs.length) return null;

  return (
    !isLoading ? (
        <div className={classNs}>
          {!isCompanyReader && 
              <div className={`${classNs}_libdocs`}>
              {filteredDocuments ? 
                (<>
                  <DownloadDocumentButton key={filteredDocuments.id} document={{ ...filteredDocuments, label: filteredDocuments.title }}>
                    {`${filteredDocuments.title}`}
                  </DownloadDocumentButton>
                  <p>{`${filteredDocuments.description}`}</p>
                </>
                ) : (
                <p>{__.document.library_document_not_found}</p>
                )}
              </div>
          }
          <UploadFiles
            className={`${classNs}_content_doc`}
            files={companyDocs}
            canUploadAndEdit={(isCompanyEditor || isCompanyAdmin) && responseSlot.hasUploadSlot}
            deleteDocument={deleteDocument}
            uploadDocument={uploadDocument}
            canEditVisibilty={!isCompanyEditor}
            hideUploadedFileTitle={true}
            />
        </div>
    ) : (
      <Loading />
    )
  );
};

export default GuideViewerResponseSlot;

import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { deleteSectionModule, repositionSectionModule } from '../../actions/section-actions';

import { blockPropTypes } from '../../constants/reused-proptypes';
import { getHashmapDepth } from '../../utils/section';
import { hasIdInParams, getUrlForModuleId } from '../../utils/navigation';
import { blockTypeDefinitions, blockTypes } from '../../constants/block-types';
import IconsContext from '../../contexts/icon-context';
import { TEMPID_KEY } from '../../constants/reused-initial-states';
import RichText from '../module-richtext-renderer/module-richtext-renderer';
import { DeleteProcess } from 'react-draft-editor';

const CLASS_NS = 'block';
/*
 * A router aware component which handels its own open/closed state according to url changes
 * Contorl the layout of extraComponents by flexbox order parameter.
 */
// TODO: Rename to BlockWrapper when old one is removed
// TODO: Move error behaviour and navigation to parent component

const Block = ({
  classNs = CLASS_NS,
  block,
  showBackgroundOverlay = true,
  onOpenWrapper = () => {},
  onCloseWrapper = () => {},
  onWrapperUnmount = () => {},

  disabled = false,
  hasError = false,
  isSubmitted = false,

  togglerComponents = [],
  nonExpandedComponents = [],
  displayHeaderControlsInline = false,
  children
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isMounted = useRef();
  const isNew = useRef();
  const containerRef = useRef(null);
  const buttonsBarRef = useRef(null);
  const { blocks } = useSelector((state) => state.sectionState);
  const blockDepth = getHashmapDepth(block.id, blocks);
  const { search } = window.location;
  const icons = useContext(IconsContext);
  const getUrlForParentModule = () => getUrlForModuleId(block.parentId, blocks); // blockDepth is not going to be less than 1
  const getUrlForModule = useCallback(
    () => getUrlForModuleId(block.id, blocks),
    [block.id, blocks]
  );

  useEffect(() => {
    if (block?.[TEMPID_KEY]) {
      // TODO: Find a more robust way of determining if a block is newly created.
      isNew.current = true;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // mount
    return () => {
      // unmount
      onWrapperUnmount();
    };
  }, [onWrapperUnmount]);

  useEffect(() => {
    setIsExpanded(hasIdInParams(block.id)); // weak comporison as param and id might be of type Number or String
  }, [search, block.id]);

  useEffect(() => {
    // Effects run on mount so this negates the problem.
    if (!isMounted.current) {
      isMounted.current = true;
      if (isNew.current === true) {
        isNew.current = false;
        history.push(getUrlForModule()); // Change url to open new block
      }
      return;
    }

    isExpanded ? onOpenWrapper() : onCloseWrapper();
  }, [isExpanded, onOpenWrapper, onCloseWrapper, getUrlForModule, history]);

  useEffect(() => {
    if (hasError && isSubmitted) {
      // submison attempted with errors
      this.navigateToModuleUrl(true);
    }
  }, [hasError, isSubmitted]);

  const onLinkClick = (e) => {
    e.stopPropagation(); // Prevents the details element from swallowing the event.
    // Validity check
    if (!block.isValid && isExpanded) {
      e.preventDefault();
    }
  };
  const onDeleteBlock = useCallback(
    (e) => {
      dispatch(deleteSectionModule(block.id));
    },
    [block.id, dispatch]
  );

  const confirmDelete = (e) => {
    e.preventDefault();
    setShowDelete(true);
  };

  const onMoveModule = useCallback(
    (dir, e) => {
      e.preventDefault();
      e.stopPropagation();
      const nuIndex = dir === 'up' ? block.index - 1 : block.index - 0 + 1;
      dispatch(repositionSectionModule(block.id, nuIndex));
    },
    [block.id, block.index, dispatch]
  );

  const blockDescription = useMemo(() => {
    let description = '';
    try {
      const blockTypeKey = Object.entries(blockTypes).reduce(
        (acc, [k, v]) => (acc = v === block.type ? k : acc),
        ''
      );
      description =
        block?.description ?? (blockTypeKey ? blockTypeDefinitions[blockTypeKey]?.label : '');
    } catch (e) {
      console.error(`Couldn't determine block title for ${block.id} : ${e}`);
    } finally {
      return description;
    }
  }, [block]);

  const getHeaderBarButton = useCallback(
    (icon, onClickHandler, type, attr = {}) => {
      const IconComponent = typeof icon === 'object' ? icon : null;
      return (
        <button
          key={`${block.id}-${type}`}
          className={`${classNs}_block_header-bar_button`}
          onClick={onClickHandler}
          dangerouslySetInnerHTML={IconComponent ? null : { __html: icon }}
          data-type={type}
          {...attr}>
          {IconComponent && <IconComponent />}
        </button>
      );
    },
    [classNs, block.id]
  );
  const getTrashButton = useCallback(
    (attr) => {
      //const TrashButton = getHeaderBarButton(icons?.block?.delete, () => {setShowDelete(true)}, 'delete', attr);
      return (
        <>
          {getHeaderBarButton(icons?.block?.delete, confirmDelete, 'delete', attr)}
          <DeleteProcess
            onCancel={() => {
              setShowDelete(false);
            }}
            onDelete={onDeleteBlock}
            open={showDelete}
          />
        </>
      );
    },
    [icons?.block?.delete, getHeaderBarButton, onDeleteBlock, showDelete]
  );
  const getMoveUpButton = useCallback(
    (attr) =>
      getHeaderBarButton(icons?.block?.move_up, onMoveModule.bind(null, 'up'), 'moveup', attr),
    [icons?.block?.move_up, getHeaderBarButton, onMoveModule]
  );
  // TODO: Figure out how to disable the bottom button if last
  const getMoveDownButton = useCallback(
    (attr) =>
      getHeaderBarButton(
        icons?.block?.move_down,
        onMoveModule.bind(null, 'down'),
        'movedown',
        attr
      ),
    [icons?.block?.move_down, getHeaderBarButton, onMoveModule]
  );
  const getAllHeaderBarButtons = (index) => [
    getTrashButton(),
    getMoveUpButton({ disabled: index === 0 }),
    getMoveDownButton()
  ];

  const getExtraComponentProps = () => ({
    block: block,
    classNs: classNs
  });

  const getExtraComponent = (Component, index) => {
    // const extraProps = getExtraComponentProps();
    if (Component.type) {
      const ExtraComponent = Component.type;
      return <ExtraComponent {...getExtraComponentProps()} {...Component.props} key={index} />;
    } else {
      return <Component {...getExtraComponentProps()} key={index} />;
    }
  };

  return (
    <section
      id={block.id}
      className={`${classNs}_block`}
      ref={containerRef}
      data-active={isExpanded}
      data-depth={blockDepth}
      data-visible={block.isVisible}>
      {showBackgroundOverlay && blockDepth < 3 ? (
        <Link
          to={getUrlForParentModule()}
          className={`${classNs}_block_background`}
          disabled={disabled} // When would this be applicable? -LR
          data-active={isExpanded}
          onClick={onLinkClick}></Link>
      ) : null}
      <details
        className={`${classNs}_block_wrapper`}
        open={isExpanded}
        onClick={(e) => e.preventDefault()}>
        <summary className={`${classNs}_block_header-bar`} key={`summary-${block.id}`}>
          {!displayHeaderControlsInline && (
            <div className={`${classNs}_block_header-bar_buttons`} ref={buttonsBarRef}>
              <Link
                to={isExpanded ? getUrlForParentModule() : getUrlForModule()}
                className={`${classNs}_block_header-bar_link`}
                onClick={onLinkClick}
                data-type="title">
                {togglerComponents.map((Component, index) => getExtraComponent(Component, index))}
              </Link>
              {getAllHeaderBarButtons(block.index)}
            </div>
          )}
          {!isExpanded && blockDescription && (
            <span className={`${classNs}_block_header-bar_description`}>
              <RichText nodes={blockDescription} headingLevel={2} />
            </span>
          )}
          {(!isExpanded || isNew) &&
            nonExpandedComponents.map((Component, index) => [
              getExtraComponent(Component, index),
              displayHeaderControlsInline ? (
                <div className={`${classNs}_block_header-bar_buttons_inline`}>
                  {getAllHeaderBarButtons(index)}
                </div>
              ) : null
            ])}
        </summary>

        {/*Swallowing the bubbling event here as the details element interferes with for instance radio button state.*/}

        <div className={`${classNs}_block_content`} onClick={(e) => e.stopPropagation()}>
          {displayHeaderControlsInline
            ? React.Children.forEach((child) => {
                return [
                  child,
                  <div className={`${classNs}_block_header-bar_buttons_inline`}>
                    {getAllHeaderBarButtons(child.index)}
                  </div>
                ];
              })
            : children}
        </div>
      </details>
    </section>
  );
};

Block.propTypes = {
  classNs: PropTypes.string,
  block: blockPropTypes,
  showBackgroundOverlay: PropTypes.bool,
  disabled: PropTypes.bool,
  onOpenWrapper: PropTypes.func,
  onCloseWrapper: PropTypes.func,
  // onMoveModule: PropTypes.func,
  // onDeleteBlock: PropTypes.func,
  onWrapperUnmount: PropTypes.func,
  extraComponents: PropTypes.array,

  isSubmitted: PropTypes.bool,
  hasError: PropTypes.bool
};

export default Block;

import { statusMessage } from "actions/layout-actions";
import * as interceptors from "api/interceptors";
import { LOADING, ERROR, SUCCESS, NOT_FOUND, FORBIDDEN } from "constants/network-states";
import { getMessage } from "utils/message";

// Based upon https://medium.com/@machadogj/async-action-creators-with-redux-thunk-83af81994250
export const createActionSequence = (type, apiFn, callback, actionCreatorCallbacks, messagesDisabledFor) => {
  return (...args) =>
    async (dispatch, getState) => {
      const params = args.length > 0 ? args[0] : {};

      dispatch({
        type: `${type}_${LOADING}`,
        params: params,
      });

      let result;
      try {
        result = await apiFn(...args, getState);

        dispatch({
          type: `${type}_${SUCCESS}`,
          payload: result,
          params: params,
        });

        callback && callback(result, params, dispatch);
        actionCreatorCallbacks && actionCreatorCallbacks.length && actionCreatorCallbacks.forEach(actionCallBack => {
          dispatch(actionCallBack(result, params));
        }); 
          

        return result;
      } catch (error) {
        console.error("Error in createActionSequence : ", error);

        const errorType = interceptors.responseErrorInterceptor(error);
        const errorCode = error.status;

        if (errorCode === 404) {
          dispatch({
            type: `${type}_${NOT_FOUND}`,
            payload: error,
            params: params,
            errorType: errorType,
          });
        } else if(errorCode === 403) {
          dispatch({
            type: `${type}_${FORBIDDEN}`,
            payload: error,
            params: params,
            errorType: errorType,
          });
        } else {
          dispatch({
            type: `${type}_${ERROR}`,
            payload: error,
            params: params,
            errorType: errorType,
          });
        }

        if (params?.surpressStatusMessage) {
          return;
        }
        // Optional according to project
        const defaultError = errorCode === 400 ? await error.json() : null;
        const errorMessage = getMessage(errorCode, defaultError);
        messagesDisabledFor ? !messagesDisabledFor.includes(errorCode) && dispatch(
          statusMessage({
            keepOpen: true,
            errorType: errorType,
            ...errorMessage,
          })
        ) : dispatch(
          statusMessage({
            keepOpen: true,
            errorType: errorType,
            ...errorMessage,
          })
        );
      }
    };
};
import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconCross } from './cross.svg';
import { ReactComponent as IconCheck } from './check.svg';

import './_overlay.scss';

const CLASS_NS = 'overlay';

// TODO: set open to default false when all implementations of Overlay have taken open param into use
export const Overlay = ({
  classNs = CLASS_NS,
  children,
  cancelUrl,
  cancelClick,
  disableScroll = false,
  open = true,
  status,
  message
}) => {
  const innerEl = useRef(null);
  const progressbarEl = useRef(null);
  const animation = useRef(null);
  const history = useHistory();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarStatus, setSnackbarStatus] = useState('');

  // Use status and message props to signal state of external process, for example uploading a file.
  useEffect(() => {
    if (status && status !== 'done') {
      setSnackbarStatus(status);
      setSnackbarMessage(message);
    }

    if (status === 'loading') {
      animation.current.updatePlaybackRate(0.25);
      animation.current.play();
    }

    if (status === 'done') {
      animation.current.updatePlaybackRate(8);
      animation.current.finished.then(() => {
        setSnackbarStatus(status);
        setSnackbarMessage(message);
        // Listen to event in other components to update states after animation is done
        document.dispatchEvent(
          new CustomEvent('OverlaySnackbarMessage', {
            detail: {
              animationDone: true
            }
          })
        );
      });
    }

    if (status === 'error') {
      animation.current.cancel();
    }
  }, [status, message]);

  // Overlay switches open state depending on incoming open prop,
  // calling showModal to be able to use ::backdrop pseudo selector.
  // https://developer.mozilla.org/en-US/docs/Web/CSS/::backdrop
  useEffect(() => {
    if (open) {
      innerEl.current.showModal();
      setSnackbarStatus('');
      setSnackbarMessage('');
      document.dispatchEvent(
        new CustomEvent('OverlaySnackbarMessage', {
          detail: {
            animationDone: false
          }
        })
      );
    }
    !open && innerEl.current.close();
  }, [open]);

  // Overlay listens to keydown event to be able to trigger incoming cancelClick
  // which in turn should set the open prop to false.
  const close = (e) => {
    if (
      e &&
      ((e.type === 'keydown' && e.key !== 'Escape') ||
        (e.type === 'click' && e.target.className !== 'overlay_wrapper'))
    )
      return;
    if (cancelClick) {
      document.dispatchEvent(
        new CustomEvent('OverlaySnackbarMessage', {
          detail: {
            animationDone: false
          }
        })
      );
      cancelClick();
    } else {
      history.push(cancelUrl, { disableScroll });
    }
  };

  useEffect(() => {
    if (innerEl) {
      innerEl.current.focus();
    }
    if (progressbarEl) {
      animation.current = progressbarEl.current.animate(
        [
          {
            width: '0%'
          },
          {
            width: '100%'
          }
        ],
        5000
      );
      animation.current.pause();
    }

    document.addEventListener('keydown', close);
    document.addEventListener('click', close);

    return () => {
      document.removeEventListener('keydown', close);
      document.removeEventListener('click', close);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <dialog className={`${classNs}_wrapper`} tabIndex="0" ref={innerEl}>
        <div className={`${classNs}_form`}>{children}</div>
        {snackbarMessage && (
          <output className={`${classNs}_status_snackbar`} data-status={snackbarStatus}>
            {snackbarStatus === 'done' && <IconCheck />}
            {snackbarStatus === 'error' && <IconCross />}
            {snackbarMessage}
          </output>
        )}
        <div
          className={`${classNs}_progressbar`}
          ref={progressbarEl}
          data-status={snackbarStatus}
        ></div>
      </dialog>
    </>
  );
};

import Companies from "./components/Companies";
import CompanyGuideSettings from "./components/CompanyGuideSettings";

import { UserIsAuthenticated } from "utils/auth-helpers";

const rootPath = "/companies";

const CompaniesRoute = () => ({
  path: `${rootPath}`, // include the additional slug for testing current route
  component: UserIsAuthenticated(Companies),

  routes: [
    {
      path: `${rootPath}/:companyId/guide/:guideId?`,
      exact: true,
      component: CompanyGuideSettings,
    },
  ],
});

export default CompaniesRoute;

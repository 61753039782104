import * as types from "actions/action-types";
import * as companiesApi from "../api/companies-api";
import { createActionSequence } from "./action-helpers";

export const getCompanyUsers = createActionSequence("GET_COMPANY_USERS", companiesApi.getCompanyUsers);

export const getCompanies = createActionSequence("GET_COMPANIES", companiesApi.getCompanies);

export const addCompanyGuide = createActionSequence("ADD_COMPANY_GUIDE", companiesApi.addCompanyGuide);

export const setSearchQuery = (value) => ({
  type: types.SET_SEARCH_QUERY_COMPANIES,
  value,
});

export const setActiveCompany = (company) => ({
  type: types.SET_ACTIVE_COMPANY,
  company,
});

export const getSSOCompanyUsers = createActionSequence("GET_SSO_COMPANY_USERS", companiesApi.getSSOCompanyUsers);

export const resetSSOCompanyUsers = () => ({
  type: types.RESET_SSO_COMPANY_USERS,
});

export const updateCompanyinfo = createActionSequence("UPDATE_COMPANY_INFO", companiesApi.putCompanyInfo);

import React from "react";
import __ from "constants/static-texts";

const StartIntro = ({ classNs = "start_content", user, role, companyName }) => {
  return (
    <div className={`${classNs}_box`}>
      <h1 className={`${classNs}_title`}>{`${__.start.welcome} ${user?.firstName}`}!</h1>
      <p className={`${classNs}_preface`}>{`${__.start.welcomeText}`}</p>
      {__.start[role].map((text, index) => (
        <p key={index} className={`${classNs}_preface`}>{`${text}${index === 0 && companyName ? companyName : ''}`}</p>
      ))}
    </div>
  );
};

export default StartIntro;

import fetchClient from "fetch-client";

export const getNews = (getState) => {
  return fetchClient({
    path: `/news`,
    getState,
  });
};

export const addNewsItem = (data, getState) =>
  fetchClient({
    path: `/news`,
    getState,
    method: `POST`,
    data,
  });

export const updateNewsItem = (data, getState) =>
  fetchClient({
    path: `/news/${data.id}`,
    getState,
    method: "PUT",
    data: data,
  });

export const deleteNewsItem = (id, getState) =>
  fetchClient({
    path: `/news/${id}`,
    getState,
    method: `DELETE`,
    data: id,
  });

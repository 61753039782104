import ApolloClient from "apollo-boost";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

import introspectionQueryResultData from "./schema.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
const uri = `${process.env?.REACT_APP_COURSE_API_BASE}/wp/graphql`;

const client = new ApolloClient({
  uri,
  cache,
});

export default client;

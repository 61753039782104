import React from "react";
import useGuideRequirements from "hooks/use-guide-requirements";
import GuideViewerRequirement from "./GuideViewerRequirement";
import __ from "constants/static-texts";
import useLoggedInUser from "hooks/use-logged-in-user";

const CLASS_NS = "guide-viewer-requirements";

const GuideViewerRequirements = ({ classNs = CLASS_NS }) => {
  const { guideMainRequirements } = useGuideRequirements();
  const { isCompanyReader } = useLoggedInUser();

  return (
    <div className={classNs}>
      <h2 className={`${classNs}_title`}>
        {guideMainRequirements.length === 0
          ? __.guide.requirement.requirement_missing
          : isCompanyReader
          ? __.guide.requirement.requirement_viewer_title
          : __.guide.requirement.requirement_title}
      </h2>
      {guideMainRequirements.length ? 
        guideMainRequirements.map((mainRequirement) => {
          return (
            <>
              <h3 className={`${classNs}_main_title`} key={guideMainRequirements.id}>{mainRequirement.title}</h3>
              {mainRequirement.requirements.map((requirement, index) => <GuideViewerRequirement requirement={requirement} index={index + 1} key={requirement.id} />)}
            </>
          )
        })
      : null}
    </div>
  );
};

export default GuideViewerRequirements;

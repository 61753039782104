import * as types from "actions/action-types";

import { WAITING, LOADING, ERROR, SUCCESS } from "constants/network-states";

const initialState = {
  workbooks: null,
  workbooksNetworkState: WAITING,
  error: null,
};

const workbooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WORKBOOKS_LOADING:
      return {
        ...state,
        workbooksNetworkState: LOADING,
      };
    case types.GET_WORKBOOKS_SUCCESS:
      return {
        ...state,
        workbooks: action.payload,
        workbooksNetworkState: SUCCESS,
      };
    case types.GET_WORKBOOKS_ERROR:
      return {
        ...state,
        error: action.payload,
        workbooksNetworkState: ERROR,
      };
    default:
      return state;
  }
};

export default workbooksReducer;

import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import {
  Wrapper,
  Button,
  Menu,
  MenuItem,
  // openMenu,
  // closeMenu
} from "react-aria-menubutton";
// type Option = {
//   id: ?string | ?number,
//   value: string | number,
//   label: string,
// }
// type Props = {
//   options: Array<Option>,
//   selectedOption: Option,
//   onSelectedOption: (option: Option) => void,
//   onToggleMenu: () => void,
//   value: ?String,
//   classNs: ?String,
//   updateOnInit: ?Boolean,
//   direction: ?String,
// }
// type State = {
//   // selectedOption: Option,
//   isOpen: boolean,
//   isDisabled: boolean,
// }

const DIRECTIONS = ["vertical", "horizontal"];
export const selectorDirections = DIRECTIONS;
// const getSvgArrow = (className)=><svg className={className || 'svg-icon'} viewBox="0 0 100 100" width="9" height="6" aria-labelledby="title" xmlns="http://www.w3.org/2000/svg"><path d="M-.9 0h9L3.6 6z" fill="inherit"/></svg>
// const defaultOption: Option = { id: 0, value: 0, label: 'default' }
const defaultOption = { id: 0, value: 0, label: "default" };
const defaultPlaceholder = "Välj";
const ARROW_ICON_SVG =
  '<svg width="25" height="10" viewBox="0 0 20 10" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M17 2.267L10.267 9 3.429 2.267" stroke-width="2" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>';
const CLASS_NS = "Selector";
/**
 * Creates a dropdown menu with aria attributes and keystroke tabbing by
 * utilizing react-aria-menubutton package.
 * NB! Selection state is handled by the parent component.
 * @extends React
 */
const Selector = ({
  classNs = CLASS_NS,
  options = [defaultOption],
  selectedOption = null,
  placeholder = defaultPlaceholder,
  onSelectedOption = () => {},
  onToggleMenu = () => {},
  updateOnInit = false,
  direction = DIRECTIONS[0],
  isActive = false, //
  disabled = false,
  ArrowIcon = ARROW_ICON_SVG,
  name,
}) => {
  const optionsState = useRef(); // Needing to place an extra options "state" because the default options array was caught in the _getSelectedOptionFromValue function.
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    optionsState.current = options;
  }, [options, optionsState]);

  // recursive function for nested option groups
  const _getSelectedOptionFromValue = useCallback(
    (optionValue, optionsArray = optionsState.current) =>
      optionsArray.reduce((acc, option) => {
        // In case the option has an embedded option Array
        if (Object.values(option).some((item) => Array.isArray(item))) {
          // TODO: Retest this after current changes.
          const optGroupOptions = Object.values(option).filter((item) => Array.isArray(item))[0];
          return _getSelectedOptionFromValue(optionValue, optGroupOptions);
        } else if (option.value === optionValue) {
          acc = option;
        }
        return acc;
      }, {}),
    []
  );

  const _onSelectOption = useCallback(
    (value) => {
      if (isDisabled) {
        return false;
      }
      const selectedOption = _getSelectedOptionFromValue(value);
      // State is handled by the parent component
      onSelectedOption(selectedOption);
    },
    [isDisabled, onSelectedOption, _getSelectedOptionFromValue]
  );

  useEffect(() => {
    // Update parent component if the selected option is not the default
    updateOnInit && selectedOption !== defaultOption && _onSelectOption(selectedOption?.value); // Notify the parent
  }, [_onSelectOption, selectedOption, updateOnInit]);

  // Leave intact. Use this for rendering optiongroup.
  // const _renderOptions = (options, extra) => {
  //   return options.map((option, index) => {
  //     const optGroupNode = Object.values(option);
  //
  //     // If the option is an array then make an optiongroup recursively
  //     if (optGroupNode.some((item) => Array.isArray(item))) {
  //       const optGroupOptions = optGroupNode.filter((item) => Array.isArray(item));
  //
  //       return (
  //         <optgroup key={index} label={option.label} disabled={option.disabled}>
  //           {_renderOptions(optGroupOptions[0])}
  //         </optgroup>
  //       );
  //     } else {
  //       let disabledProp = {};
  //       if (option.disabled) {
  //         disabledProp.disabled = true;
  //       }
  //       return (
  //         <option key={index} value={option.value} {...disabledProp}>
  //           {option.label}
  //         </option>
  //       );
  //     }
  //   });
  // };
  const getArrowIcon = () =>
    typeof ArrowIcon === "object" ? (
      <ArrowIcon className={`${classNs}_icon`} />
    ) : (
      <span className={`${classNs}_icon`} dangerouslySetInnerHTML={{ __html: ArrowIcon }}></span>
    );

  return (
    <Wrapper
      onSelection={_onSelectOption}
      onMenuToggle={({ isOpen }) => {
        setIsOpen(isOpen);
        onToggleMenu(isOpen);
      }}
      tag="div"
      className={`${classNs}`}
      data-state-open={isOpen}
      data-state-disabled={isDisabled}
      data-state-active={isActive}>
      <Button className={`${classNs}_button`} disabled={isDisabled} data-disabled={isDisabled}>
        <span className={`${classNs}_label`}>{selectedOption?.label ?? placeholder}</span>
        {getArrowIcon()}
      </Button>
      <Menu className={`${classNs}_list`} tag="ul" data-direction={direction}>
        {options.map((item, index) => {
          return (
            <MenuItem
              key={index}
              className={`${classNs}_option`}
              value={item.value}
              tag="li"
              label={item.label}
              data-active={item.value === selectedOption?.value ? true : false}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Wrapper>
  );
};

const optionShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
});
Selector.propTypes = {
  classNs: PropTypes.string,
  options: PropTypes.arrayOf(optionShape),
  selectedOption: optionShape,
  placeholder: PropTypes.string,
  onSelectedOption: PropTypes.func,
  onToggleMenu: PropTypes.func,
  updateOnInit: PropTypes.bool,
  direction: PropTypes.oneOf(DIRECTIONS),
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  ArrowIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

export default Selector;

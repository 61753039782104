import PropTypes from 'prop-types';

export const blockPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  parentId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]), // Isn't required as the top node is null
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  sections: PropTypes.array // Isn't required as for leaf nodes this is null
});

export const modulePropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  parentId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  type: PropTypes.string,
  index: PropTypes.number,
  richContent: PropTypes.string,
  commentRichContent: PropTypes.string,
  tableContent: PropTypes.string,
  images: PropTypes.array,
  files: PropTypes.array,
  displayAsExample: PropTypes.bool,
  isValid: PropTypes.bool
});

export const sectionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  index: PropTypes.number.isRequired,
  title: PropTypes.string
});

export const navigationSectionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  index: PropTypes.number.isRequired,
  label: PropTypes.string,
  link: PropTypes.string
});

import React from "react";
import { renderRoutes } from "react-router-config";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import __ from "constants/static-texts";
import ButtonLink from "components/ButtonLink/ButtonLink";
import SubHeader from "components/SubHeader/SubHeader";
import CalendarSection from "./CalendarSection";
import CalendarActivity from "./CalendarActivity";
import CalendarsRoutes from "routes/Calendars";
import { ReactComponent as Plus } from "icons/plus.svg";
import { ReactComponent as Download } from "icons/Download.svg";

import useCalendar from "hooks/use-calendar";
import useLoggedInUser from "hooks/use-logged-in-user";
import useTextByRole from "hooks/use-text-by-role";
import { printPartOfPage } from "utils/print-helpers";
import { getMonthFromISOWeek, months } from "utils/date-helpers";

const CLASS_NS = "calendar";

const Calendar = ({ route, classNs = CLASS_NS }) => {
  const { isCompanyReader } = useLoggedInUser();
  const params = useParams();
  const [calendar, calendarIsLoading] = useCalendar(parseInt(params.calendarId));

  const parseAnchorLink = () => {
    if (window.location.hash.includes("#month-")) {
      return parseInt(window.location.hash.replace("#month-", ""));
    } else {
      return null;
    }
  };

  const activeMonth = parseAnchorLink() ?? new Date().getMonth();

  const getActivities = (month) => {
    return (
      calendar.activities
        ?.filter((activity) => getMonthFromISOWeek(activity.weekNumber) === month)
        .sort((a, b) => a.weekNumber - b.weekNumber) || []
    );
  };

  const printCalendar = () => {
    printPartOfPage(
      document.getElementById("calendar"),
      `
      #calendar { 
        font-size: 14px;
      }

      [hidden] { 
        display: block; 
      }

      [data-hide-in-export] {
        display: none;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
        font-family: sans-serif;
      }

      h2 {
        border-bottom: 1px solid #ccc;
        font-size: 18px;
        padding: .4em;
        margin-bottom: 50px;
        text-align: center;
      }

      .calendar-section:not(:first-child) {
        margin-top: 50px;
      }

      h3 {
        font-size: 16px;
        display: inline;
      }

      h3::after {
        content: ' - ';
      }

      p {
        margin-top: 0;
        margin-bottom: 1em;
      }

      .calendar-item_heading-group {
        margin-bottom: .5em;
      }

      .calendar-item_sub-heading {
        font-family: sans-serif;
      }
	  .calendar-activity .button-link {
		background: transparent;
		border: none;
	  }
	  .calendar-activity .button-link svg {
		width: 16px;
	  }`
    );
  };

  const calendarTitle = useTextByRole(__.calendar.title);
  const calendarText = useTextByRole(__.calendar.text);

  return (
    <>
      <SubHeader>
        <h1 className={`${classNs}_title`}>{calendarTitle}</h1>
        <div className={`${classNs}_description`}>
          {calendarText.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </div>
      </SubHeader>
      <div className={classNs}>
        {!calendarIsLoading && (
          <>
            <div className={`${classNs}_actions`}>
              {!isCompanyReader && (
                <Link
                  to={`${CalendarsRoutes().path}/calendar/${params.calendarId}/activity/new/edit`}
                  className={`${classNs}_action`}>
                  <Plus />
                  {__.calendar.addActivity}
                </Link>
              )}
              <ButtonLink icon={<Download aria-hidden />} onClick={printCalendar}>
                {__.calendar.exportCalendar}
              </ButtonLink>
            </div>
            <div id="calendar">
              {months.map((month, i) => (
                <CalendarSection
                  id={`month-${i}`}
                  key={i}
                  heading={month}
                  initOpen={activeMonth === i && getActivities(i).length > 0}
                  region={getActivities(i).map((activity) => (
                    <CalendarActivity
                      key={activity.id}
                      activity={activity}
                      calendarId={params.calendarId}
                      heading={activity.title}
                      text={activity.description}
                    />
                  ))}
                />
              ))}
            </div>
          </>
        )}
        {!calendarIsLoading
          ? renderRoutes(route.routes, {
              cancelUrl: route.path,
            })
          : null}
      </div>
    </>
  );
};

export default Calendar;

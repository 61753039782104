export const SECTION = 'SECTION';
export const SUBSECTION = 'SUBSECTION';
export const DOWNLOAD = 'DOWNLOAD';
export const IMAGE = 'IMAGE';
export const RICHTEXT = 'RICHTEXT';
export const TIP = 'TIP';
export const HEADING = 'HEADING';
export const SUBHEADING = 'SUBHEADING';
export const EXTERNAL_LINK = 'EXTERNAL_LINK';
export const TABLE = 'TABLE';
export const EXAMPLE = 'EXAMPLE';

export const sectionBlockTypes = {
  SECTION: 'section',
  SUBSECTION: 'subsection'
};

export const uploadBlockTypes = {
  DOWNLOAD: 'download',
  IMAGE: 'image'
};

export const mediaBlockTypes = {
  RICHTEXT: 'richtext',
  ...uploadBlockTypes,
  TIP: 'tip',
  HEADING: 'heading',
  SUBHEADING: 'subheading',
  EXTERNAL_LINK: 'externalLink',
  TABLE: 'table',
  EXAMPLE: 'example'
};
export const blockTypes = {
  ...sectionBlockTypes, // This might not be needed.
  ...mediaBlockTypes
};

export const blockTypeDefinitions = {
  SECTION: {
    type: [mediaBlockTypes.SECTION],
    label: 'Sektion'
  },
  DOWNLOAD: {
    type: [blockTypes.DOWNLOAD],
    label: 'Nedladdning'
  },
  IMAGE: {
    type: [mediaBlockTypes.IMAGE],
    label: 'Bild'
  },
  RICHTEXT: {
    type: [mediaBlockTypes.RICHTEXT],
    label: 'Text'
  },
  TIP: {
    type: [mediaBlockTypes.TIP],
    label: 'Tips'
  },
  HEADING: {
    type: [mediaBlockTypes.HEADING],
    label: 'Rubrik'
  },
  SUBHEADING: {
    type: [mediaBlockTypes.SUBHEADING],
    label: 'Underrubrik'
  },
  EXTERNAL_LINK: {
    type: [mediaBlockTypes.EXTERNAL_LINK],
    label: 'Länk'
  },
  TABLE: {
    type: [mediaBlockTypes.TABLE],
    label: 'Tabell'
  },
  EXAMPLE: {
    type: [mediaBlockTypes.EXAMPLE],
    label: 'Exempel'
  }
};
// This is overridable.
// depths is 0-based. For example, a depth level of 2 makes the module available at levels 0, 1 and 2.
export const blocksSelectorDefault = [
  {
    key: blockTypes.SECTION,
    name: 'Rubrik',
    icon:
      '<svg width="94" height="94" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)" fill-rule="nonzero" fill="none"><path d="M46 28.83H31v8.58h2.15l1.55-3.11a2.15 2.15 0 0 1 1.92-1.19h7.26v24.2a2.15 2.15 0 0 1-1.47 2l-5 1.66v2.15h17.17V61l-5-1.66a2.15 2.15 0 0 1-1.47-2V33.13h7.29a2.15 2.15 0 0 1 1.92 1.19l1.55 3.11H61v-8.6H46z" fill="currentColor"/></g></svg>',
    depths: [0, 1]
  },
  {
    key: blockTypes.RICHTEXT,
    name: 'Text',
    icon:
      '<svg class="icon-text" width="94" height="94" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><g fill="currentColor"><path d="M63.75 33.5h-37.5a1.25 1.25 0 0 1 0-2.5h37.5a1.25 1.25 0 0 1 0 2.5zM53.75 41h-27.5a1.25 1.25 0 0 1 0-2.5h27.5a1.25 1.25 0 0 1 0 2.5zM63.75 48.5h-37.5a1.25 1.25 0 0 1 0-2.5h37.5a1.25 1.25 0 0 1 0 2.5zM53.75 56h-27.5a1.25 1.25 0 0 1 0-2.5h27.5a1.25 1.25 0 0 1 0 2.5zM63.75 63.5h-37.5a1.25 1.25 0 0 1 0-2.5h37.5a1.25 1.25 0 0 1 0 2.5z"/></g></g></svg>',
    depths: [0, 1, 2]
  }
];

import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers/index";
import { createUserManager, loadUser } from "redux-oidc";

import { oidcConfig } from "utils/auth-helpers";

const userManager = createUserManager(oidcConfig);

const history = createBrowserHistory();

// Add the capability to view Maps and other itterable objects in the Redux devtools
// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#windowdevtoolsextensionconfig
const enhancerOptions = {
  serialize: {
    options: true,
  },
};
const composeEnhancers = composeWithDevTools(enhancerOptions);

// Create a store by passing in the reducer
const store = createStore(
  reducers(history),
  composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware(history)))
);

loadUser(store, userManager);

export { store, userManager, history };

import React from "react";

import __ from "constants/static-texts";

import Supervisor from "components/Supervisor/Supervisor";
import { ReactComponent as ExternalLink } from "icons/External-Link.svg";

const CLASS_NS = "sub-footer";

const SubFooter = ({ classNs = CLASS_NS, ToolsComponent = null, children }) => {
  return (
    <div className={`${classNs}`}>
      <div className={`${classNs}-container`}>
        <div className={`${classNs}_columns`}>
          <Supervisor />
          <div className={`${classNs}_column`} data-col="middle">
            <h3>{__.footer.about.title}</h3>
            <div>{__.footer.about.text}</div>
            <a
              className={`${classNs}_anchor`}
              target="_blank"
              rel="noreferrer"
              href={process.env?.REACT_APP_NOT_LOGGED_IN_REDIRECT}>
              <ExternalLink />
              {__.footer.about.linkText}
            </a>
          </div>
          <div className={`${classNs}_column`} data-col="right">
            <h3>{__.footer.support.title2}</h3>
            <div className={`${classNs}_hours`}>{__.footer.support.hours}</div>
            <a className={`${classNs}_anchor`} data-type="large" href={`mailto:${__.footer.support.email}`}>
              {__.footer.support.email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubFooter;

import React from "react";
import __ from "constants/static-texts";
import LayoutColumns from "components/LayoutColumns/LayoutColumns";

const CLASS_NS = "form_overlay";

const EditCompanyDocumentDelete = ({ documentId, companyDocument, clickCancelDelete, onDeleteClick, onSaveClick }) => {
  const clickSubmitDelete = () => {
    onDeleteClick(parseInt(documentId));
    onSaveClick();
  };

  return (
    <div className={`${CLASS_NS}_delete`}>
      <h2>{__.document.deleteConfirmHeading}</h2>
      <div className={`${CLASS_NS}_form-row`}>{__.document.deleteConfirmText(companyDocument.title)}</div>
      <LayoutColumns>
        <button className={`button-inverted`} onClick={clickCancelDelete}>
          {__.document.deleteCancel}
        </button>
        <button className={`button-warning`} onClick={clickSubmitDelete}>
          {__.document.deleteConfirm}
        </button>
      </LayoutColumns>
    </div>
  );
};

export default EditCompanyDocumentDelete;
// Är du säker på att du vill ta bort kalendern?

import { useState, useCallback } from 'react';
// See https://css-tricks.com/form-validation-part-2-constraint-validation-api-javascript/
// Prevent the native error messages from displaying by seeting noValidate={true}
// HTML5 ValidityState API: https://developer.mozilla.org/en-us/docs/Web/API/ValidityState

/**
 * useValidate - Validation hook which processes the event and its ValidityState properties.
 * How to use:
 * 1. place on each input field the following attribites:
 * onBlur={validate}
 * required
 * noValidate={true}
 * 2. PLace the following element directly underneath the input element, changing the NAME_OF_INPUT_FIELD TO THE name of the input field:
 * <p className="input-error-message">{getErrorForField("NAME_OF_INPUT_FIELD")}</p>
 * 3.
 * In your onChange handler place the following code which changes the validation state if the user makes corrections:
  if (getErrorForField(e.target.name)) {
   validate(e);
 }
 * @param {object} errorMessages        HashMap of standard errors as keys and
 * developer defined error messages. Error messages can also be set directly on
 * the element node as data-* attributes. See below.
 * @param {function} validityActionCreatorFn optional custom action creator to send
 * off when error state changes.
 *
 * @returns {Array} Array functions and state [validate, getErrorForField, errorFields]
 */
const useValidate = (errorMessages, validityActionCreatorFn) => {
  const [errorFields, setErrorFields] = useState({});

  const validate = (event) => {
    const { validity, name, dataset, textLength, maxLength, minLength } = event.target;
    let errorMessage = '';

    if (!name) {
      console.error('A name property must be given to the field : ', event.target);
    } else if (!validity.valid) {
      let errorKeys = [];
      for (var key in validity) {
        if (validity[key]) {
          errorKeys.push(key);
        }
      }

      const specificErrorMessages = errorMessages[name] || {};
      const mergedErrorMessages = {
        ...errorMessages, // General error messages
        ...dataset, // error messages set directly on the element as data-* values. These should be converted from camelCase to kebab-case.Ex: valueMissing => data-value-missing
        ...specificErrorMessages // field specific error messages which overwrite the general messages
      };

      errorMessage = Object.entries(mergedErrorMessages)
        .filter(([k, v]) => errorKeys.includes(k))
        .reduce((acc, [k, v]) => (acc += v + ' '), ''); // TODO: Accommodate function error messages.
    } else {
      // TODO: Check for error message functions and use them in the first case.
      // When using react some validity fields don't work for some reason.
      // Search for validation related attributes on the DOM node and validate against those manually.
      if (maxLength >= 0 && maxLength < textLength) {
        errorMessage += errorMessages.tooLong;
      } else if (minLength >= 0 && minLength > textLength) {
        errorMessage += errorMessages.tooShort;
      }
    }

    setErrorFields({
      ...errorFields,
      [name]: errorMessage
    });

    return errorMessage;
  };

  const getErrorForField = (name) => (errorFields && errorFields[name]) || null;

  useCallback(() => {
    if (validityActionCreatorFn) {
      const hasErrors = Object.values(errorFields).some((errorMessage) => errorMessage);
      validityActionCreatorFn(!hasErrors);
    }
  }, [errorFields, validityActionCreatorFn]);

  return [validate, getErrorForField, errorFields];
};

export default useValidate;

import Fuse from "fuse.js";

export const searchArray = (list, attributes, searchQuery) => {
  const fuse = new Fuse(list, {
    isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: true,
    findAllMatches: true,
    // minMatchCharLength: 2,
    // location: 0,
    // threshold: 0.6,
    // distance: 0,
    useExtendedSearch: true,
    // ignoreLocation: true,
    // ignoreFieldNorm: false,
    keys: attributes,
  });

  // 'keyword | 'keyword2 | 'keyword3...
  const searchString = searchQuery.split(" ").reduce((acc, sr) => {
    acc = `${acc}'${sr} | `;
    return acc;
  }, "");
  const searchStringTrimmed = searchString.substr(0, searchString.length - 3);
  const filteredList = fuse.search(searchStringTrimmed).map((res) => res.item);

  return filteredList;
};

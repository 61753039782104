import * as usersApi from "../api/users-api";
import { createActionSequence } from "./action-helpers";
import * as types from "actions/action-types";
import { USER_TYPES } from "constants/user-types";

export const getUsers = createActionSequence("GET_USERS", usersApi.getUsers);
export const getUser = createActionSequence("GET_USER", usersApi.getUser);
export const addUser = createActionSequence("ADD_USER", usersApi.addUser);
export const deleteUser = createActionSequence("DELETE_USER", usersApi.deleteUser);
export const setSearchQueryUsers = (value) => ({
  type: types.SET_SEARCH_QUERY_USERS,
  value,
});

export const setSearchQuerySupervisors = (value) => ({
  type: types.SET_SEARCH_QUERY_SUPERVISORS,
  value,
});

export const updateUserRole = createActionSequence("UPDATE_USER_ROLE", usersApi.updateUserRole);

export const updateUserDetails = createActionSequence("UPDATE_USER_DETAILS", usersApi.updateUserDetails);

export const updateUserProfileImage = createActionSequence(
  "UPDATE_USER_PROFILE_IMAGE",
  usersApi.updateUserProfileImage
);

export const addUsers = (newUsersNotExist, data, dispatch) => {
  return newUsersNotExist.map((user) => {
    return dispatch(
      addUser({
        role: USER_TYPES.COMPANYADMIN.id,
        userName: user.value,
        firstName: user.firstName,
        lastName: user.lastName,
        companyId: data.companyId,
      })
    );
  });
};

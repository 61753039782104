import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyDocument } from "actions/companydocuments-actions";

import { WAITING, LOADING } from "constants/network-states";

/**
 * Provides a document by id
 */
const useCompanyDocument = (id) => {
  const dispatch = useDispatch();
  const { companyDocument, companyDocumentNetworkState } = useSelector((state) => state.companyDocsState);

  const fetchCompanyDocument = () => {
    if (companyDocument?.id !== id || (!companyDocument === 0 && companyDocumentNetworkState === WAITING))
      dispatch(getCompanyDocument(id));
  };

  const companyDocumentIsLoading = companyDocumentNetworkState === WAITING || companyDocumentNetworkState === LOADING;

  useEffect(() => {
    fetchCompanyDocument();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { companyDocument, companyDocumentIsLoading };
};

export default useCompanyDocument;

import useNotifications from "hooks/use-notifications";
import { notificationTypes } from "constants/notification-types";

const useTodos = () => {
  const { notifications, notificationsIsLoading } = useNotifications();

  const filterdNotificationsObject = notifications
    .filter(
      (notification) =>
        notification.type === notificationTypes.SECTION_ASSIGNED ||
        notification.type === notificationTypes.REQUIREMENT_ASSIGNED ||
        notification.type === notificationTypes.SECTION_ASSIGNED_DATE_CHANGED ||
        notification.type === notificationTypes.REQUIREMENT_ASSIGNED_DATE_CHANGED
    )
    .reduce((acc, todo) => {
      const detailsKey =
        todo.type === notificationTypes.SECTION_ASSIGNED ||
        todo.type === notificationTypes.SECTION_ASSIGNED_DATE_CHANGED
          ? "section"
          : "requirement";

      if (!acc[`${detailsKey}_${todo.details[detailsKey].id}`]) {
        acc[`${detailsKey}_${todo.details[detailsKey].id}`] = todo;
      }

      return acc;
    }, {});

  const filterdNotifications = Object.values(filterdNotificationsObject).map((todo) => todo);

  return { assigned: filterdNotifications, assignedIsLoading: notificationsIsLoading };
};

export default useTodos;

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const isObject = (val) => {
  if (val === null || Array.isArray(val)) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
};

/**
 * Checks if the value is a Number.
 * For a longer discussion on the solution see: https://medium.com/javascript-in-plain-english/how-to-check-for-a-number-in-javascript-8d9024708153
 */
export const isNumber = (val) => Number.isFinite(val);

/**
 * isElement - Checks if the argument is a DOM element. from https://stackoverflow.com/a/36894871/7197027
 *
 * @param {object} element Argument to be tested
 *
 * @returns {Boolean} Result if the argument is a DOM element or not.
 */
export const isElement = (element) => element instanceof Element || element instanceof HTMLDocument;

/**
 * removeArrayDuplicates - Removes duplicates in an array by using the Set type conversion.
 *
 * @param {array} array Target array
 *
 * @returns {array} Array without duplicates
 */
export const removeArrayDuplicates = (array) => [...new Set(array)];

/**
 * createMapFromArray - Creates a object from an array with keys specified by the attribute
 *
 * @param {arr} array Target array with objects
 * * @param {attributeKey} string Which object key to use as key
 *
 * @returns {array} Array without duplicates
 */
export const createMapFromArray = (array, attributeKey = "id") =>
  array.reduce((acc, item) => {
    acc[item[attributeKey]] = item;
    return acc;
  }, {});

/**
 * removeObjectArrayDuplicates - Removes duplicates in an array by specified 'key' by using the Set type conversion.
 *
 * @param {arr} array Target array with objects
 * * @param {key} string Which object key to remove by
 *
 * @returns {array} Array without duplicates
 */
export const removeObjectArrayDuplicates = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const sortArray =
  (sortBy, ASC = true, toLower = false) =>
  (a, b) => {
    const asc = ASC ? 1 : -1;
    const aValue = toLower ? a[sortBy].toLowerCase() : a[sortBy];
    const bValue = toLower ? b[sortBy].toLowerCase() : b[sortBy];
    return aValue > bValue ? 1 * asc : aValue < bValue ? -1 * asc : 0;
  };

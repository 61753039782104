import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useNews from "hooks/use-news";
import useForm from "hooks/use-form";
import useProductTypes from "hooks/use-productTypes";

import { Overlay } from 'react-draft-editor';
import SearchableSelector from 'components/SearchableSelector/SearchableSelector';
import Loading from 'components/Loading/Loading';
import RichTextEditor, {
  RichTextEditorVariant,
  RichTextEditorInitValue,
  RichTextValueEmpty,
} from "components/RichTextEditor/RichTextEditor";
import ChoiceGroup from "components/ChoiceGroup/ChoiceGroup";

import { ReactComponent as TrashCan } from "icons/trash.svg";

import { addNewsItem, updateNewsItem, deleteNewsItem } from "actions/news-actions";

import __ from "constants/static-texts";
import ButtonLink from "components/ButtonLink/ButtonLink";

const CLASS_NS = "form_overlay";

const EditNews = ({ cancelUrl, classNs = CLASS_NS }) => {
  const dispatch = useDispatch();
  const { newsId } = useParams(); // id of selected news item
  const { news, newsIsLoading } = useNews();
  const { productTypes } = useProductTypes();
  const history = useHistory();
  const [showRemove, setShowRemove] = useState(false);
  const isNew = !newsId;
  const currentNewsItem = news.find((newsItem) => newsItem.id === newsId - 0);

  const prodTypesOptions = productTypes.map((pt) => ({ value: pt.id, label: pt.name }));

  const initialState = {
    title: currentNewsItem?.title || "",
    description: currentNewsItem // The text
      ? currentNewsItem?.description
      : RichTextEditorInitValue,
    products: prodTypesOptions.filter((pt) => currentNewsItem?.products.includes(pt.value)),
    isForManagementOnly: currentNewsItem?.isForManagementOnly,
  };

  const { inputs, handleChange } = useForm(
    // Some initial state, be sure to edit the obj with your own key reflecting the value.
    {
      ...initialState,
    }
  );
  //TODO: kolla ifal vi kan förenkla
  const renderTitle = showRemove
    ? `${__.news.removeNewsTitle}`
    : isNew
    ? `${__.news.createNewsTitle}`
    : `${__.news.editNewsTitle}`;

  const handleSubmit = (e) => {
    e.preventDefault();

    const newsData = {
      ...inputs,
      id: newsId,
      products: inputs.products.map((product) => product.value),
      description: inputs.description,
      isForManagementOnly: inputs.isForManagementOnly,
    };

    isNew
      ? dispatch(
          addNewsItem({
            ...newsData,
          })
        )
      : dispatch(
          updateNewsItem({
            ...newsData,
          })
        );

    history.push(cancelUrl, { disableScroll: true });
  };

  const handleCancel = () => {
    if (showRemove) {
      setShowRemove(false);
    } else {
      history.push(cancelUrl, { disableScroll: true });
    }
  };

  const handleDelete = () => {
    dispatch(deleteNewsItem(parseInt(newsId)));
    history.push(cancelUrl, { disableScroll: true });
  };

  const onAddItem = (data, actionMeta) => {
    handleChange({
      target: {
        value: data,
        name: actionMeta.name,
        type: actionMeta.action,
      },
    });
  };

  const changeNewsContent = (content) => {
    handleChange({
      target: {
        value: content,
        name: "description",
      },
    });
  };

  const submitDisabled = inputs.title === "" || RichTextValueEmpty(inputs.description) || inputs.products.length === 0;

  return (
    <Overlay cancelUrl={cancelUrl} disableScroll={true}>
      <h2>{renderTitle}</h2>
      {newsIsLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit} className={CLASS_NS}>
          {showRemove ? (
            <>
              <div className={`${classNs}_form-row`}>{__.news.confirmDeletion}</div>
            </>
          ) : (
            <>
              <label className={`${classNs}_form-row`} htmlFor="title">
                <span className="label">{__.news.titleLabel}</span>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder={__.news.placeholderTitle}
                  value={inputs.title}
                  onChange={handleChange}
                />
              </label>
              <SearchableSelector
                label={__.news.visibility}
                classNs={`${classNs}_form-row`}
                selectText={__.news.chooseProducts}
                onChange={onAddItem}
                canAdd={false}
                options={prodTypesOptions}
                missingOptions={() => __.news.noMatch}
                selectedOptions={inputs.products}
                name="products"
              />
              <RichTextEditor
                initValue={inputs.description}
                onChange={changeNewsContent}
                variant={RichTextEditorVariant.SECONDARY}
              />
              <div className={`${classNs}_form-row`}>
                <ChoiceGroup
                  onChange={handleChange}
                  chosen={inputs.isForManagementOnly ? "1" : "0"}
                  type="checkbox"
                  inversed={true}
                  groupName="isForManagementOnly"
                  choices={[
                    {
                      value: "1",
                      label: __.news.isForManagementOnly,
                      name: "isForManagementOnly",
                    },
                  ]}
                />
              </div>
            </>
          )}
          <div className="button-row">
            <button type="button" className="button-inverted" onClick={handleCancel}>
              {__.general.cancel}
            </button>
            {showRemove ? (
              <button type="button" className="button-warning" onClick={handleDelete}>
                {__.general.confirmDelete}
              </button>
            ) : (
              <button className="button" type="submit" disabled={submitDisabled}>
                {isNew ? __.news.createItem : __.news.editItemButton}
              </button>
            )}
          </div>
          {!showRemove && !isNew && (
            <ButtonLink
              icon={<TrashCan aria-hidden />}
              variant="action"
              size="small"
              alignment="center"
              onClick={() => setShowRemove(true)}>
              {__.news.removeItemLink}
            </ButtonLink>
          )}
        </form>
      )}
    </Overlay>
  );
};

export default EditNews;

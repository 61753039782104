import React, { useCallback, useEffect, useState } from "react";
import __ from "constants/static-texts";

import SupervisorListItem from "./SupervisorListItem";
import useCompanies from "hooks/use-companies";

function SupervisorList({ users, classNs, userRoles }) {
  const [sortedUsers, setSortedUsers] = useState(users);
  const [selecedSort, setSelectSort] = useState("");

  const sortListBy = useCallback((sortBy) => {
    setSelectSort(sortBy);
    switch (sortBy) {
      case __.table.name:
        setSortedUsers([...users.sort((a, b) => (a.firstName && a.firstName.localeCompare(b?.firstName)) || 1)]);

        break;
      case __.table.email:
        setSortedUsers([...users.sort((a, b) => a.userName.localeCompare(b.userName))]);
        break;
      case __.table.responsible:
        setSortedUsers([
          ...users.sort((a, b) =>
            a.companyIds.length < b.companyIds.length ? -1 : a.companyIds.length > b.companyIds.length ? 1 : 0
          ),
        ]);
        break;
      case __.table.assignable:
        setSortedUsers([
          ...users.sort((a, b) =>
            a.details?.isOpenForNewAssignments && !b.details?.isOpenForNewAssignments
              ? -1
              : !a.details?.isOpenForNewAssignments && b.details?.isOpenForNewAssignments
              ? 1
              : 0
          ),
        ]);
        break;
      default:
        console.log("No sorting of that kind");
    }
  }, [users]);
  const sort = (e) => {
    sortListBy(e.target.textContent);
  };

  useEffect(() => {
    if (selecedSort !== "") {
      sortListBy(selecedSort);
    } else setSortedUsers(users);
  }, [users, sortListBy, selecedSort]);

  const { companiesMap } = useCompanies();
  return (
    <ul className={`${classNs}_list`}>
      <li className={`${classNs}_list_item table-header`}>
        <div onClick={sort}>{__.table.name}</div>
        <div onClick={sort}>{__.table.email}</div>
        <div onClick={sort}>{__.table.responsible}</div>
        <div onClick={sort}>{__.table.assignable}</div>
        <div onClick={sort}>{__.table.editSupervisor}</div>
      </li>
      {sortedUsers.map((user) => (
          <SupervisorListItem
            key={user.id}
            user={user}
            classNs={classNs}
            userRoles={userRoles}
            companiesMap={companiesMap}
          />
        )
      )}
    </ul>
  );
}

export default SupervisorList;

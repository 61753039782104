import React from "react";
import PropTypes from "prop-types";
import { Text } from "slate";
import { Link } from "react-router-dom";
import escapeHtml from "escape-html";
import { isExternalLink } from "utils/link-helpers";
import DownloadDocumentButton from "components/DownloadDocumentButton/DownloadDocumentButton";
import TypographyDynamicHeading from "components/TypographyDynamicHeading/TypographyDynamicHeading";

const CLASS_NS = "rich-text";

const RichText = ({ classNs = CLASS_NS, headingLevel = 2, nodes = [], children }) => {
  /**
   * Since the api can return either plain text or a stringified slate object, we have to parse
   * and normalize the incoming response (in a very naive way, but it gets the job done).
   * @param {*} nodes
   * @returns array
   */
  const parseNodes = (nodes) => {
    if (Array.isArray(nodes)) {
      return nodes;
    } else if (typeof nodes === "string") {
      return nodes.charAt(0) === "[" && nodes.charAt(nodes.length - 1) === "]"
        ? JSON.parse(nodes)
        : [{ type: "paragraph", children: [{ text: nodes }] }];
    } else {
      return [{ type: "paragraph", children: [{ text: "" }] }];
    }
  };

  const RichTextNode = ({ node }) => {
    if (Text.isText(node)) {
      let inlineText = node.text;

      if (node.text === "\n") {
        return <br />;
      }

      if (node.italic) {
        inlineText = <em>{inlineText}</em>;
      }

      if (node.bold) {
        inlineText = <strong>{inlineText}</strong>;
      }

      if (node.underline) {
        inlineText = <u>{inlineText}</u>;
      }

      return inlineText;
    }

    switch (node.type) {
      case "line-break":
        return <br />;
      case "paragraph":
        return (
          <p>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </p>
        );
      case "list-ordered":
        return (
          <ol>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </ol>
        );
      case "list-unordered":
        return (
          <ul>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </ul>
        );
      case "list-item":
        return (
          <li>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </li>
        );
      case "heading1":
        return (
          <TypographyDynamicHeading level={headingLevel}>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </TypographyDynamicHeading>
        );
      case "heading2":
        return (
          <TypographyDynamicHeading level={headingLevel + 1}>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </TypographyDynamicHeading>
        );
      case "link":
        return isExternalLink(node.url) ? (
          <a href={escapeHtml(node.url)}>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </a>
        ) : (
          <Link to={node.url}>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </Link>
        );
      case "librarydocument":
        return (
          <DownloadDocumentButton document={node.document}>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </DownloadDocumentButton>
        );
      default:
        return (
          <span>
            {node.children.map((child, i) => (
              <RichTextNode key={i} node={child} />
            ))}
          </span>
        );
    }
  };

  return (
    <div className={classNs}>
      {parseNodes(nodes).map((node, i) => (
        <RichTextNode key={i} node={node} />
      ))}
      {children}
    </div>
  );
};

RichText.propTypes = {
  classNs: PropTypes.string,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
};

export default RichText;
